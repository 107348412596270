var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-form",
            { attrs: { size: "small" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.danjuHao") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "GlobalSetObj.PleaseEnterTheDanjuhao"
                              ),
                              rows: 4,
                              type: "textarea",
                            },
                            model: {
                              value: _vm.returnWaybillNumber,
                              callback: function ($$v) {
                                _vm.returnWaybillNumber = $$v
                              },
                              expression: "returnWaybillNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: _vm.$t("GlobalSetObj.Date") },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "end-placeholder": _vm.$t(
                                        "GlobalSetObj.endDate"
                                      ),
                                      "start-placeholder": _vm.$t(
                                        "GlobalSetObj.startDate"
                                      ),
                                      clearable: "",
                                      "range-separator": "~",
                                      type: "daterange",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.timeValue,
                                      callback: function ($$v) {
                                        _vm.timeValue = $$v
                                      },
                                      expression: "timeValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("collectionCenter.siteNum"),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "GlobalSetObj.pleaseInput"
                                      ),
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryForm.siteCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryForm, "siteCode", $$v)
                                      },
                                      expression: "queryForm.siteCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("collectionCenter.siteName"),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "GlobalSetObj.pleaseInput"
                                      ),
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryForm.siteName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryForm, "siteName", $$v)
                                      },
                                      expression: "queryForm.siteName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.activeName === "1" || _vm.activeName === "2"
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          model: {
                                            value: _vm.recycleStatus,
                                            callback: function ($$v) {
                                              _vm.recycleStatus = $$v
                                            },
                                            expression: "recycleStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: 1 } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "GlobalSetObj.Confirmed"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: 0 } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "GlobalSetObj.Unconfirmed"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.searchData },
                },
                [_vm._v(" " + _vm._s(_vm.$t("collectionCenter.search")) + " ")]
              ),
              _vm.activeName === "2"
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.confirmRecycling },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("GlobalSetObj.RecyclingConfirmation")) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-upload2",
                    size: "small",
                    type: "info",
                  },
                  on: { click: _vm.exportData },
                },
                [_vm._v(" " + _vm._s(_vm.$t("newOrder.export")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "small" },
                  on: { click: _vm.reset },
                },
                [_vm._v(" " + _vm._s(_vm.$t("collectionCenter.reset")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.changeTabs },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: { label: _vm.$t("GlobalSetObj.IReturned"), name: "1" },
                },
                [
                  _c("myReturn", {
                    ref: "myReturn",
                    attrs: { "query-form": _vm.queryForm },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: { label: _vm.$t("GlobalSetObj.IRecycled"), name: "2" },
                },
                [
                  _c("myRecycling", {
                    ref: "myRecycling",
                    attrs: { "query-form": _vm.queryForm },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.AdjustmentDetails"),
                    name: "4",
                  },
                },
                [
                  _c("adjustDetails", {
                    ref: "adjustDetails",
                    attrs: { "query-form": _vm.queryForm },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.OutflowDetails"),
                    name: "5",
                  },
                },
                [
                  _c("flowOutDetails", {
                    ref: "flowOutDetails",
                    attrs: { "query-form": _vm.queryForm },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.InflowDetails"),
                    name: "6",
                  },
                },
                [
                  _c("inflowDetails", {
                    ref: "inflowDetails",
                    attrs: { "query-form": _vm.queryForm },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }