<template>
  <div class="app-container">
    <div ref="searchForm" style="margin-bottom: 5px;" class="searchForm">
      <el-card class="box-card">
        <el-form
          ref="form" :model="queryForm" label-width="auto" size="small"
          label-position="top"
        >
          <div class="searchContainer">
            <el-row :gutter="20">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--付款状态-->
                  <el-form-item :label="$t('receivablePayable.PaymentStatus')">
                    <el-select
                      v-model="queryForm.params.payableStatus"
                      filterable
                      multiple
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in PAYABLE_STATUS" :key="item.value" :label="item.label"
                        :value="item.value"
                      />

                      <!--待核付-->
                      <!-- <el-option key="1" :label="$t('receivablePayable.PendingPayment1')" :value="0" /> -->
                      <!--已核付-->
                      <!-- <el-option key="2" :label="$t('receivablePayable.Paid1')" :value="1" /> -->
                      <!--部分核付-->
                      <!-- <el-option key="3" :label="$t('receivablePayable.PartialPayment')" :value="2" /> -->
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--付款主体-->
                  <el-form-item :label="$t('GlobalSetObj.SubjectPayment')">
                    <el-select
                      v-model="queryForm.params.providerId"
                      filterable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                      @change="providerPageChange()"
                    >
                      <el-option
                        v-for="item in providerList"
                        :key="item.id"
                        :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--网点-->
                  <el-form-item :label="$t('basicData.Outlets') + '：'">
                    <QuerySiteCmp
                      :val="queryForm.params.siteIds"
                      :clearable="true"
                      :multiple="true"
                      :multiple-limit="10"
                      :provider-id="queryForm.params.providerId"
                      @change="val => queryForm.params.siteIds = val"
                    />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <!--业务类型-->
                <div class="grid-content bg-purple">
                  <el-form-item :label="$t('collectionCenter.businessT')">
                    <el-select
                      v-model="queryForm.params.businessType"
                      filterable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <!--全部-->
                      <el-option key="0" :label="$t('basicData.all')" :value="null" />
                      <el-option
                        v-for="item in BUSINESS_TYPE"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                      <!--揽收-->
                      <!-- <el-option key="1" :label="$t('collectionCenter.lanshou')" :value="2" /> -->
                      <!--派送-->
                      <!-- <el-option key="2" :label="$t('collectionCenter.dispatch')" :value="3" /> -->
                      <!--转运-->
                      <!-- <el-option key="3" :label="$t('collectionCenter.turnTransport')" :value="1" /> -->
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <!--邮件发送状态-->
                <div class="grid-content bg-purple">
                  <el-form-item :label="$t('receivablePayable.MailSendingStatus')">
                    <el-select
                      v-model="queryForm.params.emailSendingStatus"
                      filterable
                      :placeholder="$t('receivablePayable.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <!--全部-->
                      <el-option :label="$t('basicData.all')" value="" />
                      <!--已发送-->
                      <el-option :label="$t('collectionCenter.sent')" :value="1" />
                      <!--未发送-->
                      <el-option :label="$t('collectionCenter.NotSent')" :value="2" />
                      <!--发送中-->
                      <el-option :label="$t('collectionCenter.InTransit')" :value="3" />
                      <!--发送失败-->
                      <el-option :label="$t('collectionCenter.SendFailure')" :value="4" />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--账单类型-->
                  <el-form-item :label="$t('collectionCenter.TypeBill')">
                    <el-select
                      v-model="queryForm.params.payableTypes" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%"
                      clearable
                    >
                      <el-option
                        v-for="item in PAYABLE_TYPE" :key="item.value" :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--账单时间-->
                  <el-form-item :label="$t('receivablePayable.BillingDate')">
                    <el-date-picker
                      v-model="queryForm.params.payableTimes"
                      style="width: 100%"
                      type="daterange"
                      :range-separator="$t('operationCenter.to')"
                      :start-placeholder="$t('operationCenter.startDate')"
                      :end-placeholder="$t('operationCenter.endDate')"
                      value-format="yyyy-MM-dd"
                    />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--是否存在文件-->
                  <el-form-item :label="$t('basicData.ExistenceDocuments')">
                    <el-select v-model="queryForm.params.haveFile" placeholder="请选择" style="width: 100%">
                      <el-option :label="$t('GlobalSetObj.all')" :value="0" />
                      <el-option :label="$t('GlobalSetObj.yes')" :value="1" />
                      <el-option :label="$t('GlobalSetObj.No')" :value="2" />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--是否请款-->
                  <el-form-item :label="$t('receivablePayable.areYouRequestingPayment')">
                    <el-select
                      v-model="queryForm.params.requestPaymentStatus " :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%"
                      clearable
                    >
                      <!--全部-->
                      <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
                      <!--是-->
                      <el-option key="2" :label="$t('GlobalSetObj.yes')" :value="1" />
                      <!--否-->
                      <el-option key="3" :label="$t('GlobalSetObj.No')" :value="0" />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--付款单号-->
                  <el-form-item :label="$t('receivablePayable.PaymentSlipNumber')" label-width="120px">
                    <template slot="label">
                      <el-select
                        v-model="typeNumber"
                        filterable
                        :placeholder="$t('operationCenter.PleaseSelect')"
                        clearable
                        style="width: 100%; height:  28px;"
                      >
                        <!--付款单号-->
                        <el-option :label="$t('receivablePayable.PaymentSlipNumber')" :value="1" />
                        <!--账单批次号-->
                        <el-option :label="$t('receivablePayable.BillingBatchNumber')" :value="2" />
                      </el-select>
                    </template>
                    <InputTextareaCmp
                      ref="inputTextareaRef" :val="searchNum" :max-length="200"
                      @input="(_, val) => searchNum = val"
                    />
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources()"
        >
          {{ $t('operationCenter.Procurar') }}
        </el-button>
      </el-card>
    </div>
    <el-card class="box-card">
      <div class='action-table' style="margin-bottom: 10px;">
        <el-row :gutter="10" type="flex" class="btnLeft">
          <el-button
            v-permit:remove="'btn:payableManagement:payableCollection:approved'"
            size="small"
            type="primary"
            @click="operationBtn(1)"
          >{{ $t('receivablePayable.Verification') }}</el-button>

          <!-- 发送账单 -->
          <el-button
            v-permit:remove="'btn:payableManagement:payableCollection:SendBill'"
            size="small"
            type="primary"
            :disabled="isSendBillBut"
            @click="onSendBill"
          >{{ $t('receivablePayable.SendBill') }}</el-button>

          <!--自定义栏目-->
          <el-button
            size="small"
            @click="customColumn()"
          >{{ $t('orderCenterCont.CustomizeListColumn') }}</el-button>

          <!-- 编辑文件 -->
          <el-button
            v-permit:remove="'btn:payableManagement:payableCollection:EditFile'"
            size="small"
            type="primary"
            :disabled="isSendBillBut"
            @click="openUpdateFile"
          >{{ $t('GlobalSetObj.edit') }}{{ $t('base.document') }}</el-button>

          <!-- 付款（含弹出窗） -->
          <BradescoCmp :select-data="selectData" @updatePage="getExaminePage" />

          <!--付款（Santander）-->
          <payment-santander :select-data="selectData" @updatePage="getExaminePage" />

          <!-- 费用统计 -->
          <TableStatisticiansCmp :table-data="tableData" />
        </el-row>

        <div class="btnRight">
          <!-- 导出账单文件 -->
          <el-button
            v-permit:remove="'btn:payableManagement:payableCollection:exportBillFile'" size="small" type="info"
            @click="onExportBillFile"
          >{{ $t('basicData.导出账单文件') }}<i class="el-icon-download el-icon--right" /></el-button>

          <!-- 导出 付款单信息 -->
          <el-button
            v-permit:remove="'btn:payableManagement:payableCollection:export'" size="small" type="info"
            @click="onExport"
          >{{ $t('CenterForSorting.export') }}{{ $t('receivablePayable.PaymentOrderInformation') }}<i class="el-icon-download el-icon--right" /></el-button>
        </div>
      </div>

      <div class="pending">
        <el-table
          ref="elTable"
          :key="tableKey"
          class="table-height"
          :data="tableData"
          :height="tableHeightRow.height"
          border
          row-key="idCopy"
          :tree-props="{ children: 'meetSiteBill', hasChildren: true }"
          :row-class-name="rowClass"
          @header-dragend="headerWidthChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :selectable="selectable"
          />
          <el-table-column
            v-for="item in currentColumns"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!--账单批次号-->
              <span v-if="item.prop === 'payableNumber'">
                <span v-if="scope.row.isParent === true" class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.payableNumber }}</span>
                <span v-else>{{ scope.row.billBatchNumber }}</span>
              </span>
              <div v-else-if="item.prop === 'businessType'">
                <!--揽收-->
                <span v-if="scope.row.businessType === 2">{{ $t('collectionCenter.lanshou') }}</span>
                <!--派送-->
                <span v-else-if="scope.row.businessType === 3">{{ $t('collectionCenter.dispatch') }}</span>
                <!--转运-->
                <span v-else-if="scope.row.businessType === 1">{{ $t('collectionCenter.turnTransport') }}</span>
              </div>
              <div v-else-if="item.prop === 'billFee'">
                <span v-if="scope.row.isParent === true">{{ scope.row.billFee }}</span>
                <span v-else>{{ scope.row.totalReceivable }}</span>
              </div>
              <div v-else-if="item.prop === 'createTime'">
                <span>{{ scope.row.createTime ? scope.row.createTime.replace('T', ' ') : '' }}</span>
              </div>
              <div v-else-if="item.prop === 'auditTime'">
                <span>{{ scope.row.auditTime ? scope.row.auditTime.replace('T', ' ') : '' }}</span>
              </div>
              <div v-else-if="item.prop === 'remark'">
                <div class="remarkStyle" @click="showImg($event)" v-html="scope.row.remark" />
              </div>

              <!--是否请款-->
              <div v-else-if="item.prop === 'requestPaymentStatus'">
                <el-tag v-if="scope.row.requestPaymentStatus === 1" type="success">{{ $t('GlobalSetObj.yes') }}</el-tag>
                <el-tag v-if="scope.row.requestPaymentStatus === 0" type="danger">{{ $t('GlobalSetObj.No') }}</el-tag>
              </div>

              <!-- 邮件发送状态 -->
              <div v-else-if="item.prop === 'emailSendingStatus'">
                <el-tag v-if="scope.row.emailSendingStatus === 1" type="success">{{ $t('collectionCenter.sent') }}</el-tag>
                <el-tag v-else-if="scope.row.emailSendingStatus === 2">{{ $t('collectionCenter.NotSent') }}</el-tag>
                <el-tag v-else-if="scope.row.emailSendingStatus === 3" type="info">{{ $t('collectionCenter.InTransit') }}</el-tag>
                <el-tag v-else-if="scope.row.emailSendingStatus === 4" type="danger">{{ $t('collectionCenter.SendFailure') }}</el-tag>
              </div>

              <!-- 文件 -->
              <div v-else-if="item.prop === 'emailSenderFileUrl'" style="display: flex;flex-wrap: wrap;">
                <el-link
                  v-for="(key, index) of scope.row.emailSenderFileUrlArr" :key="key" type="success"
                  :href="key" target="_blank" style="margin-right: 10px;"
                >file{{ index + 1 }}</el-link>
              </div>

              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('basicData.operate')"
            align="center"
            width="140"
            fixed="right"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.payableStatus === 0">
                <el-button
                  v-permit:remove="'btn:payableManagement:payableCollection:recall'"
                  type="danger"
                  size="mini"
                  @click="recall(scope.row)"
                >{{ $t('receivablePayable.withdraw') }}</el-button> <!--撤回-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getExamineCurrentPage"
            @size-change="getExamineSizePage"
          />
        </div>
      </div>
    </el-card>
    <!--选择栏目-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="showColumn"
      direction="ltr"
      custom-class="drawerClass"
      :title="$t('orderCenterCont.SelectColumn')"
      width="500px"
      @close="closeDrawer()"
    >
      <div style="padding: 20px;height: calc(100vh - 140px);overflow: auto" class="columnTree">
        <el-tree
          ref="elTree"
          :key="dragging"
          :data="columnList"
          :props="props"
          node-key="prop"
          default-expand-all
          check-on-click-node
          :default-checked-keys="checkedColumns"
          show-checkbox
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          @check="handleCheckChange"
          @node-drop="handleDrop"
        />
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>
    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('receivablePayable.ReconcileBatchDetails') + '-' + batchNumber"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      custom-class="drawerClass"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="80%"
    >
      <div>
        <batch-number-detail
          :key="batchNumber"
          :batch-number="batchNumber"
          :bill-batch-data="billBatchData"
          @closeDetail="closeDetail"
        />
      </div>
    </el-drawer>
    <!--付款-->
    <el-dialog
      :title="$t('receivablePayable.Verification')"
      :visible.sync="showCollection"
      width="80%"
    >
      <div>
        <collection-detail
          :key="collectionKey"
          :recharge-data="selectData[0]"
          :account-option="accountOption"
          @collectionUpdate="collectionUpdate"
        />
      </div>
    </el-dialog>
    <div class="imgs">
      <el-image-viewer v-if="imageViewerObj.showViewer" :on-close="closeViewer" :url-list="imageViewerObj.imgArr" />
    </div>

    <!-- 发送邮件确认 -->
    <el-dialog
      :title="$t('receivablePayable.SendEmailConfirmation')"
      :visible.sync="sendBillRow.isShow"
      width="500px"
    >
      <div>
        <EnvieUmEmailCmp
          v-if="sendBillRow.isShow"
          :row="sendBillRow.row"
          @close="onEnvieUmEmailClose"
        />
      </div>
    </el-dialog>

    <!-- 编辑文件 -->
    <UpdateFileCmp ref="updateFileRef" @update="getExaminePage" />
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import batchNumberDetail from './cmp/batchNumberDetail';
import BradescoCmp from './cmp/optionBut/BradescoCmp.vue';
import { apiProviderPage, apiSitePayableGetPage } from '@/api/finance/basicData';
import { accountPage } from '@/api/finance/partition';
import {
  apiCustomColumnTemplateInsert,
  apiCustomColumnTemplateList,
  apiCustomColumnTemplateUpdate,
  apiSitePayableWithdraw,
  apiSitePayableExport
} from '@/api/finance/receivables';
// import { getDistributionDotList } from '@/api/logisticsOrders';
import { PAYABLE_TYPE } from '@/views/finance/payableManage/feesPayable/utils.js';
import { cloneDeep } from 'lodash-es';
import { goResourceTaskList } from '@/utils/goExportList.js';
import UpdateFileCmp from './cmp/UpdateFileCmp.vue';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { exportFile, getFileUrl } from './utils';
import { BUSINESS_TYPE } from '@/views/finance/enums.js';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';
import { PAYABLE_STATUS } from './enums.js';

export default {
  name: 'PayableCollection',

  components: {
    batchNumberDetail,
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),
    'collection-detail': () => import('./cmp/collectionDetail'),
    EnvieUmEmailCmp: () => import('./cmp/EnvieUmEmailCmp.vue'),
    'payment-santander': () => import('./cmp/optionBut/paymentSantander.vue'),
    TableStatisticiansCmp: () => import('./cmp/TableStatisticiansCmp.vue'),
    UpdateFileCmp,
    QuerySiteCmp,
    BradescoCmp,
    InputTextareaCmp
  },

  mixins: [estimateTableHeight],
  data() {
    return {
      PAYABLE_TYPE, // 账单类型
      BUSINESS_TYPE, // 业务类型
      PAYABLE_STATUS, // 付款状态
      queryForm: {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10,
        params: {
          payableStatus: [], // 付款状态
          providerId: '', // 业务主体
          // siteId: '', // 网点
          siteIds: [], // 网点
          businessType: null,
          emailSendingStatus: '', // 邮件发送状态
          payableTypes: '', // 账单类型
          payableTimes: [], // 账单时间
          haveFile: 0, // 是否存在文件
          requestPaymentStatus: '', // 是否请款
          payableNumber: '', // 付款单号
          billBatchNumber: '', // 账单批次号
        }
      },
      // createUser: [],
      showDetail: false,
      tableData: [],
      total: 0,
      tableKey: new Date().getTime(),
      batchNumber: '',
      billBatchData: {},
      providerList: [],
      showReceivableManage: false,
      selectData: [],
      showCollection: false,
      collectionKey: new Date().getTime(),
      accountOption: [],
      imageViewerObj: {
        imgArr: [],
        showViewer: false
      },
      typeNumber: 1,
      searchNum: '', // 多行输入框
      // outletsList: [],
      currentColumns: [],
      showColumn: false,
      dragging: new Date().getTime(),
      props: {
        label: 'label',
        children: 'children'
      },
      checkedColumns: [],
      checkedSelectColumns: [],
      customColumnId: '',

      // 发送邮件
      sendBillRow: {
        isShow: false,
        row: {
          payableNumber: '', // 付款单号、对账批次号
          providerId: 0 // 供应商id
        }
      }
    };
  },
  computed: {
    columnList() {
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            // 付款单号、对账批次号
            { prop: 'payableNumber', label: this.$i18n.t('receivablePayable.PaymentSlipNumber') + '/' + this.$i18n.t('receivablePayable.ReconciliationBatchNumber'), width: 220, align: 'left', highlight: false, disabled: false },
            // 账单时间
            { prop: 'billingTime', label: this.$i18n.t('receivablePayable.BillingDate'), width: 290, highlight: false, disabled: false },
            // 账单类型
            { prop: 'payableTypeStr', label: this.$i18n.t('collectionCenter.TypeBill'), width: 200, align: 'center', highlight: false, disabled: false },
            // 实际收款人
            { prop: 'actualRecipients', label: this.$i18n.t('basicData.实际收款人'), width: 200, align: 'center', highlight: false, disabled: false },
            // 付款状态
            { prop: 'payableStatusStr', label: this.$i18n.t('receivablePayable.PaymentStatus'), width: 120, align: 'left', highlight: false, disabled: false },
            // 是否请款
            { prop: 'requestPaymentStatus', label: this.$i18n.t('receivablePayable.areYouRequestingPayment'), width: 120, align: 'left', highlight: false, disabled: false },
            // 付款详细
            { prop: 'paymentDetails', label: this.$i18n.t('receivablePayable.PaymentDetails'), width: 140, align: 'left', highlight: false, disabled: false },
            // 付款主体名称
            { prop: 'providerName', label: this.$i18n.t('receivablePayable.TheNameOfThePaymentEntity'), width: 160, align: 'left', highlight: false, disabled: false },
            // 网点名称
            { prop: 'siteName', label: this.$i18n.t('basicData.siteName'), width: 160, align: 'left', highlight: false, disabled: false },
            // 业务类型
            { prop: 'businessType', label: this.$i18n.t('collectionCenter.businessT'), width: 100, align: 'left', highlight: true, disabled: false },
            // 订单数量
            { prop: 'orderQty', label: this.$i18n.t('receivablePayable.TheNumberOfOrders'), width: 100, align: 'left', highlight: true, disabled: false },
            // 费用条数
            { prop: 'feeQty', label: this.$i18n.t('receivablePayable.TheNumberOfFees'), width: 100, align: 'left', highlight: false, disabled: false },
            // 计费总重量（kg）
            { prop: 'chargeWeight', label: this.$i18n.t('receivablePayable.TotalBillableWeight'), width: 140, align: 'left', highlight: false, disabled: false },
            // 票单金额
            { prop: 'billFee', label: this.$i18n.t('receivablePayable.TicketAmount'), width: 140, align: 'left', highlight: false, disabled: false },
            // 实际收款金额
            { prop: 'actualFee', label: this.$i18n.t('receivablePayable.TheActualAmountReceived'), width: 140, align: 'left', highlight: false, disabled: false },
            // 核付人
            { prop: 'acceptUserName', label: this.$i18n.t('receivablePayable.Redeemer'), width: 100, align: 'left', highlight: false, disabled: false },
            // 核付时间
            { prop: 'acceptOperateTime', label: this.$i18n.t('receivablePayable.TimeOfPayment'), width: 160, align: 'left', highlight: false, disabled: false },
            // 交易时间
            { prop: 'acceptTime', label: this.$i18n.t('basicData.TradingHours'), width: 160, align: 'left', highlight: false, disabled: false },
            // 生成人员
            { prop: 'createUserName', label: this.$i18n.t('receivablePayable.BuildThePerson'), width: 100, align: 'left', highlight: false, disabled: false },
            // 生成时间
            { prop: 'createTime', label: this.$i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
            // 审核人员
            { prop: 'auditUserName', label: this.$i18n.t('receivablePayable.Auditors'), width: 100, align: 'left', highlight: false, disabled: false },
            // 审核时间
            { prop: 'auditTime', label: this.$i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },
            // 邮件发送状态
            { prop: 'emailSendingStatus', label: this.$i18n.t('receivablePayable.MailSendingStatus'), width: 110, align: 'left', highlight: false, disabled: false },
            // 接收账单邮件
            { prop: 'emailAcceptance', label: this.$i18n.t('receivablePayable.ReceiveBillingMail'), width: 170, align: 'left', highlight: false, disabled: false },
            // 发送人
            { prop: 'emailSenderIdStr', label: this.$i18n.t('receivablePayable.Sender'), width: 100, align: 'left', highlight: false, disabled: false },
            // 发送时间
            { prop: 'emailSendingTime', label: this.$i18n.t('collectionCenter.SendingTime'), width: 160, align: 'left', highlight: false, disabled: false },
            // 文件
            { prop: 'emailSenderFileUrl', label: this.$i18n.t('Resource.file'), width: 160, align: 'left', highlight: false, disabled: false },
            // 备注
            { prop: 'remark', label: this.$i18n.t('receivablePayable.remark'), width: 160, align: 'left', highlight: false, disabled: false }
          ]
        }
      ];
    },

    // 发送账单按钮 禁用状态
    isSendBillBut() {
      const len = this.selectData.length;
      // 仅能选中一个对账批次号的数据
      if (len === 1) {
        return false;
      }
      return true;
    }
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getHeaderColumn();
    this.getAccountOption();
    this.getProviderPage();
    // this.getSitePageList();
    this.getExaminePage();
  },
  mounted() {
    this.bus.$on('pendingData', () => {
      this.getExaminePage();
    });
  },
  methods: {
    // 找出两个数组中相同的元素
    findCommonElements(arr1, arr2) {
      return arr1.filter((element) => arr2.includes(element));
    },
    // 找出两个数组中不相同的元素
    findDifferentElements(arr1, arr2) {
      // 找出arr1中不存在于arr2的元素
      const uniqueToArr1 = arr1.filter(element => !arr2.includes(element));
      // 找出arr2中不存在于arr1的元素
      const uniqueToArr2 = arr2.filter(element => !arr1.includes(element));
      // 将两个数组中的不同元素合并
      return [...uniqueToArr1, ...uniqueToArr2];
    },
    // 获取当前用户自定义列表表头
    getHeaderColumn() {
      const param = {
        type: 0,
        menuEnum: 'copeWithNucleus'
      };
      apiCustomColumnTemplateList(param).then(res => {
        if (res.code === 200) {
          this.customColumnId = '';
          let selectedColumn = [];
          if (res.data.length > 0) {
            this.customColumnId = res.data[0].id;
            selectedColumn = JSON.parse(res.data[0].templateField);
          }
          this.currentColumns = [];
          let allColumn = [];
          const selectedProp = [];
          const allProp = [];
          selectedColumn.map(list => {
            if (selectedProp.includes(list.prop) === false) {
              selectedProp.push(list.prop);
            }
          });
          this.columnList[0].children.map(item => {
            if (allProp.includes(item.prop) === false) {
              allProp.push(item.prop);
            }
          });
          const commonElements = this.findCommonElements(selectedProp, allProp);
          if (commonElements.length > 0) {
            commonElements.map(list => {
              this.columnList[0].children.map(item => {
                if (list === item.prop) {
                  this.currentColumns.push(item);
                }
              });
            });
          } else {
            this.columnList[0].children.map(item => {
              if (selectedProp.includes(item.prop) === false) {
                selectedProp.push(item.prop);
              }
              this.currentColumns.push(item);
              // if (item.disabled) {
              //   if (selectedProp.includes(item.prop) === false) {
              //     selectedProp.push(item.prop);
              //   }
              //   this.currentColumns.push(item);
              // }
            });
          }
          allColumn = cloneDeep(this.currentColumns);
          const differentElements = this.findDifferentElements(allProp, selectedProp);
          this.columnList[0].children.map(item => {
            if (differentElements.includes(item.prop)) {
              allColumn.push(item);
            }
          });
          // 页面加载的时候设置table的高度
          this.$nextTick(() => {
            this.columnList[0].children = cloneDeep(allColumn);
            this.tableKey = new Date().getTime();
          });
        }
      });
    },
    // 打开自定义栏目弹窗
    customColumn() {
      const selectedColumn = cloneDeep(this.currentColumns);
      this.checkedColumns = [];
      this.checkedSelectColumns = cloneDeep(this.columnList[0].children);
      this.checkedSelectColumns.map(item => {
        if (selectedColumn.length > 0) {
          selectedColumn.map(list => {
            if (item.prop === list.prop) {
              this.checkedColumns.push(item.prop);
            }
          });
        } else {
          this.checkedColumns.push(item.prop);
          // if (item.disabled === true) {
          //   this.checkedColumns.push(item.prop);
          // }
        }
      });
      this.showColumn = true;
      this.dragging = new Date().getTime();
    },
    // 确定保存已选栏目
    determineColumns() {
      const columns = [];
      const neKey = this.$refs.elTree.getCheckedKeys();
      if (neKey.length > 0) {
        this.columnList[0].children.map(list => {
          if (neKey.includes(list.prop)) {
            columns.push(list);
          }
        });
      }
      const params = {
        templateField: JSON.stringify(columns),
        templateName: '应付账单核付',
        type: 0,
        menuEnum: 'copeWithNucleus',
        userId: this.$store.state.user.id
      };
      if (this.customColumnId) {
        params.id = this.customColumnId;
        apiCustomColumnTemplateUpdate(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      } else {
        apiCustomColumnTemplateInsert(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      }
      this.showColumn = false;
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
      });
    },
    // 关闭自定义栏目弹窗事件将未保存的数据还原
    closeDrawer() {
      this.columnList[0].children = this.checkedSelectColumns;
    },
    // 判断节点是否能被拖拽
    allowDrag(node) {
      return node.level === 2;
    },
    // 判断节点是否可放置
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 2 && type !== 'inner') {
        return true;
      } else {
        return false;
      }
    },
    // 拖拽成功后触发事件
    handleDrop(draggedNode, insertNode, dropLocation, event) {
      this.dragging = new Date().getTime();
    },
    // 栏目勾选
    handleCheckChange(code, node) {
      this.checkedColumns = node.checkedKeys;
    },
    // 业务主体选项变化
    providerPageChange() {
      this.queryForm.params.siteIds = [];
      // this.getSitePageList();
      // 重置
      // this.$refs.querySiteCmp.reset();
    },
    // 获取网点
    // getSitePageList() {
    //   const param = {
    //     pageNumber: 1,
    //     pageSize: 9999999,
    //     params: {
    //       siteStatus: 1,
    //       providerId: this.queryForm.params.providerId
    //     }
    //   };
    //   getDistributionDotList(param, false).then(res => {
    //     if (res.status === 'OK') {
    //       this.outletsList = res.data.records;
    //     }
    //   });
    // },
    // 图片点击放大
    showImg(e) {
      if (e.target.tagName === 'IMG') {
        this.$set(this.imageViewerObj, 'showViewer', true);
        this.$set(this.imageViewerObj, 'imgArr', [e.target.src]);
      }
    },
    // 关闭查看图片
    closeViewer() {
      this.$set(this.imageViewerObj, 'showViewer', false);
    },
    // 子级禁止选择
    selectable(row) {
      return row.isParent;
    },
    // 更新页面
    collectionUpdate(type) {
      if (type === 2) {
        this.getExaminePage();
      }
      this.showCollection = false;
    },
    // 获取账户
    getAccountOption() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 1000000,
        params: {
          status: 1
        }
      };
      accountPage(param, false).then(res => {
        if (res.code === 200) {
          this.accountOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 撤回
    recall(data) {
      const param = {
        payableNumbers: [data.payableNumber]
      };
      this.$confirm(this.$i18n.t('receivablePayable.AreYouSureYouWantToWithdraw'), this.$i18n.t('basicData.tips'), { // '确定要撤回?'
        confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
        cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiSitePayableWithdraw(param).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.getExaminePage();
          }
        });
      });
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    // 给反审行添加背景色
    rowClass({ row, column, rowIndex, columnIndex }) {
      const rowBackground = {};
      if (row.isAuditRollback) {
        rowBackground.background = '#f00';
      }
      return rowBackground;
    },
    // 获取业务主体
    getProviderPage() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    // 获取列表数据
    getExaminePage() {
      const param = this.getQueryData();

      const randomString = (e, t) => {
        // e选取字符长度; t字符串
        e = e || 4;
        const a = t.length;
        let n = '';
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n;
      };

      function repeat(payableNumber, random) {
        const randomStr = randomString(4, payableNumber);
        if (!random.includes(randomStr)) {
          random.push(randomStr);
          return randomStr;
        } else {
          repeat(payableNumber, random);
        }
      }

      function recurrence(table) {
        const randomArr = [];
        for (let i = 0; i < table.length; i++) {
          const randomId = repeat(table[i].payableNumber, randomArr);
          table[i]['idCopy'] = table[i].id + randomId;
          if (table[i].meetSiteBill && table[i].meetSiteBill.length > 0) {
            recurrence(table[i].meetSiteBill);
          }
        }
        return table;
      }

      const timeArr = ['acceptTime', 'auditTime', 'emailSendingTime'];
      apiSitePayableGetPage(param).then(res => {
        if (res.code === 200) {
          res.data.records.map(item => {
            for (const key in item) {
              // 时间处理
              if (timeArr.includes(key)) {
                if (item[key]) {
                  item[key] = item[key].replace('T', ' ');
                }
              }

              // 发送人
              if (key === 'emailSenderId') {
                item['emailSenderIdStr'] = this.createUser[item[key]];
              }

              // 文件处理
              if (key === 'emailSenderFileUrl') {
                // item['emailSenderFileUrlArr'] = (item[key] || '').split(',');
                if (item[key]) {
                  item['emailSenderFileUrlArr'] = item[key].split(',');
                } else {
                  item['emailSenderFileUrlArr'] = [];
                }
              }
            }
          });
          this.tableData = recurrence(res.data.records).map(item => {
            const { payableStatus, payableType } = item;
            delete item.requestPaymentStatus;

            const obj = {
              ...item,
              payableStatusStr: '',
              payableTypeStr: ''
            };

            // 付款状态
            obj.payableStatusStr = PAYABLE_STATUS[payableStatus]?.label ?? '';

            // 账单类型
            obj.payableTypeStr = PAYABLE_TYPE[payableType]?.name ?? '';


            return Object.freeze(obj);
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 请求参数处理
    getQueryData() {
      const queryForm = cloneDeep(this.queryForm);
      const { params } = queryForm;

      const param = {
        orderDirection: 'DESC',
        pageNumber: queryForm.pageNumber,
        pageSize: queryForm.pageSize,
        params: {
          payableNumbers: [], // 收款单号
          billBatchNumbes: [], // 对账单批次号
          siteIds: params.siteIds, // 网点
          businessType: params.businessType,
          providerId: params.providerId, // 业务主体
          payableStatus: params.payableStatus, // 收款状态
          emailSendingStatus: params.emailSendingStatus,
          requestPaymentStatus: params.requestPaymentStatus,
          payableTypes: [],
          payableTimes: [],
          haveFile: params.haveFile
        }
      };

      if (params.payableTypes !== '') {
        param.params.payableTypes = [params.payableTypes];
      }

      if (Array.isArray(queryForm.params.payableTimes)) {
        // obj.params.payableTypes = [obj.params.payableTypes];
        param.params.payableTimes = params.payableTimes;
      }

      // 付款单号 / 账单批次号
      if (this.searchNum.length >= 1) {
        const val = this.$refs.inputTextareaRef.getValue();
        if (this.typeNumber === 1) {
          param.params.payableNumbers = val;
        } else {
          param.params.billBatchNumbers = val;
        }
      }

      // if (this.searchNum) {
      //   if (this.typeNumber === 1) {
      //     param.params.payableNumber = this.searchNum;
      //     param.params.billBatchNumber = '';
      //   } else {
      //     param.params.billBatchNumber = this.searchNum;
      //     param.params.payableNumber = '';
      //   }
      // } else {
      //   param.params.payableNumber = '';
      //   param.params.billBatchNumber = '';
      // }

      return param;
    },

    // 列表数据勾选
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    // 获取对账批次详情
    showBillBatchNumber(data) {
      this.batchNumber = data.payableNumber;
      this.billBatchData = data;
      this.showDetail = true;
    },
    // 翻页
    getExamineCurrentPage(val) {
      this.queryForm.pageNumber = val;
      this.getExaminePage();
    },
    // 每页显示数量变化
    getExamineSizePage(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getExaminePage();
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getExaminePage();
    },
    // 操作
    operationBtn(type) {
      const len = this.selectData.length;
      if (len === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.SelectAtLeastOnePieceOfData')); // 最少要选择一条数据
        return;
      }
      if (type === 1) {
        if (len > 1) {
          this.$message.warning(this.$i18n.t('receivablePayable.OnlyOnePieceOfDataCanBeSelected')); // 最少要选择一条数据
          return;
        }
        this.collectionKey = new Date().getTime();
        this.showCollection = true;
        return false;
      }
    },

    // 发送邮件
    onSendBill() {
      const { selectData } = this;
      if (selectData.length !== 1) {
        this.$message.warning(this.$i18n.t('receivablePayable.OnlyOnePieceOfDataCanBeSelected')); // 最少要选择一条数据
        return;
      }

      // 当前已经发送发的邮件数量
      const { emailSendingCount } = selectData[0];
      if (emailSendingCount >= 3) {
        const str = this.$i18n.t('driver.modalSendButton');// 发送
        const str2 = this.$i18n.t('bank.email');// 邮件
        const str3 = this.$i18n.t('newOrder.Quantity');// 次数
        // const str4 = this.$i18n.t('collectionCenter.outRange');// 超出范围
        this.$message.warning(str + str2 + str3 + '(>=3)');
        return;
      }

      this.sendBillRow.isShow = true;
      const { payableNumber, providerId } = this.selectData[0];
      this.sendBillRow.row.payableNumber = payableNumber;
      this.sendBillRow.row.providerId = providerId;
    },

    // 关闭发送邮件弹窗
    onEnvieUmEmailClose(isBool) {
      this.sendBillRow.isShow = false;
      isBool && this.searchResources();
    },

    // 导出账单文件
    onExportBillFile() {
      const { selectData, tableData } = this;

      // 指定导出
      if (selectData.length > 0) {
        const arr = getFileUrl(selectData);
        exportFile(arr);
        return;
      }

      // 导出当前页数据
      const arr = getFileUrl(tableData);
      exportFile(arr);
    },

    // 导出
    onExport() {
      const { selectData } = this;
      const { params } = this.getQueryData();

      // 指定导出
      if (selectData.length > 0) {
        params.ids = selectData.map(item => item.id);
      }

      apiSitePayableExport(params).then(res => {
        goResourceTaskList();
      }).then(res => {
      }).catch(err => {
        console.error(err);
      });
    },

    // 编辑文件
    openUpdateFile() {
      const row = this.selectData[0];

      if (!row) {
        return;
      }

      const { id, emailSenderFileUrlArr } = row;

      this.$refs.updateFileRef.open(id, emailSenderFileUrlArr);
    }

  }
};
</script>

<style scoped lang="scss">
.batchNumber {
  color: #007efd;
  cursor: pointer;
}
.selectClass ::v-deep .el-input--suffix input{
  border: none;
}

.action-table {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  .btnLeft {
    flex-wrap: wrap;
    align-items: center;
  }

  .btnRight {
    display: flex;
    flex-wrap: nowrap;
  }
}

.table {
  overflow: auto;
}

</style>
<style>
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #aaa;
}
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #aaa;
}
.remarkStyle p {
  padding: 0!important;
  margin: 0!important;
}
.pending .el-table tr.background {
  background-color: #FF0000!important;
  color: #FFF;
}
.pending .el-table tr.background:hover td {
  background-color: #FF0000!important;
  color: #FFF;
}
/* .selectInput .el-form-item__label .el-input__inner {
  border: 0;
} */

.btn-group-item {
  display: flex;
}

</style>
