<template>
  <div class="Box">

    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row class="selectClass">
          <el-col class="col2">
            <el-select
              v-model="selectTime" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
              @change="changeTime"
            >
              <template slot="prefix">
                {{ (timeList.find(item => item.value === selectTime) || {}).time }}
              </template>
              <el-option
                v-for="(item, index) in timeList"
                :key="index"
                :label="item.time"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <!-- 订单创建 selectTime == 't0' -->
          <!-- 实际揽收 selectTime == 't2' -->
          <!-- 签收时间 selectTime == 't3' -->
          <el-col :span="8">
            <el-form-item class="delMl">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectCode" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                  @change="changeCode"
                >
                  <template slot="prefix">
                    {{ (selectCodeList.find(item => item.value === selectCode) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in selectCodeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!--
                  c1 '揽收订单号'
                  c2 '箱子条码'
                  c3 '客户订单号'
                  c4 '箱子号'
                  -->
                </el-select>
              </el-col>
              <el-col v-if="selectCode == 'c1'" style="width: auto;"><!-- 揽收订单号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.orderNumber"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @keydown.enter.native="BlockNewlineCode(queryForm.params.orderNumber)"
                  />
                </el-form-item>
              </el-col>
              <el-col v-if="selectCode == 'c2'" style="width: auto;"><!-- 箱子条码 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.boxBarCode"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @keydown.enter.native="BlockNewlineCode(queryForm.params.boxBarCode)"
                  />
                </el-form-item>
              </el-col>
              <el-col v-if="selectCode == 'c3'" style="width: auto;"><!-- 客户订单号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.customerNumber"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @keydown.enter.native="BlockNewlineCode(queryForm.params.customerNumber)"
                  />
                </el-form-item>
              </el-col>
              <el-col v-if="selectCode == 'c4'" style="width: auto;"><!-- 箱子号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.bagNumber"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @keydown.enter.native="BlockNewlineCode(queryForm.params.bagNumber)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectPeople" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                  @change="changePeople"
                >
                  <template slot="prefix">
                    {{ (selectPeopleList.find(item => item.value === selectPeople) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in selectPeopleList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!--              <el-option :label="$t('collectionCenter.LanShouPeople')" value="n1" />&lt;!&ndash; 揽收人 &ndash;&gt;-->
                  <!--              <el-option :label="$t('collectionCenter.signer')" value="n2" />&lt;!&ndash; 签收人 &ndash;&gt;-->
                </el-select>
              </el-col>
              <el-col v-if="selectPeople == 'n1'" style="width: auto;">
                <el-form-item class="siteClass">
                  <el-select
                    v-model="queryForm.params.collectUser"
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    :remote-method="driverNameMethod"
                    :loading="loading"
                  >
                    <el-option
                      v-for="(item, index) in driverNameList"
                      :key="index"
                      :label="item.driverName"
                      :value="item.driverId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="selectPeople == 'n2'" style="width: auto;">
                <el-form-item class="siteClass">
                  <el-select
                    v-model="queryForm.params.signerUser"
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    :remote-method="signDriverNameMethod"
                    :loading="loading"
                  >
                    <el-option
                      v-for="(item, index) in signDriverNameList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectSite" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                  @change="changeSite"
                >
                  <template slot="prefix">
                    {{ (stList.find(item => item.value === selectSite) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in stList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!--              <el-option :label="$t('collectionCenter.collectionDot')" value="s1" />&lt;!&ndash; 揽收网点 &ndash;&gt;-->
                  <!--              <el-option :label="$t('collectionCenter.deliveryDot')" value="s2" />&lt;!&ndash; 签收网点 &ndash;&gt;-->
                </el-select>
              </el-col>
              <el-col v-if="selectSite == 's1'" style="width: auto;">
                <el-form-item class="siteClass">
                  <el-select
                    v-model="queryForm.params.collectSiteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable @focus="getNewestSite"
                  >
                    <el-option
                      v-for="(item, index) in siteIdList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="selectSite == 's2'" style="width: auto;">
                <el-form-item class="siteClass">
                  <el-select
                    v-model="queryForm.params.signerSiteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable @focus="getNewestSite"
                  >
                    <el-option
                      v-for="(item, index) in siteIdList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 订单状态： -->
            <el-form-item :label="$t('collectionCenter.orderStatus')">
              <el-select
                v-model="queryForm.params.boxStatusList" multiple :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable filterable
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.collectStatusName"
                  :value="item.collectStatus"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="space-between">
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:order:warehouse-warehouse:find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:warehouse-warehouse:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:warehouse-warehouse:allExport'"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="tableList"
        :max-height="600"
        border
        :data="tableDate"
      >
        <!--        <el-table-column-->
        <!--          type="selection"-->
        <!--          width="55"-->
        <!--        />-->
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="boxNumber"
          :label="$t('collectionCenter.boxNumber')"
        /><!-- 箱子号 -->
        <el-table-column
          prop="boxBarCode"
          :label="$t('collectionCenter.boxBarCode')"
        /><!-- 箱子条码 -->
        <el-table-column
          prop="createTime"
          :label="$t('collectionCenter.orderCreateT')"
        /><!-- 订单创建时间 -->
        <el-table-column
          prop="customerNumber"
          :label="$t('collectionCenter.customerOrderNum')"
        /><!-- 客户订单号 -->
        <el-table-column
          prop="orderNumber"
          :label="$t('collectionCenter.lanshouOrderNum')"
        /><!-- 揽收订单号 -->
        <el-table-column
          prop="boxStatus"
          :label="$t('collectionCenter.OrderStatus')"
        ><!-- 订单状态 -->
          <template slot-scope="scope">
            <!-- 箱子状态:0:未揽收,10:已揽收,20:装车,30:卸车,40:已签收,50:问题件 -->
            <div>
              {{
                scope.row.boxStatus == 0 ? $t('collectionCenter.notLanShou') :
                scope.row.boxStatus == 10 ? $t('collectionCenter.hasLanShou') :
                scope.row.boxStatus == 20 ? $t('collectionCenter.truckLoading') :
                scope.row.boxStatus == 30 ? $t('collectionCenter.unloading') :
                scope.row.boxStatus == 40 ? $t('collectionCenter.haveBeenSigned') :
                scope.row.boxStatus == 50 ? $t('collectionCenter.ProblemPiece') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          :label="$t('collectionCenter.CustomerName')"
        /><!-- 客户名称 -->
        <el-table-column
          prop="customerId"
          :label="$t('collectionCenter.clientID')"
        /><!-- 客户编号 -->
        <el-table-column
          prop="senderName"
          :label="$t('orderCenterCont.sender')"
        /><!-- 发件人 -->
        <el-table-column
          prop="senderPhone"
          :label="$t('orderCenterCont.senderPhone')"
        /><!-- 发件人电话 -->
        <el-table-column
          prop="senderMobile"
          :label="$t('collectionCenter.senderMobileNumber')"
        /><!-- 发件人手机号码 -->
        <el-table-column
          prop="senderZipCode"
          :label="$t('collectionCenter.senderZipCode')"
        /><!-- 发件人邮编 -->
        <el-table-column
          prop="senderState"
          :label="$t('collectionCenter.stateOrigin')"
        /><!-- 发件州 -->
        <el-table-column
          prop="senderCity"
          :label="$t('collectionCenter.sendingCity')"
        /><!-- 发件城市 -->
        <el-table-column
          prop="senderAddress"
          :label="$t('collectionCenter.sendingDetailsAddress')"
        /><!-- 发件详细地址 -->
        <el-table-column
          prop="receiverName"
          :label="$t('orderDetails.receiveName')"
        /><!-- 收件人 -->
        <el-table-column
          prop="receiverPhone"
          :label="$t('collectionCenter.RecipientPhoneNum')"
        /><!-- 收件人电话 -->
        <el-table-column
          prop="receiverMobile"
          :label="$t('collectionCenter.RecipientP')"
        /><!-- 收件人手机 -->
        <el-table-column
          prop="receiverZipCode"
          :label="$t('collectionCenter.Recipientzc')"
        /><!-- 收件人邮编 -->
        <el-table-column
          prop="collectTime"
          :label="$t('collectionCenter.lanshouT')"
        /><!-- 揽收时间 -->
        <el-table-column
          prop="collectName"
          :label="$t('collectionCenter.LanShouPeople')"
        /><!-- 揽收人 -->
        <el-table-column
          prop="collectSiteName"
          :label="$t('collectionCenter.LanShouSite')"
        /><!-- 揽收网点 -->
        <el-table-column
          prop="collectUrl"
          :label="$t('collectionCenter.lanshouImg')"
          align="center"
        ><!-- 揽收图片 -->
          <template slot-scope="scope">
            <el-popover
              placement="left-start"
              :title="$t('collectionCenter.lanshouImg')"
              width="300"
              trigger="hover"
              content=""
            >
              <img
                v-show="scope.row.collectUrl" slot="reference" :src="scope.row.collectUrl"
                alt="" style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.collectUrl" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.collectUrl" :src="scope.row.collectUrl" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="signerTime"
          :label="$t('collectionCenter.signT')"
        /><!-- 签收时间 -->
        <el-table-column
          prop="signerName"
          :label="$t('collectionCenter.signer')"
        /><!-- 签收人 -->
        <el-table-column
          prop="signerSiteName"
          :label="$t('collectionCenter.deliveryDot')"
        /><!-- 签收网点 -->
        <el-table-column
          prop="signerUrl"
          :label="$t('collectionCenter.signImage')"
          align="center"
        ><!-- 签收图片 -->
          <template slot-scope="scope">
            <el-popover
              placement="left-start"
              :title="$t('collectionCenter.signImage')"
              width="300"
              trigger="hover"
              content=""
            >
              <img
                v-show="scope.row.signerUrl" slot="reference" :src="scope.row.signerUrl"
                alt="" style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.signerUrl" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.signerUrl" :src="scope.row.signerUrl" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isViewDetails"
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <!-- 查看详情 -->
            <el-button
              v-permit:remove="'btn:collection-center:order:warehouse-warehouse:ViewDetails'"
              style="white-space: normal;"
              size="mini"
              @click="cellClick(scope.row)"
            >{{ $t('collectionCenter.ViewDetails') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="tablePage"
        @size-change="tablePageSize"
      />
    </div>

    <div v-if="drawerIsOpen" class="drawerOpen">
      <el-dialog
        title=""
        :visible.sync="drawerIsOpen"
        width="80%"
      >
        <div class="msgBox">
          <!-- 收件人信息 -->
          <p class="msgTitle">{{ $t('collectionCenter.recipientInformation') }}</p>
          <div class="msgClass">
            <!--            收件人：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.recipient') }}</span>{{ msgList.receiverName }}</span>
            <!--            电话：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.phone') }}</span>{{ msgList.receiverPhone }}</span>
            <!--            手机：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.cellphone') }}</span>{{ msgList.receiverMobile }}</span>
            <!--            邮箱：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.mailbox') }}</span>{{ msgList.receiverEmail }}</span>
            <!--            邮编：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.zipCode') }}</span>{{ msgList.receiverZipCode }}</span>
            <!--            收件州：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.receiptState') }}</span>{{ msgList.receiverState }}</span>
            <!--            收件城市：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.receiptCity') }}</span>{{ msgList.receiverCity }}</span>
            <!--            详细地址：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.detailedArs') }}</span>{{ msgList.receiverAddress }}</span>
            <!--            税号：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.CPF') }}</span>{{ msgList.receiverCpf }}</span>
          </div>

          <!-- 发件人信息 -->
          <p class="msgTitle">{{ $t('collectionCenter.SenderInformation') }}</p>
          <div class="msgClass">
            <!--            发件人：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.adrser') }}</span>{{ msgList.senderName }}</span>
            <!--            电话：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.phone') }}</span>{{ msgList.senderPhone }}</span>
            <!--            手机：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.cellphone') }}</span>{{ msgList.senderMobile }}</span>
            <!--            邮箱：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.mailbox') }}</span>{{ msgList.senderEmail }}</span>
            <!--            邮编：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.zipCode') }}</span>{{ msgList.senderZipCode }}</span>
            <!--            发件州：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.stateOri') }}</span>{{ msgList.senderState }}</span>
            <!--            发件城市：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.sendingCt') }}</span>{{ msgList.senderCity }}</span>
            <!--            详细地址：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.detailedArs') }}</span>{{ msgList.senderAddress }}</span>
            <!--            税号：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.CPF') }}</span>{{ msgList.senderCpf }}</span>
          </div>

          <!-- 货物信息 -->
          <p class="msgTitle">{{ $t('collectionCenter.cargoInformation') }}</p>
          <div class="msgClass">
            <!--            商品名称：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.tradeN') }}</span>{{ msgList.goodsName }}</span>
            <!--            总重量：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.totalWeight') }}</span>{{ msgList.weight }}</span>
            <!--            总包裹数：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.totalParcels') }}</span>{{ msgList.totalPackage }}</span>
            <!--            总声明价值：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.totalDeclaredValue') }}</span>{{ msgList.totalValue }}</span>
            <!--            箱子长：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.boxLen') }}</span>{{ msgList.length }}</span>
            <!--            箱子宽：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.boxWei') }}</span>{{ msgList.width }}</span>
            <!--            箱子高：-->
            <span class="span"><span class="s">{{ $t('collectionCenter.boxHei') }}</span>{{ msgList.height }}</span>
          </div>

          <div style="margin-top: 25px;">
            <el-table
              ref="msgTableRef"
              border
              :max-height="600"
              :data="msgTableDate"
            >
              <el-table-column
                prop="goodsName"
                align="center"
                :label="$t('collectionCenter.tradeName')"
              /><!-- 商品名称 -->
              <el-table-column
                prop="packageNumber"
                align="center"
                :label="$t('collectionCenter.parcelNum')"
              /><!-- 包裹号 -->
              <el-table-column
                prop="scanNumber"
                align="center"
                :label="$t('collectionCenter.pgBarCode')"
              /><!-- 包裹条形码 -->
              <el-table-column
                prop="weight"
                align="center"
                :label="$t('collectionCenter.bagWeight')"
              /><!-- 包裹重量 -->
              <el-table-column
                prop="goodsInvoicNo"
                align="center"
                :label="$t('collectionCenter.commodityInvoiceNumber')"
              /><!-- 商品发票号 -->
              <el-table-column
                prop="goodsInvoiceSecretKey"
                align="center"
                :label="$t('collectionCenter.CommodityInvoiceKey')"
              /><!-- 商品发票秘钥 -->
              <el-table-column
                prop="totalValue"
                align="center"
                :label="$t('collectionCenter.declaredVal')"
              /><!-- 声明价值 -->
              <el-table-column
                prop="goodsNumber"
                align="center"
                :label="$t('collectionCenter.numberItems')"
              /><!-- 商品件数 -->
              <el-table-column
                prop="logisticsInvoiceType"
                align="center"
                :label="$t('collectionCenter.logisticsInvoiceType')"
              ><!-- 物流发票类型 1:CTE, 2:NFSe -->
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.logisticsInvoiceType == 1 ? 'CTE' : scope.row.logisticsInvoiceType == 2 ? 'NFSe' : '' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="logisticsInvoiceNo"
                align="center"
                :label="$t('collectionCenter.logisticsInvoiceNumber')"
              /><!-- 物流发票号 -->
              <el-table-column
                prop="logisticsInvoiceSecretKey"
                align="center"
                :label="$t('collectionCenter.cteInvoiceKey')"
              /><!-- cte发票秘钥 -->
            </el-table>
          </div>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {
  boxPage,
  boxGoodsDetail,
  boxExportAll,
  searchSiteIdList,
  queryCollectMan,
  queryDriver
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { commonDesensitize, desensitizeBrazilianTaxNumber, desensitizeEmail } from '@/utils/desensitization';

export default {
  data() {
    return {
      isClickAll: false,
      isViewDetails: this.$store.getters.button_permissions.includes('btn:collection-center:order:warehouse-warehouse:ViewDetails'),
      drawerIsOpen: false,
      selectTime: 't0', // 选择期望揽收时间 / 实际揽收时间
      timeList: [
        { value: 't0', time: this.$t('collectionCenter.CreationTime') }, // '创建时间'
        { value: 't1', time: this.$t('collectionCenter.lanshouT') }, // '揽收时间'
        { value: 't2', time: this.$t('collectionCenter.signT') } // '签收时间'
      ],
      timeValue: [],
      selectCode: 'c1',
      selectCodeList: [
        { value: 'c1', label: this.$t('collectionCenter.lanshouOrderNum') }, // '揽收订单号'
        { value: 'c2', label: this.$t('collectionCenter.boxBarCode') }, // '箱子条码'
        { value: 'c3', label: this.$t('collectionCenter.customerOrderNum') }, // '客户订单号'
        { value: 'c4', label: this.$t('collectionCenter.boxNumber') } // '箱子号'
      ],
      selectPeople: 'n1', // 1 揽收人   2 签收人
      selectPeopleList: [
        { value: 'n1', label: this.$t('collectionCenter.LanShouPeople') }, // '揽收人'
        { value: 'n2', label: this.$t('collectionCenter.signer') } // '签收人'
      ],
      loading: false, // 加载
      driverNameList: [], // 联想输入查询
      signDriverNameList: [], // 签收人联想查询
      selectSite: 's1', // 选择站点  1 揽收网点  2 签收网点
      stList: [
        { value: 's1', label: this.$t('collectionCenter.collectionDot') }, // '揽收网点'
        { value: 's2', label: this.$t('collectionCenter.deliveryDot') } // '签收网点'
      ],
      siteIdList: [],
      orderTypeList: [
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 10, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 20, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 30, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 40, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 50, collectStatusName: this.$t('collectionCenter.ProblemPiece') } // 问题件
      ],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          createStartTime: '',
          createEndTime: '',
          collectStartTime: '',
          collectEndTime: '',
          signerStartTime: '',
          signerEndTime: '',
          orderNumber: '',
          boxBarCode: '',
          customerNumber: '',
          bagNumber: '',
          boxStatusList: [],
          collectUser: null,
          collectSiteId: null,
          signerUser: null,
          signerSiteId: null
        }
      },
      tableDate: [],
      total: 0,
      msgList: {},
      msgTableDate: [] // 详情表格
    };
  },
  watch: {
    timeValue() {
      if (this.timeValue && this.timeValue.length <= 0) {
        // 创建时间
        this.queryForm.params.createStartTime = '';
        this.queryForm.params.createEndTime = '';
        // 揽收时间
        this.queryForm.params.collectStartTime = '';
        this.queryForm.params.collectEndTime = '';
        // 签收时间
        this.queryForm.params.signerStartTime = '';
        this.queryForm.params.signerEndTime = '';
      }
    },
    'queryForm.params.orderNumber'(val) {
      const num = val.split('\n');
      let resetScanNumber = [];
      if (num.length >= 1000) {
        resetScanNumber = num.slice(0, 1000).join('\n');
        this.queryForm.params.orderNumber = resetScanNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (num.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.boxBarCode'(val) {
      const num = val.split('\n');
      let resetScanNumber = [];
      if (num.length >= 1000) {
        resetScanNumber = num.slice(0, 1000).join('\n');
        this.queryForm.params.boxBarCode = resetScanNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (num.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.customerNumber'(val) {
      const num = val.split('\n');
      let resetScanNumber = [];
      if (num.length >= 1000) {
        resetScanNumber = num.slice(0, 1000).join('\n');
        this.queryForm.params.customerNumber = resetScanNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (num.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.bagNumber'(val) {
      const num = val.split('\n');
      let resetScanNumber = [];
      if (num.length >= 1000) {
        resetScanNumber = num.slice(0, 1000).join('\n');
        this.queryForm.params.bagNumber = resetScanNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (num.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.queryForm.pageNumber = 1;
    this.queryForm.pageSize = 10;
    this.default();
    this.searchClick();
    this.getNewestSite();
    this.isClickAll = false;
  },
  methods: {
    getNewestSite() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    // 切换所选时间——清空未选时间
    changeTime() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      // 创建时间
      this.queryForm.params.createStartTime = '';
      this.queryForm.params.createEndTime = '';
      // 揽收时间
      this.queryForm.params.collectStartTime = '';
      this.queryForm.params.collectEndTime = '';
      // 签收时间
      this.queryForm.params.signerStartTime = '';
      this.queryForm.params.signerEndTime = '';
      if (this.selectTime === 't0') {
        this.queryForm.params.createStartTime = today + ' 00:00:00';
        this.queryForm.params.createEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.collectStartTime = today + ' 00:00:00';
        this.queryForm.params.collectEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.signerStartTime = today + ' 00:00:00';
        this.queryForm.params.signerEndTime = today + ' 23:59:59';
      }
    },
    // 查询条件——选择时间
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'), // '只允许查1个月内的数据'
            type: 'warning'
          });
          this.changeTime();
          return;
        }
      }
      // 创建时间
      this.queryForm.params.createStartTime = '';
      this.queryForm.params.createEndTime = '';
      // 揽收时间
      this.queryForm.params.collectStartTime = '';
      this.queryForm.params.collectEndTime = '';
      // 签收时间
      this.queryForm.params.signerStartTime = '';
      this.queryForm.params.signerEndTime = '';
      // <!-- 创建 selectTime == 't0' -->
      // <!-- 揽收 selectTime == 't1' -->
      // <!-- 签收时间 selectTime == 't2' -->
      if (this.selectTime === 't0') {
        this.queryForm.params.createStartTime = val[0];
        this.queryForm.params.createEndTime = val[1];
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.collectStartTime = val[0];
        this.queryForm.params.collectEndTime = val[1];
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.signerStartTime = val[0];
        this.queryForm.params.signerEndTime = val[1];
      }
    },
    // 查询条件——切换单号
    changeCode() {
      this.queryForm.params.orderNumber = '';
      this.queryForm.params.boxBarCode = '';
      this.queryForm.params.customerNumber = '';
      this.queryForm.params.bagNumber = '';
    },
    // 单号限制输入
    BlockNewlineCode(val) { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      const NumberLen = val.split('\n');
      if (!this.notEnter) {
        if (NumberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    // 切换揽收人 / 签收人
    changePeople() {
      this.queryForm.params.collectUser = '';
      this.queryForm.params.signerUser = '';
    },
    // 司机list
    driverNameMethod(value) {
      if (value) {
        this.loading = true;
        setTimeout(() => {
          queryCollectMan(value).then(res => {
            if (res.status === 'OK') {
              this.driverNameList = [];
              res.data.forEach(item => {
                const driverId = item.driverId ? item.driverId : item.customerId;
                const driverName = item.driverName ? item.driverName : item.customerName;
                this.driverNameList.push({
                  driverId,
                  driverName
                });
              });
            }
          });
          this.loading = false;
        }, 200);
      }
    },
    signDriverNameMethod(value) {
      if (value !== '') {
        this.loading = true;
        setTimeout(() => {
          queryDriver(value).then(res => {
            if (res.status === 'OK') {
              this.signDriverNameList = [];
              res.data.forEach(item => {
                this.signDriverNameList.push({
                  id: item.driverId,
                  name: item.driverName
                });
              });
            }
          });
          this.loading = false;
        }, 200);
      }
    },
    // 查询条件，切换揽收 / 签收网点
    changeSite() {
      this.queryForm.params.collectSiteId = null;
      this.queryForm.params.signerSiteId = null;
    },

    default() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.selectTime = 't0';
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        createStartTime: today + ' 00:00:00',
        createEndTime: today + ' 23:59:59',
        collectStartTime: '',
        collectEndTime: '',
        signerStartTime: '',
        signerEndTime: '',
        orderNumber: '',
        boxBarCode: '',
        customerNumber: '',
        bagNumber: '',
        boxStatusList: [],
        collectUser: null,
        collectSiteId: null,
        signerUser: null,
        signerSiteId: null
      };
    },
    // 重置
    resetClick() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.selectTime = 't0';
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        createStartTime: today + ' 00:00:00',
        createEndTime: today + ' 23:59:59',
        collectStartTime: '',
        collectEndTime: '',
        signerStartTime: '',
        signerEndTime: '',
        orderNumber: '',
        boxBarCode: '',
        customerNumber: '',
        bagNumber: '',
        boxStatusList: [],
        collectUser: null,
        collectSiteId: null,
        signerUser: null,
        signerSiteId: null
      };
    },
    checkQuery() {
      /**
       *  单号去除空值
       */
      let orderNumber = this.queryForm.params.orderNumber.split('\n');
      let boxBarCode = this.queryForm.params.boxBarCode.split('\n');
      let customerNumber = this.queryForm.params.customerNumber.split('\n');
      let bagNumber = this.queryForm.params.bagNumber.split('\n');
      if (this.selectCode === 'c1') {
        orderNumber = orderNumber.filter(s => {
          return s && s.trim();
        });
        this.queryForm.params.orderNumber = orderNumber.join('\n');
      }
      if (this.selectCode === 'c2') {
        boxBarCode = boxBarCode.filter(s => {
          return s && s.trim();
        });
        this.queryForm.params.boxBarCode = boxBarCode.join('\n');
      }
      if (this.selectCode === 'c3') {
        customerNumber = customerNumber.filter(s => {
          return s && s.trim();
        });
        this.queryForm.params.customerNumber = customerNumber.join('\n');
      }
      if (this.selectCode === 'c4') {
        bagNumber = bagNumber.filter(s => {
          return s && s.trim();
        });
        this.queryForm.params.bagNumber = bagNumber.join('\n');
      }
    },
    // 查询
    searchClick() {
      this.checkQuery();
      if (((!this.queryForm.params.createStartTime || !this.queryForm.params.createEndTime) &&
        (!this.queryForm.params.collectStartTime || !this.queryForm.params.collectEndTime) &&
        (!this.queryForm.params.signerStartTime || !this.queryForm.params.signerEndTime)) &&
        !this.queryForm.params.orderNumber && !this.queryForm.params.boxBarCode && !this.queryForm.params.customerNumber && !this.queryForm.params.bagNumber) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
      } else {
        if (this.queryForm.params.orderNumber || this.queryForm.params.boxBarCode || this.queryForm.params.customerNumber || this.queryForm.params.bagNumber) {
          this.timeValue = [];
          // 创建时间
          this.queryForm.params.createStartTime = '';
          this.queryForm.params.createEndTime = '';
          // 揽收时间
          this.queryForm.params.collectStartTime = '';
          this.queryForm.params.collectEndTime = '';
          // 签收时间
          this.queryForm.params.signerStartTime = '';
          this.queryForm.params.signerEndTime = '';
        }
        this.queryForm.pageNumber = 1;
        localStorage.setItem('WTWQueryForm', JSON.stringify(this.queryForm));
        this.tablePage();
      }
    },
    tablePageSize() {
      this.queryForm.pageNumber = 1;
      const queryForm = JSON.parse(localStorage.getItem('WTWQueryForm')) || this.queryForm;
      queryForm.pageNumber = 1;
      queryForm.pageSize = this.queryForm.pageSize;
      this.both(queryForm);
    },
    both(queryForm) {
      queryForm.params.collectUser = queryForm.params.collectUser ? queryForm.params.collectUser : null;
      queryForm.params.collectSiteId = queryForm.params.collectSiteId ? queryForm.params.collectSiteId : null;
      queryForm.params.signerUser = queryForm.params.signerUser ? queryForm.params.signerUser : null;
      queryForm.params.signerSiteId = queryForm.params.signerSiteId ? queryForm.params.signerSiteId : null;
      if (queryForm.params.orderNumber || queryForm.params.boxBarCode || queryForm.params.customerNumber || queryForm.params.bagNumber) {
        queryForm.params.createStartTime = '';
        queryForm.params.createEndTime = '';
        queryForm.params.collectStartTime = '';
        queryForm.params.collectEndTime = '';
        queryForm.params.signerStartTime = '';
        queryForm.params.signerEndTime = '';
        // queryForm.params.boxStatusList = '';
        // queryForm.params.collectUser = null;
        // queryForm.params.collectSiteId = null;
        // queryForm.params.signerUser = null;
        // queryForm.params.signerSiteId = null;
      }
      boxPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
          this.tableDate.map(item => {
            // 根据权限控制收发件人信息的脱敏处理
            const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
            if (!hasPermission) {
              item.senderName = commonDesensitize(item.senderName);
              item.senderPhone = commonDesensitize(item.senderPhone);
              item.senderMobile = commonDesensitize(item.senderMobile);
              item.senderAddress = commonDesensitize(item.senderAddress);
              item.receiverName = commonDesensitize(item.receiverName);
              item.receiverPhone = commonDesensitize(item.receiverPhone);
              item.receiverMobile = commonDesensitize(item.receiverMobile);
            }
          });
        }
      }).catch({});
    },
    tablePage() {
      const queryForm = JSON.parse(localStorage.getItem('WTWQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      this.both(queryForm);
    },
    cellClick(row) {
      this.drawerIsOpen = true;
      boxGoodsDetail(row.id).then(res => {
        if (res.status === 'OK') {
          this.msgTableDate = res.data[0].boxGoodsDetailList;
          this.msgList = {
            receiverName: res.data[0].receiverName,
            receiverPhone: res.data[0].receiverPhone,
            receiverMobile: res.data[0].receiverMobile,
            receiverEmail: res.data[0].receiverEmail,
            receiverZipCode: res.data[0].receiverZipCode,
            receiverState: res.data[0].receiverState,
            receiverCity: res.data[0].receiverCity,
            receiverAddress: res.data[0].receiverAddress,
            receiverCpf: res.data[0].receiverCpf,
            senderName: res.data[0].senderName,
            senderPhone: res.data[0].senderPhone,
            senderMobile: res.data[0].senderMobile,
            senderEmail: res.data[0].senderEmail,
            senderZipCode: res.data[0].senderZipCode,
            senderState: res.data[0].senderState,
            senderCity: res.data[0].senderCity,
            senderAddress: res.data[0].senderAddress,
            senderCpf: res.data[0].senderCpf,
            goodsName: res.data[0].goodsName,
            totalPackage: res.data[0].totalPackage,
            totalValue: res.data[0].totalValue,
            weight: res.data[0].weight,
            length: res.data[0].length,
            width: res.data[0].width,
            height: res.data[0].height
          };
          // 根据权限控制收发件人信息的脱敏处理
          const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
          if (!hasPermission) {
            // 收发件人信息脱敏
            this.msgList.receiverName = commonDesensitize(this.msgList.receiverName);
            this.msgList.receiverPhone = commonDesensitize(this.msgList.receiverPhone);
            this.msgList.receiverMobile = commonDesensitize(this.msgList.receiverMobile);
            this.msgList.receiverEmail = desensitizeEmail(this.msgList.receiverEmail);
            this.msgList.receiverAddress = commonDesensitize(this.msgList.receiverAddress);
            this.msgList.receiverCpf = desensitizeBrazilianTaxNumber(this.msgList.receiverCpf);
            this.msgList.senderName = commonDesensitize(this.msgList.senderName);
            this.msgList.senderPhone = commonDesensitize(this.msgList.senderPhone);
            this.msgList.senderMobile = commonDesensitize(this.msgList.senderMobile);
            this.msgList.senderEmail = desensitizeEmail(this.msgList.senderEmail);
            this.msgList.senderAddress = commonDesensitize(this.msgList.senderAddress);
            this.msgList.senderCpf = desensitizeBrazilianTaxNumber(this.msgList.senderCpf);
            // 商品发票信息脱敏
            this.msgTableDate.map(item => {
              item.goodsInvoicNo = commonDesensitize(item.goodsInvoicNo);
              item.goodsInvoiceSecretKey = commonDesensitize(item.goodsInvoiceSecretKey);
              item.logisticsInvoiceNo = commonDesensitize(item.logisticsInvoiceNo);
              item.logisticsInvoiceSecretKey = commonDesensitize(item.logisticsInvoiceSecretKey);
            });
          }
        }
      }).catch({});
    },
    downloadAll() {
      this.isClickAll = true;
      const queryForm = JSON.parse(localStorage.getItem('WTWQueryForm')) || this.queryForm;
      boxExportAll(queryForm).then(res => {
        if (res.status === 'OK') {
          this.isClickAll = false;
          goExportList(this);
        }
      }).catch(() => {
        this.isClickAll = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }
  .drawerOpen ::v-deep .el-dialog{
    margin: 10vh auto !important;
  }
  .drawerOpen ::v-deep .el-dialog__header{
    padding: 0;
  }
  .msgBox{
    .msgClass {
      border-bottom: 1px solid #ebeef5;
    }
    .msgTitle{
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0;
    }
    .msgClass .span{
      font-size: 15px;
      margin: 15px 25px;
      display: inline-block;
      .s{
        font-weight: bold;
      }
    }
  }
  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .selectClass{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .Box ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    width: 100%;
    margin-left: 0 !important;
  }
  .Box .siteClass ::v-deep .el-select, .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .siteClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }
  .col2 {
    width: auto !important;
  }
  .delMl ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
  }
  .Box ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 150px;
    min-width: 150px;
    max-width: 200px;
  }
  .Box ::v-deep textarea{
    resize: both;
  }
  .Box .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding-left: 0;
    box-sizing: border-box;
  }

  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
