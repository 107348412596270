<template>
  <!-- 应付主体 下拉列表 -->
  <el-select
    v-model="value"
    :loading="loading"
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    style="width: 100%;"
    :clearable="clearable"
    :multiple="multiple"
    :multiple-limit="multipleLimit"
    filterable
    remote
    :reserve-keyword="multiple"
    :remote-method="getProviderPage"
    @change="onChange"
  >
    <el-option
      v-for="item in list"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { apiProviderPage } from '@/api/finance/basicData';

const param = {
  pageSize: 10,
  pageNumber: 1,
  params: {
    state: 1
  }
};

export default {
  name: '',

  props: {
    // 应付主体id 用于 回显的内容
    val: {
      type: [Number, String],
      default: ''
    },

    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否可以清空选项
    clearable: {
      type: Boolean,
      default: true
    },

    // 多选时用户最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      loading: false,
      list: [],
      value: []
    };
  },

  watch: {
    'val': {
      handler() {
        this.value = this.val;
      },
      // immediate: true
    }

  },

  created() {
    setTimeout(() => {
      this.getProviderPage();
    }, 500);
  },

  methods: {
    // 获取应付主体选项
    getProviderPage(name = '') {
      this.loading = true;
      param.params.providerBody = name;
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.list = (res.data.records || []).map(item => {
            const { id, providerBody, providerName, code } = item;
            const obj = {
              label: `${providerBody}(${providerName})(${code})`,
              value: id
            };
            return Object.freeze(obj);
          });
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(val) {
      this.$emit('change', val);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
