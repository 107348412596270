<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="5">
          <!-- 解除账号  -->
          <el-form-item :label="$t('navbar.rescissionAccount')">
            <el-select
              v-model="queryForm.params.rescissionAccountList"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="searchGetUserList"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width: 100%"
              @change="searchGetUserListFocus"
              @focus="searchGetUserListFocus"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  解除的运单号   -->
          <el-form-item :label="$t('navbar.jiechuWillybNumber')">
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1000')"
              :rows="1"
              style="width: 100%;"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--账号所属网点-->
          <el-form-item :label="$t('navbar.siteIdtohave')">
            <el-select
              v-model="queryForm.params.siteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="getSiteTransfer"
              clearable
              filterable
              remote
              reserve-keyword
              style="width: 100%;"
              @change="handleSite"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!-- 解除限制类型 -->
          <el-form-item :label="$t('navbar.rescissionType')">
            <el-select
              v-model="queryForm.params.rescissionType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" :value="null" />
              <el-option :label="$t('navbar.signDianzi')" :value="1" />
              <el-option :label="$t('navbar.smsCode')" :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <div style="margin-top: 27px">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{
              $t('newOrder.search')
            }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <el-button
        size="small" style="margin-bottom: 15px" type="primary"
        @click="newRestrictions"
      >{{ $t('GlobalSetObj.add') }}
      </el-button>
      <el-button size="small" @click="exportsTable">{{ $t('operationCenter.exports') }}</el-button>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="70"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :align="item.cloAlign"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.operation')"
        align="center"
        fixed="right"
        width="100"
      >
        <template slot-scope="scope">
          <div style="display: flex;justify-content: center;align-items: center;">
            <el-tooltip
              :content="$t('GlobalSetObj.Delete')" class="item" effect="dark"
              placement="top"
            >
              <i class="el-icon-delete textOperator1" @click="delData(scope.row)" />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 新增限制 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('navbar.xinzengzhanghaoxianzhi')"
      :visible.sync="addAccountDialog"
      width="520px"
      @close="handleAddCusTimeDialog"
    >
      <el-form
        ref="ruleForm" :model="ruleForm" :rules="rules"
        class="demo-ruleForm"
        label-position="top"
        label-width="auto"
      >
        <div style="margin: 15px 0 20px 0;">
          <el-radio v-model="radioNumber" :label="1" @change="handleRadioNumber01">{{
            $t('navbar.jiechuzhanghao')
          }}
          </el-radio>
          <el-radio v-model="radioNumber" :label="2" @change="handleRadioNumber02">{{
            $t('navbar.jiechuWillybNumber01')
          }}
          </el-radio>
        </div>
        <div v-if="radioNumber === 1">
          <el-form-item :label="$t('navbar.rescissionAccount')" prop="rescissionAccount">
            <el-select
              v-model="ruleForm.rescissionAccount"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="searchGetUserList"
              clearable
              filterable
              remote
              reserve-keyword
              style="width: 100%"
              @change="searchGetUserListFocus"
              @focus="searchGetUserListFocus"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('navbar.rescissionType')" prop="rescissionType">
            <el-select
              v-model="ruleForm.rescissionType" :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('navbar.signDianzi')" :value="1" />
              <el-option :label="$t('navbar.smsCode')" :value="2" />
            </el-select>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item :label="$t('navbar.jiechuwaybillNumber')" prop="inputwaybillNumber">
            <el-input
              v-model="ruleForm.inputwaybillNumber" :placeholder="$t('GlobalSetObj.pleaseInput')" :rows="3"
              type="textarea"
            />
          </el-form-item>
          <el-form-item :label="$t('navbar.rescissionType')" prop="rescissionType">
            <el-select
              v-model="ruleForm.rescissionType" :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('navbar.signDianzi')" :value="1" />
              <el-option :label="$t('navbar.smsCode')" :value="2" />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cusSubmit(1)">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="cusSubmit(2)">{{
          $t('GlobalSetObj.confirm')
        }}
        </el-button>
      </div>
    </el-dialog>
    <!-- Tip -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('navbar.jiechuResult')"
      :visible.sync="tipDialog"
      width="550px"
    >
      <div>
        <div class="flx-between">
          <div>
            <span>{{ $t('navbar.gongjiechushu') }}：</span>
            <span>{{ resultObj.total }}</span>
          </div>
          <div>
            <span>{{ $t('navbar.jieSuccessNum') }}：</span>
            <span>{{ resultObj.successTotal }}</span>
          </div>
          <div>
            <span>{{ $t('navbar.jiechufailTotal') }}：</span>
            <span style="color: red">{{ resultObj.failTotal }}</span>
          </div>
        </div>
        <div v-if="resultObj.msg.length" style="height: 200px; overflow-y: auto">
          <div style="margin-top: 20px; margin-bottom: 8px">
            <span> {{ $t('navbar.jiechufailWillNumber') }}:</span>
          </div>
          <div v-for="(item, index) in resultObj.msg" :key="index">
            <span>{{ index + 1 }}. </span>
            <span>{{ item.orderNumber }}: </span>
            <span>{{ item.errorReason }}</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button size="small" type="primary" @click="tipDialog = false">{{ $t('GlobalSetObj.Close') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLgAreaListData } from '@/api/orderApi';
import {
  signRescissionadd,
  signRescissiondeleted,
  signRescissionexport,
  signRescissiongetPmUserList,
  signRescissionpage,
  signRescissionreleaseWayBill
} from '@/api/resources';
import { siteFuzzyQuery } from '@/api/lanshou';

export default {
  name: 'ElectronicContactLimit',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          rescissionAccountList: [],
          siteId: null, // 状态：1、启用，2、停用
          rescissionType: null,
          inputValue: ''
        }
      },
      tableData: [],
      tableHeaderList: [
        // 解除时间
        { id: 1, label: this.$t('navbar.jiechuTime'), prop: 'operationTime', cloWidth: '160', cloAlign: 'center' },
        // 解除账号
        { id: 112, label: this.$t('navbar.jiechuaccountName'), prop: 'accountName', cloWidth: '150', cloAlign: 'center' },
        // 解除的运单号
        {
          id: 2,
          label: this.$t('navbar.jiechuWillybNumber'),
          prop: 'waybillNumber',
          cloWidth: '170',
          cloAlign: 'center'
        },
        // 解除限制类型
        {
          id: 3,
          label: this.$t('navbar.rescissionTypeName'),
          prop: 'rescissionTypeName',
          cloWidth: '150',
          cloAlign: 'center'
        },
        // 账号所属网点
        { id: 4, label: this.$t('navbar.siteIdtohave'), prop: 'siteName', cloWidth: '150', cloAlign: 'center' },
        // 网点所属州
        { id: 5, label: this.$t('collectionCenter.stateSite1'), prop: 'state', cloWidth: '150', cloAlign: 'center' },
        // 操作人
        { id: 6, label: this.$t('GlobalSetObj.operator'), prop: 'operationUserName', cloWidth: '150', cloAlign: 'center' }
      ],
      userList: [], // 用户列表
      addAccountDialog: false,
      ruleForm: {
        rescissionAccount: '',
        rescissionType: '',
        inputwaybillNumber: ''
      },
      rules: {
        rescissionAccount: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        rescissionType: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        inputwaybillNumber: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ]
      },
      StateList: [],
      siteList: [],
      radioNumber: 1,
      tipDialog: false,
      resultObj: {
        msg: []
      }
    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
    this.getAddress();
    this.getSiteTransfer('');
    this.searchGetUserList('');
  },
  methods: {
    handleRadioNumber01(val) {
      console.log(val, 'handleRadioNumber01');
      this.ruleForm = {
        rescissionAccount: '',
        rescissionType: '',
        inputwaybillNumber: ''
      };
      this.$refs.ruleForm.resetFields();
    },
    handleRadioNumber02(val) {
      console.log(val, 'handleRadioNumber02');
      this.ruleForm = {
        rescissionAccount: '',
        rescissionType: '',
        inputwaybillNumber: ''
      };
      this.$refs.ruleForm.resetFields();
    },
    newRestrictions() {
      this.searchGetUserList('');
      this.radioNumber = 1;
      this.addAccountDialog = true;
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params = {
        rescissionAccountList: [],
        siteId: null, // 状态：1、启用，2、停用
        rescissionType: null,
        inputValue: ''
      };
      this.getSiteTransfer('');
      this.getSearch();
    },
    // 查询
    getSearch() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      let newArray = inputValList.filter(item => item !== '');
      console.log(newArray, 'newArray');
      if (newArray.length > 1000) {
        this.$message.warning(this.$t('newOrder.searchMax10000'));
        newArray = newArray.slice(0, 1000); // 截取前2000个单号
      }
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      data.params.waybillNumberList = newArray;
      signRescissionpage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    // 点击确定
    cusSubmit(val) {
      if (val === 1) {
        this.addAccountDialog = false;
      } else {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let arr = [];
            if (this.ruleForm.inputwaybillNumber) {
              arr = this.ruleForm.inputwaybillNumber.split('\n');
            }
            const newArray = arr.filter(item => item !== '');
            const data = {
              rescissionAccount: this.ruleForm.rescissionAccount,
              rescissionType: this.ruleForm.rescissionType,
              waybillNumberList: newArray
            };
            if (this.radioNumber === 1) {
              signRescissionadd(data).then(res => {
                if (res.status === 'OK') {
                  this.addAccountDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                  this.getSearch();
                }
              });
            } else {
              this.addAccountDialog = false;
              signRescissionreleaseWayBill(data).then(res => {
                if (res.status === 'OK') {
                  if (res.data.msg.length > 0) {
                    this.resultObj = res.data;
                    this.tipDialog = true;
                  } else {
                    this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                  }
                  this.getSearch();
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    // 点击删除
    delData(row) {
      this.$confirm(this.$t('customerManagements.tip103'), this.$t('collectionCenter.tips'), {
        confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
        cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
        type: 'warning'
      }).then(async() => {
        const data = {
          rescissionAccountId: row.id
        };
        signRescissiondeleted(data).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
            this.getSearch();
          }
        });
      }).catch(() => {
        console.log('取消关闭');
      });
    },
    exportsTable() {
      const param = {
        ...this.queryForm.params
      };
      signRescissionexport(param).then((res) => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                if (res.data.taskId) {
                  this.$router.push({ path: '/resource/exportTaskList' });
                } else {
                  this.$router.push({ path: '/resource/exportList' });
                }
              }
            })
            .catch(() => {
            });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    handleAddCusTimeDialog() {
      this.ruleForm = {
        rescissionAccount: '',
        rescissionType: '',
        inputwaybillNumber: ''
      };
      this.$refs.ruleForm.resetFields();
    },
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    // 获取所有网点
    getSiteTransfer(val) {
      const value = val || '';
      siteFuzzyQuery(value).then(res => {
        if (res.status === 'OK') {
          this.siteList = res.data;
        }
      });
    },
    handleSite(val) {
      if (!val) {
        this.getSiteTransfer('');
      }
    },
    // 查用户
    searchGetUserList(val = '') {
      const data = {
        operateUserName: val
      };
      signRescissiongetPmUserList(data).then(res => {
        if (res.status === 'OK') {
          this.userList = res.data;
        }
      });
    },
    searchGetUserListFocus(val) {
      if (!val) {
        this.searchGetUserList('');
      }
      if (this.userList.length === 0) {
        this.searchGetUserList('');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.textOperator {
  color: #409eff;
  cursor: pointer;
  font-size: 22px;
}

.textOperator1 {
  color: #f56c6c;
  cursor: pointer;
  font-size: 22px;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.downLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  cursor: pointer;
  padding: 5px;
}

.downModule {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 15px;
}

::v-deep .el-table__fixed-right {
  height: 100% !important;
}
</style>
