<template>
  <div class="siteBox">

    <div style="margin-bottom: 20px;">
      <el-form class="findClass">
        <el-row>
          <el-col :span="8" style="width: auto;">
            <!-- 网点编号： -->
            <el-form-item :label="$t('collectionCenter.dotCode')">
              <el-input
                v-model.trim="queryForm.code" :placeholder="$t('collectionCenter.pleaseInput')" clearable
                @keyup.enter.native="searchClick"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 网点名称： -->
            <el-form-item :label="$t('collectionCenter.dotName')">
              <el-input
                v-model.trim="queryForm.name" :placeholder="$t('collectionCenter.pleaseInput')" clearable
                @keyup.enter.native="searchClick"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 状态： -->
            <el-form-item :label="$t('collectionCenter.accountState')">
              <el-select v-model="queryForm.siteStatus" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option
                  v-for="(item, index) in StatusList"
                  :key="index"
                  :label="item.siteStatusName"
                  :value="item.siteStatus"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 派送邮编： -->
            <el-form-item :label="$t('collectionCenter.deliveryPostcode')">
              <el-input
                v-model.trim="queryForm.deliveryZipcode" maxlength="8" show-word-limit
                :placeholder="$t('collectionCenter.pleaseInput')" clearable @keyup.enter.native="searchClick"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 业务类型： -->
            <el-form-item :label="$t('collectionCenter.businessType')">
              <el-select v-model="queryForm.businessType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option :label="$t('collectionCenter.tudo')" :value="null" />
                <!-- 转运 -->
                <el-option :label="$t('collectionCenter.turnTransport')" :value="1" />
                <!-- 小件揽收 -->
                <el-option :label="$t('collectionCenter.smallCollect')" :value="2" />
                <!-- 小件派送 -->
                <el-option :label="$t('collectionCenter.smallDelivery')" :value="3" />
                <!-- 大件派送 -->
                <el-option :label="$t('collectionCenter.largeDelivery')" :value="4" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <el-form-item :label="$t('collectionCenter.stateSite')">
              <div class="BlockNewlineClass">
                <el-input
                  v-model="queryForm.state"
                  type="textarea"
                  :autosize="{ maxRows: 5}"
                  :placeholder="$t('collectionCenter.BatchQuery')"
                  @blur="ftS"
                  @keydown.enter.native="BlockNewStateline"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <el-form-item :label="$t('collectionCenter.citySite')">
              <div class="BlockNewlineClass">
                <el-input
                  v-model="queryForm.city"
                  type="textarea"
                  :autosize="{ maxRows: 5}"
                  :placeholder="$t('collectionCenter.BatchQuery')"
                  @blur="ftC"
                  @keydown.enter.native="BlockNewCityline"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <!--营业时间-->
            <el-form-item prop="businessWeekDictId">
              <template slot="label">
                <el-select
                  v-model="queryForm.businessWeekDictId" :placeholder="$t('base.openingHours')" clearable
                  filterable style="width: 120px"
                >
                  <el-option
                    v-for="item in weekList"
                    :key="item.dictId"
                    :label="item.dictDescribe"
                    :value="item.dictId"
                  />
                </el-select>
              </template>
              <el-time-picker
                v-model="queryForm.businessTime"
                value-format="HH:mm"
                format="HH:mm"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:site:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:site:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:site:add'"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            :disabled="isModify"
            @click="addClick"
          >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:site:edit'"
            size="mini"
            icon="el-icon-edit-outline"
            :disabled="isModify"
            @click="editClick"
          >{{ $t('collectionCenter.edit') }}</el-button><!-- 修改 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:site:allExport'"
            icon="el-icon-upload2"
            size="mini"
            :class="isClickAll ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-row>
        <el-col :span="6">
          <div ref="treeBoxRef" class="treeClass highlightClass" :class="isModify && saveBtnName == 'add' ? 'placeholderClass' : ''">
            <el-tree
              ref="treeRef"
              class="treeCursor"
              :data="treeData"
              :props="defaultProps"
              :highlight-current="highlightCurrent"
              :accordion="true"
              node-key="id"
              :current-node-key="currentNodekey"
              :default-expanded-keys="defaultOpen"
              draggable
              @node-click="handleNodeClick"
              @node-drag-start="handleDragStart"
              @node-drag-end="handleDragEnd"
            >
              <!-- 默认展开总部—1 -->
              <span slot-scope="{ data }" class="custom-tree-node">
                <!-- <span slot-scope="{ node, data }" class="custom-tree-node">
                <span v-if="data.id">  图标...
                  <i v-if="data.isProvider == 1" class="el-icon-s-shop" />
                  <i v-else-if="node.parent.data.id" class="el-icon-user" />
                  <i v-else class="el-icon-s-custom" />
                </span> -->
                <span> {{ data.code }}</span>
              </span>
            </el-tree>
          </div>
        </el-col>
        <el-col :span="18" class="rightClass">
          <div class="modifyClass">
            <el-form ref="modifyFormRef" :model="modifyForm" :rules="rules">
              <el-row type="flex" justify="space-around" style="flex-wrap: wrap;">
                <el-col :span="7">
                  <!-- 网点编号： -->
                  <el-form-item prop="code" :label="$t('collectionCenter.dotCode')">
                    <el-input
                      v-model="modifyForm.code" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 网点名称： -->
                  <el-form-item prop="name" :label="$t('collectionCenter.dotName')">
                    <el-input
                      v-model.trim="modifyForm.name" type="textarea" maxlength="100"
                      show-word-limit clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 业务主体： -->
                  <el-form-item prop="businessPrincipal" :label="$t('collectionCenter.businessSubject')">
                    <el-select
                      v-model="modifyForm.businessPrincipal" placeholder="" clearable
                      filterable :disabled="!isModify" @focus="getProviderList"
                      @change="changeBusinessPrincipal"
                    >
                      <el-option
                        v-for="(item, index) in businessPrincipalList"
                        :key="index"
                        :label="item.providerCode"
                        :value="item.providerId"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 服务商渠道： -->
                  <el-form-item prop="providerChannelCode" :label="$t('collectionCenter.providerChannel')">
                    <el-select
                      v-model="modifyForm.providerChannelCode"
                      placeholder=""
                      clearable
                      filterable
                      :disabled="!isModify"
                      @focus="checkProviderChannelValue"
                      @change="changeProviderChannel"
                    >
                      <el-option
                        v-for="(item, index) in providerChannelNameList"
                        :key="index"
                        :label="item.name"
                        :value="item.code"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 经营模式： -->
                  <el-form-item prop="businessModel" :label="$t('collectionCenter.businessModel')">
                    <el-select
                      v-model="modifyForm.businessModel" placeholder="" clearable
                      filterable :disabled="!isModify" @change="changeBusinessModel"
                    >
                      <!--  providerId：供应商新增网点  isAll：根据业务主体限制经营模式  -->
                      <el-option v-show="isAll == 1 || isAll == 0" :label="$t('collectionCenter.directSales')" :value="1" /><!--  直营  -->
                      <el-option v-show="isAll == 2 || isAll == 0" :label="$t('collectionCenter.joinSales')" :value="2" /><!--  加盟  -->
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 网点类型： -->
                  <el-form-item prop="siteType" :label="$t('collectionCenter.dotType')">
                    <el-select
                      v-model="modifyForm.siteType" placeholder="" clearable
                      filterable :disabled="!isModify" @change="changeSiteType"
                    >
                      <el-option :label="$t('collectionCenter.hut')" :value="1" /><!-- 小屋 -->
                      <el-option :label="$t('collectionCenter.merchant')" :value="5" /><!-- 商家 -->
                      <el-option :label="$t('collectionCenter.dot')" :value="2" /><!-- 网点 -->
                      <el-option v-show="modifyForm.businessModel != 2" :label="$t('collectionCenter.transferCenter')" :value="3" /><!-- 转运中心 -->
                      <el-option v-show="modifyForm.businessModel != 2" :label="$t('collectionCenter.headOffice')" :value="4" /><!-- 总部 -->
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 业务类型： -->
                  <el-form-item prop="businessType" :label="$t('collectionCenter.businessType')" class="ywlxClass">
                    <el-select
                      v-model="modifyForm.businessType" multiple placeholder=""
                      clearable filterable :disabled="!isModify"
                      @change="changeBusinessType"
                    >
                      <!-- 转运 -->
                      <el-option v-show="modifyForm.siteType != 1 && modifyForm.siteType != 2 && modifyForm.siteType != 5" :label="$t('collectionCenter.turnTransport')" :value="1" />
                      <!-- 小件揽收 -->
                      <el-option v-show="modifyForm.siteType != 3" :label="$t('collectionCenter.smallCollect')" :value="2" />
                      <!-- 小件派送 -->
                      <el-option v-show="modifyForm.siteType != 3" :label="$t('collectionCenter.smallDelivery')" :value="3" />
                      <!-- 大件派送 -->
                      <el-option v-show="modifyForm.siteType != 3" :label="$t('collectionCenter.largeDelivery')" :value="4" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 负责人： -->
                  <el-form-item prop="contactsName" :label="$t('collectionCenter.head')">
                    <el-input
                      v-model="modifyForm.contactsName" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 联系电话： -->
                  <el-form-item prop="contactsTel" :label="$t('collectionCenter.contactPhone')">
                    <el-input
                      v-model="modifyForm.contactsTel" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 开户银行： -->
                  <el-form-item prop="bank" :label="$t('collectionCenter.openTheBank')">
                    <el-input
                      v-model="modifyForm.bank" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 是否启用： -->
                  <el-form-item prop="siteStatus" :label="$t('collectionCenter.ifEnabled')">
                    <el-select
                      v-model="modifyForm.siteStatus" placeholder="" clearable
                      filterable :disabled="!isModify"
                    >
                      <el-option :label="$t('collectionCenter.notEnabled')" :value="0" /><!-- 未启用 -->
                      <el-option :label="$t('collectionCenter.enable')" :value="1" /><!-- 启用 -->
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7" class="NumClass">
                  <!-- 网点邮编： -->
                  <el-form-item prop="zipCode" :label="$t('collectionCenter.dotZipCode')">
                    <!-- 请输入8位邮编 -->
                    <el-input
                      v-model="modifyForm.zipCode" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 网点所属州： -->
                  <el-form-item prop="state" :label="$t('collectionCenter.dotState')">
                    <el-input
                      v-model="modifyForm.state" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 网点所属城市： -->
                  <el-form-item prop="city" :label="$t('collectionCenter.dotCity')">
                    <el-input
                      v-model="modifyForm.city" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 网点所属区： -->
                  <el-form-item prop="area" :label="$t('collectionCenter.dotArea')">
                    <el-input
                      v-model="modifyForm.area" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 网点所属街道： -->
                  <el-form-item prop="area" :label="$t('collectionCenter.dotStreet')">
                    <el-input
                      v-model="modifyForm.street" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7" class="NumClass">
                  <!-- 网点地址： -->
                  <el-form-item prop="address" :label="$t('collectionCenter.dotAdress')">
                    <el-input
                      v-model="modifyForm.address" type="textarea" maxlength="255"
                      show-word-limit placeholder="" :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 经度： -->
                  <el-form-item
                    prop="longitude"
                    :label="$t('orderCenterCont.longitude')"
                    :rules="{required: modifyForm.longitude ? true : false, validator: longitudeValidator, trigger: ['blur', 'change']}"
                  >
                    <el-input
                      v-model="modifyForm.longitude" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 纬度： -->
                  <el-form-item
                    prop="latitude"
                    :label="$t('orderCenterCont.latitude')"
                    :rules="{required: modifyForm.latitude ? true : false, validator: latitudeValidator, trigger: ['blur', 'change']}"
                  >
                    <el-input
                      v-model="modifyForm.latitude" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 结算周期： -->
                  <el-form-item prop="paymentCycle" :label="$t('collectionCenter.settlementCycle')">
                    <el-input
                      v-model="modifyForm.paymentCycle"
                      placeholder=""
                      clearable
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7" class="NumClass">
                  <!-- 小件揽收范围： -->
                  <el-form-item
                    prop="collectRange"
                    :label="$t('collectionCenter.smallCollectRange')"
                    :rules="{required: collectRangeIsRules, message: $t('collectionCenter.notNull'),trigger:['blur', 'change']}"
                  >
                    <el-input
                      v-model="modifyForm.collectRange"
                      type="textarea"
                      clearable
                      placeholder=""
                      :disabled="!isModify"
                      @blur="checkCollectRange(modifyForm.collectRange, 'lanshou')"
                    /><!-- placeholder="填写对应范围(回车换行分割)" -->
                    <!-- 格式:1000000~22222222或30000000 -->
                    <div v-show="!isCollectRange && modifyForm.siteType != 4" class="zipcodeClass">{{ $t('collectionCenter.format1') }}</div>
                    <div v-show="!isCollectRange && modifyForm.siteType != 4" class="zipcodeClass">{{ $t('collectionCenter.commaSeparationTips') }}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="7" class="NumClass">
                  <!-- 小件派送范围： -->
                  <el-form-item
                    :key="timer"
                    prop="deliveryRange"
                    :label="$t('collectionCenter.smallDeliveryRange')"
                    :rules="{required: deliveryRangeIsRules, message: $t('collectionCenter.notNull'),trigger:['blur', 'change']}"
                  >
                    <el-input
                      v-model="modifyForm.deliveryRange"
                      type="textarea"
                      clearable
                      placeholder=""
                      @blur="checkDeliveryRange(modifyForm.deliveryRange, 'paisong')"
                      @focus="handOut(modifyForm.deliveryRange, 'paisong')"
                    />
                    <div v-show="!isDeliveryRange && modifyForm.siteType != 4" class="zipcodeClass">{{ $t('collectionCenter.format1') }}</div>
                    <div v-show="!isDeliveryRange && modifyForm.siteType != 4" class="zipcodeClass">{{ $t('collectionCenter.commaSeparationTips') }}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="7" class="NumClass">
                  <!-- 大件派送范围： -->
                  <el-form-item
                    :key="timer"
                    prop="bulkyDeliverZipCode"
                    :label="$t('collectionCenter.largeDeliveryRange')"
                    :rules="{required: bulkyRangeIsRules, message: $t('collectionCenter.notNull'),trigger:['blur', 'change']}"
                  >
                    <el-input
                      v-model="modifyForm.bulkyDeliverZipCode"
                      type="textarea"
                      clearable
                      placeholder=""
                      @blur="checkCollectRange(modifyForm.bulkyDeliverZipCode, 'largeDelivery')"
                      @focus="handOut(modifyForm.bulkyDeliverZipCode, 'largeDelivery')"
                    /><!-- placeholder="填写对应范围(回车换行分割)" -->
                    <!-- 格式:1000000~22222222或30000000 -->
                    <div v-show="!isLargeDelivery && modifyForm.siteType != 4" class="zipcodeClass">{{ $t('collectionCenter.format1') }}</div>
                    <div v-show="!isLargeDelivery && modifyForm.siteType != 4" class="zipcodeClass">{{ $t('collectionCenter.commaSeparationTips') }}</div>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 账户余额： -->
                  <el-form-item prop="balance" :label="$t('collectionCenter.accountBalance')">
                    <el-input
                      v-model="modifyForm.balance" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 累积扣款： -->
                  <el-form-item prop="totalDeductions" :label="$t('collectionCenter.accumulationDeduction')">
                    <el-input
                      v-model="modifyForm.totalDeductions" clearable placeholder=""
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 所属转运中心： -->
                  <el-form-item prop="department" :label="$t('collectionCenter.owningTransitCenter')">
                    <el-select
                      v-model="modifyForm.department" placeholder="" clearable
                      filterable :disabled="!isModify" @focus="getDepartment"
                    >
                      <el-option
                        v-for="(item, index) in departmentList"
                        :key="index"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 邮箱 -->
                  <el-form-item :label="$t('collectionCenter.mailbox')" prop="mailBox">
                    <el-input
                      v-model.trim="modifyForm.mailBox"
                      placeholder=""
                      clearable
                      maxlength="64"
                      show-word-limit
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 备用邮箱 -->
                  <el-form-item :label="$t('collectionCenter.spareMailbox')" prop="alternateEmail">
                    <el-input
                      v-model.trim="modifyForm.alternateEmail"
                      placeholder=""
                      clearable
                      maxlength="64"
                      show-word-limit
                      :disabled="!isModify"
                      :rules="{required: isAlternateEmail, message: $t('collectionCenter.notNull'),trigger:'blur'}"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 企业税号： -->
                  <el-form-item :label="$t('collectionCenter.enterpriseTaxNumber')" prop="cnpjNumber">
                    <el-input
                      v-model.trim="modifyForm.cnpjNumber"
                      placeholder=""
                      clearable
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="basecode：" prop="baseCode" class="fileClass">
                    <el-input
                      v-model.trim="modifyForm.baseCode"
                      placeholder=""
                      clearable
                      maxlength="30"
                      show-word-limit
                      :disabled="!isModify"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item :label="$t('base.openingHours')+'：'" class="fileClass">
                    <el-input
                      clearable
                      type="textarea"
                      show-word-limit
                      :value="getWeekStr"
                      :rows="6"
                      @focus="checkTimeFn"
                    />
                  </el-form-item>
                </el-col>
                <!-- 占位——start -->
                <el-col :span="7" />
                <el-col :span="7" />
                <!-- 占位——end -->
              </el-row>
            </el-form>
            <div v-if="isModify" class="submitClickBtn">
              <el-button type="primary" @click.stop="saveClick">{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
              <el-button type="delete" @click.stop="modifyResetClick">{{ $t('collectionCenter.empty') }}</el-button><!-- 清空 -->
              <el-button type="delete" @click.stop="closeClick">{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="codeDialog">
      <el-dialog
        width="55%" :show-close="false" :close-on-click-modal="false"
        :visible.sync="openDialog"
      >
        <div class="el-icon-close closeBtn" @click="closeDialog" />
        <el-button
          v-if="isModify"
          type="primary"
          size="medium"
          @click="saveCodeClick"
        >{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
        <el-button
          v-if="isModify"
          size="medium"
          @click="cancelCodeClick"
        >{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
        <br>
        <!-- 1. 起始邮编不能大于结束邮编；2. 不允许存在重复 -->
        <span v-if="isModify" style="color: #f56c6c;">1. {{ $t('collectionCenter.zipCodeRangeSizePrompts') }}；2. {{ $t('collectionCenter.noDuplicationAllowed') }}</span>
        <el-form
          ref="submitRef"
          :model="psForm"
        >
          <el-table
            ref="tableRef"
            border
            :max-height="600"
            :data="psForm.psTable"
          >
            <el-table-column
              prop="startValue"
              align="center"
              :label="$t('collectionCenter.startZipCode')"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <span v-if="!isModify">{{ scope.row.startValue }}</span>
                  <el-input
                    v-else
                    v-model="scope.row.startValue"
                    clearable
                    :placeholder="$t('collectionCenter.pleaseInput')"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="endValue"
              align="center"
              :label="$t('collectionCenter.endZipCode')"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <span v-if="!isModify">{{ scope.row.endValue }}</span>
                  <el-input
                    v-else
                    v-model="scope.row.endValue"
                    clearable
                    :placeholder="$t('collectionCenter.pleaseInput')"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              v-if="isModify"
              align="center"
              :label="$t('collectionCenter.operation')"
              width="100"
            ><!-- 删除 -->
              <template slot-scope="scope">
                <el-popconfirm
                  :title="$t('collectionCenter.deleteSure')"
                  @confirm="delRow(scope.row,scope.$index)"
                ><!-- 确定删除吗？ -->
                  <div slot="reference" style="color: #da0014;font-size: 15px">{{ $t('collectionCenter.Delete') }}</div><!-- 删除 -->
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div class="addClass">
          <el-button
            v-if="isModify" type="primary" icon="el-icon-plus"
            size="mini" @click="addRow"
          />
        </div>
      </el-dialog>
    </div>
    <el-dialog
      :title="$t('base.openingHours')"
      :visible.sync="siteWeekPop"
      width="800px"
      center
      :close-on-press-escape="!isModify"
      :close-on-click-modal="!isModify"
      :show-close="!isModify"
    >
      <el-form>
        <el-table
          ref="tableRef"
          border
          :max-height="500"
          style="height: 500px"
          :data="modifyForm.siteBusinessTimeData"
        >
          <el-table-column
            prop="startValue"
            align="center"
            :label="$t('GlobalSetObj.Date')"
          >
            <template slot-scope="scope">
              <el-form-item>
                <span v-if="!isModify">{{ scope.row.showVal }}</span>
                <el-select
                  v-else v-model="scope.row.sysDictIds" placeholder=""
                  clearable filterable
                  multiple
                >
                  <el-option
                    v-for="item in weekList"
                    :key="item.dictId"
                    :label="item.dictDescribe"
                    :value="item.dictId"
                    :disabled="modifyForm.siteBusinessTimeData.some((item1, index) => item1.sysDictIds.includes(item.dictId) && index !== scope.$index)"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="endValue"
            align="center"
            :label="$t('trajectory.Time')"
          >
            <template slot-scope="scope">
              <el-form-item>
                <span v-if="!isModify">{{ scope.row.businessStartTime+` ~ `+scope.row.businessEndTime }}</span>
                <template v-else>
                  <el-time-picker
                    v-model="scope.row.businessStartTime"
                    value-format="HH:mm"
                    format="HH:mm"
                    :placeholder="$t('collectionCenter.StartTime')"
                    @change="(val)=>changeStartTime(val,scope.$index)"
                  />
                  <el-time-picker
                    v-model="scope.row.businessEndTime"
                    value-format="HH:mm"
                    format="HH:mm"
                    :placeholder="$t('collectionCenter.EndTime')"
                    @change="(val)=>changeEndTime(val,scope.$index)"
                  />
                </template>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isModify"
            align="center"
            :label="$t('collectionCenter.operation')"
            width="100"
          ><!-- 删除 -->
            <template slot-scope="scope">
              <el-popconfirm
                :title="$t('collectionCenter.deleteSure')"
                @confirm="deleteWeekRow(scope.row,scope.$index)"
              ><!-- 确定删除吗？ -->
                <div slot="reference" style="color: #da0014;font-size: 15px;cursor: pointer">{{ $t('collectionCenter.Delete') }}</div><!-- 删除 -->
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
       <div v-if="modifyForm.siteBusinessTimeData.length<7" class="addClass">
        <el-button
          v-if="isModify" type="primary" icon="el-icon-plus"
          size="mini" @click="addWeekRow"
        />
      </div>
      <span v-if="isModify" slot="footer" class="dialog-footer">
        <el-button @click="resetBusinessHours">{{ $t('collectionCenter.cancel') }}</el-button>
        <el-button type="primary" @click="saveBusinessHours">{{ $t('collectionCenter.Save') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  siteAdd,
  siteEdit,
  siteExport,
  siteQuery,
  getSiteTree,
  providerList,
  getProviderChannel,
  sortSiteTree,
  getTransitCenter,
  siteBusinessTimeGetAllBusinessWeek
} from '@/api/lanshou';
import { checkMoney, checkCode, phoneNumberCode } from '@/utils/validate';
import { MessageBox } from 'element-ui';
import { cloneDeep } from 'lodash-es';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'Site',
  data() {
    const longitudeValidator = (rule, value, callback) => {
      if (!this.modifyForm.longitude) {
        return callback();
      }
      const reg = /^[-+]?(0?\d{1,2}(?:\.\d{1,7})?|1[0-7]\d(?:\.\d{1,7})?|180(?:\.0{1,7})?)$/;
      if (!reg.test(value) || value < -180 || value > 180) {
        return callback(new Error(this.$t('collectionCenter.longitudeInputError'))); // 请输入正确的经度
      } else {
        return callback();
      }
    };
    const latitudeValidator = (rule, value, callback) => {
      if (!this.modifyForm.latitude) {
        return callback();
      }
      const reg = /^[-+]?([0-8]?\d(?:\.\d{1,7})?|90(?:\.0{1,7})?)$/;
      if (!reg.test(value) || value < -90 || value > 90) {
        return callback(new Error(this.$t('collectionCenter.latitudeInputError'))); // 请输入正确的纬度
      } else {
        return callback();
      }
    };
    return {
      handOutModel: '', // 小件派送范围 / 大件派送范围 弹窗
      longitudeValidator,
      latitudeValidator,
      isClickAll: false,
      isAlternateEmail: false,
      notEnterS: true, // 是否允许换行
      notEnterC: true, // 是否允许换行
      timer: '',
      psForm: {
        psTable: []
      },
      openDialog: false, // 派送邮编弹框
      collectRangeIsRules: true, // 当网点类型为总部则揽收范围非必填  小件揽收范围
      deliveryRangeIsRules: true, // 当网点类型为总部则派送范围非必填  小件派送范围
      bulkyRangeIsRules: true, // 大件派送范围
      departmentList: [], // 转运中心
      modifyFormCopy: {}, // 新增取消后提交表单被清空，无法展示
      placeholderList: [], // 占位的上一级(展开父节点)
      highlightCurrent: true, // 高亮
      defaultOpen: [], // 默认展开总部—2
      currentNodekey: '', // 默认高亮显示总部
      treeData: [],
      treeNode: '',
      defaultProps: {
        children: 'children', // 指定子树为节点对象的某个属性值
        label: 'providerCode' // 指定节点标签为节点对象的某个属性值
      },
      isDeliveryRange: true, // 派送范围是否正确
      isCollectRange: true, // 揽收范围是否正确
      isLargeDelivery: true,
      rules: {
        providerChannelCode: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: 'change' }],
        code: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!checkCode(value)) {
                // '请填写长度6至30位的字母+数字组合'
                return callback(new Error(this.$t('collectionCenter.inputLong6')));
              } else {
                callback();
              }
            }
          }
        ],
        name: [
          // 长度不能少于4位，不能大于100位
          { required: true, min: 4, max: 100, message: this.$t('collectionCenter.long4to100'), trigger: ['blur', 'change'] }
        ],
        contactsTel: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !phoneNumberCode(value)) {
                return callback(new Error(this.$t('collectionCenter.brazilPhoneRestrict'))); // 长度不低于10位，允许包含+-空格等特殊字符
              } else {
                callback();
              }
            }
          }
        ],
        businessType: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        siteType: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: 'change' }
        ],
        businessModel: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        department: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        zipCode: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^([0-9]{8})$/;
              if (!reg.test(value)) {
                // '请输入8位邮编'
                return callback(new Error(this.$t('collectionCenter.input8ZipCode')));
              } else {
                callback();
              }
            }
          }
        ],
        state: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        city: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        address: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        longitude: [
          { required: true, validator: longitudeValidator, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        latitude: [
          { required: true, validator: latitudeValidator, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        businessPrincipal: [{ required: true, message: this.$t('placeOrder.notNull'), trigger: 'change' }],
        mailBox: [
          { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^([a-zA-Z0-9_-])+\.(com)+((.br)?)$/;
              const val = value.split('@');
              if (!reg.test(val[1])) {
                return callback(new Error(this.$t('placeOrder.emailNotFormat')));
              } else {
                callback();
              }
            }
          }
        ],
        alternateEmail: [
          { required: this.isAlternateEmail, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const reg = /^([a-zA-Z0-9_-])+\.(com)+((.br)?)$/;
                const val = value.split('@');
                if (!reg.test(val[1])) {
                  return callback(new Error(this.$t('placeOrder.emailNotFormat')));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            }
          }
        ],
        cnpjNumber: [
          { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              let index = 0;
              for (const val of Array.from(value)) {
                const reg = /^[0-9]$/;
                if (reg.test(val)) {
                  index++;
                } else {
                  const regs = /^[\.\-\/]$/;
                  if (!regs.test(val)) {
                    return callback(new Error(this.$t('collectionCenter.enterpriseTaxNumTips'))); // 数字长度为14，包含.-/等特殊字符
                  }
                }
              }
              if (index !== 14) {
                return callback(new Error(this.$t('collectionCenter.enterpriseTaxNumTips')));
              }
              return callback();
            }
          }
        ],
        balance: [
          { required: false, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !checkMoney(value)) {
                return callback(new Error(this.$t('collectionCenter.hint'))); // '请填写数字，整数部分最大长度9位，可保留两位有效小数'
              } else {
                callback();
              }
            }
          }
        ],
        totalDeductions: [
          { required: false, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !checkMoney(value)) {
                return callback(new Error(this.$t('collectionCenter.hint'))); // '请填写数字，整数部分最大长度9位，可保留两位有效小数'
              } else {
                callback();
              }
            }
          }
        ],
        paymentCycle: [
          // '不能为空'
          { required: false, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^[1-9]+[0-9]*$/;
              if (value && !reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.pleaseInputInteger'))); // '请输入正整数'
              }
              if (value && value.length > 10) {
                return callback(new Error(this.$t('collectionCenter.input10Number'))); // '请输入最多10位正整数'
              }
              if (value && (Number(value)) < 0) {
                return callback(new Error(this.$t('collectionCenter.pleaseInputInteger'))); // 请输入正整数
              }
              return callback();
            }
          }
        ],
        baseCode: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^[A-Za-z\d\-]{0,30}$/;
              if (!reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.compatibleBasecode'))); // 兼容字母、特殊字符（-）数字的组合，最大长度为30
              } else {
                return callback();
              }
            }
          }
        ]
      },
      queryForm: {
        code: '', // 网点编号
        name: '', // 网点名称
        siteStatus: '', // 状态
        deliveryZipcode: '', // 派送邮编
        state: '',
        city: '',
        businessWeekDictId: '', // 营业星期字典ID
        businessTime: '' // 营业时间
      },
      StatusList: [
        { siteStatus: 1, siteStatusName: this.$t('collectionCenter.enable') }, // '启用'
        { siteStatus: 0, siteStatusName: this.$t('collectionCenter.notEnabled') } // '未启用'
      ],
      modifyForm: {
        code: '', // 网点编号
        name: '', // 网点名称
        zipCode: '', // 邮编
        state: '', // 州
        city: '', // 城市
        area: '', // 区
        street: '', // 街道/地址
        address: '', // 网点地址
        siteType: '', // 操作类型 0：揽收 1：退件'
        department: '', // 所属转运中心
        bank: '', // 开户银行
        siteStatus: 0, // 网点状态 0：未启用 1启用
        contactsName: '', // 负责人"
        contactsTel: '', // 负责人联系方式
        deliveryRange: '', // 派送范围
        collectRange: '', // 揽收范围
        bulkyDeliverZipCode: '', // 大件派送范围
        businessModel: '', // 经营模式
        longitude: '', // 经度
        latitude: '', // 纬度
        balance: '', // 账户余额
        totalDeductions: '', // 累计扣款
        businessPrincipal: '', // 业务主体
        businessType: [], // 业务类型
        mailBox: '',
        alternateEmail: '', // 备用邮箱
        cnpjNumber: '',
        baseCode: '',
        paymentCycle: '',
        providerChannelCode: '', // 服务商渠道
        baseDistance: '', // 邮编距离
        deliveryDifficulty: 0, // 地域类型 (0.风险 1.无风险 2.农村地区)
        riskRate: 0.21, // 风险比例
        siteBusinessTimeData: [] // 营业时间
      },
      isModify: false, // 是否展示修改界面
      saveBtnName: '', // 根据设置保存按钮名称调用新增/编辑接口
      businessPrincipalList: [], // 业务主体列
      providerChannelNameList: [], // 渠道列
      // 业务主体选择影响经营模式选项
      isAll: 0, // 1 自营,  2和3 加盟
      parentId: '',
      isBusinessModel: '', // 1 直营  2 加盟
      parentNode: '', // 当前拖动节点的父节点
      copyTree: [], // 取消拖拽后将结构树赋值过去
      weekList: [], // 网点星期数据
      weekListObj: {}, // 网点星期数据
      siteWeekPop: false
    };
  },
  computed: {
    getWeekStr() {
      let weekStr = '';
      if (this.modifyForm.siteBusinessTimeData && this.modifyForm.siteBusinessTimeData.length > 0) {
        this.modifyForm.siteBusinessTimeData.forEach(item => {
          if (Object.prototype.hasOwnProperty.call(this.weekListObj, item.dictData)) {
            weekStr += `${this.weekListObj[item.sysDictId]}：${item.businessStartTime} ~ ${item.businessEndTime}\n`;
          } else {
            // 多选
            // console.log('No matching key for:', item.dictData); // 调试输出
            if (!item.sysDictIds) return;
            const all = [];
            if (item.sysDictIds) {
              item.sysDictIds.forEach(arr => {
                all.push(this.weekListObj[arr]);
              });
            } else {
              item.dictData.forEach(arr => {
                all.push(this.weekListObj[arr.sysDictId]);
              });
            }
            weekStr += `${all.join('、')}：${item.businessStartTime} ~ ${item.businessEndTime}\n`;
          }
        });
      }
      return weekStr.trim(); // 去除最后一个换行符
    }
  },
  watch: {
    'queryForm.state'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 50) {
        number = bghTrim.slice(0, 50).join('\n');
        this.queryForm.state = number;
        this.notEnterS = false;
      } else {
        this.notEnterS = true;
      }
      if (bghTrim.length > 50) {
        this.$message.warning(this.$t('collectionCenter.intercept50')); // '单次查询最多50条，已截取前50条数据'
      }
    },
    'queryForm.city'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 50) {
        number = bghTrim.slice(0, 50).join('\n');
        this.queryForm.city = number;
        this.notEnterC = false;
      } else {
        this.notEnterC = true;
      }
      if (bghTrim.length > 50) {
        this.$message.warning(this.$t('collectionCenter.intercept50')); // '单次查询最多50条，已截取前50条数据'
      }
    },

    // 校验有问题，有值时强制清除为空校验
    'modifyForm.businessPrincipal'() {
      if (this.modifyForm.businessPrincipal && this.isModify) {
        this.$nextTick(() => {
          this.$refs['modifyFormRef'].clearValidate('businessPrincipal');
        });
      }
    },
    'modifyForm.providerChannelCode'() {
      if (this.modifyForm.providerChannelCode && this.isModify) {
        this.$nextTick(() => {
          this.$refs['modifyFormRef'].clearValidate('providerChannelCode');
        });
      }
    },
    'modifyForm.businessModel'() {
      if (this.modifyForm.businessModel && this.isModify) {
        this.$nextTick(() => {
          this.$refs['modifyFormRef'].clearValidate('businessModel');
        });
      }
    },
    'modifyForm.siteType'() {
      if (this.modifyForm.siteType && this.isModify) {
        this.$nextTick(() => {
          this.$refs['modifyFormRef'].clearValidate('siteType');
        });
      }
    },
    'modifyForm.businessType'() {
      if (this.modifyForm.businessType && this.isModify) {
        this.$nextTick(() => {
          this.$refs['modifyFormRef'].clearValidate('businessType');
        });
      }
    },
    'modifyForm.alternateEmail'(val) {
      if (val) {
        this.isAlternateEmail = true;
      } else {
        this.isAlternateEmail = false;
        this.$nextTick(() => {
          this.$refs['modifyFormRef'].clearValidate('alternateEmail');
        });
      }
    }
  },
  created() {
    localStorage.setItem('siteQueryForm', JSON.stringify(this.queryForm));
    this.isAll = 0;
    this.isModify = false;
    this.siteTree();
    this.getProviderList();
    this.getDepartment();
    // 获取网点营业日期数据
    this.getSiteWeekList();
    this.isClickAll = false;
  },
  mounted() {
    this.dragenter();
  },
  methods: {
    checkTimeFn() {
      this.siteWeekPop = true;
      if (!this.isModify) {
        const siteBusinessTimeData = this.modifyForm.siteBusinessTimeData.map(arr => {
          const dic = arr.dictData.map(item => {
            return item.dictDescribe;
          });
          const showVal = dic.join('、');
          return {
            showVal,
            ...arr
          };
        });
        this.modifyForm.siteBusinessTimeData = siteBusinessTimeData;
      }
    },
    ftS() {
      const val = this.queryForm.state.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.state = bghTrim.join('\n');
    },
    BlockNewStateline() { // 文本域最大输入行数为50
      const e = window.event || arguments[0];
      this.ftS();
      const numberLen = this.queryForm.state.split('\n');
      if (!this.notEnterS) {
        if (numberLen.length >= 50) {
          this.$message.warning(this.$t('collectionCenter.intercept50')); // '单次查询最多50条，已截取前50条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    ftC() {
      const val = this.queryForm.city.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      this.queryForm.city = numLen.join('\n');
    },
    BlockNewCityline() {
      const e = window.event || arguments[0];
      this.ftC();
      const numberLen = this.queryForm.city.split('\n');
      if (!this.notEnterC) {
        if (numberLen.length >= 50) {
          this.$message.warning(this.$t('collectionCenter.intercept50')); // '单次查询最多50条，已截取前50条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    closeDialog() {
      this.cancelCodeClick();
    },
    handOut(value, model) {
      this.handOutModel = model;
      this.openDialog = true;
      this.psForm.psTable = [];
      if (!value) return;
      const dr = value.split(',');
      dr.forEach((arr) => {
        const arrL = arr.split('~');
        const start = arrL[0];
        const end = arrL[1] ? arrL[1] : arrL[0];
        this.psForm.psTable.push({
          startValue: start,
          endValue: end
        });
      });
    },
    delRow(row, index) {
      this.psForm.psTable.splice(index, 1);
    },
    addRow() {
      const addRow = { startValue: '', endValue: '' };
      this.psForm.psTable.push(addRow);
    },
    saveCodeClick() {
      const pj = [];
      let greaterThan = '';
      this.psForm.psTable.forEach((dr) => {
        if (dr.startValue > dr.endValue) {
          greaterThan = 1;
        }
        if (dr.startValue === dr.endValue) {
          pj.push(dr.startValue);
        } else if (dr.startValue && !dr.endValue) {
          pj.push(dr.startValue);
        } else if (!dr.startValue && dr.endValue) {
          pj.push(dr.endValue);
        } else {
          pj.push(dr.startValue + '~' + dr.endValue);
        }
      });
      const range = pj.filter(Boolean).join(',');
      if (this.handOutModel === 'paisong') {
        this.checkDeliveryRange(range, 'paisong');
      }
      if (this.handOutModel === 'largeDelivery') {
        this.checkCollectRange(range, 'largeDelivery');
      }
      if (this.handOutModel === 'paisong' && this.isDeliveryRange) {
        this.openDialog = false;
        this.modifyForm.deliveryRange = range;
        this.timer = new Date().getTime();
      } else if (this.handOutModel === 'largeDelivery' && this.isLargeDelivery) {
        this.openDialog = false;
        this.modifyForm.bulkyDeliverZipCode = range;
        this.timer = new Date().getTime();
      } else {
        /**
         * 做细致划分提示：
         * 起始邮编不能大于结束邮编；
         * 不允许存在重复；
        */
        const codeVal = pj.filter(Boolean);
        const indexVal = this.useIndexOf(codeVal);
        if ((new Set(codeVal)).size !== codeVal.length) {
          this.$message.warning(this.$t('collectionCenter.noDuplicationAllowed') + '，' + this.$t('collectionCenter.repeat') + indexVal);
        } else if (greaterThan) {
          this.$message.warning(this.$t('collectionCenter.zipCodeRangeSizePrompts'));
        } else {
          this.$message.warning(this.$t('collectionCenter.format1')); // 格式:1000000~22222222或30000000
        }
        return;
      }
    },
    useIndexOf(Arr) {
      const len = Arr.length;
      const result = [];
      for (let i = 0; i < len; i++) {
        const curNum = Arr[i];
        // 遍历到Arr[i]时，若在这个索引i之后都没找到相同数字，则indexOf会返回-1，说明改数字不存在重复
        if (Arr.indexOf(curNum, i + 1) !== -1) {
          result.push(curNum);
        }
      }
      return Array.from(new Set(result));
    },
    cancelCodeClick() {
      if (!this.isModify) {
        this.$refs['modifyFormRef'].clearValidate();
      }
      this.openDialog = false;
      this.psForm.psTable = [];
    },

    changeVerify() {
      const businessType = this.modifyForm.businessType.toString();
      if (this.modifyForm.siteType === 4 || this.modifyForm.siteType === 3) {
        /**
         * 1、当网点类型为总部则揽收范围、派送范围非必填
         */
        this.collectRangeIsRules = false;
        this.deliveryRangeIsRules = false;
        this.bulkyRangeIsRules = false;
        this.$refs['modifyFormRef'].clearValidate('collectRange');
        this.$refs['modifyFormRef'].clearValidate('deliveryRange');
        this.$refs['modifyFormRef'].clearValidate('bulkyDeliverZipCode');
      } else if (businessType) {
        if (businessType === '2' && this.modifyForm.siteType !== 4) {
          /**
           * 2、当业务类型为揽收时，派送范围非必填
           */
          this.collectRangeIsRules = true;
          this.deliveryRangeIsRules = false;
          this.bulkyRangeIsRules = false;
          this.$refs['modifyFormRef'].clearValidate('deliveryRange');
          this.$refs['modifyFormRef'].clearValidate('bulkyDeliverZipCode');
        } else if (businessType.indexOf('3') > -1 && this.modifyForm.siteType !== 4) {
          /**
           * 3、当业务类型中含派送时，派送范围必填且校验不允许重复————小件派送
           */
          this.deliveryRangeIsRules = true;
        } else if (businessType.indexOf('4') > -1 && this.modifyForm.siteType !== 4) {
          /**
           * 3、当业务类型中含派送时，派送范围必填且校验不允许重复————大件派送
           */
          this.bulkyRangeIsRules = true;
        } else if (businessType.indexOf('4') < 0) {
          this.bulkyRangeIsRules = false;
          this.$refs['modifyFormRef'].clearValidate('bulkyDeliverZipCode');
        } else if (businessType.indexOf('2') < 0) {
          this.collectRangeIsRules = false;
          this.$refs['modifyFormRef'].clearValidate('collectRange');
        } else if (businessType.indexOf('3') < 0) {
          this.deliveryRangeIsRules = false;
          this.$refs['modifyFormRef'].clearValidate('deliveryRange');
        }
      } else {
        this.bulkyRangeIsRules = true;
        this.collectRangeIsRules = true;
        this.deliveryRangeIsRules = true;
      }
    },
    getDepartment() { // 转运中心
      getTransitCenter().then(res => {
        if (res.status === 'OK') {
          this.departmentList = res.data;
        }
      }).catch({});
    },
    getProviderList() {
      providerList().then(res => {
        this.businessPrincipalList = [];
        if (res.status === 'OK') {
          res.data.forEach(arr => {
            this.businessPrincipalList.push({
              providerCode: arr.providerBody,
              providerId: arr.id
            });
          });
        }
      });
    },
    // 网点拖拽默认禁用图标
    dragenter() {
      let outer = document.getElementsByClassName('treeClass');
      outer = Array.from(outer);
      outer.forEach(function(item) {
        item.ondragover = dragover;
      });
      function dragover(event) {
        // 阻止默认行为，变成可放置的
        event.preventDefault();
      }
    },
    handleDragStart(node, ev) {
      this.copyTree = cloneDeep(this.treeData);
      this.parentNode = this.treeData[0];
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      const boxScreen = this.$refs.treeBoxRef.getBoundingClientRect();
      const x = boxScreen.right;
      const y = boxScreen.bottom;
      if (ev.x > x || ev.y > y || ev.x < boxScreen.left) {
        this.$message.warning(this.$t('collectionCenter.outRange')); // '超出范围'
        this.treeData = this.copyTree;
        return;
      } else if (dropNode.data.id === this.parentNode.id || dropType === 'none') {
        this.$message.warning(this.$t('collectionCenter.allowCreatePrompt')); // '只允许建立总部下的同一层级或者其下级网点'
        this.treeData = this.copyTree;
        return;
      } else {
        // 共四个参数，依次为：被拖拽节点对应的Node、结束拖拽时最后进入的节点、被拖拽节点的放置位置（before、after、inner）、event
        // before、after时，节点父id 为 dropNode.data.parentId
        // inner时，节点父id 为 dropNode.data.id
        if (dropType === 'inner') {
          draggingNode.data.parentId = dropNode.data.id;
          sortSiteTree(this.treeData).then(res => {
            // console.log(res, 'res');
          });
        } else if (dropType === 'before' || dropType === 'after') {
          draggingNode.data.parentId = dropNode.data.parentId;
          sortSiteTree(this.treeData).then(res => {
            // console.log(res, 'res');
          });
        }
      }
    },
    checkProviderChannelValue() { // 服务商
      if (!this.modifyForm.businessPrincipal) {
        this.$message.warning(this.$t('collectionCenter.chooseChannel')); // '请选择业务主体'
        this.providerChannelNameList = [];
      } else {
        if (this.modifyForm.businessPrincipal) {
          getProviderChannel(this.modifyForm.businessPrincipal).then(res => {
            if (res.status === 'OK' && res.data.providerChannelInfoList) {
              // 服务商渠道
              const channelList = [];
              res.data.providerChannelInfoList.forEach(list => {
                channelList.push({
                  name: list.providerChannelName,
                  code: list.providerChannelCode
                });
              });
              this.providerChannelNameList = channelList;
            }
          });
        }
      }
    },
    closeClick() {
      if (this.saveBtnName === 'add' || this.saveBtnName === 'edit') {
        this.modifyForm = JSON.parse(this.modifyFormCopy);
        this.$refs['modifyFormRef'].clearValidate();
      }
      this.isModify = false;
      this.saveBtnName = '';

      this.$nextTick(() => {
        this.$refs['modifyFormRef'].clearValidate();
      });
      // this.treeNode = '';
      // this.highlightCurrent = false;
    },
    siteTree() { // 获取网点结构树
      this.highlightCurrent = true;
      const form = JSON.parse(localStorage.getItem('siteQueryForm')) || this.queryForm;
      getSiteTree(form).then(res => {
        if (res.status === 'OK') {
          if (!this.queryForm.code && !this.queryForm.name && !this.queryForm.deliveryZipcode && !this.queryForm.state && !this.queryForm.city && (this.queryForm.siteStatus == 1 || this.queryForm.siteStatus == 0)) {
            localStorage.setItem('treeDataCopy', JSON.stringify(res.data));
          }
          if ((!this.queryForm.code && !this.queryForm.name && !this.queryForm.deliveryZipcode && (this.queryForm.siteStatus == 1 || this.queryForm.siteStatus == 0)) || (this.queryForm.state || this.queryForm.city)) {
            this.treeData = res.data;
          } else if ((!form.code && !form.name && !form.deliveryZipcode && (form.siteStatus == 1 || form.siteStatus == 0)) || (form.state || form.city)) {
            this.treeData = res.data;
          }
          this.parentId = this.parentId ? this.parentId : this.treeData[0].id;
          this.defaultOpen.push(this.treeData[0].id); // 默认展开总部—4
          this.currentNodekey = this.currentNodekey ? this.currentNodekey : this.treeData[0].id; // 默认高亮显示第一层
          this.$nextTick(() => {
            this.$refs.treeRef.setCurrentKey(this.currentNodekey);
          });

          const ssData = this.flatten(this.treeData);
          let isShow = false;
          ssData.map(arr => {
            if (this.currentNodekey === arr.id) {
              isShow = true;
            }
          });
          // 默认显示总部数据
          let dataForm = {};
          if (isShow) {
            dataForm = {
              id: this.currentNodekey
            };
          } else {
            dataForm = {
              id: this.treeData[0].id
            };
          }
          this.$nextTick(() => {
            this.$refs.treeRef.setCurrentKey(dataForm.id);
          });
          this.getSite(dataForm);
        }
      }).catch({});
    },
    /**
     * 配平
     * @param data
     * @param node
     */
    flatten(arr) {
      return arr.reduce((result, item) => {
        return result.concat(item, (Array.isArray(item.children) ? this.flatten(item.children) : []));
      }, []);
    },
    handleNodeClick(data, node) { // 左侧结构树
      this.placeholderList = data;
      this.treeNode = data.code; // 判断编辑是否选中网点
      this.isModify = false;
      if (data.isProvider === 1) {
        this.treeNode = '';
      }
      // if (!data.id || data.isProvider === 1) return; // 点击供应商无效
      // this.parentId = node.parent.data.id ? node.parent.data.id : 0;
      this.parentId = data.id ? data.id : 0;
      const dataForm = {
        id: data.id
      };
      this.getSite(dataForm);
      this.currentNodekey = data.id; // 保存后展示被选中的网点
    },
    getSite(dataForm) {
      this.highlightCurrent = true;
      siteQuery(dataForm).then(res => {
        this.isBusinessModel = res.data.businessModel;
        if (res.status === 'OK') {
          // 需编辑
          this.modifyForm = {
            id: res.data.id,
            code: res.data.code, // 网点编号
            name: res.data.name, // 网点名称
            zipCode: res.data.zipCode, // 邮编
            state: res.data.state, // 州
            city: res.data.city, // 城市
            area: res.data.area, // 区
            street: res.data.street, // 街道/地址
            siteType: res.data.siteType, // 操作类型 0：揽收 1：退件'
            department: res.data.department, // 所属转运中心
            bank: res.data.bank, // 开户银行
            siteStatus: res.data.siteStatus, // 网点状态 0：未启用 1启用
            contactsName: res.data.contactsName, // 负责人"
            contactsTel: res.data.contactsTel, // 负责人联系方式
            deliveryRange: res.data.deliveryRange, // 派送范围
            collectRange: res.data.collectRange, // 揽收范围
            bulkyDeliverZipCode: res.data.bulkyDeliverZipCode, // 大件派送范围
            businessModel: res.data.businessModel, // 经营模式
            address: res.data.address, // 网点地址
            longitude: res.data.longitude, // 经度
            latitude: res.data.latitude, // 纬度
            balance: res.data.balance, // 账户余额
            totalDeductions: res.data.totalDeductions, // 累计扣款
            // businessPrincipal: node.parent.data.name + ',' + node.parent.data.id, // 业务主体
            businessPrincipal: res.data.providerId, // 业务主体
            businessType: res.data.businessType ? res.data.businessType.split(',').map(Number) : '', // 业务类型
            mailBox: res.data.mailBox,
            alternateEmail: res.data.alternateEmail,
            cnpjNumber: res.data.cnpjNumber,
            baseCode: res.data.baseCode,
            paymentCycle: res.data.paymentCycle,
            providerChannelCode: res.data.providerChannelCode, // 服务商渠道
            baseDistance: res.data.baseDistance, // 邮编距离
            deliveryDifficulty: res.data.deliveryDifficulty, // 地域类型 (0.风险 1.无风险 2.农村地区)
            riskRate: res.data.riskRate,
            siteBusinessTimeData: res.data.businessTimeData ? res.data.businessTimeData : [] // 营业时间
          };
          this.modifyFormCopy = JSON.stringify(this.modifyForm);
          setTimeout(() => {
            this.$refs['modifyFormRef'].clearValidate();
          }, 100);

          setTimeout(() => {
            const siteDom = document.querySelector('.el-tree-node.is-current');
            const domScroll = document.querySelector('.highlightClass');
            const movePX = siteDom.offsetTop > 360 ? siteDom.offsetTop - 150 : 0;
            domScroll.scrollTop = movePX;
          }, 200);
          // 获取服务商渠道
          this.getProviderChannelList(res.data.providerId);
          this.changeVerify();
        }
      }).catch({});
    },
    checkDeliveryRange(value, model) { // 检查派送范围
      this.checkValue(value, model);
    },
    checkCollectRange(value, model) { // 检查揽收范围
      this.checkValue(value, model);
    },
    checkValue(value, model) {
      if (value) {
        const zipcode = value.split(',');
        const reg = /^([0-9]{8})+((~[0-9]{8}){0,1})$/;
        const isAll = [];
        // console.log('是否有重复', (new Set(zipcode)).size !== zipcode.length, (new Set(zipcode)).size, zipcode.length);
        // 整体数据里有重复值
        if ((new Set(zipcode)).size !== zipcode.length) {
          isAll.push(0);
        }
        // const single = [];
        // const arr = [];
        zipcode.forEach((code) => {
          const codeSplit = code.split('~');
          if (!code) return;
          // // 将单个数分为一组，数组分为一组
          // if (code.includes('~')) {
          //   console.log('code', code, 'codeSplit', codeSplit);
          //   // 1、数组对
          //   arr.push(codeSplit);
          // } else {
          //   // 2、单个对
          //   single.push(code);
          // }
          if (!reg.test(code)) {
            // console.log('no');
            isAll.push(0);
          } else if (!reg.test(code) && code.indexOf('~') === -1 && code.length !== 8) {
            // console.log('no');
            isAll.push(0);
          } else if (!reg.test(code) && code.indexOf('~') !== -1 && code.length !== 17) {
            // console.log('no');
            isAll.push(0);
          } else if (codeSplit[0] > codeSplit[1]) {
            isAll.push(0);
          } else {
            // console.log('ok');
            isAll.push(1);
          }
        });
        // 0 no  /  1 ok
        if (isAll.indexOf(0) !== -1) {
          if (model === 'paisong') { this.isDeliveryRange = false; }
          if (model === 'lanshou') { this.isCollectRange = false; }
          if (model === 'largeDelivery') { this.isLargeDelivery = false; }
        } else {
          if (model === 'paisong') { this.isDeliveryRange = true; }
          if (model === 'lanshou') { this.isCollectRange = true; }
          if (model === 'largeDelivery') { this.isLargeDelivery = true; }
        }
      }
    },
    searchClick() { // 查询
      this.highlightCurrent = true;
      this.isModify = false;
      localStorage.setItem('siteQueryForm', JSON.stringify(this.queryForm));
      if (this.queryForm.businessWeekDictId && !this.queryForm.businessTime) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectBusinessTime')); // '请选择营业时间'
        return;
      } else if (!this.queryForm.businessWeekDictId && this.queryForm.businessTime) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectBusinessWeek')); // '请选择营业时间'
        return;
      }
      getSiteTree(this.queryForm).then((res) => {
        if (res.status === 'OK') {
          if (res.data.length === 0) {
            this.$message.error(this.$t('collectionCenter.nullMsg')); // '暂无数据'
            // this.currentNodekey = '';
            // this.highlightCurrent = false;
            // this.modifyForm = {};
            // this.$nextTick(() => {
            //   this.$refs['modifyFormRef'].clearValidate();
            // });
            this.$nextTick(() => {
              // 默认打开第一个网点信息
              this.defaultOpen.push(this.treeData[0].id);
              this.currentNodekey = this.treeData[0].id;
              this.$refs.treeRef.setCurrentKey(this.currentNodekey);
              dataForm = {
                id: this.treeData[0].id
              };
              this.getSite(dataForm);
            });
            const queryForm = {
              code: '', // 网点编号
              name: '', // 网点名称
              siteStatus: '', // 状态
              deliveryZipcode: '', // 派送邮编
              state: '',
              city: ''
            };
            localStorage.setItem('siteQueryForm', JSON.stringify(queryForm));
            return;
          }
          let dataForm = {};
          if (!this.queryForm.code && !this.queryForm.name && !this.queryForm.deliveryZipcode && !this.queryForm.state && !this.queryForm.city && (this.queryForm.siteStatus == 1 || this.queryForm.siteStatus == 0)) {
            localStorage.setItem('treeDataCopy', JSON.stringify(res.data));
          }
          if ((!this.queryForm.code && !this.queryForm.name && !this.queryForm.deliveryZipcode && (this.queryForm.siteStatus == 1 || this.queryForm.siteStatus == 0)) || (this.queryForm.state || this.queryForm.city)) {
            this.treeData = res.data;
            this.$nextTick(() => {
              // 默认打开第一个网点信息
              this.defaultOpen.push(this.treeData[0].id);
              this.currentNodekey = this.treeData[0].id;
              this.$refs.treeRef.setCurrentKey(this.currentNodekey);
              dataForm = {
                id: this.treeData[0].id
              };
            });
          } else {
            this.treeData = JSON.parse(localStorage.getItem('treeDataCopy'));
            this.$nextTick(() => {
              this.defaultOpen.push(res.data[0].id);
              this.currentNodekey = res.data[0].id;
              this.$refs.treeRef.setCurrentKey(this.currentNodekey);
              dataForm = {
                id: res.data[0].id
              };
            });
          }
          this.$nextTick(() => {
            this.getSite(dataForm);
          });
        }
      }).catch({});
    },
    resetClick() {
      // 重置
      this.queryForm = {
        code: '', // 网点编号
        name: '', // 网点名称
        siteStatus: '', // 状态
        deliveryZipcode: '', // 派送邮编
        state: '',
        city: '',
        businessWeekDictId: '', // 营业星期字典ID
        businessTime: '' // 营业时间
      };
    },
    editClick() {
      if (!this.treeNode && this.isModify) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectDotToEdit')); // '请选择网点进行编辑'
        return;
      }
      this.isModify = true;
      this.saveBtnName = 'edit';
    },
    addClick() { // 新增
      // if (this.saveBtnName === 'add') return;
      if (this.modifyForm.id && this.isModify) {
        //             是否放弃编辑内容？
        this.$confirm(this.$t('collectionCenter.ifGiveUp'), this.$t('collectionCenter.tips'), {
          confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
          cancelButtonText: this.$t('collectionCenter.cancel') // '取消'
        }).then(() => {
          this.modifyResetClick();
          this.isModify = true;
          this.saveBtnName = 'add';
          this.treeNode = '';
          this.highlightCurrent = false;
        }).catch(() => {
        });
      } else {
        this.modifyResetClick();
        this.isModify = true;
        this.saveBtnName = 'add';
      }
      // 有父节点就展开
      if (this.$refs.treeRef.getNode(this.placeholderList) && this.$refs.treeRef.getNode(this.placeholderList).parent) {
        // console.log('selected', this.placeholderList);
        this.expandParents(this.$refs.treeRef.getNode(this.placeholderList));
      }
    },
    changeSiteType() { // 切换网点类型时清空业务类型
      this.$forceUpdate();
      this.modifyForm.businessType = [];
      this.changeVerify();
    },
    changeBusinessModel() { // 切换经营模式清空网点类型
      this.$forceUpdate();
      if (this.modifyForm.businessModel === 2) {
        this.modifyForm.siteType = '';
        this.$nextTick(() => {
          this.$refs['modifyFormRef'].clearValidate('siteType');
        });
      }
    },
    changeBusinessType() {
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$refs['modifyFormRef'].clearValidate('businessType');
      });
      if (this.modifyForm.siteType === 4) return;
      const businessType = this.modifyForm.businessType.toString();
      if (businessType === '1') {
        this.deliveryRangeIsRules = false;
        this.collectRangeIsRules = false;
        this.bulkyRangeIsRules = false;
        this.$refs['modifyFormRef'].clearValidate('collectRange');
        this.$refs['modifyFormRef'].clearValidate('deliveryRange');
        this.$refs['modifyFormRef'].clearValidate('bulkyDeliverZipCode');
        return;
      }
      if (businessType === '2') {
        /**
         * 2、当业务类型为揽收时，派送范围非必填
         */
        this.collectRangeIsRules = true;
        this.deliveryRangeIsRules = false;
        this.bulkyRangeIsRules = false;
        this.$refs['modifyFormRef'].clearValidate('deliveryRange');
        this.$refs['modifyFormRef'].clearValidate('bulkyDeliverZipCode');
        return;
      }
      if (businessType.indexOf('4') < 0) {
        this.bulkyRangeIsRules = false;
        this.$refs['modifyFormRef'].clearValidate('bulkyDeliverZipCode');
      } else {
        this.bulkyRangeIsRules = true;
      }
      if (businessType.indexOf('2') < 0) {
        this.collectRangeIsRules = false;
        this.$refs['modifyFormRef'].clearValidate('collectRange');
      } else {
        this.collectRangeIsRules = true;
      }
      if (businessType.indexOf('3') < 0) {
        this.deliveryRangeIsRules = false;
        this.$refs['modifyFormRef'].clearValidate('deliveryRange');
      } else {
        this.deliveryRangeIsRules = true;
      }
    },
    changeBusinessPrincipal() { // 切换业务主体时判断是哪种经营模式  1 自营,   2和3 加盟
      this.$forceUpdate();
      this.providerChannelNameList = [];
      this.modifyForm.providerChannelCode = '';
      this.$nextTick(() => {
        this.$refs['modifyFormRef'].clearValidate('providerChannelCode');
      });
      this.getProviderChannelList(this.modifyForm.businessPrincipal);
    },
    getProviderChannelList(businessPrincipal) {
      if (businessPrincipal) {
        getProviderChannel(businessPrincipal).then(res => {
          if (res.status === 'OK') {
            // 经营模式
            if (res.data.providerType === 1) {
              this.isAll = 1;
              this.modifyForm.businessModel = 1;
            }
            if (res.data.providerType === 2 || res.data.providerType === 3) {
              this.isAll = 2;
              this.modifyForm.businessModel = 2;
              // this.modifyForm.siteType = '';
            }
            if (!res.data.providerType) {
              // 展示直营和加盟
              this.isAll = 0;
            }

            // 服务商渠道
            const channelList = [];
            if (res.data.providerChannelInfoList) {
              res.data.providerChannelInfoList.forEach(list => {
                channelList.push({
                  name: list.providerChannelName,
                  code: list.providerChannelCode
                });
              });
              this.providerChannelNameList = channelList;
            }
          }
        });
      } else {
        this.isAll = 0;
      }
    },
    changeProviderChannel() {
      this.$forceUpdate();
      if (this.modifyForm.providerChannelCode) {
        this.$nextTick(() => {
          this.$refs['modifyFormRef'].clearValidate('providerChannelCode');
        });
      }
    },
    saveClick() { // 保存
      if (this.modifyForm.siteStatus === undefined || this.modifyForm.siteStatus === '') {
        this.$message.warning(this.$t('collectionCenter.requiredSiteStatus')); // 请填写是否启用项
        return;
      }
      if (this.modifyForm.siteStatus === 0) {
        /**
         * 4、网点停用不进行数据校验，修改为停用后保存即可
         */
        this.$refs['modifyFormRef'].clearValidate();
        this.siteNotEnabled();
      } else {
        this.$refs['modifyFormRef'].validate((valid) => {
          if (!valid) {
            setTimeout(() => {
              if (document.querySelector('.el-form-item__error')) {
                // eslint-disable-next-line no-unused-expressions
                document.querySelectorAll('.el-form-item__error')[0]
                  ?.parentNode?.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                  });
              }
            }, 1);
            return false;
          } else if (!this.isDeliveryRange) {
            return false;
          } else if (!this.isCollectRange) {
            return false;
          } else if (!this.isLargeDelivery) {
            return false;
          } else {
            MessageBox.confirm(this.$t('collectionCenter.isSave'), this.$t('collectionCenter.tips'),
              {
                confirmButtonText: this.$t('collectionCenter.Confirm'),
                center: true,
                showClose: false
              }).then((arr) => {
              this.modifyForm.businessType = this.modifyForm.businessType.toString();
              this.modifyForm.parentId = this.parentId;
              if (this.saveBtnName === 'add') {
                delete this.modifyForm.id;
                const { riskRate } = this.modifyForm;
                siteAdd({ ...this.modifyForm, riskRate: riskRate === '' ? null : riskRate }).then(addRes => {
                  if (addRes.status === 'OK') {
                    this.currentNodekey = addRes.data;
                    this.$message({
                      message: this.$t('collectionCenter.SaveSuccessfully'), // '保存成功'
                      type: 'success'
                    });
                    this.modifyForm = {};
                    this.$refs['modifyFormRef'].clearValidate();
                    this.parentId = addRes.data;
                    const queryForm11 = {
                      code: '', // 网点编号
                      name: '', // 网点名称
                      siteStatus: '', // 状态
                      deliveryZipcode: '',
                      state: '',
                      city: ''
                    };
                    this.queryForm = queryForm11;
                    localStorage.setItem('siteQueryForm', JSON.stringify(queryForm11));
                    this.siteTree();
                    this.treeNode = '';
                    this.isModify = false;
                    this.highlight();
                    this.saveBtnName = '';
                  }
                }).catch(() => {
                  this.modifyForm.businessType = this.modifyForm.businessType ? this.modifyForm.businessType.split(',').map(Number) : '';
                });
              }
              if (this.saveBtnName === 'edit') {
                delete this.modifyForm.parentId;
                const { riskRate } = this.modifyForm;
                siteEdit({ ...this.modifyForm, riskRate: riskRate === '' ? null : riskRate }).then(addRes => {
                  if (addRes.status === 'OK') {
                    this.$message({
                      message: this.$t('collectionCenter.SaveSuccessfully'), // '保存成功'
                      type: 'success'
                    });
                    this.siteTree();
                    this.treeNode = '';
                    this.isModify = false;
                    this.highlight();
                    this.saveBtnName = '';
                  }
                }).catch(() => {
                  this.modifyForm.businessType = this.modifyForm.businessType ? this.modifyForm.businessType.split(',').map(Number) : '';
                });
              }
            }).catch(() => {
            });
          }
        });
      }
    },
    /**
     * 4、网点停用不进行数据校验，修改为停用后保存即可
     */
    siteNotEnabled() {
      if (!this.modifyForm.code || !this.modifyForm.name || !this.modifyForm.siteType) {
        this.$message.warning(this.$t('collectionCenter.required')); // 请填写网点编号、网点名称、网点类型
        return;
      }
      MessageBox.confirm(this.$t('collectionCenter.isSave'), this.$t('collectionCenter.tips'),
        {
          confirmButtonText: this.$t('collectionCenter.Confirm'),
          center: true,
          showClose: false
        }).then(() => {
        this.modifyForm.businessType = this.modifyForm.businessType.toString();
        this.modifyForm.parentId = this.parentId;
        if (this.saveBtnName === 'add') {
          delete this.modifyForm.id;
          const { riskRate } = this.modifyForm;
          siteAdd({ ...this.modifyForm, riskRate: riskRate === '' ? null : riskRate }).then(addRes => {
            if (addRes.status === 'OK') {
              this.currentNodekey = addRes.data;
              this.$message({
                message: this.$t('collectionCenter.SaveSuccessfully'), // '保存成功'
                type: 'success'
              });
              this.modifyForm = {};
              this.$refs['modifyFormRef'].clearValidate();
              this.parentId = addRes.data;
              const queryForm11 = {
                code: '', // 网点编号
                name: '', // 网点名称
                siteStatus: '', // 状态
                deliveryZipcode: '',
                state: '',
                city: ''
              };
              this.queryForm = queryForm11;
              localStorage.setItem('siteQueryForm', JSON.stringify(queryForm11));
              this.siteTree();
              this.treeNode = '';
              this.isModify = false;
              this.highlight();
              this.saveBtnName = '';
            }
          }).catch(() => {
            this.modifyForm.businessType = this.modifyForm.businessType.split(',').map(Number);
          });
        }
        if (this.saveBtnName === 'edit') {
          delete this.modifyForm.parentId;
          const { riskRate } = this.modifyForm;
          console.log(riskRate, 'riskRate');
          siteEdit({
            ...this.modifyForm,
            riskRate: riskRate === '' ? null : riskRate
          }).then(addRes => {
            if (addRes.status === 'OK') {
              this.$message({
                message: this.$t('collectionCenter.SaveSuccessfully'), // '保存成功'
                type: 'success'
              });
              this.siteTree();
              this.treeNode = '';
              this.isModify = false;
              this.highlight();
              this.saveBtnName = '';
            }
          }).catch(() => {
            this.modifyForm.businessType = this.modifyForm.businessType.split(',').map(Number);
          });
        }
      }).catch(() => {
      });
    },
    // 改为调用
    highlight() {
      // 高亮选中修改行并展示网点数据
      setTimeout(() => {
        this.$refs.treeRef.setCurrentKey(this.currentNodekey);
        const selected = this.$refs.treeRef.getCurrentNode();
        // 有父节点就展开
        if (this.$refs.treeRef.getNode(selected) && this.$refs.treeRef.getNode(selected).parent) {
          this.expandParents(this.$refs.treeRef.getNode(selected).parent);
        }
      }, 300);
    },
    // 展开其节点或其父节点
    expandParents(node) {
      node.expanded = true;
      if (node.parent) {
        this.expandParents(node.parent);
      }
    },
    modifyResetClick() { // 清空
      this.isDeliveryRange = true;
      this.isCollectRange = true;
      this.isLargeDelivery = true;
      this.modifyForm = {
        id: this.modifyForm.id,
        code: '', // 网点编号
        name: '', // 网点名称
        zipCode: '', // 邮编
        state: '', // 州
        city: '', // 城市
        area: '', // 区
        street: '', // 街道/地址
        address: '', // 网点地址
        siteType: '', // 操作类型 0：揽收 1：退件'
        department: '', // 所属转运中心
        bank: '', // 开户银行
        siteStatus: 0, // 网点状态 0：未启用 1启用
        contactsName: '', // 负责人"
        contactsTel: '', // 负责人联系方式
        deliveryRange: '', // 派送范围
        collectRange: '', // 揽收范围
        bulkyDeliverZipCode: '',
        businessModel: '', // 经营模式
        longitude: '', // 经度
        latitude: '', // 纬度
        balance: '', // 账户余额
        totalDeductions: '', // 累计扣款
        businessPrincipal: '', // 业务主体
        businessType: [], // 业务类型
        mailBox: '',
        alternateEmail: '',
        cnpjNumber: '',
        baseCode: '',
        paymentCycle: '',
        providerChannelCode: '', // 服务商渠道
        baseDistance: '', // 邮编距离
        deliveryDifficulty: '', // 地域类型 (0.风险 1.无风险 2.农村地区)
        riskRate: '', // 风险比例
        siteBusinessTimeData: [] // 营业时间
      };
      setTimeout(() => {
        this.$refs['modifyFormRef'].clearValidate();
      }, 0);
      // this.$nextTick(() => {
      //   this.$refs['modifyFormRef'].clearValidate();
      // });
    },
    download() { // 全部导出
      this.isClickAll = true;
      siteExport().then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * @description: 输入框限制只能输入数字和小数点，限制最大两位小数点
     * @param event
     * @param fieldName
     */
    handleInput(event, fieldName) {
      const value = event;
      const numericValue = value.replace(/[^\d.]/g, ''); // 移除非数字和非点字符
      const match = numericValue.match(/^(\d+\.?\d{0,2})/); // 匹配最多两位小数的数字
      let finalValue = match ? match[1] : '';

      // 计算除小数点外的字符数
      const lengthWithoutDot = finalValue.replace('.', '').length;

      // 如果除小数点外的字符数超过10位，则截断到10位
      if (lengthWithoutDot > 10) {
        // 找到第10位数字的位置
        let tenthDigitIndex = 0;
        for (let i = 0, nonDotCount = 0; i < finalValue.length; i++) {
          if (finalValue[i] !== '.') {
            nonDotCount++;
            if (nonDotCount === 10) {
              tenthDigitIndex = i;
              break;
            }
          }
        }
        finalValue = finalValue.substring(0, tenthDigitIndex + 1);
      }
      this.modifyForm[fieldName] = finalValue;
    },
    /**
     * @description: 获取营业时间字典列表
     */
    getSiteWeekList() {
      siteBusinessTimeGetAllBusinessWeek().then(res => {
        if (res.status === 'OK') {
          this.weekList = res.data;
          const weekListObj = {};
          this.weekList.forEach(item => {
            weekListObj[item.dictId] = item.dictDescribe;
          });
          this.weekListObj = weekListObj;
        }
      }).catch({});
    },
    /**
     * @description 新增营业时间
     */
    addWeekRow() {
      this.modifyForm.siteBusinessTimeData.push({
        sysDictIds: [],
        businessStartTime: '',
        businessEndTime: ''
      });
    },
    /**
     * @description 删除营业时间
     * @param index
     */
    deleteWeekRow(row, index) {
      this.modifyForm.siteBusinessTimeData.splice(index, 1);
    },
    /**
     * @description: 保存营业时间
     */
    saveBusinessHours() {
      let isCheck = true;
      this.modifyForm.siteBusinessTimeData.forEach(item => {
        if (!item.sysDictIds || !item.businessStartTime || !item.businessEndTime) {
          isCheck = false;
        }
      });
      if (!isCheck) {
        this.$message.warning(this.$t('userManage.pleaseCheck')); // 请填写完整的营业时间
        return;
      }
      this.siteWeekPop = false;
    },
    /**
     * @description: 重置营业时间
     */
    resetBusinessHours() {
      this.modifyForm.siteBusinessTimeData = JSON.parse(this.modifyFormCopy).siteBusinessTimeData;
      this.siteWeekPop = false;
    },
    /**
     * @description: 营业时间选择开始时间
     */
    changeStartTime(val, index) {
      console.log(0);
      if (this.modifyForm.siteBusinessTimeData[index].businessEndTime && this.modifyForm.siteBusinessTimeData[index].businessStartTime) {
        if (val > this.modifyForm.siteBusinessTimeData[index].businessEndTime) {
          this.$message.warning(this.$t('collectionCenter.pleaseChooseAgainTime')); // 开始时间不能大于结束时间
          this.modifyForm.siteBusinessTimeData[index].businessStartTime = '';
        }
      }
    },
    /**
     * @description: 营业时间选择结束时间
     */
    changeEndTime(val, index) {
      console.log(1);
      if (this.modifyForm.siteBusinessTimeData[index].businessStartTime && this.modifyForm.siteBusinessTimeData[index].businessEndTime) {
        if (this.modifyForm.siteBusinessTimeData[index].businessStartTime > val) {
          this.$message.warning(this.$t('collectionCenter.pleaseChooseAgainTime')); // 结束时间不能小于开始时间
          this.modifyForm.siteBusinessTimeData[index].businessEndTime = '';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .BlockNewlineClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 120px !important;
  }

  /* 防止按钮重复点击*/
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .codeDialog ::v-deep .el-dialog__header{
    padding: 0;
  }
  .codeDialog ::v-deep .el-popover__reference{
    cursor: default;
  }
  .codeDialog ::v-deep .el-dialog__body{
    position: relative;
  }
  .codeDialog .closeBtn{
    position: absolute;
    right: 13px;
    top: 13px;
    transform: scale(1.3);
  }
  .addClass{
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }
  .codeDialog ::v-deep .el-form-item{
    margin-bottom: 0;
  }
  .ywlxClass ::v-deep input:disabled{
    background: none;
  }

  .fileClass ::v-deep .el-input__suffix{
    height: auto;
    bottom: 0 !important;
    top: auto;
    right: 0;
  }
  .fileClass ::v-deep .el-input__count{
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .fileClass ::v-deep .el-input .el-input__count .el-input__count-inner{
    background: transparent;
  }

  .findClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .findClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .findClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }
  .findClass ::v-deep .el-select{
    width: 100%;
  }
  .placeholderClass ::v-deep .el-tree--highlight-current .is-current.el-tree-node > .el-tree-node__content {
    position: relative;
    margin-bottom: 26px;
    background: #FFF !important;
    color: #606266 !important;
  }
  .placeholderClass ::v-deep .el-tree--highlight-current .is-current.el-tree-node > .el-tree-node__content::before {
    content: "";
    //width: calc(100% - 20px);
    width: 100%;
    height: 26px;
    position: absolute;
    bottom: -28px;
    left: 0;
    background-color: #409eff;
    border: 1px dashed #fff;
    cursor:not-allowed;
    pointer-events: none;
  }
  ::v-deep .el-tree-node{
    width: fit-content;
    min-width: 100%;
    margin-right: 10px;
  }
  ::v-deep .el-tree--highlight-current .is-current.el-tree-node > .el-tree-node__content {
    background-color: #409eff !important;
    color: white;
  }
  .rightClass{
    border-left: 1px solid #dcdfe6;
  }
  .colClass{
    display: flex;
    justify-content: flex-start;
    padding: 0 5px;
    box-sizing: border-box;
    margin-bottom: 20px;
    color: #606266;
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    div{
      width: 50%;
      padding: 8px 5px;
      word-break: break-all;
    }
    .colName{
      display: flex;
      justify-content: flex-end;
    }
    .colValue{
      text-align: left;
      font-size: 15px;
      font-weight: normal;
      border: 1px solid #DCDFE6;
      border-radius: 2px;
      box-sizing: border-box;
      max-height: 150px;
      overflow-y: auto;
    }
  }
  .zipcodeClass{
    color: #F56C6C;
    font-size: 12px;
    line-height: 18px;
  }
  .treeClass{
    max-height: 600px;
    padding-right: 10px;
    box-sizing: border-box;
    overflow: auto;
  }
  ::v-deep .el-tree-node>.el-tree-node__children{
    overflow: inherit !important;
  }
  .findClass ::v-deep .el-form-item__label{
    padding: 0;
  }
  .NumClass ::v-deep textarea.el-textarea__inner {
    max-height: 150px;
  }
  .siteBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .modifyClass{
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 70px;
    position: relative;
  }
  .modifyClass ::v-deep .el-input.is-disabled .el-input__inner,
  .modifyClass ::v-deep .el-textarea.is-disabled .el-textarea__inner{
    background-color: #fff;
    border-color: #dcdfe6;
    color: #606266;
  }
  .modifyClass ::v-deep .el-select{
    width: 100%;
  }
  .modifyClass ::v-deep .el-textarea .el-input__count{
    background: transparent;
    bottom: -9px;
    right: 18px;
  }
  .submitClickBtn{
    text-align: right;
    width: 100%;
    height: 85px;
    position: fixed;
    bottom: 0;
    right: 50px;
    background: #fdfdfd;
    box-shadow: 0 0 25px 18px #fdfdfd;
  }
  .siteBox .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
</style>
