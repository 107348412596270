<template>
  <el-dialog
    :title="$t('RouterObj.MenuSerch')"
    :visible.sync="isShowSearch"
    class="search-dialog"
    center
    width="500px"
  >
    <div style="height: 200px; width: 100%">
      <el-select
        v-model="searchMenu"
        :placeholder="$t('customerManagements.SOUrceName')"
        clearable
        filterable
        style="width: 100%"
        @change="handleSearch"
      >
        <el-option
          v-for="(item, index) in menuList"
          :key="index"
          :label="item.name"
          :value="item.path"
        />
      </el-select>
    </div>
  </el-dialog>
</template>

<script>
import { RouterObj } from '@/lang/type/route';

export default {
  name: 'SearchMenu',
  data() {
    return {
      searchMenu: '',
      isShowSearch: false,
      searchList: [],
      menuList: [],
      routerObjLang: RouterObj
    };
  },
  methods: {
    handleSearch() {
      this.isShowSearch = false;
      console.log(this.searchMenu, 'this.searchMenu');
      this.$router.push(this.searchMenu);
    },
    // 递归过滤
    extractRoutes(routes, parentPath = '', result = [], leave = 0) {
      routes.forEach((route) => {
        let currentPath = route.path;
        if (parentPath && currentPath) {
          currentPath = `${parentPath}/${currentPath}`;
        }
        const title = route.meta && route.meta.title;
        const fillterRouter = route.fillterRouter;
        if (title && currentPath && leave > 0) {
          // 判断把一级的路由过滤
          if (fillterRouter !== true) {
            result.push({ title, path: currentPath, leave, fillterRouter });
          }
        }
        if (route.children && route.children.length > 0) {
          this.extractRoutes(route.children, currentPath, result, leave + 1);
        }
      });
      return result;
    },
    openSearchMenu() {
      this.searchMenu = '';
      const arr = this.extractRoutes(this.$store.getters.addRoutes);
      // 去到数组第一个
      arr.splice(0, 1);
      arr.forEach((item) => {
        try {
          if (this.$i18n.locale !== 'zh') {
            item.name = this.routerObjLang[item.title].pu;
          } else {
            item.name = this.routerObjLang[item.title].zh;
          }
        } catch (error) {
          console.log(error, 'error');
        }
      });
      this.menuList = arr;
      console.log(arr, 'arr');
      this.isShowSearch = true;
    }
  }
};
</script>

<style scoped></style>
