<template>
  <div>
    <el-button
      size="small" type="primary" style="margin-top: 10px"
      @click="dialogVisible=true"
    >{{ $t('receivablePayable.ImportReceivableGeneration') }}</el-button>
    <el-dialog
      :title="$t('receivablePayable.ImportReceivableGeneration')"
      :visible.sync="dialogVisible"
      width="500px"
      append-to-body
      :before-close="handleClose"
    >
      <div style="margin-bottom: 20px;">
        <div style="margin-bottom: 5px;">
          {{ $t('receivablePayable.SelectGenerateAccrualDate') }}：
        </div>
        <el-date-picker
          v-model="TimeCollection"
          :end-placeholder="$t('operationCenter.endDate')"
          :start-placeholder="$t('operationCenter.startDate')"
          range-separator="-"
          style="width: 100%"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </div>
      <div style="display: flex">

        <el-button
          size="small" type="primary" style="margin-right: 10px"
          @click="downloadTemplate"
        >{{ $t('basicData.DownloadTheTemplate') }}</el-button>
        <!--        导入-->
        <upload-excel :but-name="$t('operationCenter.Import')" :on-success="importLogistics" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ExcelJs from 'exceljs';
import { saveAs } from 'file-saver';
import { siteBillImportGenerateBillNumber } from '@/api/finance/receivables';
import { CustomerPage } from '@/api/customer';
export default {
  name: 'ImportReceivableGeneration',
  components: {
    UploadExcel: () => import('@/components/UploadExcel')
  },
  props: {
    feeTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: false,
      TimeCollection: [], // 生成应收时间
      customerList: [] // 客户列表
    };
  },
  created() {
    // 获取客户选项
    const param = {
      pageNumber: 1,
      pageSize: 9999999,
      params: {}
    };
    CustomerPage(param).then((res) => {
      if (res.status === 'OK') {
        this.customerList = res.data.records;
      }
    });
  },
  methods: {
    /**
     * @description 弹窗关闭前的回调
     */
    handleClose() {
      this.TimeCollection = [];
      this.dialogVisible = false;
    },
    /**
     * @description 下载模板
     */
    downloadTemplate() {
      const that = this;
      const ddd = async() => {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        const Sheet4 = wb.addWorksheet('Sheet4');
        Sheet1.columns = [
          {
            header: this.$t('CenterForSorting.orderNum'), // 订单号
            key: '0',
            width: 40
          },
          {
            header: this.$t('receivablePayable.TicketNumber'), // 业务单号
            key: '1',
            width: 40
          },
          {
            header: this.$t('collectionCenter.businessT'), // 业务类型
            key: '2',
            width: 40
          },
          {
            header: this.$t('basicData.ExpenseType'), // 费用类型
            key: '3',
            width: 40
          },
          {
            header: this.$t('GlobalSetObj.client'), // 客户
            key: '4',
            width: 40
          }
        ];
        const Sheet1_data = [];
        Sheet1.addRows(Sheet1_data);
        // 业务类型添加下拉选项
        const businessType = [`${this.$t('collectionCenter.expressDelivery')}-0`, `${this.$t('collectionCenter.lanshou')}-1`, `${this.$t('collectionCenter.dispatch')}-2`];
        Sheet2.columns = [
          {
            header: this.$t('collectionCenter.businessT'), // 业务类型
            key: '0',
            width: 40
          }
        ];
        const Sheet2_data = businessType.map((item) => {
          return {
            0: item
          };
        });
        Sheet2.addRows(Sheet2_data);
        // 费用类型下拉选项
        const feeType = that.feeTypeOptions.map((item) => item.name + '-' + item.code);
        Sheet3.columns = [
          {
            header: this.$t('basicData.ExpenseType'), // 费用类型
            key: '0',
            width: 40
          }
        ];
        console.log(feeType);
        const Sheet3_data = feeType.map((item) => {
          return {
            0: item
          };
        });
        Sheet3.addRows(Sheet3_data);
        // 客户下拉选项
        const customer = that.customerList.map((item) => item.username + '-' + item.id);
        Sheet4.columns = [
          {
            header: this.$t('GlobalSetObj.client'), // 客户
            key: '0',
            width: 40
          }
        ];
        const Sheet4_data = customer.map((item) => {
          return {
            0: item
          };
        });
        Sheet4.addRows(Sheet4_data);
        // 为整个第三列设置数据验证
        const lastRow = Sheet1.rowCount > 1 ? Sheet1.rowCount : 300000; // 如果没有数据，默认设置100000行
        Sheet1.dataValidations.add(`C2:C${lastRow}`, {
          type: 'list',
          formulae: [`Sheet2!$A$2:$A$${businessType.length + 1}`]
        });
        Sheet1.dataValidations.add(`D2:D${lastRow}`, {
          type: 'list',
          formulae: [`Sheet3!$A$2:$A$${feeType.length + 1}`]
        });
        Sheet1.dataValidations.add(`E2:E${lastRow}`, {
          type: 'list',
          formulae: [`Sheet4!$A$2:$A$${customer.length + 1}`]
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        saveAs(blob, this.$t('receivablePayable.ImportReceivableTemplate') + '.xlsx');
        return;
      };
      ddd();
    },

    /**
     * 导入物流数据并进行字段映射
     * @param {Array} arr - 从 XLSX 文件导入的数据数组
     * @returns {Array} - 处理后的数据数组
     */
    importLogistics(arr) {
      console.log(arr);
      // 添加Loading
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let [nameStartTime, nameEndTime] = [null, null];
      if (this.TimeCollection && this.TimeCollection?.length) {
        nameStartTime = this.TimeCollection[0];
        nameEndTime = this.TimeCollection[1];
      } else {
        // 请先选择生成应收时间
        this.$message.error(this.$t('receivablePayable.PleaseSelectReceivableGenerationTime'));
        loading.close();
        return;
      }
      if (!arr || !arr[0] || !arr[0].results) {
        loading.close();
        throw new Error('Invalid input data structure');
      }
      // 导入的数据
      const arrData = arr[0].results;
      if (arrData && arrData.length === 0) {
        this.$message.error(this.$t('userManage.ImportDataIsEmpty'));
        loading.close();
        return;
      }
      // 读取完整的表头
      const arrHeader = {};
      arr[0].header.forEach((item) => {
        arrHeader[item] = item;
      });
      // 映射关系
      const logisticsKey = {
        [this.$t('CenterForSorting.orderNum')]: 'orderNumber',
        [this.$t('receivablePayable.TicketNumber')]: 'waybillNumber',
        [this.$t('collectionCenter.businessT')]: 'businessType',
        [this.$t('basicData.ExpenseType')]: 'feeCode',
        [this.$t('GlobalSetObj.client')]: 'customerId'
      };
      console.log(arrHeader, 'arrHeader');
      // 处理数据
      const data = arrData.map(row => {
        console.log(row);
        const processedRow = {};
        // 遍历表头，将数据映射到对应的字段
        for (const key in arrHeader) {
          if (logisticsKey[key]) {
            let value = row[key];

            // 特殊处理业务类型和费用类型
            if (key === this.$t('collectionCenter.businessT') || key === this.$t('basicData.ExpenseType')) {
              const match = String(value).match(/.*?-(.*)/);
              value = match ? match[1].trim() : value;
            }
            // 客户可能有多个-，只取最后一个-的后面的值
            if (key === this.$t('GlobalSetObj.client')) {
              // 使用正则表达式 \d+ 匹配最后一个破折号后的数字
              const match = String(value).match(/-(\d+)$/);
              value = match ? match[1] : value;
            }
            processedRow[logisticsKey[key]] = value || '';
          }
        }

        return processedRow;
      });
      // 如果数组超出三十万，不让导入
      const MAX_DATA_LENGTH = 300000;
      if (data.length > MAX_DATA_LENGTH) {
        this.$message.error(this.$t('receivablePayable.ImportDataLimit'));
        loading.close();
        return;
      }
      loading.close();
      // 如果data长度超过五万条了，按每五万条截取，分批导入，没超过就一次性请求完，如果是分批请求，再所有请求完成后提示操作成功
      const MAX_LENGTH = 50000;
      // 如果数据长度超过最大长度，分批导入
      if (data.length > MAX_LENGTH) {
        const dataArr = [];
        // 按每 MAX_LENGTH 条数据截取
        for (let i = 0; i < data.length; i += MAX_LENGTH) {
          dataArr.push(data.slice(i, i + MAX_LENGTH));
        }
        // 生成Promise数组
        const promises = dataArr.map(item => {
          return siteBillImportGenerateBillNumber({
            importConditionList: item,
            nameStartTime,
            nameEndTime
          });
        });
        // 并发请求
        Promise.all(promises).then(res => {
          const h = this.$createElement;
          this.$msgbox({
            // 提示
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // 导入成功，有数据未成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载
              h('span', this.$t('receivablePayable.ImportSuccessMessage'))
            ]),
            showCancelButton: true,
            // 立即前往
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // 关闭
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.dialogVisible = false;
              this.$router.push({ path: '/finance/fileUploadManagement' });
            }
          });
        }).catch(err => {
          const h = this.$createElement;
          this.$msgbox({
            // 提示
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // 导入成功，有数据未成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载
              h('span', this.$t('receivablePayable.ImportSuccessMessage'))
            ]),
            showCancelButton: true,
            // 立即前往
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // 关闭
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.dialogVisible = false;
              this.$router.push({ path: '/finance/fileUploadManagement' });
            }
          });
        });
      } else {
        siteBillImportGenerateBillNumber({
          importConditionList: data,
          nameStartTime,
          nameEndTime
        }).then(res => {
          if (res.code === 200) {
            const h = this.$createElement;
            this.$msgbox({
              // 提示
              title: this.$t('collectionCenter.tips'),
              message: h('p', null, [
                // 导入成功，有数据未成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载
                h('span', this.$t('receivablePayable.ImportSuccessMessage'))
              ]),
              showCancelButton: true,
              // 立即前往
              confirmButtonText: this.$t('collectionCenter.Immediately'),
              // 关闭
              cancelButtonText: this.$t('collectionCenter.close')
            }).then(action => {
              if (action === 'confirm') {
                this.dialogVisible = false;
                this.$router.push({ path: '/finance/fileUploadManagement' });
              }
            });
          }
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
