<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="5">
          <!--  运单号   -->
          <el-form-item prop="selectType">
            <template slot="label">
              <div class="selectLabel">
                <el-select v-model="queryForm.params.selectType" :style="IsWidth">
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.ScanNumber')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width: 100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <!--上传时间-->
          <el-form-item prop="selectTimeType">
            <template slot="label">
              <div class="selectLabel">
                <el-select v-model="queryForm.params.selectTimeType" :style="IsWidth01">
                  <el-option key="1" :label="$t('home.uploadTime')" :value="1" />
                  <el-option key="2" :label="$t('receivablePayable.ReviewTime')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale == 'zh' ? zhDate : ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width: 100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item :label="$t('GlobalSetObj.status')">
            <!--状态-->
            <el-select v-model="queryForm.params.forceType" style="width: 100%">
              <el-option key="0" :label="$t('GlobalSetObj.all')" value="" />
              <el-option key="1" :label="$t('Resource.underReview')" :value="1" />
              <el-option key="2" :label="$t('customerManagements.approved')" :value="2" />
              <el-option key="3" :label="$t('home.applivefail')" :value="3" />
            </el-select>
          </el-form-item>
        </el-col>
        <!--  网点所属州 -->
        <el-col :span="4">
          <el-form-item :label="$t('collectionCenter.stateSite1')">
            <el-select
              v-model="queryForm.params.preState"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width: 100%"
              @change="getSiteOption"
            >
              <el-option
                v-for="item in StateList"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 预派送网点 -->
        <el-col :span="4">
          <el-form-item :label="$t('GlobalSetObj.preEntrega')">
            <el-select
              v-model="queryForm.params.preDeliverSiteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width: 100%"
              @change="handlepreDeliverSiteId"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <!-- 派送员 -->
        <el-col :span="4">
          <el-form-item :label="$t('orderCenterCont.deliveryPerson')">
            <el-select
              v-model="queryForm.params.delivererId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width: 100%"
            >
              <el-option
                v-for="item in userList"
                :key="item.keyId"
                :label="item.operateUserName"
                :value="item.keyId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <div style="margin-top: 28px">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{ $t('newOrder.search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm"
            >{{ $t('newOrder.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f2f2', color: 'black' }"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')"
        align="center"
        fixed="left"
        type="index"
        width="80"
      />
      <el-table-column
        v-for="(item, index) in tableHeaderList"
        :key="index"
        :align="item.cloCenter"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'signedPhoto'">
            <div class="verifier" @click="imageSee(scope.row.signedPhoto)">
              {{ $t('home.clickSee') }}
            </div>
          </div>
          <div v-else-if="item.prop === 'signedNamePhoto'">
            <div class="verifier" @click="imageSee(scope.row.signedNamePhoto)">
              {{ $t('home.clickSee') }}
            </div>
          </div>
          <div v-else-if="item.prop === 'forceType'">
            <el-tag
              v-if="scope.row.forceType === 1" type="warning"
            >{{ $t('Resource.underReview') }}
            </el-tag>
            <el-tag
              v-else-if="scope.row.forceType === 2" type="success"
            >{{ $t('customerManagements.approved') }}
            </el-tag>
            <el-tag v-else type="danger">{{ $t('home.applivefail') }}</el-tag>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('GlobalSetObj.operate')"
        align="center"
        fixed="right"
        min-width="90"
        prop="varianceNumber"
      >
        <template slot-scope="scope">
          <i class="el-icon-user-solid verifier" @click="seeDetial(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0; float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--强制签收审核-->
    <el-drawer
      :append-to-body="true"
      :title="$t('home.qiangzhiApplive')"
      :visible.sync="waybillDescription"
      custom-class="drawerClass"
      direction="ltr"
      size="70%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto; padding: 10px 20px">
        <detailPage :key="detailKey" :row-data="rowData" @call-parent-method="parentMethod" />
      </div>
    </el-drawer>
    <!-- 图片弹框   -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('navbar.seeImage')"
      :visible.sync="dialogVisible"
      top="3vh"
      width="540px"
    >
      <div>
        <div v-if="imageList.length > 0" style="width: 500px">
          <div style="text-align: center; color: #409eff; font-size: 18px">
            <span>{{ currentIndex + 1 }}</span>
            <span>/</span>
            <span>{{ imageList.length }}</span>
          </div>
          <el-carousel
            ref="carousel" height="750px" trigger="click"
            @change="handlecarousel"
          >
            <el-carousel-item v-for="item in imageList" :key="item">
              <div style="margin-left: 10px">
                <el-image :src="item" fit="fill" style="width: 100%; height: 700px" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-else style="width: 500px; text-align: center; height: 750px">
          <el-image style="width: 100%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" style="font-size: 30px" />
            </div>
          </el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLgAreaListData,
  lgStorageForceSignpage,
  selectlnfoBySite,
  selectlnfoByState
} from '@/api/orderApi';
import detailPage from './component/detail.vue';
import dayjs from 'dayjs';

export default {
  name: 'MisallocationRate',
  components: {
    detailPage
  },
  data() {
    return {
      // 详情
      waybillDescription: false,
      detailKey: new Date().getTime(),
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          inputValue: '',
          selectType: 1,
          selectTimeType: 1,
          preState: [],
          preDeliverSiteId: [],
          delivererId: [],
          forceType: ''
        }
      },
      tableData: [],
      tableHeaderList: [
        {
          label: this.$t('newOrder.waybillNumber'),
          prop: 'waybillNumber',
          cloWidth: 160,
          cloCenter: 'center'
        },
        {
          label: this.$t('GlobalSetObj.ScanNumber'),
          prop: 'scanOrderNumber',
          cloWidth: 160,
          cloCenter: 'center'
        },
        {
          label: this.$t('GlobalSetObj.status'),
          prop: 'forceType',
          cloWidth: 150,
          cloCenter: 'center'
        },
        {
          label: this.$t('collectionCenter.stateSite1'),
          prop: 'preState',
          cloWidth: 110,
          cloCenter: 'center'
        },
        {
          label: this.$t('GlobalSetObj.preEntrega'),
          prop: 'preDeliverSiteIdName',
          cloWidth: 150,
          cloCenter: 'center'
        },
        {
          label: this.$t('orderCenterCont.deliveryPerson'),
          prop: 'delivererName',
          cloWidth: 120,
          cloCenter: 'center'
        },
        {
          label: this.$t('home.seesingPhoto'),
          prop: 'signedPhoto',
          cloWidth: 160,
          cloCenter: 'center'
        },
        {
          label: this.$t('home.ViewSignature'),
          prop: 'signedNamePhoto',
          cloWidth: 150,
          cloCenter: 'center'
        },
        {
          label: this.$t('home.uploadTime'),
          prop: 'uploadTime',
          cloWidth: 160,
          cloCenter: 'center'
        },
        {
          label: this.$t('Resource.Reviewer'),
          prop: 'approverName',
          cloWidth: 160,
          cloCenter: 'center'
        },
        {
          label: this.$t('receivablePayable.ReviewTime'),
          prop: 'approverTime',
          cloWidth: 160,
          cloCenter: 'center'
        }
      ],
      dialogVisible: false,
      imageList: [],
      currentIndex: 0,
      rowData: {},
      StateList: [], // 州
      siteList: [],
      userList: []
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '180px' : '100px'
      };
    },
    IsWidth01() {
      return {
        width: this.isTormat ? '210px' : '100px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch();
    this.getAddress();
  },
  methods: {
    handlecarousel(val) {
      this.currentIndex = val;
    },
    // 点击查看详情
    seeDetial(row) {
      console.log(row, '点击查看详情');
      this.detailKey = new Date().getTime();
      this.rowData = row;
      this.waybillDescription = true;
    },
    parentMethod(arg) {
      this.waybillDescription = arg;
      this.enterSearch();
    },
    // 点击查看 图片
    imageSee(data) {
      console.log(data, 'imageSee');
      this.imageList = data;
      this.dialogVisible = true;
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        inputValue: '',
        selectType: 1,
        selectTimeType: 1,
        preState: [],
        preDeliverSiteId: [],
        delivererId: [],
        forceType: ''
      };
      this.siteList = [];
      this.userList = [];
      this.getSearch();
    },
    // 查询
    getSearch() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          waybillNumberList: [],
          delivererId: [],
          preDeliverSiteId: this.queryForm.params.preDeliverSiteId,
          preState: this.queryForm.params.preState,
          forceType: this.queryForm.params.forceType
        }
      };
      if (this.timeValue && this.timeValue.length > 0) {
        if (this.queryForm.params.selectTimeType === 1) {
          data.params.uploadStartTime = this.timeValue[0];
          data.params.uploadEndTime = this.timeValue[1];
          data.params.approverStartTime = '';
          data.params.approverEndTime = '';
        } else {
          data.params.approverStartTime = this.timeValue[0];
          data.params.approverEndTime = this.timeValue[1];
          data.params.uploadStartTime = '';
          data.params.uploadEndTime = '';
        }
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      const selectedObjects = this.userList.filter((item) =>
        this.queryForm.params.delivererId.includes(item.keyId)
      );
      if (selectedObjects.length) {
        selectedObjects.forEach((item) => {
          if (item.expressId) {
            data.params.delivererId.push(item.expressId);
          } else {
            data.params.delivererId.push(item.supplierId);
          }
        });
      }
      if (this.queryForm.params.selectType === 1) {
        data.params.waybillNumberList = inputValList;
        data.params.scanOrderNumberList = [];
      } else {
        data.params.scanOrderNumberList = inputValList;
        data.params.waybillNumberList = [];
      }
      console.log(selectedObjects, 'selectedObjects');
      lgStorageForceSignpage(data).then((res) => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          console.log(this.tableData, 'tableData');
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(2, 'day').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map((item) => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    getSiteOption(val) {
      console.log(val);
      const data = {
        states: val
      };
      this.queryForm.params.preDeliverSiteId = [];
      this.queryForm.params.delivererId = [];
      selectlnfoByState(data).then((res) => {
        if (res.status === 'OK') {
          console.log(res.data, 'selectlnfoByState');
          this.siteList = res.data.siteList;
        }
      });
    },
    handlepreDeliverSiteId(val) {
      const data = {
        siteIds: val
      };
      this.queryForm.params.delivererId = [];
      selectlnfoBySite(data).then((res) => {
        if (res.status === 'OK') {
          console.log(res.data, 'selectlnfoBySite');
          this.userList = res.data.userList;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.verifier {
  color: #409eff;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
}
</style>
