import i18n from '@/lang';

// 业务类型
export const BUSINESS_TYPE = Object.freeze({
  1: {
    label: i18n.t('collectionCenter.turnTransport'), // 转运
    value: 1
  },
  2: {
    label: i18n.t('collectionCenter.lanshou'), // 揽收
    value: 2
  },
  3: {
    label: i18n.t('collectionCenter.dispatch'), // 派送
    value: 3
  }
});

// 揽收类别
export const COLLECTION_CATEGORY_TYPE = Object.freeze({
  1: {
    label: i18n.t('basicData.OrdinaryPickUp'), // 一般
    value: 1
  },
  2: {
    label: i18n.t('basicData.StockingAndPickup'), // 特殊
    value: 2
  }
});

// 物流订单
export const WAYBILL_STATUS = Object.freeze([
  { label: i18n.t('newOrder.all'), code: 0 }, // 待分配
  { label: i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
  { label: i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
  { label: i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
  { label: i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
  { label: i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
  { label: i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
  { label: i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
  { label: i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
  { label: i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
  { label: i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
  { label: i18n.t('newOrder.Signed'), code: 10 }, // 已签收
  { label: i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
  { label: i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
  { label: i18n.t('GlobalSetObj.deliveryFailure'), code: 14 }, // 投递失败
  { label: i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
  { label: i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
  { label: i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
  { label: i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
  { label: i18n.t('GlobalSetObj.mutilSign'), code: 20 }, // 多次签收
  { label: i18n.t('GlobalSetObj.processSettlement'), code: 21 }, // 理赔中
  { label: i18n.t('GlobalSetObj.ClaimsCompleted'), code: 22 } // 理赔完成
]);

// 揽收订单状态
export const ORDER_TYPE_LIST = Object.freeze([
  { collectStatus: 0, collectStatusName: i18n.t('collectionCenter.notLanShou') }, // '未揽收'
  { collectStatus: 1, collectStatusName: i18n.t('collectionCenter.hasLanShou') }, // '已揽收'
  { collectStatus: 2, collectStatusName: i18n.t('collectionCenter.cancelled') }, // 已取消
  { collectStatus: 4, collectStatusName: i18n.t('collectionCenter.scheduled') }, // 已调度
  { collectStatus: 5, collectStatusName: i18n.t('collectionCenter.AbnormalLanShou') }, // 揽收异常
  { collectStatus: 6, collectStatusName: i18n.t('collectionCenter.haveBeenSigned') }, // 已签收
  { collectStatus: 7, collectStatusName: i18n.t('collectionCenter.ProblemPiece') }, // 问题件
  { collectStatus: 8, collectStatusName: i18n.t('collectionCenter.SealedBag') }, // 已封袋
  { collectStatus: 9, collectStatusName: i18n.t('collectionCenter.truckLoading') }, // 装车
  { collectStatus: 10, collectStatusName: i18n.t('collectionCenter.unloading') }, // 卸车
  { collectStatus: 11, collectStatusName: i18n.t('collectionCenter.Bagged') }, // 已装袋
  { collectStatus: 12, collectStatusName: i18n.t('collectionCenter.closedLoop') }, // 已闭环
  { collectStatus: 13, collectStatusName: i18n.t('collectionCenter.unclaimed') }, // 待取件
  { collectStatus: 14, collectStatusName: i18n.t('collectionCenter.collectionPointInventory') }// 揽收网点入库
]);

// 派送模式
export const OPERATION_MODE = {
  0: {
    label: 'AJD' + i18n.t('GlobalSetObj.selfSupport'), // AJD自营
    value: 0
  },
  1: {
    label: 'DSP' + i18n.t('GlobalSetObj.three'), // DSP第三方
    value: 1
  }
};

// 备货揽收订单
export const BH_ORDER_TYPE_LIST = Object.freeze([ // 订单状态
  { collectStatus: 0, collectStatusName: i18n.t('collectionCenter.notLanShou') }, // '未揽收'
  { collectStatus: 1, collectStatusName: i18n.t('collectionCenter.hasLanShou') }, // '已揽收'
  { collectStatus: 2, collectStatusName: i18n.t('collectionCenter.haveBeenSigned') }, // 已签收
  { collectStatus: 3, collectStatusName: i18n.t('collectionCenter.cancelled') }, // 已取消
  { collectStatus: 4, collectStatusName: i18n.t('collectionCenter.truckLoading') }, // 装车
  { collectStatus: 5, collectStatusName: i18n.t('collectionCenter.unloading') }, // 卸车
  { collectStatus: 6, collectStatusName: i18n.t('operatingPlatform.collectWarehousing') } // 网点揽收入库
]);

// 揽收类型
export const COLLECT_TYPE = Object.freeze({
  1: {
    label: i18n.t('collectionCenter.dropOff'), // 上门交件
    value: 1
  },
  2: {
    label: i18n.t('collectionCenter.doorPickUp'), // 上门揽收
    value: 2
  }
});

// 全部 是 否
export const OR_NOT = Object.freeze({
  // 0: {
  //   label: i18n.t('basicData.all'), // 全部
  //   value: null
  // },
  1: {
    label: i18n.t('receivablePayable.Paid'), // 是
    value: 1
  },
  0: {
    label: i18n.t('receivablePayable.NonPayment'), // 否
    value: 0
  }
});

// 网点类型
export const SITE_TYPE = {
  1: {
    label: i18n.t('collectionCenter.hut'), // 安骏小屋
    value: 1
  },
  2: {
    label: i18n.t('collectionCenter.dot'), // 网点
    value: 2
  },
  3: {
    label: i18n.t('collectionCenter.transferCenter'), // 转运中心
    value: 3
  },
  4: {
    label: i18n.t('collectionCenter.headOffice'), // 总部
    value: 4
  },
  5: {
    label: i18n.t('collectionCenter.merchant'), // 商家
    value: 5
  }
};

// 来源
export const FEE_SOURCE = Object.freeze({
  0: {
    label: i18n.t('receivablePayable.SystemGenerated'), // 系统生成
    value: 0
  },
  1: {
    label: i18n.t('receivablePayable.ManualAddition'), // 手工添加
    value: 1
  },
  3: {
    label: i18n.t('receivablePayable.QuickRecordGeneration'), // 快录生成
    value: 3
  }

  // default: {
  //   label: i18n.t('basicData.other'), // 其他
  //   value: ''
  // }
});

// 业务时间
export const TIME_TYPE = Object.freeze([
  { label: i18n.t('basicData.BusinessTime'), value: 'operate_time' },
  // { label: i18n.t('orderCenterCont.InboundTime'), value: 'input_time' },
  // { label: i18n.t('orderCenterCont.OutboundTime'), value: 'out_time' }
]);

// 搜索
export const SEARCH_PARAMS = {
  providerId: '', // 应收主体id
  siteId: '', // 网点id
  customerId: '', // 客户id
  timeField: 'operate_time', // 查询时间类型 入库时间:input_time 出库时间:out_time 业务时间:operate_time
  startTime: '', // 业务开始时间
  endTime: '', // 业务结束时间
  numberField: 'waybill_number', // waybill_number: 业务单号 customer_number: 客户单号 order_number：条形码
  numberList: [], // 单号
  collectorIdList: [], // 揽收人
  // collectors: '',
  dispatcherList: [], // 派送人
  // dispatcher: '',
  isPaid: null, // 是否已支付
  isGenBill: null, // 是否生成对账单
  businessType: null, // 业务类型
  siteType: '', // 网点类型
  operationMode: '', // 派送模式 0:AJD(自营) 1:DSP(第三方)
  collectType: null, // 揽收方式
  billBatchNumber: '', // 批次号
  payableNumber: '', // 收款单号
  // feeName: '', // 费用类型名称
  feeCode: '', // 费用类型编码
  status: '', // 订单状态
  receiveState: '', // 收件人州
  senderState: '' // 发件人州
};

