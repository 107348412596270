<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="5">
          <!--  运单号   -->
          <el-form-item>
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType"
                  :style="IsWidth"
                >
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="3" :label="$t('GlobalSetObj.ScanNumber')" :value="3" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item :label="$t('customerManagements.daysDelayed')">
            <el-select
              v-model="queryForm.params.daysDelayed"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" value="" />
              <el-option
                v-for="item in dayList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="$t('newOrder.waybillStatus')">
            <el-select
              v-model="queryForm.params.waybillStatus"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              filterable
              style="width:100%"
            >
              <el-option
                v-for="(item, ind) in waybillStatusList"
                :key="ind"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!-- 最近轨迹产生网点 -->
          <el-form-item prop="selectType1">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType1"
                  :style="IsWidth2"
                >
                  <el-option key="1" :label="$t('customerManagements.latestTraceSiteList')" :value="1" />
                  <el-option key="2" :label="$t('customerManagements.operatorNameSite')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-select
              v-model="queryForm.params.latestTraceSiteList1"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width:100%"
            >
              <el-option
                v-for="(item) in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  最新轨迹上级转运中心   -->
          <el-form-item prop="selectType2">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType2"
                  :style="IsWidth2"
                >
                  <el-option key="1" :label="$t('customerManagements.newupTransferName')" :value="1" />
                  <el-option key="2" :label="$t('customerManagements.nextNewTransferName')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-select
              v-model="queryForm.params.upTransferList1"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width:100%"
            >
              <el-option
                v-for="item in TransferList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  最新轨迹州   -->
          <el-form-item :label="$t('GlobalSetObj.LatestTrajectoryState')">
            <el-select
              v-model="queryForm.params.latestTraceStateList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width:100%"
            >
              <el-option
                v-for="(item, ind) in allTraceStateList"
                :key="ind"
                :label="item.nameEn"
                :value="item.twoCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20">
          <!--搜索-->
          <el-button
            icon="el-icon-search" size="small" type="primary"
            @click="enterSearch"
          >{{ $t('newOrder.search') }}
          </el-button>
          <!--重置-->
          <el-button
            icon="el-icon-refresh-left" size="small"
            @click="resetForm()"
          >{{ $t('newOrder.reset') }}
          </el-button>
          <el-button
            icon="el-icon-upload2"
            size="small" style="margin-bottom: 10px" type="primary"
            @click="allExportOrder"
          >{{ $t('newOrder.export') }}
          </el-button>
          <el-radio-group
            v-model="queryForm.params.isTest"
            size="small"
            style="margin-left: 10px"
            @change="enterSearch"
          >
            <el-radio-button :label="1">{{ $t('newOrder.Formal') }}</el-radio-button>
            <el-radio-button :label="2">{{ $t('newOrder.test1') }}</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="`${$t('GlobalSetObj.serial', 'zh')}（${$t('GlobalSetObj.serial', 'pu')}）`" align="center" type="index"
        width="80"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div v-if="item.prop==='waybillNumber'">
            <div style="color: #409eff;cursor: pointer" @click="toOrderDetail(scope.row)">{{
              scope.row.waybillNumber
            }}
            </div>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--详情-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="waybillDescription"
      custom-class="drawerClass"
      direction="ltr"
      size="90%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <detailPage :key="detailKey" :query-track-param="queryTrackParam" query-type="1" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getLgAreaListData,
  getSiteTransfer,
  selectInfoByStateTwo,
  stationaryTrackMonitorPage,
  stationaryTrackMonitorPageexport
} from '@/api/orderApi';
import detailPage from '@/components/detailComponent/detailPage';
// import _debounce from '@/utils/tools';
import { waybillStatusList } from '@/utils/enumData';

export default {
  name: 'NewSortingRules',
  components: {
    detailPage
  },
  data() {
    return {
      // 详情
      waybillDescription: false,
      detailKey: new Date().getTime(),
      queryTrackParam: {},
      isTormat: false,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          isTest: 1,
          inputValue: '',
          waybillStatus: '',
          latestTraceStateList: [], // 最新轨迹州
          selectType: 1,
          daysDelayed: 1,
          latestTraceSiteList1: [],
          upTransferList1: [],
          selectType1: 1,
          selectType2: 1
        }
      },
      siteList: [], // 网点
      tableData: [],
      TransferList: [], // 上级转运中心
      allTraceStateList: [], // 全部州
      tableHeaderList: [
        // 运单号
        { id: 1, label: `${this.$t('GlobalSetObj.waybillNumber', 'zh')}（${this.$t('GlobalSetObj.waybillNumber', 'pu')}）`, prop: 'waybillNumber', cloWidth: 170 },
        // 扫描单号
        { id: 2, label: `${this.$t('GlobalSetObj.ScanNumber', 'zh')}（${this.$t('GlobalSetObj.ScanNumber', 'pu')}）`, prop: 'scanNumber', cloWidth: 170 },
        // 滞留天数
        { id: 4, label: `${this.$t('customerManagements.daysDelayed', 'zh')}（${this.$t('customerManagements.daysDelayed', 'pu')}）`, prop: 'daysDelayed', cloWidth: 150 },
        // 运单状态
        { id: 3, label: `${this.$t('newOrder.waybillStatus', 'zh')}（${this.$t('newOrder.waybillStatus', 'pu')}）`, prop: 'status', cloWidth: 180 },
        // 最新轨迹时间
        { id: 5, label: `${this.$t('newOrder.LatestTime', 'zh')}（${this.$t('newOrder.LatestTime', 'pu')}）`, prop: 'latestTraceTime', cloWidth: 180 },
        // 最新轨迹内容
        { id: 6, label: `${this.$t('orderCenterCont.zuixinguijineir', 'zh')}（${this.$t('orderCenterCont.zuixinguijineir', 'pu')}）`, prop: 'latestTraceContent', cloWidth: 180 },
        // 问题原因
        { id: 20, label: `${this.$t('orderCenterCont.wentijianyuanyin', 'zh')}（${this.$t('orderCenterCont.wentijianyuanyin', 'pu')}）`, prop: 'problemReason', cloWidth: 180 },
        // 最新轨迹产生网点
        { id: 7, label: `${this.$t('customerManagements.latestTraceSiteList', 'zh')}（${this.$t('customerManagements.latestTraceSiteList', 'pu')}）`, prop: 'latestTraceSiteName', cloWidth: 160 },
        // 最新轨迹上级转运中心
        { id: 8, label: `${this.$t('customerManagements.newupTransferName', 'zh')}（${this.$t('customerManagements.newupTransferName', 'pu')}）`, prop: 'upTransferName', cloWidth: 170 },
        // 最新轨迹州
        { id: 10, label: `${this.$t('GlobalSetObj.LatestTrajectoryState', 'zh')}（${this.$t('GlobalSetObj.LatestTrajectoryState', 'pu')}）`, prop: 'latestTraceState', cloWidth: 170 },
        // 次新上级转运中心
        { id: 24, label: `${this.$t('customerManagements.nextNewTransferName', 'zh')}（${this.$t('customerManagements.nextNewTransferName', 'pu')}）`, prop: 'nextNewTransferName', cloWidth: 180 },
        // 最新轨迹操作账号
        {
          id: 9,
          label: `${this.$t('customerManagements.latestTraceOperatorName', 'zh')}（${this.$t('customerManagements.latestTraceOperatorName', 'pu')}）`,
          prop: 'latestTraceOperatorName',
          cloWidth: 200
        },
        // 次新操作人
        { id: 22, label: `${this.$t('customerManagements.nextNewOperatorName', 'zh')}（${this.$t('customerManagements.nextNewOperatorName', 'pu')}）`, prop: 'nextNewOperatorName', cloWidth: 180 },
        // 次新操作名称
        {
          id: 21,
          label: `${this.$t('customerManagements.nextNewOperateTypeName', 'zh')}（${this.$t('customerManagements.nextNewOperateTypeName', 'pu')}）`,
          prop: 'nextNewOperateTypeName',
          cloWidth: 180
        },
        // 次新操作人网点
        { id: 23, label: `${this.$t('customerManagements.operatorNameSite', 'zh')}（${this.$t('customerManagements.operatorNameSite', 'pu')}）`, prop: 'nextNewOperatorSiteName', cloWidth: 180 }
      ],
      dayList: [1, 2, 3, 7, 15, 30, 50],
      waybillStatusList: waybillStatusList
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '220px' : '120px'
      };
    },
    IsWidth2() {
      return {
        width: this.isTormat ? '280px' : '180px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
    this.getSiteTransfer();
    this.getSiteList();
    this.getTraceStateList();
  },
  methods: {
    // 获取轨迹州列表
    getTraceStateList() {
      const param = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(param, false).then(res => {
        if (res.status === 'OK') {
          this.allTraceStateList = res.data;
        }
      });
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params = {
        isTest: 1,
        inputValue: '',
        waybillStatus: '',
        selectType: 1,
        daysDelayed: 1,
        latestTraceStateList: [],
        latestTraceSiteList1: [],
        upTransferList1: [],
        selectType1: 1,
        selectType2: 1
      };
      this.getSearch();
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      if (this.queryForm.params.selectType === 1) {
        data.params.waybillNumberList = inputValList.filter(item => item !== '');
        data.params.scanNumberList = [];
      } else {
        data.params.scanNumberList = inputValList.filter(item => item !== '');
        data.params.waybillNumberList = [];
      }
      if (this.queryForm.params.selectType1 === 1) {
        data.params.nextNewOperatorSiteList = [];
        data.params.latestTraceSiteList = this.queryForm.params.latestTraceSiteList1;
      } else {
        data.params.nextNewOperatorSiteList = this.queryForm.params.latestTraceSiteList1;
        data.params.latestTraceSiteList = [];
      }
      if (this.queryForm.params.selectType2 === 1) {
        data.params.nextNewTransferList = [];
        data.params.upTransferList = this.queryForm.params.upTransferList1;
      } else {
        data.params.nextNewTransferList = this.queryForm.params.upTransferList1;
        data.params.upTransferList = [];
      }
      if (this.queryForm.params.latestTraceStateList && this.queryForm.params.latestTraceStateList.length > 0) {
        data.params.latestTraceStateList = this.queryForm.params.latestTraceStateList;
      } else {
        data.params.latestTraceStateList = [];
      }
      stationaryTrackMonitorPage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          console.log(this.tableData, '===this.tableData');
          this.tableData.map(item => {
            item.supplierName = item.providerChannelName;
            // if (waybillStatusObj[item.waybillStatus].label) {
            //   item.statusNamewaybill = waybillStatusObj[item.waybillStatus].label;
            // }
          });
        }
      });
    },
    allExportOrder() {
      const data = this.queryForm.params;
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      if (this.queryForm.params.selectType === 1) {
        data.waybillNumberList = inputValList;
        data.scanNumberList = [];
      } else {
        data.scanNumberList = inputValList;
        data.waybillNumberList = [];
      }
      if (this.queryForm.params.selectType1 === 1) {
        data.nextNewOperatorSiteList = [];
        data.latestTraceSiteList = this.queryForm.params.latestTraceSiteList1;
      } else {
        data.nextNewOperatorSiteList = this.queryForm.params.latestTraceSiteList1;
        data.latestTraceSiteList = [];
      }
      if (this.queryForm.params.selectType2 === 1) {
        data.nextNewTransferList = [];
        data.upTransferList = this.queryForm.params.upTransferList1;
      } else {
        data.nextNewTransferList = this.queryForm.params.upTransferList1;
        data.upTransferList = [];
      }

      console.log(this.queryForm.params.latestTraceStateList, '==this.queryForm.params.latestTraceStateList');

      if (this.queryForm.params.latestTraceStateList && this.queryForm.params.latestTraceStateList.length > 0) {
        data.latestTraceStateList = this.queryForm.params.latestTraceStateList;
      } else {
        data.latestTraceStateList = [];
      }
      console.log(data, '===data.params');
      stationaryTrackMonitorPageexport(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              if (res.data.taskId) {
                this.$router.push({ path: '/resource/exportTaskList' });
              } else {
                this.$router.push({ path: '/resource/exportList' });
              }
            }
          }).catch(() => {
          });
        }
      });
    },
    // 进入运单详情
    toOrderDetail(row) {
      this.queryTrackParam = row;
      console.log(row, ':query-track-param="queryTrackParam"');
      console.log(row.waybillId, 'row.waybillIdrow.waybillIdrow.waybillId', this.queryTrackParam.waybillId);
      this.waybillDescription = true;
      this.detailKey = new Date().getTime();
      setTimeout(() => {
        this.bus.$emit('todetailBox', row.waybillId);
      }, 100);
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 获取网点列表
    getSiteList() {
      const data = {};
      selectInfoByStateTwo(data).then((res) => {
        if (res.status === 'OK') {
          this.siteList = res.data.siteList;
        }
      });
    },
    getSiteTransfer() {
      getSiteTransfer().then(res => {
        if (res.status === 'OK') {
          this.TransferList = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

>>> .el-drawer__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  color: black;
}
</style>
