<template>
  <div class="detailsBox" style="padding: 20px;box-sizing: border-box;margin-bottom: 100px;">
    <!-- 运单详情 -->
    <div style="width: 250px;height: 30px">
      <el-popover
        placement="top"
        popper-class="copy"
        trigger="hover"
      >
        <el-button
          style="color: black;font-size: 12px;padding: 2px"
          type="text"
          @click="copyContext(getDetailsList.orderNumber)"
        >
          {{ $t('orderDetails.copy') }}
        </el-button>
        <div
          slot="reference"
        >
          {{ getDetailsList.orderNumber }}
        </div>
      </el-popover>
    </div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <!-- 收/发件人 -->
      <el-tab-pane :label="$t('orderDetails.desRem')" name="x1">
        <el-card class="box-card" style="margin-bottom: 20px;">
          <div style="margin-bottom: 20px;font-size: 14px;color: #12d2ac;">{{ $t('orderCenterCont.RecipientInformation') }}</div>
          <el-form label-width="auto">
            <el-descriptions :colon="false" :column="2" direction="vertical">
              <!--收件人-->
              <el-descriptions-item :label="$t('newOrder.RecipientName')">
                <div style="display: flex;">
                  <span v-if="showDetail" style="font-weight: bold">{{ encryptionInfo.receiveName || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.receiveName || '--' }}</span>
                  <span
                    v-if="getDetailsList.receiveName"
                    class="iconEye"
                    @click="handlerhidden('receiveName')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--收件人公司-->
              <el-descriptions-item :label="$t('newOrder.RecipientCompany')">
                <div style="display: flex;">
                  <span v-if="showDetail5" style="font-weight: bold">{{ encryptionInfo.receiveCompany || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.receiveCompany || '--' }}</span>
                  <span
                    v-if="getDetailsList.receiveCompany"
                    class="iconEye"
                    @click="handlerhidden('receiveCompany')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--收件人手机-->
              <el-descriptions-item :label="$t('newOrder.Recipientphone')">
                <div style="display: flex;">
                  <span v-if="showDetail1" style="font-weight: bold">
                    {{ encryptionInfo.receiveMobile || '--' }}
                  </span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.receiveMobile || '--' }}</span>
                  <span
                    v-if="getDetailsList.receiveMobile"
                    class="iconEye"
                    @click="handlerhidden('receiveMobile')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--街道-->
              <el-descriptions-item :label="$t('newOrder.Street1')">
                <div>{{ getDetailsList.receiveStreet || '--' }}</div>
              </el-descriptions-item>
              <!--收件人门牌号-->
              <el-descriptions-item :label="$t('newOrder.doorNumber')">
                <div>{{ getDetailsList.receiveHouseNumber || '--' }}</div>
              </el-descriptions-item>
              <!--收件人区-->
              <el-descriptions-item :label="$t('newOrder.RecipientDistrict')">
                <div>{{ getDetailsList.receiveArea || '--' }}</div>
              </el-descriptions-item>
              <!--收件人城市-->
              <el-descriptions-item :label="$t('newOrder.RecipientCity')">
                <div>{{ getDetailsList.receiveCity || '--' }}</div>
              </el-descriptions-item>
              <!--收件人州-->
              <el-descriptions-item :label="$t('newOrder.RecipientState')">
                <div>{{ getDetailsList.receiveState || '--' }}</div>
              </el-descriptions-item>
              <!--收件人邮编-->
              <el-descriptions-item :label="$t('newOrder.RecipientZipCode')">
                <div>{{ getDetailsList.receiveZipcode || '--' }}</div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="1" direction="vertical">
              <!--收件人地址补充-->
              <el-descriptions-item :label="$t('GlobalSetObj.recipientComplement')">
                <div style="display: flex;">
                  <span v-if="showDetail13" style="font-weight: bold">{{ encryptionInfo.receiveComplement || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.receiveComplement || '--' }}</span>
                  <span
                    v-if="getDetailsList.receiveComplement"
                    class="iconEye"
                    @click="handlerhidden('receiveComplement')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="2" direction="vertical">
              <!--收件人税号-->
              <el-descriptions-item :label="$t('newOrder.RecipientCPF')">
                <div style="display: flex;">
                  <span v-if="showDetail11" style="font-weight: bold">{{ encryptionInfo.receiveTax || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.receiveTax || '--' }}</span>
                  <span
                    v-if="getDetailsList.receiveTax"
                    class="iconEye"
                    @click="handlerhidden('receiveTax')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--国家注册号-->
              <el-descriptions-item :label="$t('GlobalSetObj.NationalRegistrationNumber')">
                <div>{{ getDetailsList.receiveIeNumber || '--' }}</div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="1" direction="vertical">
              <!--收件地址-->
              <el-descriptions-item :label="$t('newOrder.ReceivingAddress')">
                <div style="display: flex;">
                  <span v-if="showDetail14" style="font-weight: bold">{{ encryptionInfo.receiveAddress || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.receiveAddress || '--' }}</span>
                  <span
                    v-if="getDetailsList.receiveAddress"
                    class="iconEye"
                    @click="handlerhidden('receiveAddress')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="2" direction="vertical">
              <!--收件人电话-->
              <el-descriptions-item :label="$t('newOrder.Recipientphonenumber')">
                <div style="display: flex;">
                  <span v-if="showDetail2" style="font-weight: bold">
                    {{ encryptionInfo.receivePhone || '--' }}
                  </span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.receivePhone || '--' }}</span>
                  <span
                    v-if="getDetailsList.receivePhone"
                    class="iconEye"
                    @click="handlerhidden('receivePhone')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--收件人邮箱-->
              <el-descriptions-item :label="$t('newOrder.RecipientEmail')">
                <div style="display: flex;">
                  <span v-if="showDetail3" style="font-weight: bold">{{ encryptionInfo.receiveMail || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.receiveMail || '--' }}</span>
                  <span
                    v-if="getDetailsList.receiveMail"
                    class="iconEye"
                    @click="handlerhidden('receiveMail')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
        </el-card>
        <el-card class="box-card">
          <div style="margin-bottom: 20px;font-size: 14px;color: #12d2ac;">{{ $t('orderCenterCont.SenderInformation') }}</div>
          <el-form label-width="auto">
            <el-descriptions :colon="false" :column="2" direction="vertical">
              <!--发件人-->
              <el-descriptions-item :label="$t('newOrder.SenderName')">
                <div style="display: flex;">
                  <span v-if="showDetail6" style="font-weight: bold">{{ encryptionInfo.senderName || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.senderName || '--' }}</span>
                  <span
                    v-if="getDetailsList.senderName"
                    class="iconEye"
                    @click="handlerhidden('senderName')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--发件人公司-->
              <el-descriptions-item :label="$t('newOrder.SenderCompany')">
                <div style="display: flex;">
                  <span v-if="showDetail7" style="font-weight: bold">{{ encryptionInfo.senderCompany || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.senderCompany || '--' }}</span>
                  <span
                    v-if="getDetailsList.senderCompany"
                    class="iconEye"
                    @click="handlerhidden('senderCompany')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--发件人手机-->
              <el-descriptions-item :label="$t('newOrder.senderPhone')">
                <div style="display: flex;">
                  <span v-if="showDetail8" style="font-weight: bold">{{ encryptionInfo.senderMobile || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.senderMobile || '--' }}</span>
                  <span
                    v-if="getDetailsList.senderMobile"
                    class="iconEye"
                    @click="handlerhidden('senderMobile')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--街道-->
              <el-descriptions-item :label="$t('newOrder.Street1')">
                <div>{{ getDetailsList.senderStreet || '--' }}</div>
              </el-descriptions-item>
              <!--发件人门牌号-->
              <el-descriptions-item :label="$t('newOrder.SenderNumber')">
                <div>{{ getDetailsList.senderHouseNumber || '--' }}</div>
              </el-descriptions-item>
              <!--发件人区-->
              <el-descriptions-item :label="$t('newOrder.SenderDistrict')">
                <div>{{ getDetailsList.senderArea || '--' }}</div>
              </el-descriptions-item>
              <!--发件人城市-->
              <el-descriptions-item :label="$t('newOrder.SenderCity')">
                <div>{{ getDetailsList.senderCity || '--' }}</div>
              </el-descriptions-item>
              <!--发件人州-->
              <el-descriptions-item :label="$t('newOrder.SenderState')">
                <div>{{ getDetailsList.senderState || '--' }}</div>
              </el-descriptions-item>
              <!--发件人邮编-->
              <el-descriptions-item :label="$t('newOrder.SenderZipCode')">
                <div>{{ getDetailsList.senderZipcode || '--' }}</div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="1" direction="vertical">
              <!--发件人地址补充-->
              <el-descriptions-item :label="$t('GlobalSetObj.sendderComplement')">
                <div style="display: flex;">
                  <span v-if="showDetail15" style="font-weight: bold">{{ encryptionInfo.senderComplement || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.senderComplement || '--' }}</span>
                  <span
                    v-if="getDetailsList.senderComplement"
                    class="iconEye"
                    @click="handlerhidden('senderComplement')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="2" direction="vertical">
              <!--发件人税号-->
              <el-descriptions-item :label="$t('newOrder.SenderCPF')">
                <div style="display: flex;">
                  <span v-if="showDetail12" style="font-weight: bold">{{ encryptionInfo.senderTax || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.senderTax || '--' }}</span>
                  <span
                    v-if="getDetailsList.senderTax"
                    class="iconEye"
                    @click="handlerhidden('senderTax')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--国家注册号-->
              <el-descriptions-item :label="$t('GlobalSetObj.NationalRegistrationNumber')">
                <div>{{ getDetailsList.senderIeNumber || '--' }}</div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="1" direction="vertical">
              <!--发件地址-->
              <el-descriptions-item :label="$t('newOrder.SendingAddress')">
                <div style="display: flex;">
                  <span v-if="showDetail16" style="font-weight: bold">{{ encryptionInfo.senderAddress || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.senderAddress || '--' }}</span>
                  <span
                    v-if="getDetailsList.senderAddress"
                    class="iconEye"
                    @click="handlerhidden('senderAddress')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :colon="false" :column="2" direction="vertical">
              <!--发件人电话-->
              <el-descriptions-item :label="$t('newOrder.SenderPhone')">
                <div style="display: flex;">
                  <span v-if="showDetail9" style="font-weight: bold">{{ encryptionInfo.senderPhone || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.senderPhone || '--' }}</span>
                  <span
                    v-if="getDetailsList.senderPhone"
                    class="iconEye"
                    @click="handlerhidden('senderPhone')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
              <!--发件人邮箱-->
              <el-descriptions-item :label="$t('newOrder.SenderEmail')">
                <div style="display: flex;">
                  <span v-if="showDetail10" style="font-weight: bold">{{ encryptionInfo.senderMail || '--' }}</span>
                  <span v-else style="font-weight: bold">{{ getDetailsList.senderMail || '--' }}</span>
                  <span
                    v-if="getDetailsList.senderMail"
                    class="iconEye"
                    @click="handlerhidden('senderMail')"
                  >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                      <svg-icon icon-class="iconEye" />
                    </el-tooltip>
                  </span>
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
        </el-card>
      </el-tab-pane>
      <!-- 物品信息 -->
      <el-tab-pane :label="$t('orderDetails.wpMsg')" name="x2">
        <el-form
          v-model="getDetailsList.orderWaybillList"
          label-position="top"
          label-width="auto"
        >
          <div v-for="(item, index) in getDetailsList.orderWaybillList" :key="index">
            <el-card class="box-card" style="margin-bottom: 20px;">
              <div style="margin: 10px 0;font-size: 15px;color: #12d2ac;font-weight: bold;">{{ $t('placeOrder.package') }}{{ index + 1 }}</div>
              <el-descriptions :colon="false" :column="2" direction="vertical">
                <!--长-->
                <el-descriptions-item :label="$t('placeOrder.long')">
                  <div>{{ item.length || '--' }}</div>
                </el-descriptions-item>
                <!--宽-->
                <el-descriptions-item :label="$t('placeOrder.width')">
                  <div>{{ item.width || '--' }}</div>
                </el-descriptions-item>
                <!--高-->
                <el-descriptions-item :label="$t('placeOrder.height')">
                  <div>{{ item.height || '--' }}</div>
                </el-descriptions-item>
                <!--重量-->
                <el-descriptions-item :label="$t('placeOrder.weight')">
                  <div>{{ item.weight || '--' }}</div>
                </el-descriptions-item>
                <!--包裹类型-->
                <el-descriptions-item :label="$t('placeOrder.packageType')">
                  <div>{{ item.waybillType || '--' }}</div>
                </el-descriptions-item>
                <!--面单-->
                <el-descriptions-item :label="$t('placeOrder.FaceSheet')">
                  <div v-if="item.labelUrl">
                    <div ref="invoiceDownLoad" class="downBtn" @click="initDown(item)">
                      <span class="el-icon-tickets" />
                      <span class="downBtnText">{{ $t('placeOrder.FaceSheet') }}</span>
                      <span style="color: #12d2ac;cursor: pointer;margin-left: 10px;">{{ $t('GlobalSetObj.clickDown') }}</span>
                    </div>
                  </div>
                </el-descriptions-item>
              </el-descriptions>
              <div v-for="(wpItem, index1) in item.orderItemList" :key="index1" class="wpXXX">
                <!-- 物品 -->
                <div style="margin: 20px 0;font-size: 15px;font-weight: bold;">{{ $t('placeOrder.wp') }}{{ index1 + 1 }}</div>
                <el-descriptions :colon="false" :column="2" direction="vertical">
                  <!--物品名称-->
                  <el-descriptions-item :label="$t('placeOrder.wpName')">
                    <div>{{ wpItem.name || '--' }}</div>
                  </el-descriptions-item>
                  <!--物品单价(R$/$)-->
                  <el-descriptions-item :label="$t('placeOrder.declaredValue')">
                    <div>{{ wpItem.declaredValue || '--' }}</div>
                  </el-descriptions-item>
                  <!--数量-->
                  <el-descriptions-item :label="$t('placeOrder.quantity')">
                    <div>{{ wpItem.quantity || '--' }}</div>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';

export default {
  name: 'Details',
  props: {
    details: {
      type: [Object],
      default: () => ({})
    }
  },
  data() {
    return {
      activeName: 'x1',
      getDetailsList: {},
      hasPromise: false,
      showDetail: true,
      showDetail1: true,
      showDetail2: true,
      showDetail3: true,
      showDetail4: true,
      showDetail5: true,
      showDetail6: true,
      showDetail7: true,
      showDetail8: true,
      showDetail9: true,
      showDetail10: true,
      showDetail11: true,
      showDetail12: true,
      showDetail13: true,
      showDetail14: true,
      showDetail15: true,
      showDetail16: true,
      encryptionInfo: {}
    };
  },
  watch: {
    details() {
      this.activeName = 'x1';
      this.getDetailsList = [];
      this.getDetailsList = this.details;
      this.encryptionInfo = cloneDeep(this.details);
      this.encryptionInfo.receiveName = this.encryptionInfo.receiveName ? '****' : '';
      this.encryptionInfo.receiveCompany = this.encryptionInfo.receiveCompany ? '****' : '';
      this.encryptionInfo.receiveMobile = this.encryptionInfo.receiveMobile ? '****' : '';
      this.encryptionInfo.receivePhone = this.encryptionInfo.receivePhone ? '****' : '';
      this.encryptionInfo.receiveMail = this.encryptionInfo.receiveMail ? '****' : '';
      this.encryptionInfo.senderName = this.encryptionInfo.senderName ? '****' : '';
      this.encryptionInfo.senderCompany = this.encryptionInfo.senderCompany ? '****' : '';
      this.encryptionInfo.senderMobile = this.encryptionInfo.senderMobile ? '****' : '';
      this.encryptionInfo.senderPhone = this.encryptionInfo.senderPhone ? '****' : '';
      this.encryptionInfo.senderMail = this.encryptionInfo.senderMail ? '****' : '';
      this.encryptionInfo.receiveTax = this.encryptionInfo.receiveTax ? '****' : '';
      this.encryptionInfo.senderTax = this.encryptionInfo.senderTax ? '****' : '';
      this.encryptionInfo.receiveComplement = this.encryptionInfo.receiveComplement ? '****' : '';
      this.encryptionInfo.receiveAddress = this.encryptionInfo.receiveAddress ? '****' : '';
      this.encryptionInfo.senderComplement = this.encryptionInfo.senderComplement ? '****' : '';
      this.encryptionInfo.senderAddress = this.encryptionInfo.senderAddress ? '****' : '';
      console.log('详情数据', this.getDetailsList);
    }
  },
  methods: {
    handlerhidden(item, value) {
      const buttonPermissions = this.$store.getters.button_permissions;
      const promise = 'field:customer-sensitive-info';
      const hasPromise = buttonPermissions.includes(promise);
      if (hasPromise) {
        if (item === 'receiveName') {
          this.showDetail = !this.showDetail;
        }
        if (item === 'receiveMobile') {
          this.showDetail1 = !this.showDetail1;
        }
        if (item === 'receivePhone') {
          this.showDetail2 = !this.showDetail2;
        }
        if (item === 'receiveMail') {
          this.showDetail3 = !this.showDetail3;
        }
        if (item === 'receiveCertificateCode') {
          this.showDetail4 = !this.showDetail4;
        }
        if (item === 'receiveCompany') {
          this.showDetail5 = !this.showDetail5;
        }
        if (item === 'senderName') {
          this.showDetail6 = !this.showDetail6;
        }
        if (item === 'senderCompany') {
          this.showDetail7 = !this.showDetail7;
        }
        if (item === 'senderMobile') {
          this.showDetail8 = !this.showDetail8;
        }
        if (item === 'senderPhone') {
          this.showDetail9 = !this.showDetail9;
        }
        if (item === 'senderMail') {
          this.showDetail10 = !this.showDetail10;
        }
        if (item === 'receiveTax') {
          this.showDetail11 = !this.showDetail11;
        }
        if (item === 'senderTax') {
          this.showDetail12 = !this.showDetail12;
        }
        if (item === 'receiveComplement') {
          this.showDetail13 = !this.showDetail13;
        }
        if (item === 'receiveAddress') {
          this.showDetail14 = !this.showDetail14;
        }
        if (item === 'senderComplement') {
          this.showDetail15 = !this.showDetail15;
        }
        if (item === 'senderAddress') {
          this.showDetail16 = !this.showDetail16;
        }
      } else {
        this.$message.error(this.$t('GlobalSetObj.sensitiveInfoPermission'));
      }
    },
    initDown(down) {
      // console.log('下载', down);
      window.open(down.labelUrl);
    },
    handleClick(tab, event) {
      // console.log('订单详情切换', tab, event);
    },
    copyContext(text) {
      const oInput = document.createElement('input');
      oInput.value = text;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand('Copy');
      this.$message.success(this.$t('orderDetails.ReplicationSucceeded'));
      oInput.remove();
    }
  }
};
</script>
<style scoped>
.detailsBox /deep/ .el-col {
  height: 50px;
}

.iconEye {
  cursor: pointer;
  margin-left: 10px;
}

.detailsBox /deep/ .el-descriptions-item__label {
  font-size: 13px;
  color: #909399;
}

.detailsBox /deep/ .el-descriptions-item__cell {
  /*height: 35px !important;*/
  padding-bottom: 2px !important;
}

.el-descriptions-item__cell div {
  font-weight: bold;
  font-size: 15px;
}
</style>
<style lang="scss" scoped>
// 使用::v-deep时需加上 当前组件最外层class .detailsBox 防止影响其他组件样式
::v-deep .detailsBox .el-tabs__item {
  padding: 0 6px !important;
}

::v-deep .detailsBox .el-tabs__nav-wrap::after {
  background-color: #fff !important;
}

::v-deep .detailsBox .el-tabs__header {
  width: 100% !important;
}

.wpXXX ::v-deep .el-form-item {
  margin-bottom: 0px !important;
  text-align: center;
}

.bgTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}

.xdWP {
  width: 85px;
  height: 50px;
  padding-right: 30px;
  border-right: 1px solid #f7f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
}

.detailsBox {
  .detailsBtnBox {
    width: 37%;
    height: 100px;
    padding-right: 20px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 20px;
    background: rgba(255, 255, 255, 0.8);

    .detailsBtn {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dBtn {
        color: #fff;
        border: none;
        font-size: 13px;
        background: #12D2AC;
        line-height: 30px;
      }

      .dBtn.qx {
        width: 120px;
        height: 30px;
        border-radius: 5px;
      }

      .dBtn.dy, .dBtn.bj {
        width: 120px;
        height: 30px;
        border-radius: 5px;
      }
    }
  }
}

// 轨迹信息
.trackInformation {
  .trackTop {
    margin: 10px 0 30px 0;
    display: flex;
    align-items: center;

    .top1, .top2 {
      background: #eee;
      height: 40px;
      padding: 0 20px;
      line-height: 40px;
      color: #000;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
    }

    .top2 {
      margin-left: 40%;
    }
  }
}

.detailsBox ::v-deep .el-step.is-vertical .el-step__head {
  text-align: center;
}

.detailsBox ::v-deep .el-step__icon-inner {
  display: none !important;
}

.detailsBox ::v-deep .el-step__icon {
  width: 15px !important;
  height: 15px !important;
}

.copyBtn {
  border: none;
  outline: none;
  background: #d9d9d9;
  color: #000;
  padding: 2px 3px;
  margin-left: 10px;
}

.topName {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0 7px;
}

.topName.s {
  margin: 30px 0 7px;
}

.detailsBox ::v-deep .el-select {
  width: 100%;
}
</style>
