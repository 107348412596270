import request from '@/utils/request';

import axios from 'axios';
import { getToken } from '@/utils/auth';
import store from '@/store';

const serviceNew = axios.create({
  baseURL: process.env.VUE_APP_TRACK_QUERY,
  timeout: 30000
  // headers: {
  //   'X-Token': getToken()
  // }
});
// 请求拦截器
serviceNew.interceptors.request.use(
  config => {
    // 在发送请求之前做一些事情
    if (store.getters.token) {
      // 让每个请求都带有令牌
      // ['X-Token'] is a custom headers key
      // 请根据实际情况进行修改
      config.headers['X-Token'] = getToken();
    }
    return config;
  },
  error => {
    // 做一些请求错误
    return Promise.reject(error);
  }
);
// 轨迹查询
export const getTrackQueryData = (orderNum) => {
  return serviceNew.get('/open/track?trackNo=' + orderNum);
  // return request({
  //   baseURL: process.env.VUE_APP_TRACK_QUERY,
  //   url: '/track?trackNo=' + orderNum
  // });
};
// 轨迹表 => 轨迹查询
export const getTrackQueryTable = (orderNum) => {
  return request.get('/track/' + orderNum);
};
// 手动更新轨迹
export const updateTrack = (orderNum) => {
  return serviceNew.post('/operation/register/order/number', orderNum);
};
const createUrl = process.env.VUE_APP_TAKENO;
// const createUrl = '/takeTrackNum';
export const logisticsOrder = (data) => {
  return request({
    method: 'POST',
    baseURL: createUrl,
    url: '/order/client/create',
    data: data
  });
};

// 取号
export const getTakeTrackNum = (data) => {
  return request({
    baseURL: createUrl,
    method: 'POST',
    url: '/exChange/api/create',
    data

  });
};

export const batchCreateOrder = (data) => {
  return request({
    method: 'POST',
    baseURL: createUrl,
    url: '/batch/client/create',
    data: data
  });
};

/**
 * @description 轨迹推送管理查询
 * @param data
 * @returns {AxiosPromise}
 */
export const trackPushLogPage = (data) => {
  return request({
    method: 'POST',
    url: '/trackPushLog/page',
    data: data
  });
};

/**
 * @description 轨迹接收管理查询
 * @param data
 * @returns {AxiosPromise}
 */
export const trackAcceptRecordPage = (data) => {
  return request({
    method: 'POST',
    url: '/trackAcceptRecord/page',
    data: data
  });
};
// 轨迹查询   调用跨境小包轨迹api
export const getproviderTrackQuery = (orderNum) => {
  return request.post('/provider/order/track/query', orderNum);
};

// 快递轨迹查询
export const getTrackByWaybillNumbers = (data, isLoading = true) => {
  return request({
    method: 'POST',
    url: '/track/queryByWaybillNumbers',
    data,
    isLoading
  });
};
