<template>
  <div>
    <div class="warpContent">
      <div style="display: flex">
        <div>
          <span>{{ $t('newOrder.waybillNumber') }}: </span>
          <span>{{ rowData.waybillNumber || '--' }}</span>
        </div>
        <div style="margin: 0 30px">
          <span>{{ $t('GlobalSetObj.preEntrega') }}: </span>
          <span>{{ rowData.preDeliverSiteIdName || '--' }}</span>
        </div>
        <div>
          <span>{{ $t('orderCenterCont.deliveryPerson') }}: </span>
          <span>{{ rowData.delivererName || '--' }}</span>
        </div>
      </div>
      <div style="margin: 20px 0">
        <span>{{ $t('collectionCenter.ReceivingAddress') }}: </span>
        <span>{{ rowData.receiveAddr || '--' }}</span>
      </div>
      <div>
        <span>{{ $t('orderCenterCont.HouseNumber') }}: </span>
        <span>{{ rowData.receiveHouseNumber || '--' }}</span>
      </div>
    </div>
    <div class="signImageCss">
      <div>{{ $t('home.signedPhoto') }}</div>
      <div v-if="rowData.signedPhoto" class="demo-image__preview" style="z-index: 9999">
        <el-image
          v-for="(item1, index1) in rowData.signedPhoto"
          :key="index1"
          :preview-src-list="[item1]"
          :src="item1"
          fit="contain"
          style="width: 100px; margin-right: 5px"
        />
      </div>
      <div v-else>--</div>
    </div>
    <div class="signImageCss">
      <div>{{ $t('home.signedNamePhoto') }}</div>
      <div v-if="rowData.signedNamePhoto" class="demo-image__preview" style="z-index: 9999">
        <el-image
          v-for="(item2, index2) in rowData.signedNamePhoto"
          :key="index2"
          :preview-src-list="[item2]"
          :src="item2"
          fit="contain"
          style="width: 100px; margin-right: 5px"
        />
      </div>
      <div v-else>--</div>
    </div>
    <div class="warpContent">
      <div style="display: flex; align-items: center">
        <div style="margin-right: 15px">{{ $t('home.appliveResult') }}:</div>
        <el-radio-group v-model="formInline.auditsState">
          <el-radio :label="1">{{ $t('home.pass') }}</el-radio>
          <el-radio :label="0">{{ $t('home.noPass') }}</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="flx-end" style="margin-top: 15px">
      <el-button
        size="small" style="margin-right: 20px" @click="cancelConfirm"
      >{{ $t('GlobalSetObj.cancel') }}
      </el-button>
      <el-button size="small" type="primary" @click="confirm">{{
        $t('GlobalSetObj.Confirm')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { lgStorageForceSignAudits } from '@/api/orderApi';

export default {
  name: 'Detail',
  props: {
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formInline: {
        auditsState: ''
      }
    };
  },
  mounted() {
    // this.formInline.auditsState = this.rowData.auditsState;
  },
  methods: {
    confirm() {
      if (this.formInline.auditsState !== '') {
        const data = {
          id: this.rowData.id,
          auditsState: this.formInline.auditsState
        };
        lgStorageForceSignAudits(data).then((res) => {
          if (res.status === 'OK') {
            this.$emit('call-parent-method', false);
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          }
        });
      } else {
        this.$message.error('请选择审核结果');
      }
    },
    cancelConfirm() {
      this.$emit('call-parent-method', false);
    }
  }
};
</script>

<style scoped>
.warpContent {
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #ccc;
}

.signImageCss {
  font-size: 20px;
  font-weight: bold;
  color: #409eff;
  margin: 10px 0;
}
</style>
