/**
 * 一些常规校验
 */
import $i18n from '../lang/index';
import { checkedzipcode } from '@/api/orderApi';
import { Loading } from 'element-ui';

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * 整数部分不限制，可保留3位有效小数
 * @param {*} s
 */
export function checkSumber(s) {
  return /^[0-9]+\.?[0-9]{0,3}$/.test(s);
}

/**
 *
 */
export function phoneNumberCode(val) {
  const reg = /^[0-9\-\+\s]{10,}$/;
  if (!reg.test(val)) {
    return false;
  } else {
    return true;
  }
}

/**
 * 兼容数字及特殊符号, 格式: 005.339.857-22
 */
export function numberCode(val) {
  // const reg1 = /(?=.*)(?=-*)[0-9]/; // 应该是所有特殊符号...
  // const reg1 = /^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/;
  const reg1 = /^[0-9.-]+$/;
  if (!reg1.test(val)) {
    return false;
  } else {
    return true;
  }
}

/**
 * 整数部分最大长度9位，可保留两位有效小数
 * @param {*} s
 */
export function checkMoney(s) {
  return /^-?(?:0|[1-9]\d{0,8})(?:\.\d{0,1}[1-9])?$/.test(s);
}

/**
 * 请填写长度6至30位的字母+数字组合
 * @param {*} s
 */
export function checkCode(s) {
  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,30}$/.test(s);
}

/**
 * 密码
 * 大写或者、小写 和数字组成，可选特殊符号 8到12位
 * @param {*} s
 */
export function weakPwd(s) {
  return /(?=.*[0-9].*)(?=.*[A-Z].*)|(?=.*[0-9].*)(?=.*[a-z].*).{8,12}$/g.test(s);
}

/**
 * 弱密码
 * 大写或者、小写 和数字组成
 * @param {*} s
 */
// export function weakPwd(s) {
//   // return /^[A-Za-z0-9]{4,16}$/g.test(s);
// }

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * 校验下单————带有小数点数字
 * @param {*} s
 */
export function checkFloatNumber(s) {
  if (/^[0-9]+\.?[0-9]{0,3}$/.test(s)) {
    if (Number(s) < 0 || Number(s) > 99999999.999) {
      return 1;
    } else {
      return true;
    }
  } else {
    return 2;
  }
}

export function checkFloatWeight(rule, value, callback) {
  if (!value) {
    return callback();
  }
  if (Number(value) <= 0) {
    return callback(new Error($i18n.t('placeOrder.input0500')));
  }
  if (Number(value) > 500) {
    return callback(new Error($i18n.t('placeOrder.input0500')));
  }
  if (/^[+]?[0-9]+\.?[0-9]{0,3}$/.test(value)) {
    return callback();
  } else {
    if (/^[-]?[0-9]+\.?[0-9]{0,3}$/.test(value)) {
      return callback(new Error($i18n.t('placeOrder.inputZhenshu')));
    } else {
      return callback(new Error($i18n.t('placeOrder.input3')));
    }
  }
}

/**
 * 校验下单————开票金额
 */
export function InvoiceAmounts(rule, value, callback) {
  if (Number(value) <= 0) {
    return callback(new Error($i18n.t('placeOrder.InvoiceAmountThen')));
  }
  if (Number(value) > 999999999999.99) {
    return callback(new Error($i18n.t('placeOrder.InvoiceAmountMore')));
  }
  if (/^[0-9]+\.?[0-9]{0,2}$/.test(value)) {
    return callback();
  } else {
    return callback(new Error($i18n.t('placeOrder.twoDecimalPlaces')));
  }
}

/**
 * 校验下单————物品单价
 */
export function priceOfGoods(rule, value, callback) {
  if (Number(value) < 0) {
    return callback(new Error($i18n.t('placeOrder.unitPricenotthan')));
  }
  if (Number(value) > 999999999999.99) {
    return callback(new Error($i18n.t('placeOrder.unitPricenotMore99')));
  }
  if (/^[0-9]+\.?[0-9]{0,2}$/.test(value)) {
    return callback();
  } else {
    return callback(new Error($i18n.t('placeOrder.twoDecimalPlaces')));
  }
}

/**
 * 校验下单-地址补充 addressComplement
 */
export function checkAddressComplement(rule, value, callback) {
  if (!value) {
    return callback();
  }
  if (value.length > 30) {
    return callback(new Error($i18n.t('placeOrder.input30Str')));
  } else {
    return callback();
  }
}

/**
 * 校验下单-邮箱 Mail
 */
export function E_Mail(rule, value, callback) {
  const reg = /^[a-zA-Z0-9][a-zA-Z0-9_.-]{0,99}@[a-zA-Z0-9][a-zA-Z0-9_.-]{1,99}$/;
  if (!value) {
    return callback();
  }
  if (reg.test(value)) {
    return callback();
  } else {
    return callback(new Error($i18n.t('placeOrder.emailError')));
  }
}

/**
 * 校验下单-城市 checkCityComplement
 */
export function checkCityComplement(rule, value, callback) {
  if (value.length > 30) {
    return callback(new Error($i18n.t('placeOrder.input30Str')));
  } else {
    return callback();
  }
}

/**
 * 名称为英文大小写、中文
 * @param {*} s
 */
export function isName(s) {
  return /([A-Za-z])|([\u4E00-\u9FA5])/g.test(s);
}

/**
 * 名称里不能包含数字
 * @param s
 */
export function isNameNumber(s) {
  return /[0-9]/g.test(s);
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s);
}

/**
 * 巴西网点邮箱
 * @param {*} s
 */
export function isbxEmail(s) {
  // return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+.(com)+((.br){0,1})$/.test(s);
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+\.(com)+((.br)?)$/.test(s);
}

/**
 * 邮编
 * @param {*} s
 */
export function isZipCode(s) {
  // return /^([0-9]{5})+-([0-9]{3})$/.test(s);
  return /^([0-9]{5})([0-9]{3})$/.test(s);
}

/**
 * 邮编
 */
export async function isZipCodeAsync(rule, value, callback) {
  const reg = /^[0-9]{8}$/;
  if (reg.test(value)) {
    const data = {};
    data.zipcode = value;
    try {
      const res = await checkedzipcode(data);
      if (res.status === 'OK') {
        return callback();
      }
    } catch (err) {
      return callback(new Error(err.msg));
    }
    // checkedzipcode(data).then(res => {
    //   if (res.status === 'OK') {
    //     console.log('邮编矢口抵赖');
    //     return callback();
    //   }
    // }).catch((err) => {
    //   return callback(new Error(err.msg));
    // });
  } else {
    return callback(new Error($i18n.t('placeOrder.input8ZipCode')));
  }
}

/**
 * 供应商
 */
export async function isZipCodeAsyncSupplier(rule, value, callback) {
  if (!value) {
    return callback();
  }
  const reg = /^[0-9]{8}$/;
  if (reg.test(value)) {
    const data = {};
    data.zipcode = value;
    try {
      const res = await checkedzipcode(data);
      if (res.status === 'OK') {
        return callback();
      }
    } catch (err) {
      return callback(new Error(err.msg));
    }
  } else {
    return callback(new Error($i18n.t('placeOrder.input8ZipCode')));
  }
}

/**
 * 巴西邮编范围 需求：格式：12345678~12345678 或 12345678
 *               需要输入多个
 * @param {*} s
 */
export function isbxZipCode(s) {
  // return /^([0-9]{8})$|^([0-9]{8})+~([0-9]{8})$/.test(s);
  return /^([0-9]{8})+((~[0-9]{8}){0,1})$/.test(s);
}

/**
 * 邮编
 */
export function isZipCodexiaodan(rule, value, callback) {
  const reg = /^[0-9]{8}$/;
  if (reg.test(value)) {
    const data = {};
    data.zipcode = value;
    const loading = Loading.service({
      fullscreen: true,
      lock: true,
      spinner: 'el-icon-loading',
      text: 'loading...',
      background: 'rgba(250, 250, 250, 0.6)'
    });
    checkedzipcode(data)
      .then((res) => {
        if (res.status === 'OK') {
          loading.close();
          return callback();
        }
      })
      .catch((err) => {
        loading.close();
        return callback(new Error(err.msg));
      });
  } else {
    return callback(new Error($i18n.t('placeOrder.input8ZipCode')));
  }
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^[0-9]{10,11}$/.test(s);
}

/**
 * 正则校验手机号码
 *
 */
export function MobilePhoneNumber(rule, value, callback) {
  const reg1 = /^[0-9]{10,11}$/;
  const reg2 = /[^0-9]/; // 校验不是0-9的数字
  if (reg2.test(value)) {
    return callback(new Error($i18n.t('collectionCenter.inputNum')));
  }
  if (!reg1.test(value)) {
    return callback(new Error($i18n.t('placeOrder.input11PhoneNumber')));
  }
  return callback();
}

/**
 * 正则校验联系人手机号码
 *
 */
export function Contactphonenumber(rule, value, callback) {
  if (!value) {
    return callback();
  }
  const reg1 = /^[0-9]{11}$/;
  const reg2 = /[^0-9]/; // 校验不是0-9的数字
  if (reg2.test(value)) {
    return callback(new Error($i18n.t('collectionCenter.inputNum')));
  }
  if (!reg1.test(value)) {
    return callback(new Error($i18n.t('GlobalSetObj.formatoError')));
  }
  return callback();
}

/**
 * 正则校验联系人手机号码
 *
 */
export function ContactTel(rule, value, callback) {
  if (!value) {
    return callback();
  }
  const reg1 = /^[0-9]{1,24}$/;
  const reg2 = /[^0-9]/; // 校验不是0-9的数字
  if (reg2.test(value)) {
    return callback(new Error($i18n.t('collectionCenter.inputNum')));
  }
  if (!reg1.test(value)) {
    return callback(new Error($i18n.t('GlobalSetObj.Insira24')));
  }
  return callback();
}

/**
 * 正则校验银行位16位纯数字
 *
 */
export function bankAccount(rule, value, callback) {
  const reg1 = /^[0-9]{16}$/;
  const reg2 = /[^0-9]/; // 校验不是0-9的数字
  if (!value) {
    return callback();
  }
  if (reg2.test(value)) {
    return callback(new Error($i18n.t('collectionCenter.inputNum')));
  }
  if (!reg1.test(value)) {
    return callback(new Error($i18n.t('GlobalSetObj.Insira16')));
  }
  return callback();
}

/**
 * 正14位纯数字
 *
 */
export function val14(rule, value, callback) {
  if (!value) {
    return callback();
  }
  const reg1 = /^[0-9]{14}$/;
  if (!value) {
    return callback();
  }
  if (!reg1.test(value)) {
    return callback(new Error($i18n.t('GlobalSetObj.Insira14')));
  }
  return callback();
}

/**
 * 正则11位纯数字
 *
 */
export function val11(rule, value, callback) {
  if (!value) {
    return callback();
  }
  const reg1 = /^[0-9]{11}$/;
  if (!value) {
    return callback();
  }
  if (!reg1.test(value)) {
    return callback(new Error($i18n.t('GlobalSetObj.Insira11')));
  }
  return callback();
}

/**
 * 电话号码
 * @param {*} s
 */
// export function isPhone(s) {
//   return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s);
// }

/**
 * URL地址
 * @param {*} s
 */
// export function isURL(s) {
//   return /^http[s]?:\/\/.*/.test(s);
// }

/**
 * 身份证
 * @param {*} s
 */
export function isId(s) {
  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(s);
}

/**
 * 是否为密码
 * @param s
 */
export function isPassword(s) {
  return /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{4,16}$/.test(s);
}

/**
 * 密码不能为表情
 * @param s
 */
export function isEmoge(s) {
  // eslint-disable-next-line no-misleading-character-class
  return /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/.test(
    s
  );
}

/**
 * 不能是中文
 * @param s
 */
export function isChinese(s) {
  return /[\u4E00-\u9FA5]/g.test(s);
}

/**
 * 不能有英文
 * @param s
 */
export function isEn(s) {
  return /[a-zA-Z]/g.test(s);
}

/**
 * 是否为字母加数字
 * @param s
 * @returns {boolean}
 */
export function isLetterOrNum(s) {
  return /^[A-Za-z0-9]+$/.test(s);
}

/**
 * 提示只能输入数字
 * @param s
 */
export function isNumber(s) {
  const regex = /^\d+$/; // 校验是否11位或14位数字
  if (regex.test(s)) {
    if (Number(s) < 0 || Number(s) > 99999999) {
      return 1;
    } else {
      return true;
    }
  } else {
    return 2;
  }
}

/**
 * 最小值:大于0的正整数,最大数为8个9
 * @param Number
 */
export function minData(Number) {
  const regex = /^[1-9]\d{0,7}$/;
  if (Number) {
    if (!regex.test(Number)) {
      if (parseInt(Number) > 99999999) {
        return 2;
      }
      return 1;
    }
    return true; // 校验通过
  } else {
    return true; // 校验通过
  }
}

/**
 * 巴西税号校验
 */
export function isTax(rule, value, callback) {
  const regex = /^[0-9.\-\/]{11,19}$/;
  if (!regex.test(value)) {
    return callback(new Error($i18n.t('placeOrder.taxIncorrect')));
  }
  const pattern = /\d+/g;
  const numbers = value.match(pattern);
  const a = numbers.join('');
  if (a.length === 11 || a.length === 14) {
    return callback();
  } else {
    return callback(new Error($i18n.t('placeOrder.taxFormatIncorrect')));
  }
}

/**
 * 巴西检验发票序列号
 */
export function invoiceSerialIndex(rule, value, callback) {
  // 使用正则表达式匹配1到8位的数字
  const regex = /^\d{1,8}$/;
  if (!regex.test(value)) {
    return callback(new Error($i18n.t('placeOrder.PleaseEnter1To8invoiceSerialIndex')));
  }
  return callback();
}

/**
 * 巴西发票秘钥
 */
export function invoiceSeriakey(rule, value, callback) {
  const strValue = value.replace(/\s+/g, ''); // 去除空格
  const regex = /^\d{44}$/;
  if (!regex.test(strValue)) {
    return callback(new Error($i18n.t('orderCenterCont.TheFormatIsIncorrect')));
  }
  return callback();
}

/**
 * 巴西发票号校验5-16
 */
export function invoiceNumberCheck(rule, value, callback) {
  // 使用正则表达式匹配1到64位的数字
  const regex = /^\d{5,16}$/;
  if (!regex.test(value)) {
    return callback(new Error($i18n.t('orderCenterCont.TheFormatIsIncorrect')));
  }
  return callback();
}

/**
 * 检验国家注册号9位数值
 */
export function IENumber(rule, value, callback) {
  const regex = /^\d{1,17}$/;
  if (!value) {
    return callback();
  }
  if (!regex.test(value)) {
    return callback(new Error($i18n.t('placeOrder.IENumber')));
  }
  return callback();
}

/**
 * 是否是图片类型
 * @param {*} type
 */
export function isImg(type) {
  if (
    type !== 'image/jpg' &&
    type !== 'image/jpeg' &&
    type !== 'image/png' &&
    type !== 'image/gif'
  ) {
    this.$message.error('只支持jpg、png、gif格式的图片！');
    return false;
  }
  return true;
}

/**
 * 是否是视频类型['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb']
 * @param {*} type
 */
// export function isVideo(type) {
//   if (type !== 'video/mp4' && type !== 'video/ogg' && type !== 'video/flv' && type !== 'video/avi' && type !== 'video/wmv' && type !== 'video/rmvb') {
//     this.$message.error('只支持mp4、ogg、flv、avi、wmv、rmvb格式的视频！');
//     return false;
//   }
//   return true;
// }

/**
 * 是否是压缩包类型
 * @param {*} type
 */
export function isZip(type) {
  if (type !== 'application/zip') {
    this.$message.error('只支持zip格式的图片！');
    return false;
  }
  return true;
}

/**
 * 是否是文档类型['application/msword'、'application/pdf']
 * @param {*} type
 */
export function isDoc(type) {
  if (type !== 'application/zip' && type !== 'application/pdf') {
    this.$message.error('只支持doc、docx、pdf格式的文档！');
    return false;
  }
  return true;
}

/**
 * 英文字母
 */
export function englishLetter(rule, value, callback) {
  const reg = /[a-zA-Z]/;
  const { required } = rule;
  // const val = value.trim();
  if (!required) {
    // 非必填
    return callback();
  }

  if (!value || value.length === 0) {
    return callback(new Error($i18n.t('orderCenterCont.CannotBeEmpty')));
  }

  if (!reg.test(value)) {
    return callback(new Error('请输入英文字母'));
  }

  if (rule.length && value.length !== rule.length) {
    return callback(new Error('字数长度只能为' + rule.length + '位'));
  }

  // if (value.length !== rule.length) {
  //   return callback(new Error('字数长度只能为' + rule.length + '位'));
  // }

  return callback();
  // if (rule.required) {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('请输入英文字母'));
  //     } else {
  //       if (value.length < rule.length) {
  //         return callback(new Error('字数不能少于' + rule.length));
  //       } else if (value.length > rule.length) {
  //         return callback(new Error('字数不能大于' + rule.length));
  //       } else {
  //         return callback();
  //       }
  //     }
  //   } else {
  //     return callback(new Error('不能为空'));
  //   }
  // } else {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('请输入英文字母'));
  //     } else {
  //       if (value.length < rule.length) {
  //         return callback(new Error('字数不能少于' + rule.length));
  //       } else if (value.length > rule.length) {
  //         return callback(new Error('字数不能大于' + rule.length));
  //       } else {
  //         return callback();
  //       }
  //     }
  //   } else {
  //     return callback();
  //   }
  // }
}

/**
 * 数字类型 0-9
 */
export function integerType(rule, value, callback) {
  const reg = /^[0-9]*$/;
  const { required } = rule;
  // const val = value.trim();
  if (!required) {
    return callback();
  }

  if (!value || value.length === 0) {
    return callback(new Error('不能为空'));
  }

  if (value.length === 0) {
    return callback(new Error('不能为空'));
  }
  if (!reg.test(value)) {
    return callback(new Error('请输入数字'));
  }

  return callback();

  // if (rule.required) {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('请输入数字'));
  //     } else {
  //       return callback();
  //     }
  //   } else {
  //     return callback(new Error('不能为空'));
  //   }
  // } else {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('请输入数字'));
  //     } else {
  //       return callback();
  //     }
  //   } else {
  //     return callback();
  //   }
  // }
}

/**
 * 价格校验
 */
export function priceType(rule, value, callback) {
  const reg = /(^[1-9]\d*(\.\d{1,4})?$)|(^0(\.\d{1,4})?$)/;
  // const val = value.trim();
  const { required } = rule;
  if (!required) {
    // 非必填
    return callback();
  }

  if (!value || value.length === 0) {
    return callback(new Error('不能为空'));
  }

  if (!reg.test(value)) {
    return callback(new Error('格式不正确'));
  }

  const valNum = Number(valNum);

  if (rule.max && valNum >= rule.max) {
    return callback(new Error(`必须小于${rule.max}`));
  }

  return callback();

  // if (rule.required) {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('格式不正确'));
  //     } else {
  //       if (rule.max) {
  //         if (value >= rule.max) {
  //           return callback(new Error('必须小于1000'));
  //         } else {
  //           return callback();
  //         }
  //       } else {
  //         return callback();
  //       }
  //     }
  //   } else {
  //     return callback(new Error('不能为空'));
  //   }
  // } else {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('格式不正确'));
  //     } else {
  //       if (rule.max) {
  //         if (value >= rule.max) {
  //           return callback(new Error('必须小于1000'));
  //         } else {
  //           return callback();
  //         }
  //       } else {
  //         return callback();
  //       }
  //     }
  //   } else {
  //     return callback();
  //   }
  // }
}

/**
 * 手机号
 */
export function phoneNumber(rule, value, callback) {
  const reg = /^1[0-9]{10}$/;
  const { required } = rule;
  if (!required) {
    // 非必填
    return callback();
  }

  if (!value || value.length === 0) {
    return callback(new Error('不能为空'));
  }

  if (!reg.test(value)) {
    return callback(new Error('格式不正确'));
  }
  return callback();
}

/**
 * 邮箱
 */
export function emailType(rule, value, callback) {
  const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/;
  // const val = value.trim();
  const { required } = rule;
  if (!required) {
    // 非必填
    return callback();
  }

  if (!value || value.length === 0) {
    return callback(new Error('不能为空'));
  }

  if (!reg.test(value)) {
    return callback(new Error('格式不正确'));
  }
  return callback();
}

/**
 * 字母和数字
 */
export function alphanumeric(rule, value, callback) {
  const reg = /[\u4E00-\u9FA5]/g;
  // const val = value.trim();
  const { required } = rule;
  if (!required) {
    // 非必填
    return callback();
  }

  if (!value || value.length === 0) {
    return callback(new Error('不能为空'));
  }

  if (reg.test(value)) {
    return callback(new Error('不能输入中文'));
  }
  return callback();
  // const reg = /[\u4E00-\u9FA5]/g;
  // if (rule.required) {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback();
  //     } else {
  //       return callback(new Error('不能输入中文'));
  //     }
  //   } else {
  //     return callback(new Error('不能为空'));
  //   }
  // } else {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback();
  //     } else {
  //       return callback(new Error('不能输入中文'));
  //     }
  //   } else {
  //     return callback();
  //   }
  // }
}

/**
 * 不能输入‘|’
 */
export function rulesVertical(rule, value, callback) {
  const reg = '|';
  // const val = value.trim();
  const { required } = rule;
  if (!required) {
    return callback();
  }

  if (!value || value.length === 0) {
    return callback(new Error('不能为空'));
  }

  if (value.includes(reg)) {
    return callback(new Error('不能输入"|"'));
  }
  return callback();

  // if (rule.required) {
  //   if (value) {
  //     if (value.indexOf(reg) === -1) {
  //       return callback();
  //     } else {
  //       return callback(new Error('不能输入"|"'));
  //     }
  //   } else {
  //     return callback(new Error('不能为空'));
  //   }
  // } else {
  //   if (value) {
  //     if (value.indexOf(reg) === -1) {
  //       return callback();
  //     } else {
  //       return callback(new Error('不能输入"|"'));
  //     }
  //   } else {
  //     return callback();
  //   }
  // }
}

/**
 * 电话
 */
export function telephone(rule, value, callback) {
  const reg = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
  // const val = value.trim();
  const { required } = rule;
  if (!required) {
    return callback();
  }

  if (!value || value.length === 0) {
    return callback(new Error('不能为空'));
  }

  if (!reg.test(value)) {
    return callback(new Error('格式不正确'));
  }
  return callback();
  // const reg = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
  // if (rule.required) {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('格式不正确'));
  //     } else {
  //       return callback();
  //     }
  //   } else {
  //     return callback(new Error('不能为空'));
  //   }
  // } else {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('格式不正确'));
  //     } else {
  //       return callback();
  //     }
  //   } else {
  //     return callback();
  //   }
  // }
}

/**
 * 正整数
 */
export function positiveInteger(rule, value, callback) {
  // const val = value.trim();
  const { required } = rule;
  if (!required) {
    return callback();
  }
  if (!value || value.length === 0) {
    return callback(new Error('不能为空'));
  }

  if (!Number.isInteger(Number(value))) {
    return callback(new Error('请输入正整数'));
  }

  return callback();

  // const reg = /(^[0-9]\d*$)/;
  // if (rule.required) {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('格式不正确'));
  //     } else {
  //       return callback();
  //     }
  //   } else {
  //     return callback(new Error('不能为空'));
  //   }
  // } else {
  //   if (value) {
  //     if (reg.test(value) === false) {
  //       return callback(new Error('格式不正确'));
  //     } else {
  //       return callback();
  //     }
  //   } else {
  //     return callback();
  //   }
  // }
}

// 大于0的正整数
// export function Integer0(rule, value, callback) {
//   const reg = /(^[1-9]\d*$)/;
//   if (rule.required) {
//     if (value) {
//       if (reg.test(value) === false) {
//         return callback(new Error('必须位大于0的正整数'));
//       } else {
//         return callback();
//       }
//     } else {
//       return callback(new Error('不能为空'));
//     }
//   } else {
//     if (value) {
//       if (reg.test(value) === false) {
//         return callback(new Error('必须位大于0的正整数'));
//       } else {
//         return callback();
//       }
//     } else {
//       return callback();
//     }
//   }
// }

/**
 * 不能输入中文
 */
export function chineseValidate(rule, value, callback) {
  if (rule.min) {
    if (value.length < rule.min) {
      return callback(new Error('不能少于' + rule.min + '个字符'));
    }
  }
  if (rule.max) {
    if (value.length > rule.max) {
      return callback(new Error('不能多于' + rule.max + '个字符'));
    }
  }
  const reg = /^(?:(?![\u4E00-\u9FA5]).)+$/; // /[\u4E00-\u9FA5]/g;
  if (rule.required) {
    if (value) {
      if (reg.test(value) === false) {
        return callback(new Error('不能输入中文'));
      } else {
        return callback();
      }
    } else {
      return callback(new Error('不能为空'));
    }
  } else {
    if (value) {
      if (reg.test(value) === false) {
        return callback(new Error('不能输入中文'));
      } else {
        return callback();
      }
    } else {
      return callback();
    }
  }
}

// 校验数字
export function checkNumber(rule, value, callback) {
  const regex = /^\d{1,9}$/;
  if (!regex.test(value)) {
    return callback(new Error($i18n.t('placeOrder.inputNum')));
  }
  return callback();
}

/**
 *  @rule 校验用户密码
 */
export function checkPassword(rule, value, callback) {
  const regexp = /^(?=.*\d)(?=.*[a-zA-Z]).{8,12}$/;
  if (!regexp.test(value)) {
    callback(new Error($i18n.t('placeOrder.password')));
  } else {
    return callback();
  }
}

/**
 *  @rule cpf
 */
export function checkCPF(rule, value, callback) {
  const regexp = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/;
  if (!regexp.test(value)) {
    callback(new Error($i18n.t('placeOrder.enterpriseTaxNumTips')));
  } else {
    return callback();
  }
}
