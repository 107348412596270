// 供应商

import { apiProviderPage } from '@/api/finance/basicData';
import { STATUS, claimNode, CLAIMS_TYPE } from '@/views/compensationManagement/content.js';

export const claimantMixin = {
  data() {
    return {
      STATUS: STATUS,
      claimNode: claimNode,
      CLAIMS_TYPE: CLAIMS_TYPE,
      providerList: [] // 供应商列表
    };
  },

  created() {
    // this.getVendorList();
  },
  methods: {
    // 获取供应商列表
    getVendorList() {
      const param = {
        pageSize: 99999999,
        pageNumber: 1,
        params: {}
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
