import { getUserInfo, getUserPage, login } from '@/api/user';
import { getToken, removeToken, setToken } from '@/utils/auth';
import { resetRouter } from '@/router';
import { setHttpTurnHttps } from '@/utils/tools';
import { Message } from 'element-ui';

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    // 页面路由权限
    roles: [],
    // 按钮级权限
    buttonPermissions: [],
    id: '', // 用户ID
    code: '', // 工号
    username: '',
    hasAudit: 0, // 当前用户是否可以审核工单 0:没有资格 1:可以审核
    realName: '', // 用户真实名字
    siteId: '', // 站点
    siteUserType: '', // 站点类型
    dotName: '', // 网点名-——截图用
    siteCode: '', // 网点编码
    orgId: '', // 部门id
    orgCode: '', // 部门code
    platformCode: '', // 所属平台
    managerList: [], // 经办人列表
    passwordLastModified: '', // 用户上次修改密码时间
    getmessageNumber: 0, // 系统消息数量
    getmessageShowObj: {}// 控制是否显示系统消息
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_CODE: (state, code) => {
    state.code = code;
  },
  SET_USER_NAME: (state, username) => {
    state.username = username;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_REAL_NAME: (state, realName) => {
    state.realName = realName;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, menuPermissions) => {
    state.roles = menuPermissions;
  },
  SET_BUTTONPERMISSIONS: (state, buttonPermissions) => {
    state.buttonPermissions = buttonPermissions;
  },
  SET_AUDIT: (state, hasAudit) => {
    state.hasAudit = hasAudit;
  },
  SET_REALNAME: (state, realName) => {
    state.realName = realName;
  },
  SET_SITEID: (state, siteId) => {
    state.siteId = siteId;
  },
  SET_SITEUSERTYPE: (state, siteUserType) => {
    state.siteUserType = siteUserType;
  },
  SET_DOTNAME: (state, dotName) => {
    state.dotName = dotName;
  },
  SET_SITECODE: (state, siteCode) => {
    state.siteCode = siteCode;
  },
  SET_ORGID: (state, orgId) => {
    state.orgId = orgId;
  },
  SET_PLATFORMCODE: (state, platformCode) => {
    state.platformCode = platformCode;
  },
  // 经办人列表赋值
  SET_MANAGERLIST: (state, data) => {
    state.managerList = data;
  },
  SET_PASSWORDLASTMODIFIED: (state, passwordLastModified) => {
    state.passwordLastModified = passwordLastModified;
  },
  // 部门code
  SET_ORG_CODE: (state, orgCode) => {
    state.orgCode = orgCode;
  },
  // 系统消息数量
  set_getmessageNumber: (state, getmessageNumber) => {
    state.getmessageNumber = getmessageNumber;
  },
  set_getmessageShow: (state, getmessageShowObj) => {
    state.getmessageShowObj = getmessageShowObj;
  }
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    // const { customerName, password } = userInfo;
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response;
          if (data.status === 'ERROR') {
            Message({
              message: data.msg,
              type: 'error'
            });
          }
          commit('SET_TOKEN', data.token);
          setToken(data.token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((response) => {
          const { data } = response;
          if (!data) {
            reject('验证失败，请重新登录。');
          }
          const {
            username,
            avatar,
            permission,
            // menuPermissions,
            // buttonPermissions,
            id,
            code,
            hasAudit,
            realName,
            siteId,
            siteUserType,
            siteName,
            siteCode,
            orgId,
            orgCode,
            platformCode,
            passwordLastModified
          } = data;
          // debugger;
          const menuPermissions = permission.menuPermissions;
          const buttonPermissions = permission.buttonPermissions;
          // 默认添加主页权限,防止死循环
          menuPermissions.push('dashboard');
          commit('SET_ROLES', menuPermissions);

          commit('SET_ID', id);// 当前用户ID
          commit('SET_CODE', code);
          commit('SET_USER_NAME', username); // 工号

          commit('SET_AUDIT', hasAudit);
          commit('SET_REALNAME', realName);
          commit('SET_SITEID', siteId);
          commit('SET_SITEUSERTYPE', siteUserType);
          commit('SET_DOTNAME', siteName);
          commit('SET_SITECODE', siteCode);

          commit('SET_BUTTONPERMISSIONS', buttonPermissions);
          commit('SET_NAME', username);
          commit('SET_REAL_NAME', realName);
          commit('SET_ORGID', orgId);
          commit('SET_ORG_CODE', orgCode);
          commit('SET_PLATFORMCODE', platformCode);
          commit('SET_PASSWORDLASTMODIFIED', passwordLastModified);
          commit(
            'SET_AVATAR',
            setHttpTurnHttps(avatar) ||
            'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      // logout(state.token).then(() => {
      removeToken(); // must remove  token  first
      resetRouter();
      commit('RESET_STATE');
      resolve();
      // }).catch(error => {
      //   reject(error)
      // })
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token';

    commit('SET_TOKEN', token);
    setToken(token);

    const { roles } = await dispatch('getInfo');

    resetRouter();

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true });
    // dynamically add accessible routes
    this.$router.addRoutes(accessRoutes);

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true });
  },
  // 获取经办人列表
  getManagerList({ commit, dispatch }, name) {
    console.log('被调用了', name);
    // 获取操作员
    const param = {
      pageNumber: 1,
      pageSize: 10,
      params: {
        realName: '',
        accountState: '1'
      }
    };
    if (name) {
      param.params.realName = name;
    }
    return new Promise((resolve, reject) => {
      getUserPage(param)
        .then((res) => {
          if (res.status === 'OK') {
            commit('SET_MANAGERLIST', res.data.records);
            resolve();
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
