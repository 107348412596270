<template>
  <div class="app-container">
    <el-card class="box-card" style="margin-bottom: 10px">
      <el-form :model="queryForm" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <!--日期-->
            <el-form-item :label="$t('GlobalSetObj.Date')">
              <el-date-picker
                v-model="queryForm.params.monthDate"
                :placeholder="$t('base.selectTime')"
                clearable
                style="width: 100%"
                type="month"
                value-format="yyyy-MM"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--网点编号-->
            <el-form-item :label="$t('collectionCenter.siteNum')">
              <el-input
                v-model="queryForm.params.siteCode"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--网点名称-->
            <el-form-item :label="$t('collectionCenter.siteName')">
              <el-input
                v-model="queryForm.params.siteName"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--状态-->
            <el-form-item :label="$t('collectionCenter.stateZT')">
              <el-select
                v-model="queryForm.params.enableStatus"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                style="width: 100%"
              >
                <el-option key="1" :label="$t('GlobalSetObj.enable')" :value="1" /><!--启用-->
                <el-option key="2" :label="$t('GlobalSetObj.stop')" :value="0" /><!--停用-->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <!--<el-checkbox-group v-model="limitCountCondition">-->
              <!--超过最大限制数-->
              <el-checkbox v-model="minLimitCount">{{
                $t('GlobalSetObj.ExceedsMaximumLimit')
              }}</el-checkbox>
              <!--低于最小限制数-->
              <el-checkbox v-model="maxLimitCount">{{
                $t('GlobalSetObj.BelowMinimumLimit')
              }}</el-checkbox>
              <!--</el-checkbox-group>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-button
          icon="el-icon-search" size="small" type="primary"
          @click="searchData"
        >
          {{ $t('collectionCenter.search') }} </el-button><!--查询-->
        <el-button size="small" type="primary" @click="urgeRecycling">
          {{ $t('GlobalSetObj.UrgeRecycling') }} </el-button><!--催回收-->
        <el-button
          icon="el-icon-upload2" size="small" type="info"
          @click="exportData"
        >
          {{ $t('newOrder.export') }} </el-button><!--导出-->
        <el-button icon="el-icon-refresh" size="small" @click="reset">
          {{ $t('collectionCenter.reset') }} </el-button><!--重置-->
      </div>
    </el-card>
    <el-card class="box-card">
      <div class="table-box">
        <!--表格-->
        <TableCmp
          ref="tableCmpRef"
          :column-list="columnList"
          :is-select-btn="isSelectBtn"
          :table-data="tableData"
          :table-type="7"
          @selectionChange="selectionChange"
        />
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 50, 100, 200, 400]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 20px 0; text-align: right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  apiBagRecycleSummaryExportMonthlyList,
  apiGetBagRecycleSummaryMonthlyList,
  apiGetBagRecycleUrge
} from '@/api/newExpressAPI';
import { goExportList } from '@/utils/goExportList';

export default {
  name: 'MonthlySummary',
  components: {
    TableCmp: () => import('../detailManagement/cmp/tableCmp.vue')
  },
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          monthDate: '', // 日期
          enableStatus: null, // 状态
          siteCode: this.$store.state.user.siteCode, // 网点编号
          siteName: this.$store.state.user.dotName, // 网点名称
          limitCountCondition: null // 是否确认
        }
      },
      minLimitCount: false, // 最小限制数
      maxLimitCount: false, // 最大限制数
      isSelectBtn: true,
      total: 0,
      selectArr: [], // 选中数组
      tableData: [],
      columnList: [
        {
          label: this.$t('GlobalSetObj.Date'),
          prop: 'monthDate',
          minWidth: '180px',
          align: 'center'
        }, // 日期
        {
          label: this.$t('collectionCenter.siteNum'),
          prop: 'siteCode',
          minWidth: '180px',
          align: 'center'
        }, // 网点编号
        {
          label: this.$t('collectionCenter.siteName'),
          prop: 'siteName',
          minWidth: '180px',
          align: 'center'
        }, // 网点名称
        {
          label: this.$t('basicData.TheTypeOutlet'),
          prop: 'siteType',
          minWidth: '180px',
          align: 'center'
        }, // 网点类型
        {
          label: this.$t('collectionCenter.owningTransitCenter1'),
          prop: 'transferCenterName',
          minWidth: '180px',
          align: 'center'
        }, // 所属转运中心
        {
          label: this.$t('GlobalSetObj.TotalBalanceQuantity'),
          prop: 'balanceCount',
          minWidth: '180px',
          align: 'center'
        }, // 结余总数量
        {
          label: this.$t('GlobalSetObj.MinimumLimit'),
          prop: 'minLimitCount',
          minWidth: '180px',
          align: 'center'
        }, // 最小限制数
        {
          label: this.$t('GlobalSetObj.MaximumLimit'),
          prop: 'maxLimitCount',
          minWidth: '180px',
          align: 'center'
        }, // 最大限制数
        {
          label: this.$t('GlobalSetObj.OutflowQuantity'),
          prop: 'flowOutCount',
          minWidth: '180px',
          align: 'center',
          isClick: true
        }, // 流出数量
        {
          label: this.$t('GlobalSetObj.InflowQuantity'),
          prop: 'flowInCount',
          minWidth: '180px',
          align: 'center',
          isClick: true
        }, // 流入数量
        {
          label: this.$t('GlobalSetObj.RecycledQuantity'),
          prop: 'recycledCount',
          minWidth: '180px',
          align: 'center',
          isClick: true
        }, // 已回收数量
        {
          label: this.$t('GlobalSetObj.RecyclingQuantity'),
          prop: 'recyclingCount',
          minWidth: '180px',
          align: 'center',
          isClick: true
        }, // 回收途中数量
        {
          label: this.$t('GlobalSetObj.ReturnedQuantity'),
          prop: 'returnedCount',
          minWidth: '180px',
          align: 'center',
          isClick: true
        }, // 已返数量
        {
          label: this.$t('GlobalSetObj.QuantityOnTheWayBack'),
          prop: 'returningCount',
          minWidth: '180px',
          align: 'center',
          isClick: true
        }, // 返回途中数量
        // { label: this.$t('GlobalSetObj.ReceivingQuantity'), prop: 'receivingQuantity', minWidth: '180px', align: 'center', isClick: true }, // 领用数量
        {
          label: this.$t('GlobalSetObj.AbnormalAdjustmentQuantity'),
          prop: 'abnormalAdjustCount',
          minWidth: '180px',
          align: 'center',
          isClick: true
        }, // 异常调整数量
        {
          label: this.$t('GlobalSetObj.EnableStatus'),
          prop: 'enableStatus',
          minWidth: '180px',
          align: 'center'
        } // 启用状态
      ]
    };
  },
  watch: {
    minLimitCount(val) {
      if (val === true) {
        this.maxLimitCount = false;
      }
    },
    maxLimitCount(val) {
      if (val === true) {
        this.minLimitCount = false;
      }
    }
  },
  created() {
    this.getTimer();
    this.getTableData();
  },
  methods: {
    // 获取当前月份
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM');
      this.queryForm.params.monthDate = today;
    },
    // 数据勾选
    selectionChange(data, type) {
      // type = delete: 删除 selected: 列表数据勾选 confirmRecycling: 回收确认
      if (type === 'selected') {
        this.selectArr = data;
      }
    },
    // 查询
    searchData() {
      this.queryForm.pageNumber = 1;
      this.$refs.tableCmpRef.clearSelection();
      this.getTableData();
    },
    // 催回收
    urgeRecycling() {
      if (this.selectArr.length === 0) {
        this.$message({
          type: 'warning',
          message: this.$t('collectionCenter.checkMsg') // '请选择数据'
        });
      } else {
        console.log(this.selectArr, '===this.selectArr');
        const params = {
          siteIdList: []
        };
        this.selectArr.map((item) => {
          params.siteIdList.push(item.id);
        });
        apiGetBagRecycleUrge(params).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: this.$t('GlobalSetObj.TheOperationSucceeded') // '催回成功'
            });
          }
        });
      }
    },
    // 导出
    exportData() {
      let limitCountCondition = null;
      if (this.minLimitCount === true) {
        limitCountCondition = 1;
      } else if (this.maxLimitCount === true) {
        limitCountCondition = 0;
      } else {
        limitCountCondition = null;
      }
      const params = {
        monthDate: this.queryForm.params.monthDate,
        enableStatus: this.queryForm.params.enableStatus,
        siteCode: this.queryForm.params.siteCode,
        siteName: this.queryForm.params.siteName,
        limitCountCondition: limitCountCondition
      };
      apiBagRecycleSummaryExportMonthlyList(params).then((res) => {
        if (res.code === 200) {
          goExportList(this, '/resource/exportTaskList');
          this.$refs.tableCmpRef.clearSelection();
        }
      });
    },
    // 重置
    reset() {
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          monthDate: '', // 日期
          enableStatus: null, // 状态
          siteCode: this.$store.state.user.siteCode, // 网点编号
          siteName: this.$store.state.user.dotName, // 网点名称
          limitCountCondition: null // 是否确认
        }
      };
      this.minLimitCount = false;
      this.maxLimitCount = false;
      this.getTimer();
      this.getTableData();
    },
    // 获取列表数据
    getTableData() {
      let limitCountCondition = null;
      if (this.minLimitCount === true) {
        limitCountCondition = 1;
      } else if (this.maxLimitCount === true) {
        limitCountCondition = 0;
      } else {
        limitCountCondition = null;
      }
      this.queryForm.params.limitCountCondition = limitCountCondition;
      apiGetBagRecycleSummaryMonthlyList(this.queryForm).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 每页条数
    handleSizeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    },
    // 当前页
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    }
  }
};
</script>

<style scoped></style>
