<template>
  <div class="expressOrder">
    <div class="orderInfo">
      <div id="box1" class="basicInformation">
        <div class="title center">
          <span style="font-size: 16px; font-weight: bold">{{
            $t('newOrder.BasicInformation')
          }}</span>
          <el-button @click="orderDialog = true">{{ $t('placeOrder.copyOrder') }}</el-button>
        </div>
        <div class="customerContent">
          <div class="selectContent">
            <el-form
              ref="baseRuleForm"
              :model="baseRuleForm"
              class="demo-baseRuleForm"
              inline
              label-position="top"
            >
              <el-row :gutter="50">
                <el-col :span="6">
                  <!--货物类型-->
                  <el-form-item
                    :label="$t('placeOrder.goodType')"
                    :rules="{
                      required: true,
                      message: $t('placeOrder.pleaseChoose'),
                      trigger: ['blur', 'change']
                    }"
                    prop="cargoType"
                    style="width: 100%"
                  >
                    <el-select
                      v-model="baseRuleForm.cargoType"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                      style="width: 100%"
                      @change="cargoTypeChange"
                    >
                      <el-option :label="$t('placeOrder.Normal')" :value="0" />
                      <el-option :label="$t('GlobalSetObj.big01')" :value="1" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <!-- 客户-->
                  <el-form-item
                    :label="$t('placeOrder.client')"
                    :rules="{
                      required: true,
                      message: $t('placeOrder.pleaseChoose'),
                      trigger: ['blur', 'change']
                    }"
                    prop="customeId"
                    style="width: 100%"
                  >
                    <el-select
                      v-model="baseRuleForm.customeId"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                      filterable
                      style="width: 100%"
                      @change="selectCustomeId"
                    >
                      <el-option
                        v-for="item in customerList"
                        :key="item.id"
                        :label="item.username"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <!--商户-->
                  <el-form-item prop="merchantNumber" style="width: 100%">
                    <template slot="label">
                      <span>{{ $t('placeOrder.CommercialOwner') }}</span>
                      <el-tooltip class="QuestionMark" placement="top">
                        <div slot="content">{{ $t('placeOrder.collate') }}</div>
                        <svg-icon icon-class="QuestionMark" />
                      </el-tooltip>
                    </template>
                    <el-select
                      v-model="baseRuleForm.merchantNumber"
                      :disabled="isInput"
                      :filter-method="eventEnter"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                      filterable
                      style="width: 100%"
                      @change="handlemerchantCode"
                      @focus="focuseventEnter"
                    >
                      <el-option
                        v-for="item in commercialList"
                        :key="item.id"
                        :label="item.merchantName"
                        :value="item.merchantNumber"
                      >
                        <span style="float: left">{{ item.merchantName || '--' }}</span>
                        <span style="margin-left: 20px; margin-right: 5px">{{
                          item.merchantNumber || '--'
                        }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <!--服务类型-->
                  <el-form-item
                    :label="$t('GlobalSetObj.serviceTypes')"
                    prop="region"
                    style="width: 100%"
                  >
                    <el-select
                      v-model="baseRuleForm.expressType"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                      style="width: 100%"
                    >
                      <el-option :label="$t('GlobalSetObj.Canal')" value="2" />
                      <!--   邮政   -->
                      <el-option :label="$t('GlobalSetObj.postal')" value="3" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <!--揽收类型-->
                  <el-form-item
                    :label="$t('newOrder.CollectionType')"
                    :rules="{
                      required: true,
                      message: $t('placeOrder.pleaseChoose'),
                      trigger: ['blur', 'change']
                    }"
                    prop="collectType"
                    style="width: 100%"
                  >
                    <el-select
                      v-model="baseRuleForm.collectType"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                      style="width: 100%"
                    >
                      <el-option :label="$t('collectionCenter.dropOff')" value="1" />
                      <el-option :label="$t('collectionCenter.doorPickUp')" value="2" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <!--客户订单-->
                  <el-form-item
                    :label="$t('newOrder.orderNumber')"
                    :rules="{
                      min: 2,
                      max: 50,
                      message: $t('GlobalSetObj.to50characters'),
                      trigger: 'blur'
                    }"
                    prop="customerNumber"
                    style="width: 100%"
                  >
                    <el-input
                      v-model.trim="baseRuleForm.customerNumber"
                      :disabled="isInput"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      maxlength="50"
                      style="width: 100%"
                      type="text"
                      @blur="getCustomerNumber(baseRuleForm.customerNumber)"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <!--包裹类型-->
                  <el-form-item
                    :label="$t('placeOrder.packageType')"
                    :rules="{
                      required: true,
                      message: $t('placeOrder.pleaseChoose'),
                      trigger: ['blur', 'change']
                    }"
                    prop="orderPackageType"
                    style="width: 100%"
                  >
                    <el-select
                      v-model="baseRuleForm.orderPackageType"
                      :disabled="baseRuleForm.cargoType === 1 || baseRuleForm.cargoType === 0"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                      style="width: 100%"
                    >
                      <el-option :label="$t('placeOrder.zhifa')" value="1" />
                      <el-option :label="$t('GlobalSetObj.big01')" value="4" />
                      <!--<el-option label="合单" value="2" />-->
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <div id="box2" class="basicInformation">
        <div class="title center">
          <!--发件人-->
          <span style="font-size: 16px; font-weight: bold">{{ $t('placeOrder.senderName') }}</span>
          <el-button
            @click="customerAddress($t('placeOrder.senderName'))"
          >{{ $t('placeOrder.senderAddress') }}
          </el-button>
        </div>
        <div class="customerContent">
          <div class="selectContent">
            <el-form
              ref="senderInfoForm"
              :model="senderInfoParams"
              class="demo-baseRuleForm"
              inline
              label-position="top"
            >
              <el-row :gutter="50">
                <el-col :span="8">
                  <!--姓名-->
                  <el-form-item
                    :label="$t('placeOrder.name')"
                    :rules="checkStr50"
                    prop="senderName"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="senderInfoParams.senderName"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <!--手机-->
                <el-col :span="8">
                  <el-form-item
                    :label="$t('GlobalSetObj.MobilePhone')"
                    :rules="isPhone"
                    prop="senderMobile"
                    style="width: 80%"
                  >
                    <el-input
                      v-model.trim="senderInfoParams.senderMobile"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 税号 -->
                  <el-form-item
                    :label="$t('placeOrder.TaxCPF')"
                    :rules="isTax12"
                    prop="senderTax"
                    style="width: 80%"
                  >
                    <el-input
                      v-model.trim="senderInfoParams.senderTax"
                      :placeholder="$t('newOrder.PleaseEnter12Or14DigitTaxNumber')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="50">
                <el-col :span="8">
                  <!--国家注册号-->
                  <el-form-item
                    :label="$t('GlobalSetObj.NationalRegistrationNumber')"
                    :rules="IeNumber"
                    prop="senderIeNumber"
                    style="width: 80%"
                  >
                    <el-input
                      v-model.trim="senderInfoParams.senderIeNumber"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 邮编 -->
                  <el-form-item
                    :label="$t('placeOrder.zipcode')"
                    :rules="isZipCode"
                    prop="senderZipcode"
                    style="width: 80%"
                  >
                    <el-input
                      v-model.trim="senderInfoParams.senderZipcode"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8" style="display: flex">
                  <!-- 州 -->
                  <el-form-item
                    :label="$t('placeOrder.State')"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeOrder.pleaseChoose'),
                        trigger: ['blur', 'change']
                      }
                    ]"
                    prop="senderState"
                    style="width: 30%"
                  >
                    <el-select
                      v-model="senderInfoParams.senderState"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      class="formTable"
                      filterable
                      style="width: 90%"
                      @change="stateChangeOption(1)"
                    >
                      <el-option
                        v-for="item in senderStateList"
                        :key="item.id"
                        :label="item.shortName"
                        :value="item.shortName"
                      />
                    </el-select>
                  </el-form-item>
                  <!-- 城市 -->
                  <el-form-item
                    :label="$t('placeOrder.city')"
                    :rules="cityComplement"
                    prop="senderCity"
                    style="width: 70%"
                  >
                    <el-select
                      v-model="senderInfoParams.senderCity"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      class="formTable"
                      filterable
                      style="width: 80%"
                      :disabled="!senderInfoParams.senderState"
                      remote
                      reserve-keyword
                      :remote-method="getSenderCity"
                      @visible-change="getSenderCity()"
                    >
                      <el-option
                        v-for="item in senderCityList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-show="isShowSenderMoreInfo" :gutter="50">
                <el-col :span="8">
                  <!-- 区 -->
                  <el-form-item
                    :label="$t('placeOrder.Area')"
                    :rules="addressComplement"
                    prop="senderArea"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="senderInfoParams.senderArea"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 街道 -->
                  <el-form-item
                    :label="$t('newOrder.Street')"
                    prop="senderStreet"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="senderInfoParams.senderStreet"
                      :placeholder="$t('newOrder.ConfirmStreetNumber')"
                      clearable
                      maxlength="50"
                      show-word-limit
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!--门牌号 -->
                  <el-form-item
                    :label="$t('placeOrder.HouseNumber')"
                    :rules="checkStr5"
                    prop="senderHouseNumber"
                    style="width: 80%"
                  >
                    <el-input
                      v-model.trim="senderInfoParams.senderHouseNumber"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      maxlength="6"
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 地址补充 -->
                  <el-form-item
                    :label="$t('placeOrder.addressComplement')"
                    :rules="addressComplement"
                    prop="senderComplement"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="senderInfoParams.senderComplement"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      maxlength="50"
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 邮箱 -->
                  <el-form-item
                    :label="$t('placeOrder.email')"
                    :rules="checkMail"
                    prop="senderMail"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="senderInfoParams.senderMail"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 70%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 电话 -->
                  <el-form-item
                    :label="$t('GlobalSetObj.userPhone')"
                    prop="senderPhone"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="senderInfoParams.senderPhone"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 70%"
                    />
                    <span class="moreInfo" @click="showSenderMore">
                      《{{ $t('placeOrder.fold') }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="!isShowSenderMoreInfo" :gutter="50">
                <el-col :span="24" style="margin-bottom: 10px">
                  <!--更多信息-->
                  <span
                    class="moreInfo" @click="showSenderMore"
                  >{{ $t('placeOrder.moreInformation') }} 》</span>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="verticallyCentered">
                <el-col :span="18">
                  <el-form-item
                    :label="$t('placeOrder.detailedAddress')"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeOrder.pleaseInput'),
                        trigger: ['blur', 'change']
                      },
                      {
                        min: 2,
                        max: 200,
                        message: $t('newOrder.TdetailedAddressCharacter'),
                        trigger: 'blur'
                      }
                    ]"
                    prop="senderAddress"
                    style="width: 100%"
                  >
                    <el-input
                      v-model="senderInfoParams.senderAddress"
                      :placeholder="$t('newOrder.StreetHouseNumber')"
                      maxlength="200"
                      show-word-limit
                      type="text"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-button
                    :type="`${isInput === false ? 'primary' : 'info'}`"
                    @click="SynchronousCustomer($t('placeOrder.senderName'))"
                  >
                    {{ $t('placeOrder.SynchronizeClient') }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <div id="box3" class="basicInformation">
        <div class="title center">
          <span style="font-size: 16px; font-weight: bold">{{ $t('placeOrder.receiveName') }}</span>
          <!--客户收件地址 -->
          <el-button
            @click="customerAddress($t('placeOrder.receiveName'))"
          >{{ $t('placeOrder.clientAddress') }}
          </el-button>
        </div>
        <div class="customerContent">
          <div class="selectContent">
            <el-form
              ref="receiverInfoForm"
              :model="receiverInfoParams"
              class="demo-baseRuleForm"
              inline
              label-position="top"
            >
              <el-row :gutter="50">
                <el-col :span="8">
                  <!--  姓名 -->
                  <el-form-item
                    :label="$t('placeOrder.name')"
                    :rules="checkStr50"
                    prop="receiveName"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveName"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 电话 -->
                  <el-form-item
                    :label="$t('GlobalSetObj.MobilePhone')"
                    :rules="isPhone"
                    prop="receiveMobile"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveMobile"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 税号 -->
                  <el-form-item
                    :label="$t('placeOrder.TaxCPF')"
                    :rules="isTax12"
                    prop="receiveTax"
                    style="width: 80%"
                  >
                    <el-input
                      v-model.trim="receiverInfoParams.receiveTax"
                      :placeholder="$t('newOrder.PleaseEnter12Or14DigitTaxNumber')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="50">
                <el-col :span="8">
                  <!--国家注册号 -->
                  <el-form-item
                    :label="$t('GlobalSetObj.NationalRegistrationNumber')"
                    :rules="IeNumber1"
                    prop="receiveIeNumber"
                    style="width: 80%"
                  >
                    <el-input
                      v-model.trim="receiverInfoParams.receiveIeNumber"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 邮编 -->
                  <el-form-item
                    :label="$t('placeOrder.zipcode')"
                    :rules="isZipCode"
                    prop="receiveZipcode"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveZipcode"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8" style="display: flex">
                  <!-- 州 -->
                  <el-form-item
                    :label="$t('placeOrder.State')"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeOrder.pleaseChoose'),
                        trigger: ['blur', 'change']
                      }
                    ]"
                    prop="receiveState"
                    style="width: 30%"
                  >
                    <el-select
                      v-model="receiverInfoParams.receiveState"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                      filterable
                      style="width: 90%"
                      @change="stateChangeOption(2)"
                    >
                      <el-option
                        v-for="item in senderStateList"
                        :key="item.id"
                        :label="item.shortName"
                        :value="item.shortName"
                      />
                    </el-select>
                  </el-form-item>
                  <!--城市 -->
                  <el-form-item
                    :label="$t('placeOrder.city')"
                    :rules="cityComplement"
                    prop="receiveCity"
                    style="width: 70%"
                  >
                    <el-select
                      v-model="receiverInfoParams.receiveCity"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      class="formTable"
                      filterable
                      style="width: 80%"
                      :disabled="!receiverInfoParams.receiveState"
                      remote
                      reserve-keyword
                      :remote-method="getReceiveCity"
                      @visible-change="getReceiveCity()"
                    >
                      <el-option
                        v-for="item in receiveCityList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-show="isShowRecipientMoreInfo" :gutter="50">
                <el-col :span="8">
                  <!--区 -->
                  <el-form-item
                    :label="$t('placeOrder.Area')"
                    :rules="addressComplement"
                    prop="receiveArea"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveArea"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      maxlength="31"
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!--街道 -->
                  <el-form-item
                    :label="$t('newOrder.Street')"
                    prop="receiveStreet"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveStreet"
                      :placeholder="$t('newOrder.ConfirmStreetNumber')"
                      clearable
                      maxlength="50"
                      show-word-limit
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!--门牌号 -->
                  <el-form-item
                    :label="$t('placeOrder.HouseNumber')"
                    :rules="checkStr5"
                    prop="receiveHouseNumber"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveHouseNumber"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      maxlength="6"
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 地址补充 -->
                  <el-form-item
                    :label="$t('placeOrder.addressComplement')"
                    :rules="addressComplement"
                    prop="receiveComplement"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveComplement"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      maxlength="35"
                      style="width: 80%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 邮箱 -->
                  <el-form-item
                    :label="$t('placeOrder.email')"
                    :rules="checkMail"
                    prop="receiveMail"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveMail"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 70%"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!-- 电话 -->
                  <el-form-item
                    :label="$t('GlobalSetObj.userPhone')"
                    prop="receivePhone"
                    style="width: 80%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receivePhone"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      style="width: 70%"
                    />
                    <span class="moreInfo" @click="showRecipientMore">
                      《{{ $t('placeOrder.fold') }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="!isShowRecipientMoreInfo" :gutter="50">
                <el-col :span="24" style="margin-bottom: 10px">
                  <!--更多信息-->
                  <span
                    class="moreInfo" @click="showRecipientMore"
                  >{{ $t('placeOrder.moreInformation') }} 》</span>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="verticallyCentered">
                <el-col :span="18">
                  <el-form-item
                    :label="$t('placeOrder.detailedAddress')"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeOrder.pleaseInput'),
                        trigger: ['blur', 'change']
                      },
                      {
                        min: 2,
                        max: 200,
                        message: $t('newOrder.TdetailedAddressCharacter'),
                        trigger: 'blur'
                      }
                    ]"
                    prop="receiveAddress"
                    style="width: 100%"
                  >
                    <el-input
                      v-model="receiverInfoParams.receiveAddress"
                      :placeholder="$t('newOrder.StreetHouseNumber')"
                      clearable
                      maxlength="200"
                      show-word-limit
                      type="text"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <!-- 同步客户 -->
                  <el-button
                    :type="`${isInput === false ? 'primary' : 'info'}`"
                    @click="SynchronousCustomer($t('placeOrder.receiveName'))"
                  >
                    {{ $t('placeOrder.SynchronizeClient') }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <div id="box4" class="basicInformation">
        <div class="title center">
          <span style="font-size: 16px; font-weight: bold">{{ $t('placeOrder.infoPackage') }}</span>
          <el-button v-show="baseRuleForm.cargoType === 0" icon="el-icon-plus" @click="addPackage">
            {{ $t('placeOrder.addPackage') }}
          </el-button>
        </div>
        <el-form
          ref="packageRuleForm"
          :model="orderPackageListInfo"
          class="demo-baseRuleForm"
          inline
          label-position="top"
        >
          <div
            v-for="(item, index) in orderPackageListInfo.orderWaybillList"
            :key="index"
            class="customerContent"
            style="margin-top: 10px"
          >
            <div class="selectContent">
              <el-row :gutter="30">
                <el-col :span="4">
                  <!-- 包裹类型 -->
                  <el-form-item
                    :label="$t('placeOrder.packageType')"
                    :prop="'orderWaybillList.' + index + '.waybillType'"
                    :rules="[
                      { required: true, message: $t('placeOrder.pleaseChoose'), trigger: 'change' }
                    ]"
                  >
                    <el-select
                      v-model="item.waybillType"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                    >
                      <el-option
                        v-for="packageType in item.packageType"
                        :key="packageType.code"
                        :label="packageType.code"
                        :value="packageType.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <template v-if="baseRuleForm.cargoType === 0">
                  <el-col :span="12">
                    <el-row :gutter="10">
                      <!-- 长度 -->
                      <el-col :span="8">
                        <el-form-item
                          :label="$t('placeOrder.long')"
                          :prop="'orderWaybillList.' + index + '.length'"
                          :rules="checkFloatNumber"
                        >
                          <el-input
                            v-model="item.length"
                            :placeholder="$t('placeOrder.pleaseInput')"
                            clearable
                            type="number"
                          />
                        </el-form-item>
                      </el-col>
                      <!-- 宽度 -->
                      <el-col :span="8">
                        <el-form-item
                          :label="$t('placeOrder.width')"
                          :prop="'orderWaybillList.' + index + '.width'"
                          :rules="checkFloatNumber"
                        >
                          <el-input
                            v-model="item.width"
                            :placeholder="$t('placeOrder.pleaseInput')"
                            clearable
                            type="number"
                          />
                        </el-form-item>
                      </el-col>
                      <!-- 高度 -->
                      <el-col :span="8">
                        <el-form-item
                          :label="$t('placeOrder.height')"
                          :prop="'orderWaybillList.' + index + '.height'"
                          :rules="checkFloatNumber"
                        >
                          <el-input
                            v-model="item.height"
                            :placeholder="$t('placeOrder.pleaseInput')"
                            clearable
                            type="number"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5" class="verticallyCentered">
                    <!-- 包裹重量 -->
                    <el-form-item
                      :label="$t('placeOrder.parcelWeight')"
                      :prop="'orderWaybillList.' + index + '.weight'"
                      :rules="checkFloatNumber"
                    >
                      <el-input
                        v-model="item.weight"
                        :placeholder="$t('placeOrder.pleaseInput')"
                        clearable
                        type="number"
                      />
                    </el-form-item>
                  </el-col>
                </template>
                <template v-else>
                  <el-col :span="14">
                    <el-row :gutter="10">
                      <!-- 总件数 -->
                      <el-col :span="8">
                        <el-form-item
                          :key="totalFormKey"
                          :label="$t('operationCenter.TotalNumberOfPieces')"
                          :rules="totalNumberPieces"
                          prop="totalParcel"
                        >
                          <el-input-number
                            :key="totalKey"
                            v-model="orderPackageListInfo.totalParcel"
                            :disabled="
                              orderPackageListInfo.detailList &&
                                orderPackageListInfo.detailList.length > 0
                            "
                            :max="999"
                            :min="1"
                            :placeholder="$t('placeOrder.pleaseInput')"
                            :precision="0"
                            :step="1"
                            style="width: 100%"
                          />
                        </el-form-item>
                      </el-col>
                      <!-- 包裹总重量 -->
                      <el-col :span="8">
                        <el-form-item
                          :key="totalFormKey"
                          :label="$t('GlobalSetObj.pesoTotal')"
                          :rules="pesoTotal"
                          prop="totalParcelDeclaredWeight"
                        >
                          <el-input-number
                            :key="totalKey"
                            v-model="orderPackageListInfo.totalParcelDeclaredWeight"
                            :disabled="
                              orderPackageListInfo.detailList &&
                                orderPackageListInfo.detailList.length > 0
                            "
                            :max="9999"
                            :min="0.001"
                            :placeholder="$t('placeOrder.pleaseInput')"
                            :precision="3"
                            :step="1"
                            clearable
                            style="width: 100%"
                          />
                        </el-form-item>
                      </el-col>
                      <!-- 包裹总体积 -->
                      <el-col :span="8">
                        <el-form-item
                          :key="totalFormKey"
                          :label="$t('GlobalSetObj.PackageTotalWeight') + '(m³)'"
                          :rules="parcelVolume"
                          prop="totalParcelDeclaredVolume"
                        >
                          <el-input-number
                            :key="totalKey"
                            v-model="orderPackageListInfo.totalParcelDeclaredVolume"
                            :disabled="
                              orderPackageListInfo.detailList &&
                                orderPackageListInfo.detailList.length > 0
                            "
                            :max="999"
                            :min="0.001"
                            :placeholder="$t('placeOrder.pleaseInput')"
                            :precision="3"
                            :step="1"
                            clearable
                            style="width: 100%"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="5">
                    <!--补充详细重量体积-->
                    <span class="volumeClass" @click="openDetailWindow()">{{
                      $t('GlobalSetObj.AddDetailedWeightAndVolume')
                    }}</span>
                    <!--<el-button type="text" @click="openDetailWindow()">{{ $t('GlobalSetObj.AddDetailedWeightAndVolume') }}</el-button>-->
                  </el-col>
                </template>
                <el-col v-if="index !== 0" :span="1" class="delPackge">
                  <!-- 删除包裹 -->
                  <el-button
                    type="danger" @click="delPackage(index)"
                  >{{ $t('placeOrder.Delete') }}
                  </el-button>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">
                  <!-- 开票日期 -->
                  <el-form-item
                    :label="$t('placeOrder.DateOfInvoicing')"
                    :prop="'orderWaybillList.' + index + '.invoiceIssueDate'"
                    :rules="[
                      { required: true, message: $t('placeOrder.pleaseChoose'), trigger: 'change' }
                    ]"
                    style="display: block"
                  >
                    <el-date-picker
                      v-model="item.invoiceIssueDate"
                      :clearable="false"
                      :format="$i18n.locale == 'zh' ? zhDate : ptDate"
                      :picker-options="pickerOptions"
                      :placeholder="$t('newOrder.SelectDatetime')"
                      :value-format="$i18n.locale == 'zh' ? zhDate : ptDate"
                      style="width: 100%"
                      type="date"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <!-- 开票金额 -->
                  <el-form-item
                    :label="$t('placeOrder.InvoiceAmount')"
                    :prop="'orderWaybillList.' + index + '.invoiceMoney'"
                    :rules="InvoiceAmountRule"
                    style="width: 100%"
                  >
                    <el-input
                      v-model="item.invoiceMoney"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      type="number"
                    />
                  </el-form-item>
                </el-col>
                <!-- 发票号 -->
                <el-col :span="6">
                  <el-form-item
                    :label="$t('placeOrder.InvoiceNumber')"
                    :prop="'orderWaybillList.' + index + '.invoiceNumber'"
                    :rules="invoiceNumber"
                    style="display: block"
                  >
                    <el-input
                      v-model.trim="item.invoiceNumber"
                      :placeholder="$t('placeOrder.PleaseEnter6To16DigitInvoiceNumber')"
                      clearable
                      style="width: 100%"
                    />
                  </el-form-item>
                </el-col>
                <!-- 发票序列号 -->
                <el-col :span="6">
                  <el-form-item
                    :label="$t('GlobalSetObj.TheInvoiceSerialNumber')"
                    :prop="'orderWaybillList.' + index + '.invoiceSerialNumber'"
                    :rules="iceSerialIndex"
                    style="display: block"
                  >
                    <el-input
                      v-model.trim="item.invoiceSerialNumber"
                      :placeholder="$t('placeOrder.PleaseEnter1To8invoiceSerialIndex')"
                      clearable
                      style="width: 100%"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <!--扫描单号-->
                  <el-form-item
                    :label="$t('placeOrder.scanOrderNumber')"
                    :prop="'orderWaybillList.' + index + '.scanOrderNumber'"
                    style="display: block"
                  >
                    <el-input
                      v-model.trim="item.scanOrderNumber"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      maxlength="80"
                    />
                  </el-form-item>
                </el-col>
                <!--发票密钥-->
                <el-col :span="12">
                  <el-form-item
                    :label="$t('placeOrder.InvoiceKey')"
                    :prop="'orderWaybillList.' + index + '.invoiceAccessKey'"
                    :rules="invoiceKey"
                    style="display: block"
                  >
                    <el-input
                      v-model.trim="item.invoiceAccessKey"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      rows="2"
                      style="width: 100%"
                      type="textarea"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" style="border-bottom: 1px dashed #cccccc; margin-bottom: 10px" />
              </el-row>
              <el-row
                v-for="(itemGood, goodIndex) in item.orderItemList"
                :key="goodIndex"
                :gutter="30"
              >
                <el-col :span="7">
                  <!-- 物品名称 -->
                  <el-form-item
                    :label="$t('placeOrder.wpName')"
                    :prop="'orderWaybillList.' + index + '.orderItemList.' + goodIndex + '.name'"
                    :rules="[
                      { required: true, message: $t('placeOrder.pleaseInput'), trigger: 'change' }
                    ]"
                    style="width: 100%"
                  >
                    <el-input
                      v-model="itemGood.name"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      maxlength="128"
                      show-word-limit
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 物品单价 -->
                  <el-form-item
                    :label="$t('placeOrder.declaredValue')"
                    :prop="
                      'orderWaybillList.' + index + '.orderItemList.' + goodIndex + '.declaredValue'
                    "
                    :rules="checkFloatNumber1"
                    style="width: 100%"
                  >
                    <el-input
                      v-model="itemGood.declaredValue"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      class="input-with-select"
                      clearable
                      type="number"
                    >
                      <span slot="prefix">R$</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <!-- 数量 -->
                  <el-form-item
                    :label="$t('placeOrder.quantity')"
                    :prop="
                      'orderWaybillList.' + index + '.orderItemList.' + goodIndex + '.quantity'
                    "
                    :rules="isNumber"
                    style="width: 100%"
                  >
                    <el-input
                      v-model="itemGood.quantity"
                      :placeholder="$t('placeOrder.pleaseInput')"
                      clearable
                      type="number"
                    />
                  </el-form-item>
                </el-col>
                <el-col v-if="item.orderItemList.length > 1" :span="1" class="delPackge">
                  <div v-if="goodIndex !== 0">
                    <el-button
                      type="danger" @click="delGoods(index, goodIndex)"
                    >{{ $t('placeOrder.Delete') }}
                    </el-button>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="verticallyCentered">
                  <div
                    style="
                      cursor: pointer;
                      color: #409eff;
                      font-size: 16px;
                      font-weight: bold;
                      margin-bottom: 10px;
                    "
                    @click="addGoods(index)"
                  >
                    <i class="el-icon-plus" style="font-size: 16px; font-weight: bold" />
                    <span style="margin-left: 5px">{{ $t('orderCenterCont.AddProducts') }}</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>
      </div>
      <div style="display: flex; justify-content: end">
        <!--    清空      -->
        <el-button @click="empty">{{ $t('newOrder.empty') }}</el-button>
        <!--    下单      -->
        <el-button type="primary" @click="placeAnOrder">{{ $t('placeOrder.test') }}</el-button>
      </div>
    </div>
    <div class="orderInfoList">
      <div v-for="(item, index) in orderInfoList" :key="item.id">
        <el-link
          :type="`${ulLi === index + 1 ? 'primary' : ''}`"
          :underline="false"
          @click="scrollTop(item.id)"
        >{{ item.title }}
        </el-link>
      </div>
    </div>
    <!--  下单成功倒计时  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="false"
      :title="$t('GlobalSetObj.tips')"
      :visible.sync="countdownDialog"
      :width="$i18n.locale == 'zh' ? '450px' : '550px'"
      top="30vh"
    >
      <div
        style="display: flex; align-items: center; justify-content: center; margin: 20px 0 30px 0"
      >
        <div class="sucessIcon">
          <i class="iconfont icon-chenggong1" />
        </div>
        <div style="font-weight: bold; font-size: 20px">
          {{ $t('orderCenterCont.orderWasPlacedSuccessfully') }}
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <el-button
          size="small" style="margin-right: 10px" @click="toWillyNumber"
        >{{ $t('GlobalSetObj.toWillyNumber') }}
        </el-button>
        <el-button
          size="small" type="primary" @click="continuePlaceOrders"
        >{{ $t('placeOrder.continueOrders') }}
        </el-button>
      </div>
    </el-dialog>

    <!--  复制订单  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="orderDialog"
      custom-class="copyOrder"
      top="20vh"
      width="900px"
    >
      <div>
        <div style="color: #303133; font-size: 20px; font-weight: bold">
          {{ $t('placeOrder.PleaseInputNumber') }}
        </div>
        <div style="font-size: 18px; margin-top: 15px; margin-bottom: 15px">
          <el-form
            ref="ruleForm" :model="order" class="demo-ruleForm"
            @submit.native.prevent
          >
            <el-form-item
              :rules="{
                required: true,
                message: this.$t('placeOrder.pleaseInput'),
                trigger: ['blur', 'change']
              }"
              prop="WaybillNumber"
              style="width: 60%"
            >
              <el-input
                v-model="order.WaybillNumber"
                :placeholder="$t('GlobalSetObj.pedidoCliente')"
                clearable
                @keyup.enter.native.prevent="getSearch"
              >
                <el-button
                  slot="append" icon="el-icon-search" type="primary"
                  @click="getSearch"
                />
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <!--  表格数据  -->
        <el-table
          :data="tableData"
          :header-cell-style="{ background: '#d9d9d9' }"
          border
          style="width: 100%"
          @row-click="confirmOrderDialog"
        >
          <el-table-column :label="$t('GlobalSetObj.client')" prop="customerName" width="180" />
          <el-table-column
            :label="$t('placeOrder.destinationZone')"
            prop="receiveAddress"
            width="220"
          >
            <template slot-scope="scope">
              <div>
                {{ $t('placeOrder.State') }}: {{ scope.row.receiverInfo.receiveState || '- -' }}
              </div>
              <div>{{ $t('newOrder.City') }} {{ scope.row.receiverInfo.receiveCity || '- -' }}</div>
              <div>
                {{ $t('orderDetails.Area') }}: {{ scope.row.receiverInfo.receiveArea || '- -' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('placeOrder.destinationAddress')" prop="receiveAddress">
            <template slot-scope="scope">
              <div>{{ scope.row.receiverInfo.receiveAddress || '- -' }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="orderDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button
          type="primary" @click="confirmOrderDialog(0)"
        >{{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <!--  发件地址  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="`${addressTitle}${$t('customerManagements.address')}`"
      :visible.sync="addressDialog"
      top="10vh"
      width="800px"
    >
      <div>
        <div style="font-size: 18px; margin-bottom: 15px">
          {{ $t('GlobalSetObj.pleaseChoose') }}{{ addressTitle
          }}{{ $t('customerManagements.address') }}
        </div>
        <div class="addressList">
          <el-select
            v-model="addressForm.type"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            style="width: 150px"
          >
            <el-option
              v-if="addressTitle === `${$t('placeOrder.senderName')}`"
              :label="addressTitle"
              :value="addressTitle"
            />
            <el-option v-else :label="addressTitle" :value="addressTitle" />
            <el-option :label="$t('GlobalSetObj.zipCode')" value="2" />
          </el-select>
        </div>
        <div style="width: 60%; margin-top: 15px">
          <el-input
            v-model="addressForm.name"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            clearable
            @keyup.enter.native="getSearchAddress"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              style="background-color: #409eff; color: black"
              @click="getSearchAddress"
            />
          </el-input>
        </div>
        <div class="addressClass">
          <div v-if="customerAddressList.length > 0">
            <div
              v-for="item in customerAddressList"
              :key="item.id"
              class="addressItem"
              @click="selectAddress(item, addressTitle)"
            >
              <div>
                <span style="color: #000000">{{ item.name || '--' }}</span>
                <span style="margin-left: 20px">{{ item.phone || '--' }}</span>
                <span style="margin-left: 20px">{{ item.cpf || '--' }}</span>
              </div>
              <div>
                <span style="">{{ item.zipcode || '--' }}</span>
                <span style="margin-left: 20px">{{ item.state || '--' }}</span>
                <span style="margin-left: 20px">{{ item.city || '--' }}</span>
              </div>
              <div>
                <span style="">{{ item.area || '--' }}</span>
                <span style="margin-left: 20px">{{ item.addressDetail || '--' }}</span>
              </div>
            </div>
          </div>
          <div v-else class="nodataImage">
            <svg-icon icon-class="noData" />
          </div>
        </div>
        <!--  分页  -->
        <el-pagination
          :current-page="queryForm.pageNumber"
          :page-size="queryForm.pageSize"
          :page-sizes="[5, 10, 20]"
          :total="queryForm.total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0; float: right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div slot="footer" class="dialog-footer" />
    </el-dialog>
    <!--补充详细重量体积 多件包裹重量体积-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('GlobalSetObj.WeightVolumeMultiplePackages')"
      :visible.sync="isOpenDetailWindow"
      custom-class="weightVolume"
      top="10vh"
      width="800px"
    >
      <div>
        <volume-weight
          :key="isOpenDetailWindow"
          ref="openDetail"
          :click-detail="clickDetail"
          @save="getSaveDetail"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isOpenDetailWindow = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveDetail">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addCustomerAddress,
  checkCustomerNumber,
  checkedzipcode,
  CopyOrder,
  createOrder,
  customerMerchant,
  getLgAreaListData,
  inquireMerchant,
  pageCustomerAddress,
  selectState,
  selectCity
} from '@/api/orderApi';
import {
  checkAddressComplement,
  checkCityComplement,
  checkFloatNumber,
  E_Mail,
  IENumber,
  InvoiceAmounts,
  invoiceNumberCheck,
  invoiceSeriakey,
  invoiceSerialIndex,
  isNumber,
  isTax,
  isZipCodexiaodan,
  MobilePhoneNumber,
  priceOfGoods
} from '@/utils/validate';
import { CustomerPage } from '@/api/customer';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'Order',
  components: {
    // 补充详细重量体积
    'volume-weight': () => import('./volumeWeight')
  },
  data() {
    // 数量验证
    const quantityVerification = (rule, value, callback) => {
      // rule.int 1: 正整数 2：整数或浮点数（正数）
      // rule.length: 小数点位数
      let regex = null;
      if (rule.length > 0) {
        const reg = '^[0-9]+(.?[0-9]{1,' + rule.length + '})?$';
        regex = new RegExp(reg);
      }
      if (rule.required) {
        if (value) {
          if (rule.int === 1) {
            if (!Number.isInteger(Number(value))) {
              callback(new Error(this.$t('collectionCenter.pleaseInputInteger')));
            }
          }
          if (value < rule.min) {
            callback(new Error(this.$t('placeOrder.数字不能小于') + rule.min));
          }
          if (value > rule.max) {
            callback(new Error(this.$t('placeOrder.数字不能大于') + rule.max));
          }
          if (regex && !regex.test(value)) {
            callback(new Error(this.$t('placeOrder.最多保留3位小数')));
          }
          callback();
        } else {
          callback(new Error(this.$t('placeOrder.pleaseInput')));
        }
      } else {
        if (value) {
          if (rule.int === 1) {
            if (!Number.isInteger(Number(value))) {
              callback(new Error(this.$t('collectionCenter.pleaseInputInteger')));
            }
          }
          if (value < rule.min) {
            callback(new Error(this.$t('placeOrder.数字不能小于') + rule.min));
          }
          if (value > rule.max) {
            callback(new Error(this.$t('placeOrder.数字不能大于') + rule.max));
          }
          if (regex && !regex.test(value)) {
            callback(new Error(this.$t('placeOrder.最多保留3位小数')));
          }
          callback();
        } else {
          callback();
        }
      }
    };
    return {
      // 是否可以输入
      isInput: true,
      // 是否同步,
      isSync: true,
      // 倒计时弹窗
      countdownDialog: false,
      // 复制订单
      orderDialog: false,
      order: {
        WaybillNumber: null // 搜索物流订单
      },
      tableHeaderList: [
        { id: 1, label: this.$t('GlobalSetObj.client'), prop: 'customerName', cloWidth: '180' },
        {
          id: 2,
          label: this.$t('placeOrder.destinationZone'),
          prop: 'receiveAddress',
          cloWidth: '200'
        },
        { id: 3, label: this.$t('placeOrder.destinationAddress'), prop: 'receiveAddress' }
      ],
      tableData: [],
      orderInformation: {}, // 复用订单
      addressDialog: false, // 客户地址
      addressTitle: this.$t('placeOrder.senderName'),
      addressForm: {
        type: this.$t('placeOrder.senderName'),
        name: ''
      },
      customerAddressList: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0
      },
      orderInfoList: [
        {
          id: 1,
          title: this.$t('placeOrder.baseData')
        },
        {
          id: 2,
          title: this.$t('placeOrder.senderName')
        },
        {
          id: 3,
          title: this.$t('placeOrder.receiveName')
        },
        {
          id: 4,
          title: this.$t('placeOrder.infoPackage')
        }
      ],
      baseRuleForm: {
        collectType: '', // 揽收类型
        expressType: '2', // 服务类型
        customerNumber: '', // 客户订单
        customeId: '', // 客户id
        merchantNumber: '', // 商户COde
        merchantName: '', // 商户名
        orderPackageType: '1', // 包裹类型
        cargoType: 0 // 货物类型
      },
      // 更多信息展示
      isShowSenderMoreInfo: false,
      isShowRecipientMoreInfo: false,
      orderPackageListInfo: {
        totalParcelDeclaredWeight: undefined, //  包裹总重量（kg）
        totalParcelDeclaredVolume: undefined, //  包裹总体积（m³）
        totalParcel: 1, // 包裹总数量
        detailList: [],
        orderWaybillList: [
          {
            dictName: 'WRPER1',
            length: '', // 长
            width: '', // 宽
            height: '', // 高
            weight: '', // 重量(KG)
            volume: '', // 体积
            TotalNumberPieces: '', //  总件数
            totalParcelDeclaredWeight: undefined, //  包裹总重量（kg）
            totalParcelDeclaredVolume: undefined, //  包裹总体积（m³）
            invoiceNumber: '', // 发票号
            invoiceSerialNumber: '', // 发票序列号
            invoiceIssueDate: '', // 开票日期
            invoiceAccessKey: '', // 发票密钥
            scanOrderNumber: '', // 扫描单号
            invoiceMoney: '', // 开票金额
            waybillType: 'goods',
            tradeType: 1, // 交易类型
            exchangeOrder: false,
            hasBack: false,
            packageType: [
              // 包裹类型
              { code: this.$t('placeOrder.package'), value: 'package' },
              { code: this.$t('orderCenterCont.commodity'), value: 'goods' },
              { code: this.$t('orderCenterCont.gift'), value: 'gift' },
              { code: this.$t('orderCenterCont.file'), value: 'file' },
              { code: this.$t('orderCenterCont.other'), value: 'other' }
            ], // 当前选择包裹类型
            orderItemList: [
              {
                id: 0,
                name: '', // 物品名称
                declaredValue: '', // 输入的单价
                declaredWeight: null,
                quantity: '', // 数量
                length: null, // 长
                width: null, // 宽
                height: null // 高
                // invoice: [], // 商品发票证明
                // invoiceName: '' // 发票名
              }
            ]
          }
        ]
      },
      ulLi: 1,
      customerList: [], // 客户列表
      senderStateList: [], // 州列表
      senderCityList: [], // 发件人市列表
      receiveCityList: [], // 收件人城市
      receiverInfoParams: {
        // 收
        receiveName: '', // （收/发）名字
        receiveMobile: '', // 电话
        receiveTax: '', // 税号
        receiveMail: '', // 邮箱
        receiveZipcode: '', // 邮编
        receiveStreet: '', // 街道（精确到街道号）
        // receiveNeighborhood: '', // 邻里
        receiveAddress: '', // 详细地址
        receiveHouseNumber: '', // 门牌号
        receiveState: '', // 州
        receiveCity: '', // 城市
        receiveArea: '', // 区
        receiveIeNumber: '',
        receiveComplement: '', // 地址补充
        receivePhone: '' // 收件人电话
      },
      senderInfoParams: {
        // 发
        senderName: '', // （收/发）名字
        senderMobile: '', // 电话
        senderTax: '', // 税号
        senderMail: '', // 邮箱
        senderZipcode: '', // 邮编
        senderStreet: '', // 街道（精确到街道号）
        // senderNeighborhood: '', // 邻里
        senderAddress: '', // 详细地址
        senderHouseNumber: '', // 门牌号
        senderState: '', // 州
        senderCity: '', // 城市
        senderArea: '', // 区
        senderIeNumber: '', // 国家注册号
        senderComplement: '', // 地址补充
        senderPhone: '' // 发件人电话
      },
      isZipcode1: true, // 光标离开校验邮编-发件人
      isZipcode2: true, // 光标离开校验邮编-收件人
      isBlur: 0, // 光标移出 0 未移出不调用事件  1 已移出
      isCN: 0, // 客户单号是否验证成功
      isFlage1: false, // 是否发送下单请求
      isFlage2: false, // 是否发送下单请求
      isFlage3: false, // 是否发送下单请求
      isFlage4: false, // 是否发送下单请求
      isZipCode: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: isZipCodexiaodan, trigger: ['blur'] }
      ],
      checkFloatNumber: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        {
          validator: (rule, value, callback) => {
            if (checkFloatNumber(value) !== true) {
              if (checkFloatNumber(value) === 1) {
                return callback(new Error(this.$t('placeOrder.beyondRange')));
              } else {
                return callback(new Error(this.$t('placeOrder.float2Number')));
              }
            } else {
              callback();
            }
          }
        }
      ],
      checkFloatNumber1: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: priceOfGoods, trigger: ['blur', 'change'] }
      ],
      // 开票金额校验
      InvoiceAmountRule: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: InvoiceAmounts, trigger: ['blur', 'change'] }
      ],
      // 地址补充
      addressComplement: [
        { required: false },
        { validator: checkAddressComplement, trigger: ['blur', 'change'] }
      ],
      // 邮箱 E_Mail
      checkMail: [{ required: false }, { validator: E_Mail, trigger: ['blur', 'change'] }],
      // 城市
      cityComplement: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: checkCityComplement, trigger: ['blur', 'change'] }
      ],
      // 校验50个字符
      checkStr50: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        {
          validator: (rule, value, callback) => {
            if (value.trim().length === 0) {
              return callback(new Error(this.$t('placeOrder.pleaseInput')));
            } else if (value.length > 50) {
              return callback(new Error(this.$t('placeOrder.input50Str')));
            } else {
              callback();
            }
          },
          trigger: ['blur', 'change']
        }
      ],
      // 校验5个字符
      checkStr5: [
        { required: false },
        { min: 1, max: 5, message: this.$t('placeOrder.input5'), trigger: ['blur', 'change'] }
      ],
      isNumber: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        {
          validator: (rule, value, callback) => {
            if (isNumber(value) !== true) {
              if (isNumber(value) === 1) {
                return callback(new Error(this.$t('placeOrder.beyondRangeNumber')));
              } else {
                return callback(new Error(this.$t('placeOrder.pleaseInput')));
              }
            } else {
              callback();
            }
          }
        }
      ],
      isPhone: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: MobilePhoneNumber, trigger: ['blur', 'change'] }
      ],
      IeNumber: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: IENumber, trigger: ['blur', 'change'] }
      ],
      IeNumber1: [{ required: false }, { validator: IENumber, trigger: ['blur', 'change'] }],
      isTax12: [
        // 112125456431
        {
          required: true,
          message: this.$t('newOrder.TheTaxIDCannotBeEmpty'),
          trigger: ['blur', 'change']
        },
        { validator: isTax, trigger: ['blur', 'change'] }
      ],
      zhDate: 'yyyy-MM-dd',
      ptDate: 'dd-MM-yyyy',
      iceSerialIndex: [
        // 112125456431
        {
          required: true,
          message: this.$t('placeOrder.PleaseEnter1To8invoiceSerialIndex'),
          trigger: ['blur', 'change']
        },
        { validator: invoiceSerialIndex, trigger: ['blur', 'change'] }
      ],
      pickerOptions: {
        disabledDate(time) {
          const curDate = new Date().getTime();
          const three = 180 * 24 * 3600 * 1000;
          const threeMonths = curDate - three;
          return time.getTime() > Date.now() - 8.64e6 || time.getTime() < threeMonths;
        }
      },
      invoiceNumber: [
        {
          required: true,
          message: this.$t('placeOrder.PleaseEnter6To16DigitInvoiceNumber'),
          trigger: ['blur', 'change']
        },
        { validator: invoiceNumberCheck, trigger: ['blur', 'change'] }
      ],
      invoiceKey: [
        {
          required: true,
          message: this.$t('placeOrder.PleaseEnter1To64DigitInvoiceNumber'),
          trigger: ['blur', 'change']
        },
        { validator: invoiceSeriakey, trigger: ['blur', 'change'] }
      ],
      // 总件数校验
      totalNumberPieces: [
        {
          required: true,
          validator: quantityVerification,
          int: 1,
          min: 1,
          max: 999,
          length: 0,
          trigger: ['blur']
        }
        // { validator: quantityVerification, int: 1, min: 1, max: 999, length: 0, trigger: ['blur'] }
      ],
      // 包裹总重量校验
      pesoTotal: [
        {
          required: true,
          validator: quantityVerification,
          int: 2,
          min: 0.001,
          max: 9999,
          length: 3,
          trigger: ['blur']
        }
        // { validator: quantityVerification, int: 2, min: 0.001, max: 9999, length: 3, trigger: ['blur'] }
      ],
      // 包裹总体积校验
      parcelVolume: [
        {
          required: true,
          validator: quantityVerification,
          int: 2,
          min: 0.001,
          max: 999,
          length: 3,
          trigger: ['blur']
        }
        // { validator: quantityVerification, int: 2, min: 0.001, max: 999, length: 3, trigger: ['blur'] }
      ],
      dateName: '',
      commercialList: [], // 商户列表
      timer: 0,
      isOpenDetailWindow: false,
      clickDetail: null,
      totalKey: new Date().getTime(),
      totalFormKey: new Date().getTime()
    };
  },
  watch: {
    orderDialog: {
      handler: function(val) {
        this.$refs['ruleForm']?.resetFields();
        this.WaybillNumber = '';
        this.tableData = [];
      }
    },
    addressDialog: {
      handler: function(val, oldVal) {
        if (val) {
          this.getSearchAddress();
        }
      }
    },
    'baseRuleForm.customeId': {
      handler: function(val, oldVal) {
        if (val) {
          this.isSync = false;
        }
      },
      immediate: true
    },
    'orderPackageListInfo.totalParcel': {
      handler(newVal, oldVal) {
        if (this.orderPackageListInfo.detailList.length > 0) {
          let total = 0;
          this.orderPackageListInfo.detailList.map((item) => {
            total += Number(item.quantity);
          });
          if (newVal < total) {
            let parcel = 0;
            this.$message.warning(this.$t('placeOrder.TheTotalNumberItemsCannotReduced'));
            this.orderPackageListInfo.detailList.map((item) => {
              parcel += Number(item.quantity);
            });
            this.$set(this.orderPackageListInfo, 'totalParcel', parcel);
            this.totalKey = new Date().getTime();
          }
        }
      },
      immediate: true
    },
    'orderPackageListInfo.totalParcelDeclaredWeight': {
      handler(newVal, oldVal) {
        if (this.orderPackageListInfo.detailList.length > 0) {
          let total = 0;
          this.orderPackageListInfo.detailList.map((item) => {
            total += Number(item.unitWeight) * Number(item.quantity);
          });
          total = total.toFixed(3);
          if (newVal < total) {
            let weight = 0;
            this.$message.warning(this.$t('placeOrder.TheTotalWeightItemsCannotReduced'));
            this.orderPackageListInfo.detailList.map((item) => {
              weight += Number(item.unitWeight) * Number(item.quantity);
            });
            weight = weight.toFixed(3);
            this.$set(this.orderPackageListInfo, 'totalParcelDeclaredWeight', weight);
            this.totalKey = new Date().getTime();
          }
        }
      },
      immediate: true
    },
    'orderPackageListInfo.totalParcelDeclaredVolume': {
      handler(newVal, oldVal) {
        if (this.orderPackageListInfo.detailList.length > 0) {
          let total = 0;
          this.orderPackageListInfo.detailList.map((item) => {
            total += Number(item.unitVolume) * Number(item.quantity);
          });
          total = total.toFixed(3);
          if (newVal < total) {
            let volume = 0;
            this.$message.warning(this.$t('placeOrder.TheTotalVolumeItemsCannotReduced'));
            this.orderPackageListInfo.detailList.map((item) => {
              volume += Number(item.unitVolume) * Number(item.quantity);
            });
            volume = volume.toFixed(3);
            this.$set(this.orderPackageListInfo, 'totalParcelDeclaredVolume', volume);
            this.totalKey = new Date().getTime();
          }
        }
      },
      immediate: true
    }
  },
  created() {
    const date = new Date();
    const yy = date.getFullYear();
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    this.dateName = yy + '-' + (mm < 10 ? '0' + mm : mm) + '-' + (dd < 10 ? '0' + dd : dd);
    this.orderPackageListInfo.orderWaybillList[0].invoiceIssueDate = this.dateName;
    this.getCustomerPage();
    this.getAddress();
  },
  methods: {
    // 货物类型切换
    cargoTypeChange(val) {
      console.log(val, '货物类型切换');
      this.orderPackageListInfo.totalParcelDeclaredVolume = undefined;
      this.orderPackageListInfo.totalParcelDeclaredWeight = undefined;
      this.orderPackageListInfo.totalParcel = 1;
      this.orderPackageListInfo.detailList = [];
      this.orderPackageListInfo.orderWaybillListNew = [];
      const data = [];
      this.orderPackageListInfo.orderWaybillList.forEach((item) => {
        data.push({
          ...item,
          totalParcelDeclaredVolume: undefined,
          totalParcelDeclaredWeight: undefined,
          weight: '',
          volume: '',
          length: '',
          width: '',
          height: '',
          TotalNumberPieces: null,
          orderItemList: [
            {
              id: 0,
              name: '', // 物品名称
              declaredValue: '', // 输入的单价
              declaredWeight: null,
              quantity: '', // 数量
              length: null, // 长
              width: null, // 宽
              height: null // 高
              // invoice: [], // 商品发票证明
              // invoiceName: '' // 发票名
            }
          ]
        });
      });
      this.$set(this.orderPackageListInfo, 'orderWaybillList', [data[0]]);
      if (val === 0) {
        this.baseRuleForm.orderPackageType = '1';
      } else if (val === 1) {
        this.baseRuleForm.orderPackageType = '4';
      } else {
        this.baseRuleForm.orderPackageType = '1';
      }
    },
    // 打开补充详细体积、重量弹窗
    openDetailWindow() {
      this.clickDetail = cloneDeep(this.orderPackageListInfo);
      this.isOpenDetailWindow = true;
    },
    // 保存明细
    saveDetail() {
      this.$refs.openDetail.save();
    },
    getSaveDetail(data) {
      this.orderPackageListInfo.totalParcelDeclaredVolume = data.packageVolume;
      this.orderPackageListInfo.totalParcelDeclaredWeight = data.totalWeight;
      this.orderPackageListInfo.totalParcel = data.totalNumber;
      this.orderPackageListInfo.detailList = data.detailList;
      if (Number(data.totalNumber)) {
        this.orderPackageListInfo.orderWaybillListNew = [];
        for (let i = 0; i < data.detailList.length; i++) {
          const dataList = cloneDeep(data.orderWaybillList[0]);
          dataList.totalParcelDeclaredVolume = data.packageVolume;
          dataList.totalParcelDeclaredWeight = data.totalWeight;
          dataList.TotalNumberPieces = data.totalNumber;
          dataList.volume = data.detailList[i].unitVolume;
          dataList.weight = data.detailList[i].unitWeight;
          if (data.detailList[i].quantity > 1) {
            for (let j = 0; j < data.detailList[i].quantity; j++) {
              this.orderPackageListInfo.orderWaybillListNew.push(dataList);
            }
          } else {
            this.orderPackageListInfo.orderWaybillListNew.push(dataList);
          }
        }
      }
      this.orderPackageListInfo.orderWaybillList = [
        this.orderPackageListInfo.orderWaybillListNew[0]
      ];
      this.isOpenDetailWindow = false;
      this.totalFormKey = new Date().getTime();
    },
    // handleInputsenderTax() {
    //   this.senderInfoParams.senderTax = this.senderInfoParams.senderTax.replace(/[^\d]/g, ''); // 只保留数字
    // },
    // handleInputreceiveTax() {
    //   this.receiverInfoParams.receiveTax = this.receiverInfoParams.receiveTax.replace(/[^\d]/g, ''); // 只保留数字
    // },
    // 当前客户订单
    getCustomerNumber(customerNumber) {
      // 如果有客户单号，先验证客户单号
      if (this.baseRuleForm.customerNumber) {
        if (this.baseRuleForm.customerNumber.length < 2) {
          return;
        }
        const checkCustomerData = {
          customerNumber: this.baseRuleForm.customerNumber,
          customerId: this.baseRuleForm.customeId
        };
        // console.log('管理端校验接口', checkCustomerData);
        checkCustomerNumber(checkCustomerData).then((res) => {
          if (res.status === 'OK') {
            // 客户单号可用
            this.$message({
              message: this.$t('placeOrder.customerNumberUsable'),
              type: 'success'
            });
            this.isCN = 1;
          }
          if (res.status === 'ERROR') {
            this.isCN = 0;
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 是否展示更多信息
    showSenderMore() {
      this.isShowSenderMoreInfo = !this.isShowSenderMoreInfo;
    },
    // 是否展示更多信息
    showRecipientMore() {
      this.isShowRecipientMoreInfo = !this.isShowRecipientMoreInfo;
    },
    // 添加商品
    addGoods(index) {
      let i = 0;
      this.orderPackageListInfo.orderWaybillList[index].orderItemList.push({
        id: i++,
        name: '', // 物品名称
        declaredValue: '', // 输入的单价
        quantity: '' // 数量
        // invoice: [], // 商品发票证明
        // invoiceName: '' // 发票名
      });
    },
    // 删除商品
    delGoods(index, goodIndex) {
      this.orderPackageListInfo.orderWaybillList.forEach((item, index) => {
        item.orderItemList.forEach((goodItem, indexx) => {
          if (indexx === goodIndex && indexx !== 0) {
            this.orderPackageListInfo.orderWaybillList[index].orderItemList.splice(goodIndex, 1);
          }
        });
      });
    },
    // 添加包裹
    addPackage() {
      this.orderPackageListInfo.orderWaybillList.push({
        dictName: 'WRPER1',
        tradeType: 1, // 交易类型
        length: '', // 长
        width: '', // 宽
        height: '', // 高
        weight: '', // 重量(KG)
        TotalNumberPieces: '', //  总件数
        totalParcelDeclaredWeight: undefined, //  包裹总重量（kg）
        totalParcelDeclaredVolume: undefined, //  包裹总体积（m³）
        invoiceNumber: '', // 发票号
        invoiceSerialNumber: '', // 发票序列号
        invoiceIssueDate: this.dateName, // 开票日期
        invoiceAccessKey: '', // 发票密钥
        scanOrderNumber: '', // 扫描单号
        invoiceMoney: '', // 开票金额
        waybillType: 'goods',
        packageType: [
          // 包裹类型
          { code: this.$t('placeOrder.package'), value: 'package' },
          { code: this.$t('orderCenterCont.commodity'), value: 'goods' },
          { code: this.$t('orderCenterCont.gift'), value: 'gift' },
          { code: this.$t('orderCenterCont.file'), value: 'file' },
          { code: this.$t('orderCenterCont.other'), value: 'other' }
        ], // 当前选择包裹类型
        orderItemList: [
          {
            name: '', // 物品名称
            declaredValue: '', // 输入的单价
            quantity: '' // 数量
            // invoice: [], // 商品发票证明
            // invoiceName: '' // 发票名
          }
        ]
      });
    },
    // 删除包裹
    delPackage(bgIndex) {
      this.orderPackageListInfo.orderWaybillList.forEach((arr, index) => {
        if (index === bgIndex) {
          this.orderPackageListInfo.orderWaybillList.splice(bgIndex, 1);
        }
      });
    },
    // 滚动
    scrollTop(index) {
      this.ulLi = index;
      document.querySelector(`#box${this.ulLi}`).scrollIntoView({
        behavior: 'instant', // 平滑过渡
        block: 'start' // start 上边框  center 中间  end 底部边框 与视窗顶部平齐
      });
    },
    // 客户列表
    getCustomerPage() {
      this.customerList = [];
      const customerPageDate = {
        pageNumber: 1,
        pageSize: 10000,
        params: {
          company: '',
          source: '',
          username: ''
        }
      };
      CustomerPage(customerPageDate).then((res) => {
        res.data.records.map((arr) => {
          this.customerList.push({
            id: arr.id,
            username: arr.username
          });
        });
      });
    },
    // 发件人——级联地区
    getAddress(name = '') { // level 0 州    1 城市   2 地区
      const params = {
        pageNumber: 1,
        pageSize: 200,
        params: {
          name,
          countryName: 'Brazil'
        }
      };
      selectState(params, false).then((res) => {
        if (res.code === 200) {
          this.senderStateList = res.data.records;
        }
      });
    },
    // 州省变化获取城市
    stateChangeOption(type) {
      let id = null;
      if (type === 1) {
        this.senderCityList = [];
        this.senderInfoParams.senderCity = '';
        this.senderStateList.map(item => {
          if (item.shortName === this.senderInfoParams.senderState) {
            id = item.id;
          }
        });
      } else {
        this.receiveCityList = [];
        this.receiverInfoParams.receiveCity = '';
        this.senderStateList.map(item => {
          if (item.shortName === this.receiverInfoParams.receiveState) {
            id = item.id;
          }
        });
      }
      const params = {
        pageNumber: 1,
        pageSize: 200,
        params: {
          id,
          name: ''
        }
      };
      selectCity(params, false).then(res => {
        if (res.code === 200) {
          if (type === 1) {
            this.senderCityList = res.data.records;
          } else {
            this.receiveCityList = res.data.records;
          }
        }
      });
    },
    // 获取城市
    getSenderCity(name = '') {
      let id = null;
      this.senderStateList.map(item => {
        if (item.shortName === this.senderInfoParams.senderState) {
          id = item.id;
        }
      });
      const params = {
        pageNumber: 1,
        pageSize: 200,
        params: {
          id,
          name
        }
      };
      selectCity(params, false).then(res => {
        if (res.code === 200) {
          this.senderCityList = res.data.records;
        }
      });
    },
    getReceiveCity(name = '') {
      let id = null;
      this.senderStateList.map(item => {
        if (item.shortName === this.receiverInfoParams.receiveState) {
          id = item.id;
        }
      });
      const params = {
        pageNumber: 1,
        pageSize: 200,
        params: {
          id,
          name
        }
      };
      selectCity(params, false).then(res => {
        if (res.code === 200) {
          this.receiveCityList = res.data.records;
        }
      });
    },
    // 邮编校验
    checkPostcode(postcode, pType) {
      if (postcode.length === 0) {
        return;
      }
      // pType === 1 发件人   pType === 2 收件人
      if (this.isBlur === 1) {
        const data = {};
        data.zipcode = postcode;
        checkedzipcode(data)
          .then((res) => {
            // console.log('验证邮编', res);
            if (res.status === 'OK') {
              if (!res.data) {
                if (pType === 1) {
                  this.$message.error(this.$t('placeOrder.inputCodeErr'));
                  this.senderInfoParams.senderZipcode = '';
                }
                if (pType === 2) {
                  this.$message.error(this.$t('placeOrder.inputCodeErr'));
                  this.receiverInfoParams.receiveZipcode = '';
                }
              }
            }
          })
          .catch(() => {
            if (pType === 1) {
              this.senderInfoParams.senderZipcode = '';
            }
            if (pType === 2) {
              this.receiverInfoParams.receiveZipcode = '';
            }
          });
      }
    },
    // 下单
    async placeAnOrder() {
      this.$refs['baseRuleForm'].validate((valid) => {
        this.isFlage1 = !!valid;
      });
      // this.$refs['senderInfoForm'].validate((valid) => {
      //   this.isFlage2 = !!valid;
      // });
      // this.$refs['receiverInfoForm'].validate((valid) => {
      //   this.isFlage3 = !!valid;
      // });
      this.$refs['packageRuleForm'].validate((valid) => {
        this.isFlage4 = !!valid;
      });
      const valid2 = await this.$refs['senderInfoForm'].validate();
      this.isFlage2 = valid2;
      const valid3 = await this.$refs['receiverInfoForm'].validate();
      this.isFlage3 = valid3;
      if (this.isFlage1 && this.isFlage2 && this.isFlage3 && this.isFlage4) {
        const param = cloneDeep(this.orderPackageListInfo);
        console.log(param, 'param');
        // 大件补充数据
        if (param.orderWaybillListNew && param.orderWaybillListNew.length > 0) {
          param.orderWaybillList = param.orderWaybillListNew;
          if (param.totalParcel > param.orderWaybillListNew.length) {
            const total = param.totalParcel - param.orderWaybillListNew.length;
            for (let i = 0; i < total; i++) {
              param.orderWaybillList.push({
                ...param.orderWaybillList[0],
                height: 0,
                length: 0,
                volume: '',
                weight: '',
                width: 0
              });
            }
          }
        } else {
          // param.orderWaybillList = param.orderWaybillListNew;
          if (this.baseRuleForm.cargoType === 1) {
            if (param.totalParcel > param.orderWaybillList.length) {
              const total = param.totalParcel - param.orderWaybillList.length;
              for (let i = 0; i < total; i++) {
                param.orderWaybillList.push({
                  ...param.orderWaybillList[0],
                  height: 0,
                  length: 0,
                  volume: '',
                  weight: '',
                  width: 0
                });
              }
            }
          }
        }
        delete param.orderWaybillListNew;
        delete param.detailList;
        param.orderWaybillList.map((e) => {
          delete e.packageType;
          delete e.TotalNumberPieces;
          delete e.totalParcelDeclaredWeight;
          delete e.totalParcelDeclaredVolume;
        });
        const data = {
          cargoType: this.baseRuleForm.cargoType, // 货物类型
          collectType: this.baseRuleForm.collectType,
          orderPackageType: this.baseRuleForm.orderPackageType,
          expressType: this.baseRuleForm.expressType,
          currency: 'BRL', // 币种
          customerId: this.baseRuleForm.customeId,
          customerNumber: this.baseRuleForm.customerNumber, // 客户单号
          receiverInfoParams: this.receiverInfoParams,
          senderInfoParams: this.senderInfoParams,
          totalParcelDeclaredWeight: param.totalParcelDeclaredWeight,
          totalParcelDeclaredVolume: param.totalParcelDeclaredVolume,
          orderWaybillList: param.orderWaybillList
        };
        if (this.baseRuleForm.merchantNumber) {
          data.merchantNumber = this.baseRuleForm.merchantNumber;
          data.merchantName = this.baseRuleForm.merchantName;
        }
        const str1 = data.receiverInfoParams.receiveTax;
        const pattern = /\d+/g;
        const numbers = str1.match(pattern);
        data.receiverInfoParams.receiveTax = numbers.join('');
        const str2 = data.senderInfoParams.senderTax;
        const numbers2 = str2.match(pattern);
        data.senderInfoParams.senderTax = numbers2.join('');
        console.log(param, 'param111111');
        if (param.orderWaybillList.length > 0) {
          for (let i = 0; i < param.orderWaybillList.length; i++) {
            param.orderWaybillList[i].invoiceAccessKey = this.removeSpaces(
              param.orderWaybillList[i].invoiceAccessKey
            );
            param.orderWaybillList[i].invoiceIssueDate =
              param.orderWaybillList[i].invoiceIssueDate + ' 00:00:00';
            const length = param.orderWaybillList[i].length;
            const height = param.orderWaybillList[i].height;
            const width = param.orderWaybillList[i].width;
            const weight = param.orderWaybillList[i].weight;
            const sum = Number(length) + Number(width) + Number(height);
            if (data.cargoType === 0) {
              if (Number(length) > 170 || Number(height) > 170 || Number(width) > 170) {
                this.$message.error(this.$t('GlobalSetObj.LWH'));
                return;
              }
              if (sum > 240) {
                this.$message.error(this.$t('GlobalSetObj.LWHSum'));
                return;
              }
              if (Number(weight) > 500) {
                this.$message.error(this.$t('GlobalSetObj.maximo500kg'));
                return;
              }
            }
          }
        }
        console.log(data, 'rewrwetetfgsdgdf');
        createOrder(data).then((res) => {
          if (res.status === 'OK') {
            this.countdownDialog = true;
          }
        });
      }
    },
    // 去除所有的空格
    removeSpaces(str) {
      return str.replace(/\s/g, '');
    },
    toWillyNumber() {
      this.countdownDialog = false;
      this.$router.push({ path: '/orderCenter/logisticsOrders' });
    },
    // 继续下单
    continuePlaceOrders() {
      this.countdownDialog = false;
      this.$router.push({ path: '/orderCenter/placeOrder' });
      this.baseRuleForm.cargoType = 0;
      this.orderPackageListInfo.detailList = [];
      this.orderPackageListInfo.orderWaybillListNew = [];
      this.orderPackageListInfo.orderWaybillList = [
        {
          dictName: 'WRPER1',
          tradeType: 1, // 交易类型
          length: '', // 长
          width: '', // 宽
          height: '', // 高
          weight: '', // 重量(KG)
          TotalNumberPieces: undefined, //  总件数
          totalParcelDeclaredWeight: undefined, //  包裹总重量（kg）
          totalParcelDeclaredVolume: undefined, //  包裹总体积（m³）
          invoiceNumber: '', // 发票号
          invoiceSerialNumber: '', // 发票序列号
          invoiceIssueDate: '', // 开票日期
          invoiceAccessKey: '', // 发票密钥
          scanOrderNumber: '', // 扫描单号
          invoiceMoney: '', // 开票金额
          waybillType: 'goods',
          exchangeOrder: false,
          hasBack: false,
          packageType: [
            // 包裹类型
            { code: this.$t('placeOrder.package'), value: 'package' },
            { code: this.$t('orderCenterCont.commodity'), value: 'goods' },
            { code: this.$t('orderCenterCont.gift'), value: 'gift' },
            { code: this.$t('orderCenterCont.file'), value: 'file' },
            { code: this.$t('orderCenterCont.other'), value: 'other' }
          ], // 当前选择包裹类型
          orderItemList: [
            {
              id: 0,
              name: '', // 物品名称
              declaredValue: '', // 输入的单价
              declaredWeight: null,
              quantity: '', // 数量
              length: null, // 长
              width: null, // 宽
              height: null // 高
            }
          ]
        }
      ];
      this.$refs['baseRuleForm']?.resetFields();
      this.$refs['senderInfoForm']?.resetFields();
      this.$refs['receiverInfoForm']?.resetFields();
      this.$refs['packageRuleForm']?.resetFields();
    },
    // 清空
    empty() {
      this.$refs['baseRuleForm']?.resetFields();
      this.$refs['senderInfoForm']?.resetFields();
      this.$refs['receiverInfoForm']?.resetFields();
      this.$refs['packageRuleForm']?.resetFields();
    },
    // 复制订单
    getSearch() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const data = { waybillNumber: this.order.WaybillNumber };
          CopyOrder(data)
            .then((res) => {
              this.tableData = [];
              if (res.status === 'OK') {
                this.orderInformation = res.data;
                this.tableData = res.data;
                // res.data.forEach(item => {
                //   item.receiverInfo.customerName = item.customerName;
                //   this.tableData.push(item.receiverInfo);
                // });
              }
            })
            .catch((e) => {
              this.tableData = [];
            });
        }
      });
    },
    confirmOrderDialog(row) {
      if (row === 0) {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            const data = { waybillNumber: this.order.WaybillNumber };
            CopyOrder(data)
              .then((res) => {
                if (res.status === 'OK') {
                  this.orderInformation = res.data[0];
                  this.orderDialog = false;
                  this.baseRuleForm.collectType = String(this.orderInformation.collectType);
                  if (this.orderInformation.orderPackageType) {
                    if (String(this.orderInformation.orderPackageType) === '2') {
                      this.baseRuleForm.orderPackageType = '1';
                    } else {
                      this.baseRuleForm.orderPackageType = String(
                        this.orderInformation.orderPackageType
                      );
                    }
                  }
                  this.baseRuleForm.cargoType = this.orderInformation.cargoType || 0;
                  this.baseRuleForm.customeId = this.orderInformation.customerId;
                  this.isInput = false;
                  this.baseRuleForm.expressType = String(this.orderInformation.expressType);
                  this.baseRuleForm.customerNumber = null;
                  this.baseRuleForm.merchantNumber = this.orderInformation.merchantNumber;
                  this.baseRuleForm.merchantName = this.orderInformation.merchantName;
                  this.receiverInfoParams = this.orderInformation.receiverInfo;
                  this.senderInfoParams = this.orderInformation.senderInfo;
                  this.orderInformation.waybillOrderList.forEach((item) => {
                    item.tradeType = item.tradeType ? item.tradeType : 1;
                    if (this.baseRuleForm.cargoType === 1) {
                      item.weight = '';
                      item.volume = '';
                    }
                  });
                  this.orderPackageListInfo.orderWaybillList =
                    this.orderInformation.waybillOrderList;
                  this.orderPackageListInfo.detailList = [];
                  this.orderPackageListInfo.totalParcelDeclaredWeight = this.orderInformation
                    .totalParcelDeclaredWeight
                    ? this.orderInformation.totalParcelDeclaredWeight
                    : 0;
                  this.orderPackageListInfo.totalParcelDeclaredVolume = this.orderInformation
                    .totalParcelDeclaredVolume
                    ? this.orderInformation.totalParcelDeclaredVolume
                    : 0;
                  this.orderPackageListInfo.totalParcel = this.orderInformation
                    .totalCreateOrderCount
                    ? this.orderInformation.totalCreateOrderCount
                    : 0;
                  const packageTypeList = [
                    // 包裹类型
                    { code: this.$t('placeOrder.package'), value: 'package' },
                    { code: this.$t('orderCenterCont.commodity'), value: 'goods' },
                    { code: this.$t('orderCenterCont.gift'), value: 'gift' },
                    { code: this.$t('orderCenterCont.file'), value: 'file' },
                    { code: this.$t('orderCenterCont.other'), value: 'other' }
                  ];
                  this.orderPackageListInfo.orderWaybillList.forEach((item) => {
                    item.packageType = packageTypeList;
                  });
                  this.getSenderCity();
                  this.getReceiveCity();
                }
              })
              .catch(() => {
                this.orderDialog = false;
              });
          }
        });
      } else {
        this.orderInformation = row;
        this.orderDialog = false;
        this.baseRuleForm.collectType = String(this.orderInformation.collectType);
        if (this.orderInformation.orderPackageType) {
          if (String(this.orderInformation.orderPackageType) === '2') {
            this.baseRuleForm.orderPackageType = '1';
          } else {
            this.baseRuleForm.orderPackageType = String(this.orderInformation.orderPackageType);
          }
        }
        this.baseRuleForm.cargoType = this.orderInformation.cargoType || 0;
        this.baseRuleForm.customeId = this.orderInformation.customerId;
        this.isInput = false;
        this.baseRuleForm.expressType = String(this.orderInformation.expressType);
        this.baseRuleForm.customerNumber = null;
        this.baseRuleForm.merchantNumber = this.orderInformation.merchantNumber;
        this.baseRuleForm.merchantName = this.orderInformation.merchantName;
        this.receiverInfoParams = this.orderInformation.receiverInfo;
        this.senderInfoParams = this.orderInformation.senderInfo;
        this.orderInformation.waybillOrderList.forEach((item) => {
          item.tradeType = item.tradeType ? item.tradeType : 1;
          if (this.baseRuleForm.cargoType === 1) {
            item.weight = '';
            item.volume = '';
          }
        });
        this.orderPackageListInfo.orderWaybillList = this.orderInformation.waybillOrderList;
        this.orderPackageListInfo.detailList = [];
        this.orderPackageListInfo.totalParcelDeclaredWeight = this.orderInformation
          .totalParcelDeclaredWeight
          ? this.orderInformation.totalParcelDeclaredWeight
          : 0;
        this.orderPackageListInfo.totalParcelDeclaredVolume = this.orderInformation
          .totalParcelDeclaredVolume
          ? this.orderInformation.totalParcelDeclaredVolume
          : 0;
        this.orderPackageListInfo.totalParcel = this.orderInformation.totalCreateOrderCount
          ? this.orderInformation.totalCreateOrderCount
          : 0;
        const packageTypeList = [
          // 包裹类型
          { code: this.$t('placeOrder.package'), value: 'package' },
          { code: this.$t('orderCenterCont.commodity'), value: 'goods' },
          { code: this.$t('orderCenterCont.gift'), value: 'gift' },
          { code: this.$t('orderCenterCont.file'), value: 'file' },
          { code: this.$t('orderCenterCont.other'), value: 'other' }
        ];
        this.orderPackageListInfo.orderWaybillList.forEach((item) => {
          item.packageType = packageTypeList;
        });
        this.getSenderCity();
        this.getReceiveCity();
      }
      console.log(this.orderPackageListInfo, '===this.orderPackageListInfo');
    },
    // 客户发件地址
    getSearchAddress() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params.customerId = this.baseRuleForm.customeId;
      if (this.addressForm.type === this.$t('placeOrder.receiveName')) {
        data.params.name = this.addressForm.name;
        data.params.zipcode = '';
      } else if (this.addressForm.type === this.$t('placeOrder.senderName')) {
        data.params.name = this.addressForm.name;
        data.params.zipcode = '';
      } else {
        if (this.addressTitle === this.$t('placeOrder.receiveName')) {
          data.params.zipcode = this.addressForm.name;
          data.params.name = '';
        } else {
          data.params.zipcode = this.addressForm.name;
          data.params.name = '';
        }
      }
      pageCustomerAddress(data).then((res) => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.customerAddressList = res.data.records;
        }
      });
      // if (this.addressTitle === this.$t('placeOrder.receiveName')) {
      //   pageCustomerReceive(data).then(res => {
      //     if (res.status === 'OK') {
      //       this.customerAddressList = res.data.records;
      //       this.queryForm.total = res.data.total;
      //     }
      //   });
      // } else {
      //   pageCustomerSender(data).then(res => {
      //     if (res.status === 'OK') {
      //       this.customerAddressList = res.data.records;
      //       this.queryForm.total = res.data.total;
      //     }
      //   });
      // }
    },
    customerAddress(title) {
      if (this.baseRuleForm.customeId) {
        this.$nextTick(() => {
          this.addressForm.type = title;
        });
        this.addressTitle = title;
        this.addressDialog = true;
      } else {
        this.$message.error(this.$t('placeOrder.selectClient'));
      }
    },
    // 选择客户地址
    selectAddress(item, title) {
      if (title === this.$t('placeOrder.senderName')) {
        delete item.id;
        this.senderInfoParams.senderName = item.name;
        this.senderInfoParams.senderMobile = item.phone;
        this.senderInfoParams.senderTax = item.cpf;
        this.senderInfoParams.senderMail = item.email;
        this.senderInfoParams.senderHouseNumber = item.houseNumber;
        this.senderInfoParams.senderIeNumber = item.ieNumber;
        this.senderInfoParams.senderComplement = item.complement;
        this.senderInfoParams.senderZipcode = item.zipcode;
        this.senderInfoParams.senderCity = item.city;
        this.senderInfoParams.senderArea = item.area;
        this.senderInfoParams.senderStreet = item.street;
        this.senderInfoParams.senderAddress = item.addressDetail;
        this.senderInfoParams.senderState = item.state;
        this.senderInfoParams.senderPhone = item.telephone;
      } else {
        delete item.id;
        this.receiverInfoParams.receiveName = item.name;
        this.receiverInfoParams.receiveMobile = item.phone;
        this.receiverInfoParams.receiveTax = item.cpf;
        this.receiverInfoParams.receiveMail = item.email;
        this.receiverInfoParams.receiveHouseNumber = item.houseNumber;
        this.receiverInfoParams.receiveIeNumber = item.ieNumber;
        this.receiverInfoParams.receiveComplement = item.complement;
        this.receiverInfoParams.receiveZipcode = item.zipcode;
        this.receiverInfoParams.receiveCity = item.city;
        this.receiverInfoParams.receiveArea = item.area;
        this.receiverInfoParams.receiveStreet = item.street;
        this.receiverInfoParams.receiveAddress = item.addressDetail;
        this.receiverInfoParams.receivePhone = item.telephone;
      }
      this.addressDialog = false;
    },
    // 同步客户
    SynchronousCustomer(flag) {
      if (this.baseRuleForm.customeId) {
        if (flag === this.$t('placeOrder.senderName')) {
          this.$refs['senderInfoForm'].validate((valid) => {
            if (valid) {
              const data = {
                name: this.senderInfoParams.senderName,
                phone: this.senderInfoParams.senderMobile,
                cpf: this.senderInfoParams.senderTax,
                email: this.senderInfoParams.senderMail,
                houseNumber: this.senderInfoParams.senderHouseNumber,
                ieNumber: this.senderInfoParams.senderIeNumber,
                complement: this.senderInfoParams.senderComplement,
                zipcode: this.senderInfoParams.senderZipcode,
                city: this.senderInfoParams.senderCity,
                area: this.senderInfoParams.senderArea,
                street: this.senderInfoParams.senderStreet,
                addressDetail: this.senderInfoParams.senderAddress,
                state: this.senderInfoParams.senderState,
                telephone: this.senderInfoParams.senderPhone
              };
              data.customerId = this.baseRuleForm.customeId;
              const obj = this.customerList.find((item) => item.id === this.baseRuleForm.customeId);
              data.customerName = obj.username;
              addCustomerAddress(data)
                .then((res) => {
                  if (res.status === 'OK') {
                    this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                  }
                })
                .catch(() => {});
            } else {
              this.$message.error(this.$t('GlobalSetObj.required'));
            }
          });
        } else {
          this.$refs['receiverInfoForm'].validate((valid) => {
            if (valid) {
              const data = {
                name: this.receiverInfoParams.receiveName,
                phone: this.receiverInfoParams.receiveMobile,
                cpf: this.receiverInfoParams.receiveTax,
                email: this.receiverInfoParams.receiveMail,
                houseNumber: this.receiverInfoParams.receiveHouseNumber,
                ieNumber: this.receiverInfoParams.receiveIeNumber,
                complement: this.receiverInfoParams.receiveComplement,
                zipcode: this.receiverInfoParams.receiveZipcode,
                city: this.receiverInfoParams.receiveCity,
                area: this.receiverInfoParams.receiveArea,
                street: this.receiverInfoParams.receiveStreet,
                addressDetail: this.receiverInfoParams.receiveAddress,
                state: this.receiverInfoParams.receiveState,
                telephone: this.receiverInfoParams.receivePhone
              };
              data.customerId = this.baseRuleForm.customeId;
              addCustomerAddress(data)
                .then((res) => {
                  if (res.status === 'OK') {
                    this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                  }
                })
                .catch(() => {});
            } else {
              this.$message.error(this.$t('GlobalSetObj.required'));
            }
          });
        }
      } else {
        this.$message.error(this.$t('placeOrder.selectClient'));
      }
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.getSearchAddress();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearchAddress();
    },
    // 选择客户
    selectCustomeId(val) {
      this.isInput = val === '';
      this.commercialList = [];
      this.baseRuleForm.merchantNumber = '';
    },
    // 节流
    throttle(query, func, wait) {
      const _this = this;
      const now = new Date().getTime();
      if (now - _this.timer > wait) {
        _this.timer = now + wait;
        const tiem = setTimeout(function() {
          clearTimeout(tiem);
          func(query);
        }, wait);
      }
    },
    getShanghu() {
      if (this.baseRuleForm.customeId) {
        const data = {
          customerId: this.baseRuleForm.customeId
        };
        customerMerchant(data).then((res) => {
          if (res.status === 'OK') {
            this.commercialList = res.data;
          }
        });
      }
    },
    focuseventEnter() {
      this.getShanghu();
    },
    // 触发回车事件使用节流
    eventEnter(query) {
      if (query) {
        this.throttle(query, this.remoteMethod, 300);
      } else {
        this.getShanghu();
      }
    },
    // 远程搜索
    remoteMethod(query) {
      if (query) {
        if (this.baseRuleForm.customeId) {
          const data = {
            customerId: this.baseRuleForm.customeId,
            merchantBlur: query
          };
          inquireMerchant(data).then((res) => {
            if (res.status === 'OK') {
              this.commercialList = res.data;
            }
          });
        }
      }
    },
    // 切换商户
    handlemerchantCode(val) {
      console.log(this.baseRuleForm.customeId, 'this.baseRuleForm.customeId');
      console.log(val, '商户三生三世');
      if (val) {
        const findObjCus = this.commercialList.find((item) => {
          return item.merchantNumber === val;
        });
        if (findObjCus) {
          this.baseRuleForm.merchantName = findObjCus.merchantName;
          this.baseRuleForm.merchantNumber = findObjCus.merchantNumber;
        } else {
          this.baseRuleForm.merchantName = '';
          this.baseRuleForm.merchantNumber = '';
        }
        console.log(findObjCus, 'findObjCus');
      } else {
        this.getShanghu();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .demo-ruleForm .el-input-group__append {
  background-color: #409eff;
  border: 1px solid #409eff;
  color: #fff;
}

.countdown {
  width: 60px;
  height: 60px;
  font-size: 50px;
  border-radius: 30px;
  background-color: #ddf0f8;
}

::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: bold;
}

::v-deep .addressList .el-input__inner {
  border: none;
  font-weight: bold;
}

.addressClass {
  height: 360px;
  margin-top: 20px;
  overflow: auto;

  .nodataImage {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      width: 120px !important;
      height: 120px !important;
    }
  }

  .addressItem {
    font-size: 14px;
    background-color: #f2f2f2;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 15px 10px;
    line-height: 25px;

    &:hover {
      background-color: #ddf0f8;
    }
  }
}

.expressOrder {
  padding: 20px;

  .orderInfoList {
    width: 150px;
    height: 150px;
    line-height: 30px;
    text-align: center;
    position: fixed;
    top: 120px;
    right: 7px;
    left: 90%;
  }
}

.orderInfo {
  width: 90%;
}

.basicInformation {
  margin-bottom: 20px;

  .title {
    margin-bottom: 15px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  ::v-deep input[type='number'] {
    -moz-appearance: textfield !important;
  }

  .customerContent {
    width: 100%;
    padding: 10px;
    background: #f1f3f4;

    .formTable {
      width: 120%;
    }

    .moreInfo {
      color: #409eff;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .verticallyCentered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .delPackge {
    margin-top: 28px;
  }

  .input-with-select ::v-deep .el-input__inner {
    padding-left: 40px !important;
  }

  .input-with-select ::v-deep .el-input__prefix {
    color: #606266;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #dddede;
  }

  .upload-demo .upLoadHint {
    color: #f66c6c;
    font-size: 12px;
    text-align: left;
    float: left;
    padding: 0;
    line-height: 16px !important;
  }

  .isInvoiceName {
    display: flex;
    flex-flow: nowrap;

    i {
      color: red;
      cursor: pointer;
      font-size: 20px;
      margin-top: 10px;
    }

    span {
      font-size: 14px;
      font-weight: bold;
      padding-left: 20px;
      margin-right: 30px;
      word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
      text-overflow: ellipsis; /*溢出文字显示省略号*/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden; /** 隐藏超出的内容 **/
    }
  }
}

.sucessIcon {
  width: 30px;
  height: 30px;
  background-color: #00a121;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
<style>
.volumeClass {
  position: absolute;
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 16px;
  bottom: 25px;
  color: #409eff;
  cursor: pointer;
}

.weightVolume .el-dialog__body {
  padding: 0 !important;
}

.copyOrder .el-dialog__header {
  height: 50px;
}
</style>
