<template>
  <div>
    <div class="addFormBox">
      <!--基础数据-->
      <div class="title">{{ $t('basicData.UnderlyingData') }}</div>
      <el-form
        ref="queryForm"
        class="queryFormClass"
        size="small"
        :model="formData"
        :rules="rules"
        label-position="top">
        <el-row :gutter="10">
          <!--报价方案名称-->
          <el-col :span="6">
            <el-form-item :label="$t('basicData.TheNameOfTheQuoteProposal')" prop="name">
              <el-input
                v-model.trim="formData.name"
                :placeholder="$t('orderDetails.pleaseInput')"
                clearable
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <!--有效期开始-->
          <el-col :span="6">
            <el-form-item :label="$t('basicData.TheValidityPeriodBegins')" prop="startOfValidityPeriod">
              <el-date-picker
                v-model="formData.startOfValidityPeriod"
                type="datetime"
                :format="$i18n.locale==='zh'?zhDate:ptDate"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%;"
                :disabled="operationType===2&&editData&&editData.offerPriceStatus===1"
                :placeholder="$t('basicData.SelectADatetime')"
              />
            </el-form-item>
          </el-col>
          <!--业务类型-->
          <el-col :span="6">
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select
                v-model="formData.businessType"
                style="width: 100%;"
                clearable
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                @change="changeBusinessType(formData.businessType)"
              >
                <el-option
                  v-for="item in BUSINESS_TYPE_TWO"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 如果“业务类型”选中“第三方揽收，则显示揽收方式并且必填 -->
          <el-col v-if="formData.businessType === 2" :span="6">
            <el-form-item
              :rules="{ required: formData.businessType === 2, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              :label="$t('collectionCenter.lanshouTypee')"
              prop="collectType"
            >
              <el-select
                v-model="formData.collectType"
                filterable
                clearable
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                @change="collectTypeChange()"
              >
                <!--上门交件-->
                <el-option :key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
                <!--上门揽收-->
                <el-option :key="2" :label="$t('collectionCenter.doorPickUp')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <!--分区-->
          <el-col :span="6">
            <el-form-item
              :label="$t('operationCenter.partition')"
              prop="partitionId"
              :rules="{ required: false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="formData.partitionId"
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                filterable
                clearable
                @change="getOtherFeeDetail()"
              >
                <el-option
                  v-for="item in partitionSchemeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--应付主体-->
          <el-col :span="6">
            <el-form-item :label="$t('basicData.PayablePrincipal')" prop="payableEntityId">
              <el-select
                v-model="formData.payableEntityId"
                filterable
                clearable
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                @change="providerInfoChange(formData.payableEntityId)"
              >
                <el-option
                  v-for="item in businessPrincipalList"
                  :key="item.id"
                  :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--网点-->
          <el-col :span="6">
            <el-form-item :label="$t('basicData.Outlets')" prop="siteIds">
              <el-select
                v-model="formData.siteIds"
                filterable
                clearable
                :multiple="editData.id ? false : true"
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                @change="siteIdChange(formData.siteIds)"
              >
                <!--请先选择应收主体-->
                <el-option v-if="!formData.payableEntityId" value="">
                  <span style="color:#f00;font-weight: bold">{{ $t('basicData.PleaseSelectThePayableEntity') }}</span>
                </el-option>
                <el-option
                  v-for="item in outletsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--AJD账单显示 结算节点-->
          <el-col :span="6" v-if="formData.businessType === 3">
            <el-form-item
              :rules="{ required: formData.businessType === 3, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              :label="$t('basicData.SettlementNode')"
              prop="settlementNode"
            >
              <el-select
                v-model="formData.settlementNode"
                filterable
                clearable
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
              >
                <!--派送任务-->
                <el-option :key="1" :label="$t('basicData.DeliveryTask')" :value="1" />
                <!--签收-->
                <el-option :key="2" :label="$t('basicData.signFor')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="formData.businessType === 5" :span="6">
            <!--报价模式-->
            <el-form-item
              :label="$t('basicData.报价模式')"
              prop="quotationMode"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="formData.quotationMode"
                filterable
                clearable
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
              >
                <!--保低价-->
                <el-option :key="0" :label="$t('basicData.保底价')" :value="1" />
                <!--区间取最高-->
                <el-option :key="1" :label="$t('basicData.区间取最高')" :value="2" />
                <!--直乘-->
                <el-option :key="2" :label="$t('basicData.直乘')" :value="3" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!--价格详情-->
      <div class="title"> {{ $t('basicData.PriceDetails') }}</div>
      <!-- DSP派送及安俊小屋及运输干线 操作按钮 -->
      <template v-if="[1,4,5].includes(formData.businessType)">
        <!-- 分区 -->
        <div v-if="formData.partitionId" style="margin-bottom: 10px;">
          <el-radio-group v-model="formData.partitionAffiliateId" size="small">
            <el-radio-button
              v-for="(item, ind) in subregionList"
              :key="ind"
              :label="item.id"
            >
              {{ item.partitionIdentifier }}
            </el-radio-button>
          </el-radio-group>
          <div style="clear: both;" />
        </div>

        <div style="margin-bottom: 10px;">
          <!-- 添加一行 -->
          <el-button
            v-permit:remove="'btn:payable:quoteManagement:addEdit'"
            size="small"
            type="primary"
            @click="handleAdd()"
          >{{ $t('basicData.AddARow') }}</el-button>
          <!--  导入/下载  -->
          <el-popover placement="bottom-start" trigger="hover">
            <import-download
              :key="exportKey"
              :expense-type-list="expenseTypeList"
              :operation-mode="formData.operationMode"
              :table-data-obj="(subregionList && subregionList.length > 0) ? tableDataObj : tableDataArr"
              :table-data="{ formData, subregionList }"
              @importPartition="importPartition"
            />
            <el-button
              slot="reference" size="small" plain
              style="margin-left: 10px"
            >{{ $t('basicData.ImportDownload') }}</el-button>
          </el-popover>
          <!-- 清空报价 -->
          <el-popover placement="bottom-start" trigger="hover">
            <!-- 清空当前分区 -->
            <div style="margin-bottom: 10px;">
              <el-button
                size="small"
                type="primary"
                @click="clearOffers(1)"
              >{{ $t('basicData.EmptyCurrentPartition') }}
              </el-button>
            </div>
            <!-- 清空所有分区 -->
            <el-button
              size="small"
              type="primary"
              @click="clearOffers(2)"
            >{{ $t('basicData.EmptyAllPartitions') }}
            </el-button>
            <!--清空报价-->
            <el-button
              slot="reference"
              v-permit:remove="'btn:payable:quoteManagement:addEdit'"
              size="small"
              plain
              style="margin-left: 10px"
            >{{ $t('basicData.ClearTheOffer') }}
            </el-button>
          </el-popover>
        </div>
      </template>

      <!-- 第三方揽收（业务类型） + 上门交件（揽收方式） 显示此组件 -->
      <CollectionForwardingCentreHomeDeliveryCmp
        v-if="isCollectionForwardingCentreHomeDeliveryCmp"
        ref="collectionForwardingCentreHomeDeliveryRef"
        :subregion-list="subregionList"
        :partition-id="formData.partitionId"
      />

      <!-- 第三方揽收（业务类型） + 上门揽收（揽收方式）展示此组件 -->
      <CollectionAndDoorToDoorCollectionAndThirdPartyCmp
        v-else-if="isCollectionAndDoorToDoorCollectionAndThirdParty"
        ref="collectionAndDoorToDoorCollectionAndThirdPartyRef"
        :subregion-list="subregionList"
        :partition-id="formData.partitionId"
      />

      <!-- 业务类型AJD派送 显示此组件 -->
      <AJDAddCmp
        v-else-if="isBusinessType3AndOperationMode1"
        ref="ajdAddRef"
        :subregion-list="subregionList"
        :partition-id="formData.partitionId"
      />

      <!-- 业务类型DSP派送 -->
      <template v-if="formData.businessType === 4">
        <div style="margin-top: 10px;" class="priceTableForm">
          <el-form
            ref="tableForm1"
            :model="formData"
            label-width="0"
            label-position="top"
            class="demo-ruleForm"
            size="mini"
          >
            <el-table :data="formData.collectFeesList" border>
              <!-- 费用类型 -->
              <el-table-column :label="$t('basicData.ExpenseType')" min-width="180">
                <template slot="header">
                  <div>
                    <span style="color: red;">*</span>
                    <span>{{ $t('basicData.ExpenseType') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.feeTypeCode'"
                      :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                    >
                      <el-select
                        v-model="scope.row.feeTypeCode"
                        :placeholder="$t('basicData.PleaseSelect')"
                        style="width: 100%;"
                        @change="chargeTypeChange(scope)"
                      >
                        <el-option
                          v-for="item in expenseTypeList"
                          :key="item.id"
                          :label="item.displayName + '(' + item.code + ')'"
                          :value="item.code"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--重量开始KG（不包含）-->
              <el-table-column :label="$t('basicData.WeightStartKG')" min-width="180">
                <template slot="header">
                  <div>
                    <span style="color: red;">*</span>
                    <span>{{ $t('basicData.WeightStartKG') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.startWeight'"
                      :rules="{
                        required: true,
                        negative: '',
                        suffix: 3,
                        negative1: 1,
                        package: scope.row.endWeight,
                        validator: priceType,
                        trigger: ['blur', 'change']
                      }"
                    >
                      <el-input v-model="scope.row.startWeight" />
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--重量结束KG（包含）-->
              <el-table-column :label="$t('basicData.WeightEndKG')" min-width="180">
                <template slot="header">
                  <div>
                    <span style="color: red;">*</span>
                    <span>{{ $t('basicData.WeightEndKG') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.endWeight'"
                      :rules="{
                        required: true,
                        negative: '',
                        suffix: 3,
                        negative1: 2,
                        package: scope.row.startWeight,
                        validator: priceType,
                        trigger: ['blur', 'change']
                      }"
                    >
                      <el-input v-model="scope.row.endWeight" />
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--0-30KM价格-->
              <el-table-column :label="'0-31KM' + $t('basicData.Price')" min-width="180">
                <template slot="header">
                  <div>
                    <span style="color: red;">*</span>
                    <span>{{ '0-31KM' + $t('basicData.Price') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.innerDistancePrice'"
                    :rules="{ required: true, suffix: 5, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                  >
                    <el-input v-model="scope.row.innerDistancePrice" />
                  </el-form-item>
                </template>
              </el-table-column>
              <!--'31-101KM价格'-->
              <el-table-column :label="'31-101KM' + $t('basicData.Price')" min-width="180">
                <template slot="header">
                  <div>
                    <!-- <span style="color: red;">*</span>-->
                    <span>{{ '31-101KM' + $t('basicData.Price') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.outerDistancePrice'"
                    :rules="{ required: false, suffix: 5, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                  >
                    <el-input v-model="scope.row.outerDistancePrice" />
                  </el-form-item>
                </template>
              </el-table-column>
              <!--'>=101KM价格'-->
              <el-table-column :label="'>=101KM' + $t('basicData.Price')" min-width="180">
                <template slot="header">
                  <div>
                    <!-- <span style="color: red;">*</span>-->
                    <span>{{ '>=101KM' + $t('basicData.Price') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.greaterThanDistancePrice'"
                    :rules="{ required: false, suffix: 5, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                  >
                    <el-input v-model="scope.row.greaterThanDistancePrice" />
                  </el-form-item>
                </template>
              </el-table-column>
              <!--0-31KM超出重量加价（每KG）-->
              <el-table-column :label="'0-31KM' + $t('basicData.ExcessWeightFareIncrease')" min-width="200">
                <template slot="header">
                  <span>{{ '0-31KM' + $t('basicData.ExcessWeightFareIncrease') }}</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.overWeightExtraPrice'"
                    :rules="{ required: false, suffix: 5, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                  >
                    <el-input v-model="scope.row.overWeightExtraPrice" />
                  </el-form-item>
                </template>
              </el-table-column>
              <!--'31-101KM超出重量加价（每KG）'-->
              <el-table-column :label="'31-101KM' + $t('basicData.ExcessWeightFareIncrease')" min-width="240">
                <template slot="header">
                  <span>{{ '31-101KM' + $t('basicData.ExcessWeightFareIncrease') }}</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.overKilometerExtraPrice'"
                    :rules="{ required: false, suffix: 5, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                  >
                    <el-input v-model="scope.row.overKilometerExtraPrice" />
                  </el-form-item>
                </template>
              </el-table-column>
              <!--'>=101KM超出重量加价（每KG）'-->
              <el-table-column :label="'>=101KM' + $t('basicData.ExcessWeightFareIncrease')" min-width="240">
                <template slot="header">
                  <span>{{ '>=101KM' + $t('basicData.ExcessWeightFareIncrease') }}</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.greaterThanKilometerExtraPrice'"
                    :rules="{ required: false, suffix: 5, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                  >
                    <el-input v-model="scope.row.greaterThanKilometerExtraPrice" />
                  </el-form-item>
                </template>
              </el-table-column>
              <!--操作-->
              <el-table-column :label="$t('basicData.operate')" width="120" fixed="right">
                <template slot-scope="scope">
                  <!--确定删除吗？-->
                  <el-popconfirm
                    :title="$t('basicData.AreYouSureYouWantToDelete')"
                    @confirm="handleDel(scope.$index, 'collectFeesList')"
                  >
                    <!--删除-->
                    <el-button slot="reference" type="danger" size="mini">{{ $t('basicData.Delete') }}</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </template>

      <!-- 运输（干/支线） -->
      <template v-if="formData.businessType === 5">
        <el-form
          ref="tableForm1"
          :model="formData"
          label-width="0"
          label-position="top"
          class="demo-ruleForm"
          size="mini"
        >
          <el-table :data="formData.collectFeesList" border>
            <!--费用类型-->
            <el-table-column :label="$t('basicData.ExpenseType')" min-width="160">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('basicData.ExpenseType') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.feeTypeCode'"
                    :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                  >
                    <el-select
                      v-model="scope.row.feeTypeCode"
                      :placeholder="$t('basicData.PleaseSelect')"
                      style="width: 100%;"
                      @change="chargeTypeChange(scope)"
                    >
                      <el-option
                        v-for="item in expenseTypeList"
                        :key="item.id"
                        :label="item.displayName + '(' + item.code + ')'"
                        :value="item.code"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--车型-->
            <el-table-column :label="$t('vehicle.modelLabel')" min-width="160">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('vehicle.modelLabel') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.vehicleType'"
                    :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                  >
                    <el-select
                      v-model="scope.row.vehicleType"
                      :placeholder="$t('basicData.PleaseSelect')"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in VEHICLE_MODEL"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--公里数开始KM（不包含）-->
            <el-table-column :label="$t('basicData.KilometersStartKM')" min-width="160">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('basicData.KilometersStartKM') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.startDistance'"
                    :rules="{
                      required: true,
                      negative: '',
                      suffix: 3,
                      negative1: 1,
                      package: scope.row.endDistance,
                      validator: priceType,
                      trigger: ['blur', 'change']
                    }"
                  >
                    <el-input v-model="scope.row.startDistance" />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--公里数结束KM（包含）-->
            <el-table-column :label="$t('basicData.MileageEndKM')" min-width="160">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('basicData.MileageEndKM') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'collectFeesList.' + scope.$index + '.endDistance'"
                  :rules="{
                    required: true,
                    negative: '',
                    suffix: 3,
                    negative1: 2,
                    package: scope.row.startDistance,
                    validator: priceType,
                    trigger: ['blur', 'change']
                  }"
                >
                  <el-input v-model="scope.row.endDistance" />
                </el-form-item>
              </template>
            </el-table-column>
            <!--价格-->
            <el-table-column :label="$t('basicData.Price')" min-width="160">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('basicData.Price') + '/KM' }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'collectFeesList.' + scope.$index + '.price'"
                  :rules="{
                    required: true,
                    negative: '',
                    suffix: 3,
                    negative1: 2,
                    validator: priceType,
                    trigger: ['blur', 'change']
                  }"
                >
                  <el-input v-model="scope.row.price" />
                </el-form-item>
              </template>
            </el-table-column>
            <!--最低价格-->
            <el-table-column :label="$t('basicData.保底价公里数')" min-width="160">
              <template slot="header">
                <div>
                  <span>{{ $t('basicData.保底价公里数') + 'KM' }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'collectFeesList.' + scope.$index + '.standardDistance'"
                  :rules="{
                    required: false,
                    negative: '',
                    suffix: 3,
                    negative1: 2,
                    validator: priceType,
                    trigger: ['blur', 'change']
                  }"
                >
                  <el-input v-model="scope.row.standardDistance" />
                </el-form-item>
              </template>
            </el-table-column>
            <!--操作-->
            <el-table-column :label="$t('basicData.operate')" width="120" fixed="right">
              <template slot-scope="scope">
                <!--确定删除吗？-->
                <el-popconfirm
                  :title="$t('basicData.AreYouSureYouWantToDelete')"
                  @confirm="handleDel(scope.$index, 'collectFeesList')"
                >
                  <!--删除-->
                  <el-button slot="reference" type="danger" size="mini">{{ $t('basicData.Delete') }}</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </template>

      <!-- 安俊小屋 -->
      <template v-if="formData.businessType === 1">
        <el-form
          ref="tableForm1"
          :model="formData"
          label-width="0"
          label-position="top"
          class="demo-ruleForm"
          size="mini"
        >
          <el-table :data="formData.collectFeesList" border>
            <!--费用类型-->
            <el-table-column :label="$t('basicData.ExpenseType')" min-width="180">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('basicData.ExpenseType') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.feeTypeCode'"
                    :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                  >
                    <el-select
                      v-model="scope.row.feeTypeCode"
                      :placeholder="$t('basicData.PleaseSelect')"
                      style="width: 100%;"
                      @change="chargeTypeChange(scope)"
                    >
                      <el-option
                        v-for="item in expenseTypeList"
                        :key="item.id"
                        :label="item.displayName + '(' + item.code + ')'"
                        :value="item.code"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--货量开始（票）(不包含)-->
            <el-table-column :label="$t('basicData.QuantityStartTicketNotIncluded')" min-width="180">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('basicData.QuantityStartTicketNotIncluded') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.startOfGoodsQuantity'"
                    :rules="{
                      required: true,
                      suffix: '',
                      negative: '',
                      negative1: 1,
                      package: scope.row.endOfGoodsQuantity,
                      validator: Integer0,
                      trigger: ['blur', 'change']
                    }"
                  >
                    <el-input v-model="scope.row.startOfGoodsQuantity" />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--货量结束（票）(包含)-->
            <el-table-column :label="$t('basicData.EndOfVolumeTicketIncluded')" min-width="180">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('basicData.EndOfVolumeTicketIncluded') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.endOfGoodsQuantity'"
                    :rules="{
                      required: true,
                      suffix: '',
                      negative: '',
                      negative1: 2,
                      package: scope.row.startOfGoodsQuantity,
                      validator: Integer0,
                      trigger: ['blur', 'change']
                    }"
                  >
                    <el-input v-model="scope.row.endOfGoodsQuantity" />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--揽收价格-->
            <el-table-column :label="$t('basicData.PickUpPrice')" min-width="180">
              <template slot="header">
                <div>
                  <span style="color: red;">*</span>
                  <span>{{ $t('basicData.PickUpPrice') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-form-item
                    :prop="'collectFeesList.' + scope.$index + '.price'"
                    :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                  >
                    <el-input v-model="scope.row.price" />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--操作-->
            <el-table-column :label="$t('basicData.operate')" width="120" fixed="right">
              <template slot-scope="scope">
                <!--确定删除吗？-->
                <el-popconfirm
                  :title="$t('basicData.AreYouSureYouWantToDelete')"
                  @confirm="handleDel(scope.$index, 'collectFeesList')"
                >
                  <!--删除-->
                  <el-button slot="reference" type="danger" size="mini">{{ $t('basicData.Delete') }}</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </template>
    </div>

    <!-- 操作区 -->
    <div class="addFooter">
      <!--取消-->
      <el-button size="small" @click="handleUpdatePage(false)">{{ $t('basicData.Cancel') }}</el-button>
      <!--保存-->
      <el-button
        v-permit:remove="'btn:payable:quoteManagement:addEdit'" size="small" type="primary"
        @click="submitForm"
      >{{ $t('basicData.Save') }}</el-button>
    </div>

    <!--保存提示-->
    <el-dialog
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="400"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color: red;margin-bottom: 8px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--确 定-->
        <el-button type="primary" @click="dialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiGetAffiliateById, apiGetPayableOfferPriceById, apiPartitionPage, feePage } from '@/api/finance/partition';
import {
  apiListByOfferPriceId,
  apiListByPayableOfferPriceId,
  apiPayableOfferPriceDetailInsert,
  apiPayableOfferPriceDetailUpdate,
  apiProviderPage,
  apiSiteQuery
} from '@/api/finance/basicData';
import { cloneDeep } from 'lodash-es';
import { BUSINESS_TYPE_TWO, Transport_VEHICLE_MODEL } from '@/views/finance/utils.js';

export default {
  components: {
    'import-download': () => import('./ImportDownload'),
    'AJDAddCmp': () => import('./AJDCmp/AJDAddCmp.vue'),
    'CollectionForwardingCentreHomeDeliveryCmp': () => import('./CollectionForwardingCentreHomeDeliveryCmp/index.vue'),
    CollectionAndDoorToDoorCollectionAndThirdPartyCmp: () => import('./CollectionAndDoorToDoorCollectionAndThirdPartyCmp/index.vue')
  },
  props: {
    // 类型标识 1新增 2查看 3 编辑
    operationType: {
      type: Number,
      default: 1
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    // 正负保留小数点
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            this.adopt = true;
            if (rule.package !== null && rule.package !== '' && rule.package !== undefined) {
              if (rule.negative1 === 2) {
                if (parseFloat(value) <= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
                } else if (parseFloat(value) <= 0) {
                  return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
                }
              } else {
                if (parseFloat(value) >= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
                } else {
                  return callback();
                }
              }
            }
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            this.adopt = true;
            if (rule.negative1 === 2) {
              if (parseFloat(value) <= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
              } else if (parseFloat(value) <= 0) {
                return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
              }
            } else {
              if (parseFloat(value) >= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
              } else {
                return callback();
              }
            }
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    // 大于0的正整数
    const Integer0 = (rule, value, callback) => {
      let reg;
      if (rule.negative === 1) {
        reg = /(^[1-9]\d*$)/;
      } else {
        reg = /(^[0-9]\d*$)/;
      }
      if (rule.required) {
        if (value !== null && value !== '' && value !== undefined) {
          if (reg.test(value) === false) {
            if (rule.negative === 1) {
              return callback(new Error(this.$i18n.t('basicData.MustPositiveIntegerGreaterThan0'))); // '必须是大于0的正整数'
            } else {
              return callback(new Error(this.$i18n.t('basicData.MustPositiveIntegerGreaterThanOrEqual0'))); // '必须是大于或等于0的正整数'
            }
          } else {
            if (rule.package !== null && rule.package !== '' && rule.package !== undefined) {
              if (rule.negative1 === 2) {
                if (parseFloat(value) <= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
                } else {
                  return callback();
                }
              } else {
                if (parseFloat(value) >= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
                } else {
                  return callback();
                }
              }
            }
            return callback();
          }
        } else {
          return callback(new Error(this.$i18n.t('basicData.CannotBeEmpty'))); // '不能为空'
        }
      } else {
        if (value !== null && value !== '' && value !== undefined) {
          if (reg.test(value) === false) {
            if (rule.negative === 1) {
              return callback(new Error(this.$i18n.t('basicData.MustPositiveIntegerGreaterThan0'))); // '必须是大于0的正整数'
            } else {
              return callback(new Error(this.$i18n.t('basicData.MustPositiveIntegerGreaterThanOrEqual0'))); // '必须是大于或等于0的正整数'
            }
          } else {
            return callback();
          }
        } else {
          return callback();
        }
      }
    };
    // 有效期校验
    const checkBeginTime = (rule, value, callback) => {
      if (this.operationType === 2 && this.editData && this.editData.offerPriceStatus === 1) {
        callback();
      }
      if (!value) {
        return callback(new Error(this.$i18n.t('basicData.CannotBeEmpty'))); // '不能为空'
      }
      // const valTime = date2TimeStamp(value);
      // const nowTime = Date.now();
      // if (valTime <= nowTime) {
      //   return callback(new Error(this.$i18n.t('basicData.CannotBeLessThanTheCurrentDate'))); // 日期不能小于当前日期
      // }
      callback();
    };
    return {
      rules: {
        name: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          // '长度在 2 到 50 个字符'
          { min: 2, max: 50, message: this.$i18n.t('basicData.TheLengthBetween2And50Characters'), trigger: ['blur', 'change'] }
        ],
        startOfValidityPeriod: [
          { required: !(this.operationType === 2 && this.editData && this.editData.offerPriceStatus === 1), validator: checkBeginTime, trigger: ['blur', 'change'] }
        ],
        businessType: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        partitionId: [
          { required: false, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        payableEntityId: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        siteIds: [
          { required: false, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ]
      },
      errData: [],
      dialogVisible: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      businessPrincipalList: [], // 应付主体下拉数据
      outletsList: [], // 网点下拉数据
      partitionSchemeList: [], // 分区下拉
      subregionList: [], // 分区明细数据
      expenseTypeList: [], // 费用类型下拉数据
      // 基础数据
      formData: {
        name: '', // 报价名称
        startOfValidityPeriod: '', // 开始时间
        businessType: '', // 业务类型
        collectType: '', // 揽收类型
        partitionId: '', // 分区id,
        partitionName: '', // 分区名称
        payableEntityId: '', // 应付主体
        payableEntityName: '',
        siteIds: [], // 网点id
        siteName: '', // 网点名称,
        settlementNode: '', // 结算节点
        quotationMode: '', // 报价模式
        partitionAffiliateId: '', // 分区关联明细
        collectFeesList: [], // 报价费用列表
        feeTypeList: [] // 其他规则列表
      },
      // 下载导入模板
      tableDataObj: {
        collectFeesList: {},
        feeTypeList: {}
      },
      tableDataArr: {
        collectFeesList: [],
        feeTypeList: []
      },
      Integer0,
      priceType,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 3600 * 1000; // 不能选择生效日期在当前服务时间之前
        }
      },
      exportKey: new Date().getTime(),
      idCustomerList: [],
      selectFeeTypeCode: [],
    };
  },

  computed: {
    // 业务类型AJD派送 显示此组件
    isBusinessType3AndOperationMode1() {
      const { businessType } = this.formData;
      if (businessType === 3) {
        return true;
      }
      return false;
    },

    /**
     * 功能： 第三方揽收（业务类型） + 上门交件（揽收方式），需要同时满足
     */
    isCollectionForwardingCentreHomeDeliveryCmp() {
      const { businessType, collectType } = this.formData;
      if (businessType === 2 && collectType === 1) {
        return true;
      }
      return false;
    },

    /**
     * 功能： 第三方揽收 （业务类型） + 上门揽收（揽收方式），需要同时满足
     *    条件1：业务类型businessType === 第三方揽收
     *    条件2：揽收方式collectType === 上门揽收
     * @returns {boolean} 符合条件返回true
     */
    isCollectionAndDoorToDoorCollectionAndThirdParty() {
      const { businessType, collectType } = this.formData;
      if (businessType === 2 && collectType === 2) {
        return true;
      }
      return false;
    },
  },

  watch: {
    'formData.partitionAffiliateId'(val) {
      if (val) {
        this.tableDataObj.collectFeesList[val] = this.tableDataObj.collectFeesList[val] || [];
        this.tableDataObj.feeTypeList[val] = this.tableDataObj.feeTypeList[val] || [];
        this.formData['collectFeesList'] = this.tableDataObj.collectFeesList[val];
        this.formData['feeTypeList'] = this.tableDataObj.feeTypeList[val];
      } else {
        this.tableDataArr.collectFeesList = this.tableDataArr.collectFeesList || [];
        this.tableDataArr.feeTypeList = this.tableDataArr.feeTypeList || [];
        this.formData['collectFeesList'] = this.tableDataArr.collectFeesList;
        this.formData['feeTypeList'] = this.tableDataArr.feeTypeList;
      }
    },
    'partitionSchemeList': {
      handler() {
        this.formData.partitionName = this.partitionSchemeList.find(item => item.id === this.formData.partitionId)?.name;
      },
      deep: true
    },

    // 监听业务类型变化，揽收业务-主体非必填
    'formData.businessType'(val) {
      console.log(val, '业务类型');
      // 业务类型为第三方揽收无需校验主体
      if (val === 2) {
        this.rules.payableEntityId[0].required = false
      } else {
        this.rules.payableEntityId[0].required = true;
      }
    }
  },

  created() {
    this.BUSINESS_TYPE_TWO = BUSINESS_TYPE_TWO;
    this.VEHICLE_MODEL = Transport_VEHICLE_MODEL;

    // 获取应付主体选项
    this.getProviderPage();

    // 获取分区方案
    this.getSubregionData();

    const edit = cloneDeep(this.editData);
    if (edit.id) {
      // 基础数据详情
      this.getBasicDataQuotation();
    }

    // 获取费用类型
    this.getExpenseTypeList();
  },
  methods: {
    /**
     * 获取报价编辑详情
     * @param type
     */
    // 获取报价详情基础数据
    getBasicDataQuotation() {
      const ids = this.editData.id;
      apiGetPayableOfferPriceById(ids).then(res => {
        if (res.code === 200) {
          this.formData = {
            name: res.data.name, // 报价名称
            businessType: res.data.businessType, // 业务类型 1 安俊 2 第三方 3 AJD 4 DSP 5 运输干/支线
            collectType: res.data.collectType, // 揽收方式 1：上门交件 2：上门揽收
            payableEntityName: res.data.payableEntityName, // 应付主体名称
            payableEntityId: res.data.payableEntityId ? parseInt(res.data.payableEntityId) : '', // 应付主体
            siteIds: res.data.siteId, // 网点id
            siteName: res.data.siteName, // 网点名称,
            partitionId: res.data.partitionId, // 分区id,
            partitionName: res.data.partitionName, // 分区名称
            settlementNode: res.data.settlementNode, // 结算节点
            quotationMode: res.data.quotationMode, // 报价模式 1:报底价，2:区间取最高，3直乘
            startOfValidityPeriod: res.data.startOfValidityPeriod ? res.data.startOfValidityPeriod.replace('T', ' ') : '', // 开始时间
            partitionAffiliateId: res.data.partitionAffiliateId, // 分区明细id
            collectFeesList: [],
            feeTypeList: [] // 其他规则列表
          };

          // 报价价格详情
          this.asyncGetAffiliateById(ids);

          // 通过分区ID查明细
          this.getOtherFeeDetail();

          // 根据业务类型查询费用类型
          this.getExpenseTypeList();

          // 通过主体ID查询网点
          if (this.formData.payableEntityId) {
            // 获取站点
            this.getSiteQuery(this.formData.payableEntityId);
          }
          this.exportKey = new Date().getTime();
        }
      });
    },

    /**
     * 通过报价id获取价格详情
     * @param ids 报价id
     */
    asyncGetAffiliateById(ids) {
      // 通过分区ID查明细
      // this.getOtherFeeDetail();
      apiListByPayableOfferPriceId(ids, false).then(res => {
        // console.log(this.formData, 'this.formData');
        // console.log('价格详情', res);

        if (res.code !== 200) {
          return;
        }

        const { data } = res;
        data.forEach((item) => {
          if (item.vehicleType) {
            item.vehicleType = this.formData.businessType === 5 ? Number(item.vehicleType) : item.vehicleType;
          }
        });

        // 当前是 AJD派送
        if (this.isBusinessType3AndOperationMode1) {
          this.$refs.ajdAddRef.setData(data || []);
          return;
        }

        // 当前是 揽收 + 上门揽收
        if (this.isCollectionAndDoorToDoorCollectionAndThirdParty) {
          this.$refs.collectionAndDoorToDoorCollectionAndThirdPartyRef.setData(data || []);
          return;
        }

        // 选择了 揽收（业务类型 + 上门交件（揽收方式）
        if (this.isCollectionForwardingCentreHomeDeliveryCmp) {
          this.$refs.collectionForwardingCentreHomeDeliveryRef.setData(data || []);
          return;
        }

        /**
         * 安俊小屋、DSP派送
         */
        if (data && data.length > 0) {
          console.log('安俊小屋、DSP派送', data, this.formData);
          const partitionedData = {};
          const unpartitionedData = [];

          data.forEach((item) => {
            if (item.partitionAffiliateId) {
              // 如果存在分区ID，则按分区ID分类存放
              if (!partitionedData[item.partitionAffiliateId]) {
                partitionedData[item.partitionAffiliateId] = [];
              }
              partitionedData[item.partitionAffiliateId].push(item);
            } else {
              // 如果不存在分区ID，则存放到未分类数组中
              unpartitionedData.push(item);
            }
          });

          // 更新原对象或数组
          this.tableDataObj.collectFeesList = partitionedData;
          this.tableDataArr.collectFeesList = unpartitionedData;
        }

        if (this.formData.partitionAffiliateId) {
          this.formData['collectFeesList'] = this.tableDataObj.collectFeesList[this.formData.partitionAffiliateId] || [];
        } else {
          this.formData['collectFeesList'] = this.tableDataArr.collectFeesList || [];
        }
        this.exportKey = new Date().getTime();

        // if (data && data.length > 0) {
        //   data.map((item) => {
        //     if (item.partitionAffiliateId) {
        //       this.tableDataObj.collectFeesList[item.partitionAffiliateId] = this.tableDataObj.collectFeesList[item.partitionAffiliateId] || [];
        //       this.tableDataObj.collectFeesList[item.partitionAffiliateId].push(item);
        //     } else {
        //       this.tableDataArr.collectFeesList = this.tableDataArr.collectFeesList || [];
        //       this.tableDataArr.collectFeesList.push(item);
        //     }
        //   });
        // }
        // if (this.formData.partitionAffiliateId) {
        //   this.formData['collectFeesList'] = this.tableDataObj.collectFeesList[this.formData.partitionAffiliateId] || [];
        // } else {
        //   this.formData['collectFeesList'] = this.tableDataArr.collectFeesList || [];
        // }
        // this.exportKey = new Date().getTime();
      });
      // 获取其他规则详情
      apiListByOfferPriceId(ids).then(res => {
        const { code, data } = res;
        if (code !== 200) {
          return;
        }
        if (data && data.length > 0) {
          data.map((item) => {
            if (item.partitionAffiliateId) {
              this.tableDataObj.feeTypeList[item.partitionAffiliateId] = this.tableDataObj.feeTypeList[item.partitionAffiliateId] || [];
              this.tableDataObj.feeTypeList[item.partitionAffiliateId].push(item);
            } else {
              this.tableDataArr.feeTypeList = this.tableDataArr.feeTypeList || [];
              this.tableDataArr.feeTypeList.push(item);
            }
          });
        }
        if (this.formData.partitionAffiliateId) {
          this.formData['feeTypeList'] = this.tableDataObj.feeTypeList[this.formData.partitionAffiliateId] || [];
        } else {
          this.formData['feeTypeList'] = this.tableDataArr.feeTypeList || [];
        }
        this.exportKey = new Date().getTime();
      });
    },

    // 清空普通报价详情
    clearOffers(type) {
      // type 1:清空当前分区 2:清空所有分区
      if (type === 1) {
        this.formData['collectFeesList'] = [];
        if (this.subregionList && this.subregionList.length > 0) {
          this.tableDataObj.collectFeesList[this.formData.partitionAffiliateId] = [];
        } else {
          this.tableDataArr.collectFeesList = [];
        }
      } else {
        this.formData['collectFeesList'] = [];
        if (this.subregionList && this.subregionList.length > 0) {
          this.subregionList.map(item => {
            this.tableDataObj.collectFeesList[item.id] = [];
          });
        } else {
          this.tableDataArr.collectFeesList = [];
        }
      }
    },
    // 揽收方式变化
    collectTypeChange() {
      this.formData['collectFeesList'] = [];
      this.formData['feeTypeList'] = [];
      if (this.formData.partitionId) {
        this.tableDataObj = {
          collectFeesList: {},
          feeTypeList: {}
        };
      } else {
        this.tableDataArr = {
          collectFeesList: [],
          feeTypeList: []
        };
      }
      this.exportKey = new Date().getTime();
    },
    // 获取已选费用类型code
    disabledFeeTyp() {
      this.selectFeeTypeCode = [];
      this.formData.collectFeesList.map(item => {
        this.selectFeeTypeCode.push(item.feeTypeCode);
      });
    },

    // 计费类型变化判断同一费用类型是否存在多种计费类型
    chargeTypeChange(scope) {
      this.formData.collectFeesList.map((item, ind) => {
        if (ind !== scope.$index) {
          if (item.feeTypeCode && (item.feeTypeCode === scope.row.feeTypeCode)) {
            if (item.billingType && (item.billingType !== scope.row.billingType)) {
              this.$message.warning(this.$t('basicData.ThereCantBeDifferentBillingTypesForTheSameChargeType')); // '同一费用类型不能存在相同计费类型'
              this.formData.collectFeesList[scope.$index].billingType = '';
            }
          }
        }
      });
    },
    // 获取费用类型
    getExpenseTypeList() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          state: 1,
          attribute: 2,
          businessType: this.formData.businessType
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.expenseTypeList = res.data.records;
          this.exportKey = new Date().getTime();
        }
      });
    },
    // 获取分区方案详情
    getOfferAffiliateList(id) {
      apiGetAffiliateById(id, false).then(res => {
        if (res.code === 200) {
          this.subregionList = res.data.partitionAffiliateList || [];
          this.formData.partitionAffiliateId = this.subregionList[0].id;
        }
      });
    },
    // 站点变化
    siteIdChange(val) {
      if (val) {
        this.outletsList.map(item => {
          if (val === item.id) {
            this.formData.siteName = item.name;
          }
        });
      } else {
        this.formData.siteName = '';
      }
    },
    // 报价类型变化
    offerTypeChange() {
      this.formData.siteIds = [];
      this.formData.siteName = '';
      this.formData.payableEntityId = '';
      this.formData.payableEntityName = '';
    },
    /**
     * 应付主体变化同步清除网点数据
     * @param val
     */
    providerInfoChange(val) {
      this.formData.siteIds = [];
      this.formData.siteName = '';
      this.outletsList = [];

      if (!val) {
        this.formData.payableEntityName = '';
      } else {
        this.businessPrincipalList.map(item => {
          if (item.id === val) {
            this.formData.payableEntityName = item.name;
          }
        });

        this.getSiteQuery(val);
      }
    },
    /**
     * 获取应付主体下拉数据
     */
    getProviderPage() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          const dataList = res.data.records;
          // 1.判断是否是新增的主体
          if (this.operationType !== 1) {
            // 判断主体是否存在，避免出现错误主体
            const list = dataList.filter(item => item.id === this.editData.payableEntityId);
            if (list.length === 0) {
              const obj = {
                providerName: this.editData.payableEntityName,
                id: this.editData.payableEntityId
              };
              dataList.push(obj);
            }
          }

          this.businessPrincipalList = dataList;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * 获取网点下拉数据
     * @params id 主体id
     */
    getSiteQuery(id) {
      apiSiteQuery(id, false).then(res => {
        if (res.status === 'OK') {
          this.outletsList = res.data;
        }
      });
    },
    /**
     * 业务类型变化 触发更新分区及费用类型数据、结算节点
     * @param data
     */
    changeBusinessType(data) {
      this.formData.collectType = ''; // 清空揽收方式
      this.formData.partitionId = ''; // 清空分区
      this.formData.partitionName = '';
      this.formData.settlementNode = ''; // 清空结算节点
      this.formData.quotationMode = ''; // 清空报价模式
      this.formData['collectFeesList'] = [];

      // 获取分区下拉
      // this.getSubregionData();

      if ([1, 4, 5].includes(this.formData.businessType)) {
        this.formData['feeTypeList'] = [];
        // 获取费用类型
        this.getExpenseTypeList();
      }

      if (this.formData.partitionId) {
        this.tableDataObj = {
          collectFeesList: {},
          feeTypeList: {}
        };
      } else {
        this.tableDataArr = {
          collectFeesList: [],
          feeTypeList: []
        };
      }

      this.exportKey = new Date().getTime();
    },
    /**
     * 切换分区重置报价
     * 获取分区详情
     */
    getOtherFeeDetail() {
      // 清空分区明细
      this.formData.partitionAffiliateId = '';
      this.formData['collectFeesList'] = [];
      this.formData['feeTypeList'] = [];

      if (this.formData.partitionId) {
        this.getOfferAffiliateList(this.formData.partitionId);
        this.tableDataObj = {
          collectFeesList: {},
          feeTypeList: {}
        };
      } else {
        this.formData.partitionName = '';
        this.tableDataArr = {
          collectFeesList: [],
          feeTypeList: []
        };
      }

      if (this.subregionList && this.subregionList.length > 0) {
        this.subregionList.map(item => {
          this.tableDataObj.collectFeesList[item.id] = [];
          this.tableDataObj.feeTypeList[item.id] = [];
        });
      } else {
        this.tableDataArr.collectFeesList = [];
        this.tableDataArr.feeTypeList = [];
      }

      // const partitionItem = this.partitionSchemeList.find(item => item.id === this.formData.partitionId);
      // console.log(partitionItem, this.partitionSchemeList);
      // if (partitionItem) {
      //   this.formData.partitionName = partitionItem.name;
      //   // 分区ID查询分区明细
      //   console.log(this.formData.partitionId, '分区ID');
      //   this.getOfferAffiliateList(this.formData.partitionId);
      //   // 初始化tableDataObj，仅在找到分区时
      //   this.tableDataObj = {
      //     collectFeesList: {},
      //     feeTypeList: {}
      //   };
      // } else {
      //   this.formData.partitionName = '';
      //   this.tableDataArr = {
      //     collectFeesList: [],
      //     feeTypeList: []
      //   };
      // }

      // if (this.subregionList && this.subregionList.length > 0) {
      //   this.subregionList.map(item => {
      //     this.tableDataObj.collectFeesList[item.id] = [];
      //     this.tableDataObj.feeTypeList[item.id] = [];
      //   });
      // } else {
      //   this.tableDataArr.collectFeesList = [];
      //   this.tableDataArr.feeTypeList = [];
      // }
    },

    /**
     * 分区下拉数据
     */
    getSubregionData() {
      const param = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          attribute: 2,
          state: 1
          // businessType: this.formData.businessType
        }
      };
      apiPartitionPage(param).then(res => {
        const { code, data } = res;
        if (code !== 200) {
          return;
        }
        this.partitionSchemeList = data.records;
      }).catch(err => {
        console.error(err);
      });
    },
    // 增加报价
    handleAdd() {
      const obj = {
        partitionAffiliateId: this.formData.partitionAffiliateId, // 分区
        feeTypeCode: '', // 费用类型
        billingType: '', // 计费类型，1:重量，2:票数
        collectType: this.formData.collectType, // 揽收类型 1：上门交件 2：上门揽收
        startOfGoodsQuantity: '', // 货量开始（票）
        endOfGoodsQuantity: '', // 货量结束（票）
        price: '', // 价格
        startWeight: '', // 开始重量
        endWeight: '', // 结束重量
        overWeightExtraPrice: '', // 超重加价
        innerDistancePrice: '', // 0-30KM价格
        merchantType: '', // 商家类型
        vehicleType: '', // 车型
        standardCargo: '', // 标准货量
        royaltyChargeType: '', // 价格费用类型
        standardPrice: '', // 标准价格
        overshipmentPrice: '', // 超重价格
        outerDistancePrice: '', // 31-101KM价格
        greaterThanDistancePrice: '', // >=100KM价格
        overKilometerExtraPrice: '', // 超出公里加价（每KM）
        greaterThanKilometerExtraPrice: '' //  >=100千米超出重量加价（每KG）
      };
      this.formData['collectFeesList'].push(obj);
      if (this.formData.partitionAffiliateId) {
        this.tableDataObj.collectFeesList[this.formData.partitionAffiliateId] = this.formData['collectFeesList'];
      } else {
        this.tableDataArr.collectFeesList = this.formData['collectFeesList'];
      }
      this.exportKey = new Date().getTime();
    },
    // 增加其他规则
    handleAdd1() {
      const obj = {
        feeTypeCode: '', // '费用类型',
        taxRateOne: '', // 税率1
        taxRate: '', // 税率
        price: '', // 价格
        partitionAffiliateId: this.formData.partitionAffiliateId // 分区
      };
      this.formData['feeTypeList'].push(obj);
      if (this.formData.partitionAffiliateId) {
        this.tableDataObj.feeTypeList[this.formData.partitionAffiliateId] = this.formData['feeTypeList'];
      } else {
        this.tableDataArr.feeTypeList = this.formData['feeTypeList'];
      }
      this.exportKey = new Date().getTime();
    },
    // 导入价格详情
    importPartition(arr) {
      if (this.subregionList && this.subregionList.length > 0) {
        this.subregionList.map(item => {
          this.tableDataObj.collectFeesList[item.id] = this.tableDataObj.collectFeesList[item.id] || [];
        });
      } else {
        this.tableDataArr.collectFeesList = this.tableDataArr.collectFeesList || [];
      }
      for (let i = 0; i < arr.length; i++) {
        try {
          if (arr[i].partitionAffiliateId) {
            this.tableDataObj.collectFeesList[arr[i].partitionAffiliateId] = []; // .push(arr[i]);
          } else {
            this.tableDataArr.collectFeesList = []; // .push(arr[i]);
          }
        } catch (err) {
          this.$message.warning(this.$t('basicData.templatePartition'));
          break;
        }
      }

      setTimeout(() => {
        for (let i = 0; i < arr.length; i++) {
          try {
            if (arr[i].partitionAffiliateId) {
              this.tableDataObj.collectFeesList[arr[i].partitionAffiliateId].push(arr[i]);
            } else {
              this.tableDataArr.collectFeesList.push(arr[i]);
            }
          } catch (err) {
            this.$message.warning(this.$t('basicData.templatePartition'));
            break;
          }
        }
        if (this.formData.partitionAffiliateId) {
          this.formData['collectFeesList'] = this.tableDataObj.collectFeesList[this.formData.partitionAffiliateId] || [];
        } else {
          this.formData['collectFeesList'] = this.tableDataArr.collectFeesList || [];
        }
        this.exportKey = new Date().getTime();
      }, 500);
    },
    // 删除分区
    handleDel(index, type) {
      this.formData[type].splice(index, 1);
      if (this.formData.partitionAffiliateId) {
        this.tableDataObj[type][this.formData.partitionAffiliateId] = this.formData[type];
      } else {
        this.tableDataArr[type] = this.formData[type];
      }
      this.exportKey = new Date().getTime();
    },
    // 取消
    handleUpdatePage(type) {
      this.$emit('saveData', type);
    },

    // 校验列表数据
    async verificationTable() {
      let tableForm1 = false;

      // 如果选择了 揽收+上门揽收
      if (this.isCollectionAndDoorToDoorCollectionAndThirdParty) {
        // 直接调用子级的校验方法
        const isBool = await this.$refs.collectionAndDoorToDoorCollectionAndThirdPartyRef.submitForm();
        return isBool;
      }

      // 如果选择了 派送+AJD
      if (this.isBusinessType3AndOperationMode1) {
        // 直接调用子级的校验方法
        const isBool = await this.$refs.ajdAddRef.submitForm();
        return isBool;
      }

      // 选择了 揽收（业务类型） + 上门交件（揽收方式）
      if (this.isCollectionForwardingCentreHomeDeliveryCmp) {
        const isBool = await this.$refs.collectionForwardingCentreHomeDeliveryRef.submitForm();
        return isBool;
      }

      this.$refs.tableForm1.validate((tabRules) => {
        tableForm1 = tabRules;
      });

      return tableForm1;
    },
    // 保存
    submitForm() {
      this.errData = [];
      this.$refs.queryForm.validate(async(valid) => {
        if (!valid) {
          return false;
        }
        // 校验列表
        {
          const isBool = await this.verificationTable();
          // console.log(isBool, 'isBool');

          if (!isBool) {
            return false;
          }
        }

        // 网点字段处理
        let siteIds = '';

        if (this.editData.id) {
          const { siteIds: site } = this.formData;

          // 类型是 数组
          if (Array.isArray(site)) {
            siteIds = site.join(',');
          } else {
            siteIds = site;
          }
        } else {
          // 新增
          siteIds = this.formData.siteIds.join(',');
        }

        const param = {
          name: this.formData.name, // 报价方案名称
          payableEntityId: this.formData.payableEntityId, // 应付主体(应付账户id)
          partitionId: this.formData.partitionId, // 关联的分区ID
          siteIds, // 网点id
          businessType: this.formData.businessType, // 业务类型
          collectType: this.formData.collectType, // 揽收类型 1：上门交件 2：上门揽收
          startOfValidityPeriod: this.formData.startOfValidityPeriod, // 有效期开始时间
          payableEntityName: this.formData.payableEntityName,
          siteName: this.formData.siteName,
          partitionName: this.formData.partitionName,
          settlementNode: this.formData.settlementNode, // 结算节点
          quotationMode: this.formData.quotationMode,
          detailList: [],
          otherRulesList: []
        };

        // 选择了 第三方揽收（业务类型） + 上门交件（揽收方式）
        if (this.isCollectionForwardingCentreHomeDeliveryCmp) {
          const arr = this.$refs.collectionForwardingCentreHomeDeliveryRef.getData();
          this.asyncAddAndUpdate(param, arr);
          return;
        }

        // 选择了 第三方揽收（业务类型） + 上门揽收（揽收方式）
        if (this.isCollectionAndDoorToDoorCollectionAndThirdParty) {
          const arr = this.$refs.collectionAndDoorToDoorCollectionAndThirdPartyRef.getData();
          this.asyncAddAndUpdate(param, arr);
          return;
        }

        // 选择了 派送+AJD
        if (this.isBusinessType3AndOperationMode1) {
          const arr = this.$refs.ajdAddRef.getData();
          this.asyncAddAndUpdate(param, arr);
          return;
        }

        if (this.formData.partitionAffiliateId) {
          for (const k in this.tableDataObj.collectFeesList) {
            if (this.tableDataObj.collectFeesList[k] && this.tableDataObj.collectFeesList[k].length > 0) {
              this.tableDataObj.collectFeesList[k].map((item) => {
                param.detailList.push(item);
              });
            }
          }
          for (const k in this.tableDataObj.feeTypeList) {
            if (this.tableDataObj.feeTypeList[k] && this.tableDataObj.feeTypeList[k].length > 0) {
              this.tableDataObj.feeTypeList[k].map((item) => {
                param.otherRulesList.push(item);
              });
            }
          }
        } else {
          this.tableDataArr.collectFeesList.map((item) => {
            param.detailList.push(item);
          });

          this.tableDataArr.feeTypeList.map((item) => {
            param.otherRulesList.push(item);
          });
        }
        // <!--运输干支线费用校验-->
        if (this.formData.businessType === 5) {
          const hasIntersection = (interval1, interval2) => {
            // 排除上一个区间的最大值等于下一个区间的最小值的情况
            if (Number(interval1[1]) === Number(interval2[0]) || Number(interval2[1]) === Number(interval1[0])) {
              return false;
            }
            return Math.max(Number(interval1[0]), Number(interval2[0])) <= Math.min(Number(interval1[1]), Number(interval2[1]));
          };
          const findIntersectingIntervals = (intervals) => {
            const result = [];
            for (let i = 0; i < intervals.length; i++) {
              for (let j = i + 1; j < intervals.length; j++) {
                if (hasIntersection(intervals[i], intervals[j])) {
                  result.push([intervals[i], intervals[j]]);
                }
              }
            }
            return result;
          };
          if (param.detailList.length > 0) {
            const section = {};
            param.detailList.map(item => {
              section[item.feeTypeCode + item.vehicleType] = {
                section: [],
                min: []
              };
            });
            param.detailList.map(item => {
              section[item.feeTypeCode + item.vehicleType].section.push([item.startDistance, item.endDistance]);
              if (item.standardDistance) {
                section[item.feeTypeCode + item.vehicleType].min.push(item.standardDistance);
              }
            });
            let ifResult = false;
            for (const key in section) {
              const result = findIntersectingIntervals(section[key].section);
              console.log(result, '====result');
              if (result.length > 0) {
                ifResult = true;
                this.$message.warning(this.$t('basicData.TheRangeOfKilometersCannotOverlap'));
                break;
              }
              if (section[key].min.length > 1) {
                ifResult = true;
                this.$message.warning(this.$t('basicData.TheMinimumMileageCanOnlyBeFilledInOne'));
                break;
              }
              if (section[key].min.length === 0) {
                if (this.formData.quotationMode === 1) {
                  ifResult = true;
                  this.$message.warning(this.$t('basicData.AtLeastOneMinimumMileageMustBeFilledIn'));
                  break;
                }
              }
            }
            if (ifResult) {
              return;
            }
          }
        }
        if (this.errData.length > 0) {
          this.dialogVisible = true;
          return;
        }
        // 费用类型取值code
        if (param?.detailList?.length > 0) {
          param.detailList.forEach(item => {
            // 判断feeTypeCode字段是否存在，并且有值
            if (item.feeTypeCode) {
              // 取字符串->后面的字符
              if (item.feeTypeCode && item.feeTypeCode.includes('->')) {
                item.feeTypeCode = item.feeTypeCode.split('->')[1];
              }
            }
          });
        }
        if (this.editData.id) {
          param.id = this.editData.id;
          apiPayableOfferPriceDetailUpdate(param).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('basicData.TheOperationSucceeded')); // 操作成功
              this.$emit('saveData', true);
            }
          });
        } else {
          apiPayableOfferPriceDetailInsert(param).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('basicData.TheOperationSucceeded'));
              this.$emit('saveData', true);
            }
          });
        }
      });
    },

    // AJD 保存
    // async ajdSubmitForm(query) {
    //   const param = {
    //     ...query,
    //     detailList: []
    //   };
    //   let res = {};

    //   // 获取列表数据
    //   param.detailList = this.$refs.ajdAddRef.getData();

    //   try {
    //     if (this.editData.id) {
    //       param.id = this.editData.id;
    //       // 编辑
    //       res = await apiPayableOfferPriceDetailUpdate(param);
    //     } else {
    //     // 新增
    //       res = await apiPayableOfferPriceDetailInsert(param);
    //     }

    //     if (res.status === 'OK') {
    //       this.$message.success(this.$t('basicData.TheOperationSucceeded'));
    //       this.$emit('saveData', true);
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   let res = {};
    // },

    // 揽收+上门揽收+第三方 保存
    // async thirdPartySubmitForm(query, arr) {
    //   const param = {
    //     ...query,
    //     detailList: arr
    //   };
    //   let res = {};
    //   // 获取列表数据
    //   // param.detailList = this.$refs.collectionAndDoorToDoorCollectionAndThirdPartyRef.getData();

    //   // try {
    //   //   if (this.editData.id) {
    //   //     param.id = this.editData.id;
    //   //     // 编辑
    //   //     res = await apiPayableOfferPriceDetailUpdate(param);
    //   //   } else {
    //   //   // 新增
    //   //     res = await apiPayableOfferPriceDetailInsert(param);
    //   //   }

    //   //   if (res.status === 'OK') {
    //   //     this.$message.success(this.$t('basicData.TheOperationSucceeded'));
    //   //     this.$emit('saveData', true);
    //   //   }
    //   // } catch (error) {
    //   //   console.error(error);
    //   // }
    // },

    // 提交保存
    async asyncAddAndUpdate(query, arr) {
      const param = {
        ...query,
        detailList: arr
      };
      let res = {};

      try {
        if (this.editData.id) {
          param.id = this.editData.id;
          // 编辑
          res = await apiPayableOfferPriceDetailUpdate(param);
        } else {
        // 新增
          res = await apiPayableOfferPriceDetailInsert(param);
        }

        if (res.code === 200) {
          this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          this.$emit('saveData', true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped>
.addFormBox {
  padding: 20px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}
.addFooter {
  border-top: 1px solid #ccc;
  text-align: right;
  padding: 10px;
}
.title {
  padding: 15px 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.demo-ruleForm .el-form-item {
  margin-bottom: 0;
}
.demo-ruleForm /deep/ .el-form-item__error {
  position: static !important;
}
</style>
<style>
.tableItemBtMg .el-form-item {
  margin-bottom: 18px;
}
/* .demo-ruleForm .el-form-item {
  margin-bottom: 0;
} */
</style>
