<template>
  <div>
    <el-card class="box-card">
      <div style="float:left;">
        <el-button
          icon="el-icon-upload2"
          size="mini"
          @click="exportData">
          {{ activeName === 'hz' ? $t('collectionCenter.SummaryExport') : $t('collectionCenter.明细导出') }}
        </el-button>
      </div>
      <div class="right">
        <div style="display: flex;justify-content: space-between;align-items: center">
          <div style="margin-right: 20px;display: flex;justify-content: space-between;align-items: center">
            <!-- 更新时间 -->
            <span v-show="updateTime">{{ $t('GlobalSetObj.UpdateTime') }}：{{ updateTime }}</span>
            <div class="dateBox">
              <span :class="{'d1': type == 0}" @click="get2Data(0)">{{ $t('collectionCenter.yesterday') }}</span><!-- 昨日 -->
              <span :class="{'d1': type == 1}" @click="get2Data(1)">{{ $t('collectionCenter.sameDay') }}</span><!-- 当天 -->
              <span :class="{'d1': type == 2}" @click="get2Data(2)">{{ $t('collectionCenter.nearlyAWeek') }}</span><!-- 近一周 -->
              <span :class="{'d1': type == 3}" @click="get2Data(3)">{{ $t('collectionCenter.sameMonth') }}</span><!-- 当月 -->
            </div>
          </div>
          <el-button
            type="primary" plain
            size="small"
            @click="closeWindow">
            {{ $t('collectionCenter.close') }}
          </el-button>
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane :label="tabsName" name="hz">
          <div>
            <el-table
              :data="tableData1"
              border
              show-summary
              :summary-method="getSummaries"
            >
              <el-table-column
                :label="$t('operationCenter.ind')"
                width="60px"
                align="center"
                fixed="left"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectCount"
                :label="$t('collectionCenter.TotalCollectionPackages')"
                align="center"
              ><!-- 揽收包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 1)">
                    {{ scope.row.collectCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="siteLoadCount"
                :label="$t('collectionCenter.TotalPackagesLoadedBranch')"
                align="center"
              ><!-- 网点装车包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 2)">
                    {{ scope.row.siteLoadCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="hubUnloadCount"
                :label="$t('collectionCenter.TotalUnloadedParcelsTransferCenter')"
                align="center"
              ><!-- 转运中心卸车包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 3)">
                    {{ scope.row.hubUnloadCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="hubLoadCount"
                :label="$t('collectionCenter.TotalPackagesLoadedTransferCenter')"
                align="center"
              ><!-- 转运中心装车包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 4)">
                    {{ scope.row.hubLoadCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="glpUnloadCount"
                :label="$t('collectionCenter.TotalUnloadedParcelsDestinationWarehouse')"
                align="center"
              ><!-- 揽收目的仓卸车包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 5)">
                    {{ scope.row.glpUnloadCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="signCount"
                :label="$t('collectionCenter.TotalSignedParcelsDestinationWarehouse')"
                align="center"
              ><!-- 目的仓签收包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 6)">
                    {{ scope.row.signCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="timeoutSignCount"
                :label="$t('collectionCenter.SigningTimeoutTotal')"
                align="center"
              ><!-- 目的仓签收超时包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 8)">
                    {{ scope.row.timeoutSignCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="closeCount"
                :label="$t('collectionCenter.TotalClosedPackages')"
                align="center"
              ><!-- 闭环包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 7)">
                    {{ scope.row.closeCount }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="activeName === 'mx'" :label="tabsName + $t('collectionCenter.packageDetail')" name="mx">
          <el-table
            :data="tableDataMX"
            border
            :max-height="mxHeight"
          >
            <el-table-column
              :label="$t('operationCenter.ind')"
              width="60px"
              align="center"
              fixed="left"
            >
              <template slot-scope="scope">
                <span>{{ (formMX.pageNumber - 1) * formMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
              min-width="200"
              align="center"
            /><!-- 条形码 -->
            <el-table-column
              prop="packageNumber"
              :label="$t('collectionCenter.parcelNum')"
              min-width="200"
              align="center"
            /><!-- 包裹号 -->
            <el-table-column
              prop="collectTime"
              :label="$t('collectionCenter.lanshouT')"
              min-width="180"
              align="center"
            /><!-- 揽收时间 -->
            <el-table-column
              prop="collectUserName"
              :label="$t('collectionCenter.LanShouPeople')"
              min-width="180"
              align="center"
            /><!-- 揽收人 -->
            <el-table-column
              prop="collectSiteName"
              :label="$t('collectionCenter.LanShouSite')"
              min-width="180"
              align="center"
            /><!-- 揽收网点 -->
            <el-table-column
              prop="siteLoadVehicleNumber"
              :label="$t('collectionCenter.BranchLoadingLicensePlate')"
              min-width="180"
              align="center"
            /><!-- 网点装车车牌 -->
            <el-table-column
              prop="siteLoadTime"
              :label="$t('collectionCenter.networkLoadingTime')"
              min-width="180"
              align="center"
            /><!-- 网点装车时间 -->
            <el-table-column
              prop="siteLoadUserName"
              :label="$t('collectionCenter.networkLoader')"
              min-width="180"
              align="center"
            /><!-- 网点装车人 -->
            <el-table-column
              prop="siteLoadSiteName"
              :label="$t('customerManagements.loadSiteIdList')"
              min-width="180"
              align="center"
            /><!-- 装车网点 -->
            <el-table-column
              prop="hubUnloadSiteName"
              :label="$t('collectionCenter.UnloadingTransferCenter')"
              min-width="180"
              align="center"
            /><!-- 卸车转运中心 -->
            <el-table-column
              prop="hubUnloadTime"
              :label="$t('collectionCenter.unloadingTransferCenterTime')"
              min-width="180"
              align="center"
            /><!-- 转运中心卸车时间 -->
            <el-table-column
              prop="hubUnloadUserName"
              :label="$t('collectionCenter.unloadingTransferCenterP')"
              min-width="180"
              align="center"
            /><!-- 转运中心卸车人 -->
            <el-table-column
              prop="hubLoadSiteName"
              :label="$t('collectionCenter.LoadingTransferCenter')"
              min-width="180"
              align="center"
            /><!-- 装车转运中心 -->
            <el-table-column
              prop="hubLoadTime"
              :label="$t('collectionCenter.transferCenterLoadingTime')"
              min-width="180"
              align="center"
            /><!-- 转运中心装车时间 -->
            <el-table-column
              prop="hubLoadUserName"
              :label="$t('collectionCenter.transferCenterLoader')"
              min-width="180"
              align="center"
            /><!-- 转运中心装车人 -->
          </el-table>
          <div class="right">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :current-page.sync="formMX.pageNumber"
              :page-sizes="[10, 20, 50, 100, 500, 1000]"
              :page-size.sync="formMX.pageSize"
              :total="totalMX"
              background
              style="margin: 10px 0"
              @current-change="getDatePageMX"
              @size-change="getPageSizeMX"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import {
  getPunctualSummaryData,
  getPunctualOrderDataPage,
  punctualSummaryDataExport, punctualOrderDataExport
} from '@/api/lanshou';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList';

export default {
  name: 'Punctuality',
  props: {
    timeForm: {
      type: Object,
      default: () => {
        return {
          startTime: '',
          endTime: '',
          mdStayType: '',
          timeType: '',
          updateTime: ''
        };
      }
    },
    tabName: {
      type: String,
      default: ''
    },
    dateType: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      activeName: 'hz',
      tableData1: [],
      formMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          collectPunctualType: '',
          punctualOrderType: '',
          latestBatchTime: ''
        }
      },
      totalMX: 0,
      tableDataMX: [],
      mxHeight: 600,
      tabsName: this.tabName,
      updateTime: this.timeForm.updateTime,
      type: this.dateType,
      currentTime: {},
      mxType: null
    };
  },
  created() {
    console.log(this.timeForm, '===timeForm');
    this.mxHeight = document.body.clientHeight - 270;
    this.getData();
  },
  methods: {
    // 标签页切换
    tabClick(tab) {
      console.log(tab, 'tab');
      if (tab.name === 'hz') {
        this.pageNumber = 1;
        this.getData();
      } else if (tab.name === 'mx') {
        this.formMX.pageNumber = 1;
        if (this.mxType === 1) {
          this.getTableDatePageMX(); // 袋明细
        } else {
          this.getTableDatePageMXOrder(); // 票明细
        }
      }
    },
    // 导出明细
    exportData() {
      const data = cloneDeep(this.timeForm);
      if (this.activeName === 'hz') {
        const param = {
          startTime: this.currentTime.startTime || data.startTime,
          endTime: this.currentTime.endTime || data.endTime,
          collectPunctualType: data.mdStayType
        };
        punctualSummaryDataExport(param).then(res => {
          goExportList(this, '/resource/exportTaskList');
        });
      } else {
        const param = {
          startTime: this.currentTime.startTime || data.startTime,
          endTime: this.currentTime.endTime || data.endTime,
          collectPunctualType: data.mdStayType,
          punctualOrderType: this.mxType,
          latestBatchTime: data.updateTime
        };
        punctualOrderDataExport(param).then(res => {
          goExportList(this, '/resource/exportTaskList');
        });
      }
    },
    get2Data(type) {
      this.type = type;
      const d = new Date();
      const day0 = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD');
      const day1 = dayjs(d).format('YYYY-MM-DD');
      const day2 = dayjs(d).subtract(6, 'day').format('YYYY-MM-DD');
      const day3 = dayjs(d).startOf('month').format('YYYY-MM-DD');
      let date = {};
      if (type === 0) {
        date = {
          startTime: day0,
          endTime: day0
        };
      }
      if (type === 1) {
        date = {
          startTime: day1,
          endTime: day1
        };
      }
      if (type === 2) {
        date = {
          startTime: day2,
          endTime: day1
        };
      }
      if (type === 3) {
        date = {
          startTime: day3,
          endTime: day1
        };
      }
      this.currentTime = {
        startTime: date.startTime,
        endTime: date.endTime
      };
      const data = cloneDeep(this.timeForm);
      if (this.activeName === 'hz') {
        const param = {
          startTime: date.startTime,
          endTime: date.endTime,
          collectPunctualType: data.mdStayType
        };
        getPunctualSummaryData(param).then(res => {
          if (res.status === 'OK') {
            this.tableData1 = [res.data];
          }
        });
      }
      if (this.activeName === 'mx') {
        this.formMX = {
          pageNumber: 1,
          pageSize: 10,
          params: {
            startTime: date.startTime,
            endTime: date.endTime,
            collectPunctualType: data.mdStayType,
            punctualOrderType: this.mxType,
            latestBatchTime: data.updateTime
          }
        };
        this.getTableDatePageMX();
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (column.label === this.$t('collectionCenter.TotalCollectionPackages') ||
          (column.label === this.$t('collectionCenter.TotalPackagesLoadedBranch')) ||
          (column.label === this.$t('collectionCenter.TotalUnloadedParcelsTransferCenter')) ||
          (column.label === this.$t('collectionCenter.TotalPackagesLoadedTransferCenter')) ||
          (column.label === this.$t('collectionCenter.TotalUnloadedParcelsDestinationWarehouse')) ||
          (column.label === this.$t('collectionCenter.TotalSignedParcelsDestinationWarehouse')) ||
          (column.label === this.$t('collectionCenter.TotalClosedPackages')) ||
          (column.label === this.$t('collectionCenter.SigningTimeoutTotal'))
        ) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    getData() {
      const data = cloneDeep(this.timeForm);
      const param = {
        startTime: this.currentTime.startTime || data.startTime,
        endTime: this.currentTime.endTime || data.endTime,
        collectPunctualType: data.mdStayType
      };
      getPunctualSummaryData(param).then(res => {
        if (res.status === 'OK') {
          this.tableData1 = [res.data];
        }
      });
    },
    toMX(row, type) {
      this.mxType = type;
      const data = cloneDeep(this.timeForm);
      this.formMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: this.currentTime.startTime || data.startTime,
          endTime: this.currentTime.endTime || data.endTime,
          collectPunctualType: data.mdStayType,
          punctualOrderType: type,
          latestBatchTime: data.updateTime
        }
      };
      this.getTableDatePageMX();
      this.activeName = 'mx';
    },
    getTableDatePageMX() {
      getPunctualOrderDataPage(this.formMX).then(res => {
        this.tableDataMX = [];
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDataMX = res.data.records;
        }
      });
    },
    getPageSizeMX(val) {
      this.formMX.pageNumber = 1;
      this.formMX.pageSize = val;
      this.getTableDatePageMX();
    },
    getDatePageMX(val) {
      this.formMX.pageNumber = val;
      this.getTableDatePageMX();
    },
    // 关闭
    closeWindow() {
      this.$emit('closeWindow');
    }
  }
};
</script>

<style lang="scss" scoped>
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dateBox{
  display: flex; justify-content: flex-start; align-items: center;
  margin-left: 5px;
  span{display: inline-block;border: 1px solid #5EBC7F;padding: 0 15px;box-sizing: border-box;cursor: pointer; text-align: center;color: #5EBC7F;}
  span:hover, span.d1:hover{background: #51d96b;color: #fff;}
  span.d1:hover{border: 1px solid #51D96B;box-sizing: border-box;}
  span.d1{background: linear-gradient(90deg, #51D96B, transparent);box-sizing: border-box;color: #009C37}
}
</style>
