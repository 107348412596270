<template>
  <div class="lanShouOrdersBox">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row
          class="selectClass" type="flex" justify="start"
          style="flex-wrap: wrap;"
        >
          <el-col class="col2">
            <el-select
              v-model="selectTime"
              :placeholder="$t('collectionCenter.pleaseChoose')"
              class="selectTimeClass autoWidth"
              @change="changeTime"
            >
              <template slot="prefix">
                {{ (timeList.find(item => item.value === selectTime) || {}).time }}
              </template>
              <el-option
                v-for="(item, index) in timeList"
                :key="index"
                :label="item.time"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <!-- 订单创建 selectTime == 't0' -->
          <!-- 实际揽收 selectTime == 't2' -->
          <!-- 签收时间 selectTime == 't3' -->
          <!-- 闭环时间 selectTime == 't4' -->
          <el-col :span="8">
            <el-form-item class="delMl">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('collectionCenter.EndTime')"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectSite"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  class="selectTimeClass autoWidth"
                  @change="changeSite"
                >
                  <template slot="prefix">
                    {{ (stList.find(item => item.value === selectSite) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in stList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!-- "s1"  揽收网点 -->
                  <!-- "s2"  签收网点 -->
                </el-select>
              </el-col>
              <el-col v-if="selectSite == 's1'" style="width: auto;">
                <el-form-item class="siteClass">
                  <el-select
                    ref="searchSite1"
                    v-model="queryForm.params.siteIdList"
                    :placeholder="$t('collectionCenter.pleaseChoose')"
                    clearable
                    filterable
                    multiple
                    @focus="getNewestSite"
                    @input.native="filterData(1)"
                  >
                    <el-option
                      v-for="(item, index) in siteIdL"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="selectSite == 's2'" style="width: auto;">
                <el-form-item class="siteClass">
                  <el-select
                    ref="searchSite2"
                    v-model="queryForm.params.signSiteIdList"
                    :placeholder="$t('collectionCenter.pleaseChoose')"
                    clearable
                    filterable
                    multiple
                    @focus="getNewestSite"
                    @input.native="filterData(2)"
                  >
                    <el-option
                      v-for="(item, index) in siteIdL"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="customerId"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
                @change="changeMerchant"
                @focus="getNewestCustomer"
              >
                <el-option
                  v-for="(item, index) in customerIdList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 订单类型： -->
            <el-form-item :label="$t('collectionCenter.tipo')">
              <el-select
                v-model="queryForm.params.orderType"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                filterable
              >
                <el-option :label="$t('collectionCenter.tudo')" :value="0" /><!-- 全部 -->
                <el-option :label="$t('collectionCenter.normal')" :value="1" /><!-- 普通订单 -->
                <el-option :label="$t('collectionCenter.cutOff')" :value="2" /><!-- 截单订单 -->
                <el-option :label="$t('collectionCenter.bulkyOrder')" :value="3" /><!-- 大件订单 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="col10" style="width: auto;">
            <!-- 商户号/商户名称： -->
            <el-form-item class="m">
              <el-select
                v-model="merchantChoose"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                class="selectTimeClass autoWidth"
                @change="changeMerchant"
              >
                <template slot="prefix">
                  {{ (merchantCodeOrName.find(item => item.m === merchantChoose) || {}).name }}
                </template>
                <el-option
                  v-for="(item, index) in merchantCodeOrName"
                  :key="index"
                  :label="item.name"
                  :value="item.m"
                />
              </el-select>
              <el-select
                v-model="merchantCodeValue"
                :loading="loading"
                :placeholder="$t('collectionCenter.pleaseInput')"
                :remote-method="remoteMethod"
                clearable
                filterable
                remote
                reserve-keyword
                @change="changeValue"
              >
                <el-option
                  v-for="(item, index) in merchantCodeList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 寄件邮编： -->
            <el-form-item :label="$t('collectionCenter.SendZipCode')">
              <el-input
                v-model.trim="queryForm.params.senderZipCode"
                :placeholder="$t('collectionCenter.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 收件邮编： -->
            <el-form-item :label="$t('collectionCenter.receiptZipCode')">
              <el-input
                v-model.trim="queryForm.params.receiverZipCode"
                :placeholder="$t('collectionCenter.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 订单状态： -->
            <el-form-item :label="$t('collectionCenter.orderStatus')">
              <el-select
                v-model="queryForm.params.collectStatusList"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
                multiple
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.collectStatusName"
                  :value="item.collectStatus"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectPeople"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  class="selectTimeClass autoWidth"
                  @change="changePeople"
                >
                  <template slot="prefix">
                    {{ (selectPeopleList.find(item => item.value === selectPeople) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in selectPeopleList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!--              <el-option :label="$t('collectionCenter.LanShouPeople')" value="n1" />&lt;!&ndash; 揽收人 &ndash;&gt;-->
                  <!--              <el-option :label="$t('collectionCenter.signer')" value="n2" />&lt;!&ndash; 签收人 &ndash;&gt;-->
                </el-select>
              </el-col>
              <el-col v-if="selectPeople == 'n1'" style="width: auto;">
                <el-form-item class="siteClass">
                  <!-- <el-input v-model.trim="queryForm.params.driverName" clearable :placeholder="$t('collectionCenter.pleaseInput')" /> -->
                  <el-select
                    v-model="queryForm.params.driverId"
                    :loading="loading"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    :remote-method="driverNameMethod"
                    clearable
                    filterable
                    remote
                    reserve-keyword
                  >
                    <el-option
                      v-for="(item, index) in driverNameList"
                      :key="index"
                      :label="item.driverName"
                      :value="item.driverId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="selectPeople == 'n2'" style="width: auto;">
                <el-form-item class="siteClass">
                  <!-- <el-input v-model.trim="queryForm.params.signDriverName" clearable :placeholder="$t('collectionCenter.pleaseInput')" /> -->
                  <el-select
                    v-model="queryForm.params.signDriverId"
                    :loading="loading"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    :remote-method="signDriverNameMethod"
                    clearable
                    filterable
                    remote
                    reserve-keyword
                  >
                    <el-option
                      v-for="(item, index) in signDriverNameList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: auto;"><!-- 车签号 -->
            <el-form-item :label="$t('collectionCenter.bagNumber')">
              <el-input
                v-model="queryForm.params.tagNumber"
                :placeholder="$t('collectionCenter.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;"><!--揽收方式 -->
            <el-form-item :label="$t('collectionCenter.lanshouType')">
              <el-select
                v-model="queryForm.params.customerCollectType"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
              >
                <el-option :label="$t('collectionCenter.toCollect')" value="2" /><!--上门揽收 -->
                <el-option :label="$t('collectionCenter.dropOff')" value="1" /><!-- 上门交件 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectCode"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  class="selectTimeClass autoWidth"
                  @change="changeCode"
                >
                  <template slot="prefix">
                    {{ (selectCodeList.find(item => item.value === selectCode) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in selectCodeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col style="width: auto;">
                <el-form-item class="siteClass">
                  <el-input
                    v-model="numberList"
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    autosize
                    type="textarea"
                    @blur="ft"
                    @keydown.enter.native="BlockNewlineNum"
                  /><!-- 支持批量查询(回车换行分割) -->
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" class="col10" style="width: auto;">
            <!-- 发件人州： -->
            <el-form-item class="m">
              <el-select
                v-model="selectState"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                class="selectTimeClass autoWidth"
                @change="changeState"
              >
                <template slot="prefix">
                  {{ (stateList.find(item => item.value === selectState) || {}).name }}
                </template>
                <el-option
                  v-for="(item, index) in stateList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-input
                v-if="selectState === 's1'"
                v-model="queryForm.params.senderState"
                :placeholder="$t('collectionCenter.BatchQuery')"
                autosize
                type="textarea"
                @keydown.enter.native="BlockNewlineSenderState"
              /><!-- 支持批量查询(回车换行分割) -->
              <el-input
                v-if="selectState === 's2'"
                v-model="queryForm.params.receiveState"
                :placeholder="$t('collectionCenter.BatchQuery')"
                autosize
                type="textarea"
                @keydown.enter.native="BlockNewlineReceiveState"
              /><!-- 支持批量查询(回车换行分割) -->
            </el-form-item>
          </el-col>
          <el-col :span="6" class="col10" style="width: auto;">
            <el-form-item class="m">
              <el-select
                v-model="selectCity"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                class="selectTimeClass autoWidth"
                @change="changeCity"
              >
                <template slot="prefix">
                  {{ (cityList.find(item => item.value === selectCity) || {}).name }}
                </template>
                <el-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-input
                v-if="selectCity === 'c1'"
                v-model="queryForm.params.senderCity"
                :placeholder="$t('collectionCenter.BatchQuery')"
                autosize
                type="textarea"
                @keydown.enter.native="BlockNewlineSenderCity"
              /><!-- 支持批量查询(回车换行分割) -->
              <el-input
                v-if="selectCity === 'c2'"
                v-model="queryForm.params.receiveCity"
                :placeholder="$t('collectionCenter.BatchQuery')"
                autosize
                type="textarea"
                @keydown.enter.native="BlockNewlineReceiveCity"
              /><!-- 支持批量查询(回车换行分割) -->
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;"><!--所属平台 -->
            <el-form-item :label="$t('collectionCenter.PlatformWhichBelongs')">
              <el-select
                v-model="queryForm.params.platform"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
              >
                <el-option :value="1" label="shein" /><!-- shein -->
                <el-option :value="2" label="kwai" /><!-- kwai -->
                <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
                <el-option :label="$t('collectionCenter.other')" :value="0" /><!-- 其他 -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row justify="space-between" type="flex">
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:order:lanshou-order:find'"
            icon="el-icon-search"
            size="mini"
            type="primary"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}
          </el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:lanshou-order:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}
          </el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:lanshou-order:export'"
            icon="el-icon-upload2"
            size="mini"
            :class="idList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:lanshou-order:dispatchDriver'"
            icon="el-icon-truck"
            size="mini"
            @click="schedulingDriver(1)"
          >{{ $t('collectionCenter.schedulingDriver') }}
          </el-button><!-- 调度司机 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:lanshou-order:reDispatchDriver'"
            icon="el-icon-refresh"
            size="mini"
            @click="schedulingDriver(2)"
          >{{ $t('collectionCenter.schedulingDriverAgain') }}
          </el-button><!-- 重新调度司机 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:lanshou-order:cancelDispatch'"
            icon="el-icon-remove-outline"
            size="mini"
            @click="cancelDriverClick"
          >{{ $t('collectionCenter.cancelSchedule') }}
          </el-button><!-- 取消调度 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:lanshou-order:allExport'"
            :disabled="ifExport"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div class="tableClass">
      <el-table
        ref="tableList"
        :data="tableDate"
        :max-height="600"
        border
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" align="center" type="index"
          width="60"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.lanshouOrderNum')"
          prop="orderNumber"
        /><!-- 揽收订单号 -->
        <el-table-column
          :label="$t('collectionCenter.Barcode')"
          prop="scanNumber"
        /><!-- 条形码 -->
        <el-table-column
          :label="$t('collectionCenter.customerOrderNum')"
          prop="customerNumber"
        /><!-- 客户订单号 -->
        <el-table-column
          :label="$t('collectionCenter.clientID')"
          prop="customerId"
        /><!-- 客户编号 -->
        <el-table-column
          :label="$t('collectionCenter.CustomerName')"
          prop="customerName"
        /><!-- 客户名称 -->
        <el-table-column
          :label="$t('collectionCenter.tipoTitle')"
          prop="orderType"
        /><!-- 订单类型 -->
        <el-table-column
          :label="$t('collectionCenter.BagNumber')"
          prop="orderBagNumber"
        /><!-- 袋号 -->
        <el-table-column
          :label="$t('collectionCenter.carNumber')"
          prop="tagNumber"
        /><!-- 车签号 -->
        <el-table-column
          :label="$t('collectionCenter.parcelNum')"
          prop="packageNumber"
        /><!-- 包裹号 -->
        <el-table-column
          :label="$t('collectionCenter.lanshouTypee')"
          prop="customerCollectTypeName"
        /><!-- 揽收方式 -->
        <el-table-column
          :label="$t('collectionCenter.bagWeight')"
          prop="weight"
        /><!-- 包裹重量 -->
        <el-table-column
          :label="$t('collectionCenter.bagCost')"
          prop="declaredValue"
        /><!-- 包裹申明价值 -->
        <el-table-column
          :label="$t('collectionCenter.lanshouImg')"
          align="center"
          prop="finishSignUrl"
        ><!-- 揽收图片 -->
          <template slot-scope="scope">
            <el-popover
              :title="$t('collectionCenter.lanshouImg')"
              content=""
              placement="left-start"
              trigger="hover"
              width="300"
            >
              <img
                v-show="scope.row.finishSignUrl"
                slot="reference"
                :src="scope.row.finishSignUrl"
                alt=""
                style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.finishSignUrl" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.finishSignUrl"
                :src="scope.row.finishSignUrl"
                alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.orderCreateT')"
          prop="createTime"
        /><!-- 订单创建时间 -->
        <el-table-column
          :label="$t('collectionCenter.ExpectedCollectionTime')"
          prop="expectTime"
        /><!-- 期望揽收时间 -->
        <el-table-column
          :label="$t('collectionCenter.ActualCollectionTime')"
          prop="scanTime"
        /><!-- 实际揽收时间 -->
        <el-table-column
          :label="$t('collectionCenter.OrderStatus')"
          prop="collectStatusName"
        /><!-- 订单状态 -->
        <el-table-column
          :label="$t('collectionCenter.collectionDot')"
          prop="siteName"
        /><!-- 揽收网点 -->
        <el-table-column
          :label="$t('collectionCenter.LanShouPeople')"
          prop="driverName"
        /><!-- 揽收人 -->
        <el-table-column
          :label="$t('collectionCenter.loader')"
          prop="loadedUserName"
        /><!-- 装车人 -->
        <el-table-column
          :label="$t('collectionCenter.loadTime')"
          prop="loadedTime"
        /><!-- 装车时间 -->
        <el-table-column
          :label="$t('collectionCenter.loadingSignPicture')"
          align="center"
          prop="loadSignImg"
        ><!-- 装车签名图片 -->
          <template slot-scope="scope">
            <el-popover
              :title="$t('collectionCenter.loadingSignPicture')"
              content=""
              placement="left-start"
              trigger="hover"
              width="300"
            >
              <img
                v-show="scope.row.loadSignImg"
                slot="reference"
                :src="scope.row.loadSignImg"
                alt=""
                style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.loadSignImg" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.loadSignImg"
                :src="scope.row.loadSignImg"
                alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.unloader')"
          prop="unloadUserName"
        /><!-- 卸车人 -->
        <el-table-column
          :label="$t('collectionCenter.unloadTime')"
          prop="unloadTime"
        /><!-- 卸车时间 -->
        <el-table-column
          :label="$t('collectionCenter.unloadSignImg')"
          prop="unloadSignImg"
        ><!-- 卸车签名图片 -->
          <template slot-scope="scope">
            <el-popover
              :title="$t('collectionCenter.unloadSignImg')"
              content=""
              placement="left-start"
              trigger="hover"
              width="300"
            >
              <img
                v-show="scope.row.unloadSignImg"
                slot="reference"
                :src="scope.row.unloadSignImg"
                alt=""
                style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.unloadSignImg" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.unloadSignImg" :src="scope.row.unloadSignImg" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.customerMerchantNumber')"
          prop="merchantCode"
        /><!-- 客户商户号 -->
        <el-table-column
          :label="$t('collectionCenter.NameOfCustomerMerchant')"
          prop="merchantName"
        /><!-- 客户商户名称 -->
        <el-table-column
          :label="$t('collectionCenter.signImage')"
          align="center"
          prop="signerImg"
        ><!-- 签收图片 -->
          <template slot-scope="scope">
            <el-popover
              :title="$t('collectionCenter.signImage')"
              content=""
              placement="left-start"
              trigger="hover"
              width="300"
            >
              <img
                v-show="scope.row.signerImg"
                slot="reference"
                :src="scope.row.signerImg"
                alt=""
                style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.signerImg" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.signerImg" :src="scope.row.signerImg" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.deliveryDot')"
          prop="signSiteName"
        /><!-- 签收网点 -->
        <el-table-column
          :label="$t('collectionCenter.signer')"
          prop="signerUsername"
        /><!-- 签收人 -->
        <el-table-column
          :label="$t('collectionCenter.signT')"
          prop="signerTime"
        /><!-- 签收时间 -->
        <el-table-column
          :label="$t('collectionCenter.postZ')"
          prop="senderZipcode"
        /><!-- 寄件人邮编 -->
        <el-table-column
          :label="$t('collectionCenter.postAds')"
          prop="senderAddress"
          show-overflow-tooltip
        /><!-- 寄件地址 -->
        <el-table-column
          :label="$t('collectionCenter.postT')"
          prop="senderPhone"
        /><!-- 寄件人电话 -->
        <el-table-column
          :label="$t('collectionCenter.platform')"
          prop="platformName"
        /><!-- 所属平台 -->
        <el-table-column
          :label="$t('collectionCenter.closeTime')"
          prop="closeTime"
        /><!-- 闭环时间 -->
        <el-table-column
          :label="$t('collectionCenter.commodityInvoiceNumber')"
          prop="goodsInvoicNo"
        /><!-- 商品发票号 -->
        <el-table-column
          :label="$t('collectionCenter.logisticsInvoiceType')"
          prop="logisticsInvoiceType"
        /><!-- 物流发票类型 -->
        <el-table-column
          :label="$t('collectionCenter.logisticsInvoiceNumber')"
          prop="logisticsInvoiceNo"
        /><!-- 物流发票号 -->
        <el-table-column
          :label="$t('collectionCenter.invoiceSecretKey')"
          prop="logisticsInvoiceSecretKey"
        /><!-- 发票秘钥 -->
        <el-table-column
          :label="$t('newOrder.CancellationTime')"
          prop="cancelTime"
        /><!-- 取消时间 -->
        <el-table-column
          :label="$t('collectionCenter.cancelReason')"
          :show-overflow-tooltip="false"
          align="center"
          prop="cancelReason"
        ><!-- 取消原因 -->
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content" style="max-height: 200px;overflow: auto;max-width:500px;">{{ scope.row.cancelReason }}</div>
              <div class="paramsStyle">{{ scope.row.cancelReason }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.batchNo')"
          prop="batchNo"
        /><!-- 揽收波次 -->
        <el-table-column
          :label="$t('collectionCenter.waveNumberCreationTime')"
          prop="cutOffCreateTime"
        /><!-- 波次号创建时间 -->
        <el-table-column
          :label="$t('collectionCenter.actPickUpCode')"
          prop="actPickUpCode"
        /><!-- 实际揽收码 -->
        <!-- <el-table-column
          :label="$t('collectionCenter.pickupCode')"
          prop="pickupCode"
        />揽收码 -->
        <el-table-column
          :label="$t('collectionCenter.clearImage')"
          prop="clearImageList"
        ><!-- 清场图片 -->
          <template slot-scope="scope">
            <div v-for="(cImg, index) in scope.row.clearImageList" :key="index" style="max-height: 60px;">
              <el-popover
                placement="top"
                popper-class="copy"
                trigger="hover"
              >
                <el-button
                  type="text"
                  @click="copy(cImg)"
                >
                  {{ $t('orderDetails.copy') }}
                </el-button>
                <span slot="reference" class="clearImageClass" @click="openImg(cImg)">{{ cImg }}</span>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('operatingPlatform.scanWeight')"
          align="center"
          prop="weighingWeight"
        /><!-- 称重重量 -->
        <el-table-column
          :label="$t('operatingPlatform.volumeWeight')"
          align="center"
          prop="volumeWeight"
        /><!-- 体积重 -->
        <el-table-column
          :label="$t('operatingPlatform.long')"
          align="center"
          prop="length"
        /><!-- 长 -->
        <el-table-column
          :label="$t('operatingPlatform.width')"
          align="center"
          prop="width"
        /><!-- 宽 -->
        <el-table-column
          :label="$t('operatingPlatform.height')"
          align="center"
          prop="height"
        /><!-- 高 -->
        <el-table-column
          :label="$t('newOrder.clientExitTime')"
          align="center"
          prop="customerSendTime"
        /><!-- 客户出仓时间 -->
        <el-table-column
          v-if="isViewInformation"
          :label="$t('collectionCenter.operation')"
          align="center"
          fixed="right"
          width="160"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <!-- 查看寄/收件人信息 -->
            <el-button
              size="mini"
              style="white-space: normal;"
              @click="cellClick(scope.row)"
            >{{ $t('collectionCenter.viewInformation') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="isViewMoreRows ? [10, 20, 50, 100, 500, 1000, 2000] : [10, 20, 50, 100, 500, 1000]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getLanShouOrdersPage"
        @size-change="getPageSize"
      />
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <div class="dialogBox">
        <el-form
          ref="ScheduleBox" :model="dialogForm" :rules="rules"
          label-width="120px"
        >
          <!-- 站点： -->
          <el-form-item :label="$t('collectionCenter.site')">
            <div class="dialogC">
              <el-select
                v-model="dialogForm.siteName"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
                @change="chooseSite"
                @focus="getNewestSite"
              >
                <el-option
                  v-for="(item, index) in siteIdL"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </el-form-item>
          <!-- 司机： -->
          <el-form-item :label="$t('collectionCenter.driver')">
            <div class="dialogC">
              <el-select
                v-model="dialogForm.driverId"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
                @focus="focusDriver"
              >
                <el-option
                  v-for="(item, index) in driverList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </div>
          </el-form-item>
          <!-- 期望揽收时间： -->
          <el-form-item :label="$t('collectionCenter.ExpectedCollectionT')" prop="expectLanshouTime">
            <div class="dialogC">
              <div class="block">
                <el-date-picker
                  v-model="dialogForm.expectLanshouTime"
                  :clearable="false"
                  :picker-options="pickerOptionsSJ"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </div>
            </div>
          </el-form-item>
          <!-- 联系电话： -->
          <el-form-item :label="$t('collectionCenter.contactPhone')" prop="expectLanshouTel">
            <div class="dialogC">
              <el-input
                v-model.trim="dialogForm.expectLanshouTel"
                :placeholder="$t('collectionCenter.pleaseInput')"
                clearable
              />
            </div>
          </el-form-item>
          <!-- 揽收地址： -->
          <el-form-item :label="$t('collectionCenter.LanShouAddress')" prop="expectLanshouAddress">
            <div class="dialogC">
              <el-input
                v-model.trim="dialogForm.expectLanshouAddress"
                :placeholder="$t('collectionCenter.pleaseInput')"
                autosize
                clearable
                type="textarea"
              />
            </div>
          </el-form-item>
          <!-- 备注： -->
          <el-form-item :label="$t('collectionCenter.note')">
            <div class="dialogC">
              <el-input
                v-model="dialogForm.expectLanshouScanNumber"
                autosize
                placeholder=""
                type="textarea"
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="dialogCancel">{{ $t('collectionCenter.cancel') }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="dialogConfirm">{{ $t('collectionCenter.confirm') }}</el-button>
      </span>
    </el-dialog>

    <div v-if="drawerIsOpen" class="drawerOpen">
      <el-drawer
        :direction="direction"
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        size="40%"
      >
        <div class="drawerBox">
          <div class="drawerClass">
            <!-- 收件人： -->
            <div><span>{{ $t('collectionCenter.recipient') }}</span>{{ drawerList.receiveName }}</div>
            <!-- 收件人邮箱： -->
            <div><span>{{ $t('collectionCenter.RecipientMailbox') }}</span>{{ drawerList.receiveMail }}</div>
            <!-- 收件人邮编： -->
            <div><span>{{ $t('collectionCenter.RecipientzipCode') }}</span>{{ drawerList.receiveZipcode }}</div>
            <!-- 收件人电话： -->
            <div><span>{{ $t('collectionCenter.RecipientPhone') }}</span>{{ drawerList.receivePhone }}</div>
            <!-- 收件州： -->
            <div><span>{{ $t('collectionCenter.receiptState') }}</span>{{ drawerList.receiveState }}</div>
            <!-- 收件城市： -->
            <div><span>{{ $t('collectionCenter.receiptCity') }}</span>{{ drawerList.receiveCity }}</div>
            <!-- 收件区： -->
            <div><span>{{ $t('collectionCenter.receiptRegional') }}</span>{{ drawerList.receiveArea }}</div>
            <!-- 收件地址： -->
            <div><span>{{ $t('collectionCenter.receiptAddress') }}</span>{{ drawerList.receiveAddress }}</div>
            <!-- 收件街道： -->
            <div><span>{{ $t('collectionCenter.receiptStreet') }}</span>{{ drawerList.receiveStreet }}</div>
            <!-- 收件门牌号： -->
            <div><span>{{ $t('collectionCenter.receiptHouseNumber') }}</span>{{ drawerList.receiveHouseNumber }}</div>
            <!-- 收件人邻里： -->
            <div><span>{{ $t('collectionCenter.receiptNeighborhood') }}</span>{{ drawerList.receiveNeighborhood }}</div>
          </div>
        </div>
        <div class="drawerBox">
          <div class="drawerClass mb5">
            <!-- 寄件人： -->
            <div><span>{{ $t('collectionCenter.postPerson') }}</span>{{ drawerList.senderName }}</div>
            <!-- 寄件人邮箱： -->
            <div><span>{{ $t('collectionCenter.postMail') }}</span>{{ drawerList.senderMail }}</div>
            <!-- 寄件人邮编： -->
            <div><span>{{ $t('collectionCenter.postZip') }}</span>{{ drawerList.senderZipcode }}</div>
            <!-- 寄件人电话： -->
            <div><span>{{ $t('collectionCenter.postTel') }}</span>{{ drawerList.senderPhone }}</div>
            <!-- 寄件州： -->
            <div><span>{{ $t('collectionCenter.postState') }}</span>{{ drawerList.senderState }}</div>
            <!-- 寄件城市： -->
            <div><span>{{ $t('collectionCenter.postCity') }}</span>{{ drawerList.senderCity }}</div>
            <!-- 寄件区： -->
            <div><span>{{ $t('collectionCenter.postRegional') }}</span>{{ drawerList.senderArea }}</div>
            <!-- 寄件地址： -->
            <div><span>{{ $t('collectionCenter.postAddress') }}</span>{{ drawerList.senderAddress }}</div>
            <!-- 寄件街道： -->
            <div><span>{{ $t('collectionCenter.postStreet') }}</span>{{ drawerList.senderStreet }}</div>
            <!-- 寄件门牌号： -->
            <div><span>{{ $t('collectionCenter.postHouseNumber') }}</span>{{ drawerList.senderHouseNumber }}</div>
            <!-- 寄件人邻里： -->
            <div><span>{{ $t('collectionCenter.postNeighborhood') }}</span>{{ drawerList.senderNeighborhood }}</div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  cancelDriver,
  collectDriverQueryBySiteId,
  collectOrderExportAll,
  collectOrderPage,
  CollectOrderVO,
  orderExport,
  queryAllCustomer,
  queryAllMerchantCode,
  queryCollectMan,
  queryDriver,
  schedulingDriver,
  searchQueryWholeSite
} from '@/api/lanshou';
import { copyData } from '@/utils/copyData';
import dayjs from 'dayjs';
import { commonDesensitize, desensitizeEmail } from '@/utils/desensitization';

export default {
  name: 'LanShouOrders',
  data() {
    return {
      numberList: '',
      isClickAll: false,
      isClick: false, // 是否点击了导出
      // 按钮权限控制表格列是否展示
      isViewInformation: this.$store.getters.button_permissions.includes('btn:collection-center:order:lanshou-order:viewInformation'),
      isViewMoreRows: this.$store.getters.button_permissions.includes('but:system-manage:viewlist'),
      timeValue: [],
      selectCode: 'c1',
      selectCodeList: [
        { value: 'c1', label: this.$t('collectionCenter.Barcode') }, // '条形码'
        { value: 'c2', label: this.$t('collectionCenter.lanshouOrderNum') }, // '揽收订单号'
        { value: 'c3', label: this.$t('collectionCenter.parcelNum') }, // '包裹号'
        { value: 'c4', label: this.$t('collectionCenter.customerOrderNum') }, // '客户订单号'
        { value: 'c5', label: this.$t('collectionCenter.rangeWaveNumber') }, // '揽收波次号'
        { value: 'c6', label: this.$t('collectionCenter.BagNumber') } // ''
      ],
      selectPeople: 'n1', // 1 揽收人   2 签收人
      selectPeopleList: [
        { value: 'n1', label: this.$t('collectionCenter.LanShouPeople') }, // '揽收人'
        { value: 'n2', label: this.$t('collectionCenter.signer') } // '签收人'
      ],
      selectSite: 's1', // 选择站点  1 揽收网点  2 签收网点
      stList: [
        { value: 's1', label: this.$t('collectionCenter.collectionDot') }, // '揽收网点'
        { value: 's2', label: this.$t('collectionCenter.deliveryDot') } // '签收网点'
      ],
      signDriverNameList: [], // 签收人联想查询
      driverNameList: [], // 联想输入查询
      notEnter: true, // 是否允许换行
      ifExport: false, // 是否允许全部导出

      merchantChoose: 'm2', // 选择输入商户号还是商户名称
      merchantCodeOrName: [
        { m: 'm1', name: this.$t('collectionCenter.MerchantNum') }, // '商户号'
        { m: 'm2', name: this.$t('collectionCenter.MerchantName') } // '商户名称'
      ],
      pickerOptionsSJ: {
        disabledDate: (time) => { // this.dialogForm.expectLanshouTime
          const newDate = new Date();
          return time.getTime() <= newDate.getTime() - 8.64e7;
        }
      },
      loading: false, // 商户号加载
      selectTime: 't0', // 选择期望揽收时间 / 实际揽收时间
      timeList: [
        { value: 't0', time: this.$t('collectionCenter.orderCreateT') }, // '订单创建时间'
        // { value: 't1', time: this.$t('collectionCenter.ExpectedCollectionTime') }, // '期望揽收时间'
        { value: 't2', time: this.$t('collectionCenter.ActualCollectionTime') }, // '实际揽收时间'
        { value: 't3', time: this.$t('collectionCenter.signT') }, // '签收时间'
        { value: 't4', time: this.$t('collectionCenter.closeTime') }, // '闭环时间'
        { value: 't5', time: this.$t('collectionCenter.waveNumberCreationTime') } // '波次号创建时间'
      ],
      drawerIsOpen: false,
      drawerTitle: this.$t('collectionCenter.detail'), // '详情'
      direction: 'ltr',
      drawerList: {},
      customerId: '', // 选择商户号前必选客户名称
      customerIdList: [], // 客户名称列表
      merchantCodeValue: '',
      merchantCodeList: [], // 商户编号列表
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          createStartTime: '', // 订单创建时间
          createEndTime: '', // 订单创建时间
          // startTime: '', // 期望揽收开始时间
          // endTime: '', // 期望揽收结束时间
          scanStartTime: '', // 扫件开始时间
          scanEndTime: '', // 扫件结束时间
          signStartTime: '', // 签收开始时间
          signEndTime: '', // 签收结束时间
          closeStartTime: '', // 闭环时间
          batchNoStartTime: '', // 波次号创建开始时间
          batchNoEndTime: '', // 波次号创建结束时间
          batchNo: '', // 揽收波次号
          closeEndTime: '',
          orderBagNumber: '', // 袋号
          tagNumber: '', // 车签号
          packageNumber: '', // 包裹号
          customerNumber: '', // 客户订单号
          orderNumber: '', // 揽收订单号
          customerId: '', // 客户名称
          orderType: '', // 订单类型
          collectStatusList: [], // 订单状态
          scanNumber: '', // 条形码
          siteIdList: [], // 揽收网点
          signSiteIdList: [], // 签收网点
          driverId: '', // 司机 / 揽收人
          signDriverId: '', // 签收人
          senderZipCode: '', // 寄件邮编
          receiverZipCode: '', // 收件邮编
          merchantCode: '', // 客户商户号
          merchantName: '', // 客户商户名称
          customerCollectType: '',
          platform: '', // 所属平台
          senderState: '',
          receiveState: '',
          senderCity: '',
          receiveCity: ''
        }
      },
      total: 0,
      tableDate: [],
      orderTypeList: [
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 2, collectStatusName: this.$t('collectionCenter.cancelled') }, // 已取消
        // { collectStatus: 3, collectStatusName: this.$t('collectionCenter.LanShouing') }, // 揽收中
        { collectStatus: 4, collectStatusName: this.$t('collectionCenter.scheduled') }, // 已调度
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.AbnormalLanShou') }, // 揽收异常
        { collectStatus: 6, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 7, collectStatusName: this.$t('collectionCenter.ProblemPiece') }, // 问题件
        { collectStatus: 8, collectStatusName: this.$t('collectionCenter.SealedBag') }, // 已封袋
        { collectStatus: 9, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 10, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 11, collectStatusName: this.$t('collectionCenter.Bagged') }, // 已装袋
        { collectStatus: 12, collectStatusName: this.$t('collectionCenter.closedLoop') }, // 已闭环
        { collectStatus: 13, collectStatusName: this.$t('collectionCenter.unclaimed') }, // 待取件
        { collectStatus: 14, collectStatusName: this.$t('collectionCenter.collectionPointInventory') }// 揽收网点入库
      ],
      siteIdL: [],
      batchSelectList: {
        collectOrderIdList: [],
        driverId: '',
        siteId: ''
      },
      dialogTitle: '',
      dialogVisible: false, // 调度司机弹窗
      dialogForm: {
        driverId: '',
        siteName: '',
        expectLanshouTime: '', // 调度司机 期望揽收
        expectLanshouTel: '', // 联系电话
        expectLanshouAddress: '', // 揽收地址
        expectLanshouScanNumber: '' // 备注
      },
      rules: {
        expectLanshouTime: [
          { required: true, message: this.$t('collectionCenter.pleaseChoose'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const nowTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
              const td = nowTime.split(' ');
              const val = value.split(' ');
              if (td[0] === val[0]) {
                if (td[1] > val[1]) {
                  return callback(new Error(this.$t('collectionCenter.selectedTimeThanCurrentTime'))); // '选中时间需大于当前时间'
                }
              } else {
                callback();
              }
            }
          }
        ],
        expectLanshouTel: [{
          required: true,
          message: this.$t('collectionCenter.pleaseInput'),
          trigger: ['blur', 'change']
        }],
        expectLanshouAddress: [{
          required: true,
          message: this.$t('collectionCenter.pleaseInput'),
          trigger: ['blur', 'change']
        }]
      },
      driverList: [],
      idList: [],
      isSubmit: true,
      selectCity: 'c1',
      cityList: [
        { value: 'c1', name: this.$t('collectionCenter.sendingCity') }, // '发件城市'
        { value: 'c2', name: this.$t('collectionCenter.receivingCity') } // '收件城市'
      ],
      selectState: 's1',
      stateList: [
        { value: 's1', name: this.$t('collectionCenter.stateOrigin') }, // '发件州'
        { value: 's2', name: this.$t('collectionCenter.receivingState') } // '收件州'
      ]
    };
  },
  watch: {
    customerId() {
      this.queryForm.params.customerId = this.customerId;
    },
    numberList(val) {
      const bgh1 = val.split('\n');
      let numberList = [];
      if (bgh1.length >= 1000) {
        numberList = bgh1.slice(0, 1000).join('\n');
        this.numberList = numberList;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bgh1.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.senderState'(val) {
      const bgh = val.split('\n');
      let stateVal = [];
      if (bgh.length >= 100) {
        stateVal = bgh.slice(0, 100).join('\n');
        this.queryForm.params.senderState = stateVal;
      }
      if (bgh.length > 100) {
        this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
      }
    },
    'queryForm.params.receiveState'(val) {
      const bgh = val.split('\n');
      let stateVal = [];
      if (bgh.length >= 100) {
        stateVal = bgh.slice(0, 100).join('\n');
        this.queryForm.params.receiveState = stateVal;
      }
      if (bgh.length > 100) {
        this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
      }
    },
    'queryForm.params.senderCity'(val) {
      const bgh = val.split('\n');
      let stateVal = [];
      if (bgh.length >= 100) {
        stateVal = bgh.slice(0, 100).join('\n');
        this.queryForm.params.senderCity = stateVal;
      }
      if (bgh.length > 100) {
        this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
      }
    },
    'queryForm.params.receiveCity'(val) {
      const bgh = val.split('\n');
      let cityVal = [];
      if (bgh.length >= 100) {
        cityVal = bgh.slice(0, 100).join('\n');
        this.queryForm.params.receiveCity = cityVal;
      }
      if (bgh.length > 100) {
        this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
      }
    },
    'queryForm.params.driverId'(val) {
      if (!val) {
        this.driverNameList = [];
      }
    },
    'queryForm.params.signDriverId'(val) {
      if (!val) {
        this.signDriverNameList = [];
      }
    },
    timeValue() {
      if (this.timeValue && this.timeValue.length <= 0) {
        // 订单创建
        this.queryForm.params.createStartTime = '';
        this.queryForm.params.createEndTime = '';
        // 实际揽收
        this.queryForm.params.scanStartTime = '';
        this.queryForm.params.scanEndTime = '';
        // 签收时间
        this.queryForm.params.signStartTime = '';
        this.queryForm.params.signEndTime = '';
        // 闭环时间
        this.queryForm.params.closeStartTime = '';
        this.queryForm.params.closeEndTime = '';
        // 波次号时间
        this.queryForm.params.batchNoStartTime = '';
        this.queryForm.params.batchNoEndTime = '';
      }
    },
    isClick(val) {
      if (val) {
        setTimeout(() => {
          this.isClick = false;
        }, 10000);
      }
    }
  },
  created() {
    this.default();
    localStorage.setItem('lanshouOrdersQueryForm', JSON.stringify(this.queryForm));
    this.getLanShouOrdersPage();
    this.getNewestSite();
    this.getNewestCustomer();
    this.isClick = false;
    this.isClickAll = false;
  },
  methods: {
    changeState() {
      this.queryForm.params.senderState = '';
      this.queryForm.params.receiveState = '';
    },
    changeCity() {
      this.queryForm.params.senderCity = '';
      this.queryForm.params.receiveCity = '';
    },
    openImg(url) {
      window.open(url, '_blank');
    },
    copy(val) {
      // console.log('val', val);
      copyData(val);
    },
    // 获取最新的客户list
    getNewestCustomer() {
      queryAllCustomer().then(res => {
        if (res.status === 'OK') {
          this.customerIdList = [];
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },

    filterData(type) {
      const data = type === 1 ? this.$refs.searchSite1.$data.query : type === 2 ? this.$refs.searchSite2.$data.query : '';
      const d1 = data.split(' ');
      const d2 = d1.filter(function(f) {
        return f && f.trim();
      });
      const selestS = [];
      this.siteIdL.forEach(arr => {
        d2.forEach(list => {
          if (list === arr.name) {
            selestS.push(arr.id);
          }
        });
      });
      if (selestS.length <= 0) return;
      if (type === 1) {
        this.queryForm.params.siteIdList = selestS;
      } else {
        this.queryForm.params.signSiteIdList = selestS;
      }
    },
    // 获取最新的网点list数据
    getNewestSite() {
      searchQueryWholeSite().then(res => {
        if (res.status === 'OK') {
          this.siteIdL = [];
          res.data.forEach(data => {
            this.siteIdL.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      // 订单创建
      this.queryForm.params.createStartTime = '';
      this.queryForm.params.createEndTime = '';
      // 实际揽收
      this.queryForm.params.scanStartTime = '';
      this.queryForm.params.scanEndTime = '';
      // 签收时间
      this.queryForm.params.signStartTime = '';
      this.queryForm.params.signEndTime = '';
      // 闭环时间
      this.queryForm.params.closeStartTime = '';
      this.queryForm.params.closeEndTime = '';
      // 波次号时间
      this.queryForm.params.batchNoStartTime = '';
      this.queryForm.params.batchNoEndTime = '';
      // <!-- 订单创建 selectTime == 't0' -->
      // <!-- 实际揽收 selectTime == 't2' -->
      // <!-- 签收时间 selectTime == 't3' -->
      // <!-- 闭环时间 selectTime == 't4' -->
      if (this.selectTime === 't0') {
        this.queryForm.params.createStartTime = val[0];
        this.queryForm.params.createEndTime = val[1];
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.scanStartTime = val[0];
        this.queryForm.params.scanEndTime = val[1];
      }
      if (this.selectTime === 't3') {
        this.queryForm.params.signStartTime = val[0];
        this.queryForm.params.signEndTime = val[1];
      }
      if (this.selectTime === 't4') {
        this.queryForm.params.closeStartTime = val[0];
        this.queryForm.params.closeEndTime = val[1];
      }
      if (this.selectTime === 't5') {
        this.queryForm.params.batchNoStartTime = val[0];
        this.queryForm.params.batchNoEndTime = val[1];
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-7, 'day').format('YYYY-MM-DD 23:59:59');
        if (val[1] > endTime) {
          this.$message({
            // '最多可查询到7天的数据'
            message: this.$t('collectionCenter.max7DaysDate'),
            type: 'warning'
          });
          this.changeTime();
        }
      }
    },
    ft() {
      const val = this.numberList.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const numTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.numberList = numTrim.join('\n');
    },
    BlockNewlineNum() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.numberList.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    changeCode() {
      this.numberList = '';
      this.queryForm.params.scanNumber = '';
      this.queryForm.params.orderNumber = '';
      this.queryForm.params.packageNumber = '';
      this.queryForm.params.customerNumber = '';
      this.queryForm.params.batchNo = '';
      this.queryForm.params.orderBagNumber = '';
    },
    changePeople() { // 切换揽收人 / 签收人
      this.queryForm.params.driverId = '';
      this.queryForm.params.signDriverId = '';
    },
    changeSite() { // 查询条件，切换揽收 / 签收网点
      this.queryForm.params.signSiteIdList = [];
      this.queryForm.params.siteIdList = [];
    },
    changeMerchant() { // 切换商户号/名称 清空输入框
      this.merchantCodeValue = '';
      this.queryForm.params.merchantCode = '';
      this.queryForm.params.merchantName = '';
      this.merchantCodeList = [];
    },
    driverNameMethod(value) { // 司机list
      if (value !== '') {
        this.loading = true;
        setTimeout(() => {
          queryCollectMan(value).then(res => {
            // console.log(res, '揽收人');
            if (res.status === 'OK') {
              this.driverNameList = [];
              res.data.forEach(item => {
                const driverId = item.driverId ? item.driverId : item.customerId;
                const driverName = item.driverName ? item.driverName : item.customerName;
                this.driverNameList.push({
                  driverId,
                  driverName
                });
              });
            }
          });
          this.loading = false;
        }, 200);
      }
    },
    signDriverNameMethod(value) {
      if (value !== '') {
        this.loading = true;
        setTimeout(() => {
          queryDriver(value).then(res => {
            // console.log(res, '签收人');
            if (res.status === 'OK') {
              this.signDriverNameList = [];
              res.data.forEach(item => {
                this.signDriverNameList.push({
                  id: item.driverId,
                  name: item.driverName
                });
              });
            }
          });
          this.loading = false;
        }, 200);
      }
    },
    remoteMethod(value) {
      const merchantCodeList = [];
      if (this.customerId) {
        if (value !== '') {
          this.loading = true;
          // const merchantForm = {
          //   customerId: this.customerId,
          //   merchantCode: value
          // };
          let merchantForm = {};
          if (this.merchantChoose === 'm1') {
            merchantForm = {
              customerId: this.customerId,
              merchantCode: value,
              merchantName: ''
            };
          } else {
            merchantForm = {
              customerId: this.customerId,
              merchantCode: '',
              merchantName: value
            };
          }
          setTimeout(() => {
            queryAllMerchantCode(merchantForm).then(res => {
              if (res.status === 'OK') {
                res.data.records.forEach(list => {
                  if (list.merchantName) {
                    merchantCodeList.push({
                      name: list.merchantCode + ' / ' + list.merchantName
                    });
                  } else {
                    merchantCodeList.push({
                      name: list.merchantCode
                    });
                  }
                });
              }
            });
            this.merchantCodeList = merchantCodeList;
            this.loading = false;
          }, 200);
        } else {
          this.merchantCodeList = [];
        }
      } else {
        this.merchantCodeValue = '';
        this.$message.warning(this.$t('collectionCenter.chooseCustomerName')); // '请选择客户名称'
      }
    },
    changeValue(value) {
      value = value.split(' / ');
      this.queryForm.params.merchantCode = value[0];
      this.queryForm.params.merchantName = value[1];
    },
    default() { // 默认
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.selectTime = 't0';
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.numberList = '';
      this.queryForm.params = {
        // startTime: '', // 期望揽收时间
        // endTime: '',
        signStartTime: '', // 签收开始时间
        signEndTime: '', // 签收结束时间
        closeStartTime: '', // 闭环时间
        closeEndTime: '',
        createStartTime: today + ' 00:00:00', // 订单创建时间
        createEndTime: today + ' 23:59:59', // 订单创建时间——结束时间
        scanStartTime: '', // 实际揽收时间
        scanEndTime: '',
        tagNumber: '',
        packageNumber: '', // 包裹号
        customerNumber: '', // 客户订单号
        orderNumber: '', // 揽收订单号
        customerId: '', // 客户名称
        orderType: '', // 订单类型
        scanNumber: '',
        collectStatusList: [], // 订单状态
        siteIdList: [], // 站点
        signSiteIdList: [], // 签收网点
        driverId: '', // 司机 / 揽收人
        signDriverId: '', // 签收人
        senderZipCode: '', // 寄件邮编
        receiverZipCode: '', // 收件邮编
        merchantCode: '', // 客户商户号
        merchantName: '', // 客户商户名称
        customerCollectType: '',
        platform: '', // 所属平台
        senderState: '',
        receiveState: '',
        senderCity: '',
        receiveCity: '',
        batchNo: '', // 揽收波次号
        orderBagNumber: '' // 袋号
      };
      this.customerId = '';
      this.selectCity = 'c1';
      this.selectState = 's1';
    },
    changeTime() { // 切换所选时间——清空未选时间
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params.scanStartTime = '';
      this.queryForm.params.scanEndTime = '';
      this.queryForm.params.signStartTime = '';
      this.queryForm.params.signEndTime = '';
      this.queryForm.params.closeStartTime = '';
      this.queryForm.params.closeEndTime = '';
      this.queryForm.params.createStartTime = '';
      this.queryForm.params.createEndTime = '';
      this.queryForm.params.batchNoStartTime = '';
      this.queryForm.params.batchNoEndTime = '';
      if (this.selectTime === 't0') {
        this.queryForm.params.createStartTime = today + ' 00:00:00';
        this.queryForm.params.createEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.scanStartTime = today + ' 00:00:00';
        this.queryForm.params.scanEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't3') {
        this.queryForm.params.signStartTime = today + ' 00:00:00';
        this.queryForm.params.signEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't4') {
        this.queryForm.params.closeStartTime = today + ' 00:00:00';
        this.queryForm.params.closeEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't5') {
        this.queryForm.params.batchNoStartTime = today + ' 00:00:00';
        this.queryForm.params.batchNoEndTime = today + ' 23:59:59';
      }
    },
    getLanShouOrdersPage() { // 揽收订单表
      const queryForm = JSON.parse(localStorage.getItem('lanshouOrdersQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectOrderPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.idList = [];
          this.tableDate = res.data.records;
          this.total = res.data.total;
          this.scollerFunc();
          this.ifExport = res.extra;
          // 数据脱敏处理
          this.desensitization();
        }
      });
    },
    // 数据脱敏处理
    desensitization() {
      // 根据权限控制敏感信息脱敏处理
      const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
      if (!hasPermission) {
        this.tableDate.map((item) => {
          item.senderAddress = commonDesensitize(item.senderAddress);
          item.logisticsInvoiceNo = commonDesensitize(item.logisticsInvoiceNo);
          item.logisticsInvoiceSecretKey = commonDesensitize(item.logisticsInvoiceSecretKey);
        });
      }
    },
    scollerFunc() {
      if (this.$refs.tableList) {
        const dom = this.$refs.tableList.bodyWrapper;
        dom.scrollTop = 0;
      }
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('lanshouOrdersQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      collectOrderPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
          this.ifExport = res.extra;
          this.scollerFunc();
          // 数据脱敏处理
          this.desensitization();
        }
      });
    },
    checkNumQuery() {
      this.queryForm.params.scanNumber = '';
      this.queryForm.params.orderNumber = '';
      this.queryForm.params.packageNumber = '';
      this.queryForm.params.customerNumber = '';
      this.queryForm.params.batchNo = '';
      this.queryForm.params.orderBagNumber = '';
      if (this.selectCode === 'c1') {
        this.queryForm.params.scanNumber = this.numberList;
      }
      if (this.selectCode === 'c2') {
        this.queryForm.params.orderNumber = this.numberList;
      }
      if (this.selectCode === 'c3') {
        this.queryForm.params.packageNumber = this.numberList;
      }
      if (this.selectCode === 'c4') {
        this.queryForm.params.customerNumber = this.numberList;
      }
      if (this.selectCode === 'c5') {
        this.queryForm.params.batchNo = this.numberList;
      }
      if (this.selectCode === 'c6') {
        this.queryForm.params.orderBagNumber = this.numberList;
      }
    },
    searchClick() { // 查询
      if (this.selectCode === 'c5' && this.queryForm.params.batchNo) {
        this.resetBatchNo();
      }
      this.checkNumQuery();
      /*
       *  有包裹号：删掉时间
       *  没有包裹号，也没有时间：提示选择时间 return
       */
      if (
        this.queryForm.params.scanNumber ||
        this.queryForm.params.orderNumber ||
        this.queryForm.params.packageNumber ||
        this.queryForm.params.customerNumber ||
        this.queryForm.params.batchNo ||
        this.queryForm.params.orderBagNumber ||
        this.queryForm.params.tagNumber
      ) {
        this.timeValue = [];
        this.queryForm.params.scanStartTime = '';
        this.queryForm.params.scanEndTime = '';
        this.queryForm.params.signStartTime = '';
        this.queryForm.params.signEndTime = '';
        this.queryForm.params.closeStartTime = '';
        this.queryForm.params.closeEndTime = '';
        this.queryForm.params.createStartTime = '';
        this.queryForm.params.createEndTime = '';
        this.queryForm.params.batchNoStartTime = '';
        this.queryForm.params.batchNoEndTime = '';
      } else if ((this.queryForm.params.scanStartTime && this.queryForm.params.scanEndTime) ||
        (this.queryForm.params.signStartTime && this.queryForm.params.signEndTime) ||
        (this.queryForm.params.createStartTime && this.queryForm.params.createEndTime) ||
        (this.queryForm.params.closeStartTime && this.queryForm.params.closeEndTime) || (this.queryForm.params.batchNoStartTime && this.queryForm.params.batchNoEndTime)) {
        //
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      this.idList = [];
      this.queryForm.pageNumber = 1;
      localStorage.setItem('lanshouOrdersQueryForm', JSON.stringify(this.queryForm));
      this.getLanShouOrdersPage();
      this.scollerFunc();
    },
    resetClick() { // 重置
      this.default();
      this.changeMerchant();
    },
    cellClick(row) {
      this.drawerIsOpen = true;
      // this.drawerList = row;
      CollectOrderVO(row.id).then(od => {
        if (od.status === 'OK') {
          this.drawerList = od.data;
        }
      });
    },
    batchSelect(selection, row) {
      const collectOrderIdList = [];
      selection.forEach(list => {
        collectOrderIdList.push(list.id);
      });
      this.batchSelectList = {
        collectOrderIdList: collectOrderIdList
      };
      this.idList = collectOrderIdList;
      if (selection[0].senderAddress) {
        this.dialogForm.expectLanshouAddress = selection[0].senderAddress;
      }
    },
    batchSelectAll(selection) {
      const collectOrderIdListAll = [];
      selection.forEach(list => {
        collectOrderIdListAll.push(list.id);
      });
      this.batchSelectList = {
        collectOrderIdList: collectOrderIdListAll
      };
      this.idList = collectOrderIdListAll;
      if (selection[0].senderAddress) {
        this.dialogForm.expectLanshouAddress = selection[0].senderAddress;
      }
    },
    download() { // 导出
      this.isClick = true;
      if (this.idList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
      } else {
        const queryFormParams = JSON.parse(localStorage.getItem('lanshouOrdersQueryForm'));
        const queryForm = {
          pageNumber: this.queryForm.pageNumber,
          pageSize: this.queryForm.pageSize,
          idList: this.idList,
          params: queryFormParams.params || this.queryForm.params
        };
        orderExport(queryForm).then(res => {
          if (res.status === 'OK') {
            this.isClick = false;
            const h = this.$createElement;
            this.$msgbox({
              // '提示'
              title: this.$t('collectionCenter.tips'),
              message: h('p', null, [
                // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
                h('span', this.$t('collectionCenter.exportChangeTip')),
                // '请勿重复导出！'
                h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
              ]),
              showCancelButton: true,
              // '立即前往'
              confirmButtonText: this.$t('collectionCenter.Immediately'),
              // '关闭'
              cancelButtonText: this.$t('collectionCenter.close')
            }).then(action => {
              if (action === 'confirm') {
                this.$router.push({ path: '/resource/exportTaskList' });
              }
            }).catch(() => {
            });
          }
        }).catch(err => {
          console.log(err);
          this.isClick = false;
        });
      }
    },
    downloadAll() { // 全部导出
      this.isClickAll = true;
      const queryForm = JSON.parse(localStorage.getItem('lanshouOrdersQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectOrderExportAll(queryForm).then(res => {
        if (res.status === 'OK') {
          this.isClickAll = false;
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      }).catch(() => {
        this.isClickAll = false;
      });
    },
    BlockNewlineSenderState() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.senderState.split('\n');
      if (!this.notEnter) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineReceiveState() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.receiveState.split('\n');
      if (!this.notEnter) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineSenderCity() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.senderCity.split('\n');
      console.log(stateLen, 'stateLen');
      if (!this.notEnter) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineReceiveCity() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.receiveCity.split('\n');
      if (!this.notEnter) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    cancelDriverClick() { // 取消调度司机
      if (this.batchSelectList.collectOrderIdList.length <= 0) {
        this.$message({
          // '请勾选数据'
          message: this.$t('collectionCenter.checkMsg'),
          type: 'warning'
        });
      } else {
        const collectOrderId = {
          collectOrderIdList: this.batchSelectList.collectOrderIdList
        };
        cancelDriver(collectOrderId).then(res => {
          if (res.status === 'OK') {
            this.$message({
              // '已取消'
              message: this.$t('collectionCenter.cancelled'),
              type: 'success'
            });
            this.getLanShouOrdersPage();
            this.batchSelectList = {
              collectOrderIdList: [],
              driverId: '',
              siteId: ''
            };
          }
        });
      }
    },
    schedulingDriver(indexx) { // 调度司机
      //                                        '调度司机'                                    : '重新调度司机'
      this.dialogTitle = indexx === 1 ? this.$t('collectionCenter.schedulingDriver') : this.$t('collectionCenter.schedulingDriverAgain');
      if (this.batchSelectList.collectOrderIdList.length <= 0) {
        this.$message({
          // '请勾选数据'
          message: this.$t('collectionCenter.checkMsg'),
          type: 'warning'
        });
      } else {
        this.dialogVisible = true;
      }
    },
    chooseSite() {
      if (this.dialogForm.siteName) {
        collectDriverQueryBySiteId(this.dialogForm.siteName).then(res => {
          if (res.status === 'OK') {
            this.dialogForm.driverId = '';
            this.driverList = res.data;
          }
        });
      }
    },
    focusDriver() {
      if (!this.dialogForm.siteName) {
        this.$message({
          // '请选择站点'
          message: this.$t('collectionCenter.pleaseCheckSite'),
          type: 'warning'
        });
      }
    },
    dialogConfirm() { // 调度司机 —— 确定
      const againTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
      const againTime1 = againTime.split(' ');
      const chooseTime = this.dialogForm.expectLanshouTime.split(' ');
      this.$refs['ScheduleBox'].validate((valid) => {
        if (valid) {
          this.isSubmit = true;
        }
        if (!valid) {
          this.isSubmit = false;
        }
      });
      if (!this.dialogForm.siteName) {
        this.isSubmit = false;
        this.$message({
          // '请选择站点'
          message: this.$t('collectionCenter.pleaseCheckSite'),
          type: 'warning'
        });
      } else if (!this.dialogForm.driverId) {
        this.isSubmit = false;
        this.$message({
          // '请选择司机'
          message: this.$t('collectionCenter.pleaseCheckDriver'),
          type: 'warning'
        });
      } else if (!this.dialogForm.expectLanshouTel) {
        this.isSubmit = false;
        this.$message({
          // '请输入手机号'
          message: this.$t('collectionCenter.PleaseEnterPhone'),
          type: 'warning'
        });
      } else if (!this.dialogForm.expectLanshouTime) {
        this.isSubmit = false;
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
      } else if (againTime1[0] === chooseTime[0] && this.dialogForm.expectLanshouTime < againTime) {
        this.isSubmit = false;
        this.$message.warning(this.$t('collectionCenter.selectedTimeThanCurrentTime')); // '选中时间需大于当前时间'
      } else {
        this.isSubmit = true;
      }
      if (this.isSubmit) {
        this.batchSelectList = {
          collectOrderIdList: this.idList,
          driverId: this.dialogForm.driverId,
          siteId: this.dialogForm.siteName,
          expectTime: this.dialogForm.expectLanshouTime,
          telephone: this.dialogForm.expectLanshouTel,
          address: this.dialogForm.expectLanshouAddress,
          remark: this.dialogForm.expectLanshouScanNumber
        };
        schedulingDriver(this.batchSelectList).then(res => {
          if (res.status === 'OK') {
            this.$message({
              // '调度成功'
              message: this.$t('collectionCenter.schedulingSuccess'),
              type: 'success'
            });
            this.$refs.tableList.clearSelection();
            this.dialogVisible = false;
            this.getLanShouOrdersPage();
            this.batchSelectList = {
              collectOrderIdList: [],
              driverId: '',
              siteId: ''
            };
            this.dialogCancel();
          }
          this.dialogCancel();
        }).catch(() => {
          this.dialogForm = {
            driverId: '',
            siteName: '',
            expectLanshouTime: '', // 调度司机 期望揽收
            expectLanshouTel: '', // 联系电话
            expectLanshouAddress: '', // 揽收地址
            expectLanshouScanNumber: '' // 备注
          };
          this.$nextTick(() => {
            this.$refs['ScheduleBox'].clearValidate();
          });
          this.dialogVisible = false;
        });
      }
    },
    dialogCancel() {
      this.dialogForm = {
        driverId: '',
        siteName: '',
        expectLanshouTime: '', // 调度司机 期望揽收
        expectLanshouTel: '', // 联系电话
        expectLanshouAddress: '', // 揽收地址
        expectLanshouScanNumber: '' // 备注
      };
      this.batchSelectList = {
        collectOrderIdList: [],
        driverId: '',
        siteId: ''
      };
      this.$nextTick(() => {
        this.$refs['ScheduleBox'].clearValidate();
      });
      this.dialogVisible = false;
      this.$refs.tableList.clearSelection();
    },
    // 重置波次号
    resetBatchNo() {
      if (this.selectCode === 'c5' && this.queryForm.params.batchNo) {
        this.timeValue = [];
        this.customerId = '';
        this.merchantCodeValue = '';
        this.queryForm.params = {
          ...this.queryForm.params,
          // startTime: '', // 期望揽收时间
          // endTime: '',
          signStartTime: '', // 签收开始时间
          signEndTime: '', // 签收结束时间
          closeStartTime: '', // 闭环时间
          closeEndTime: '',
          createStartTime: '', // 订单创建时间
          createEndTime: '', // 订单创建时间——结束时间
          scanStartTime: '', // 实际揽收时间
          scanEndTime: '',
          orderBagNumber: '', // 袋号
          tagNumber: '',
          packageNumber: '', // 包裹号
          customerNumber: '', // 客户订单号
          orderNumber: '', // 揽收订单号
          customerId: '', // 客户名称
          orderType: '', // 订单类型
          scanNumber: '',
          collectStatusList: [], // 订单状态
          siteIdList: [], // 站点
          signSiteIdList: [], // 签收网点
          driverId: '', // 司机 / 揽收人
          signDriverId: '', // 签收人
          senderZipCode: '', // 寄件邮编
          receiverZipCode: '', // 收件邮编
          merchantCode: '', // 客户商户号
          merchantName: '', // 客户商户名称
          customerCollectType: '',
          platform: '', // 所属平台
          senderState: '',
          receiveState: '',
          senderCity: '',
          receiveCity: ''
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }
  .tableClass ::v-deep .el-table .cell{
    max-height: 300px;
  }

.clearImageClass {
  color: #606266;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.clearImageClass:hover {
  color: #409eff;
}

.paramsStyle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.autoWidth ::v-deep .el-input__prefix { // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}

.autoWidth ::v-deep input {
  position: absolute;
}

.selectClass ::v-deep .el-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lanShouOrdersBox .selectClass ::v-deep .el-form-item__label {
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}

.selectClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.lanShouOrdersBox .siteClass ::v-deep .el-select, .selectClass ::v-deep .el-select {
  width: 100%;
}

.siteClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  width: 100%;
}

.col2 {
  width: auto !important;
}

.drawerBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.drawerClass.mb5 {
  margin-bottom: 50px;
}

.drawerClass {
  width: 100%;
  padding: 20px;
  margin: 20px;
  box-sizing: border-box;
  border: 1px solid #DCDFE6;

  div {
    text-align: left;
    margin: 20px auto;
    font-size: 15px;

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.lanShouOrdersBox {
  padding: 20px;
  box-sizing: border-box;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dialogBox {
  padding: 20px 50px;
}

.selectClass ::v-deep .el-date-editor .el-range-separator {
  width: 28px;
}

.lanShouOrdersBox ::v-deep .el-date-editor {
  width: 100%;
}

.lanShouOrdersBox ::v-deep .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lanShouOrdersBox ::v-deep .el-dialog {
  margin-top: 0 !important;
}

.lanShouOrdersBox ::v-deep .el-table {
  font-size: 10px !important;
}

.lanShouOrdersBox .selectTimeClass {
  margin-left: 2px;
}

.lanShouOrdersBox .dialogC ::v-deep .el-select {
  width: 100%;
}

.lanShouOrdersBox .delMl ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  width: 100%;
}

.lanShouOrdersBox ::v-deep textarea.el-textarea__inner {
  max-height: 150px;
  min-width: 150px;
  max-width: 200px;
}

.lanShouOrdersBox ::v-deep textarea {
  resize: both;
}

.lanShouOrdersBox .drawerOpen ::v-deep .el-drawer__open .el-drawer.ltr {
  overflow-y: scroll;
}

/*2.隐藏滚动条，太丑了*/
.lanShouOrdersBox .drawerOpen ::v-deep .el-drawer__container ::-webkit-scrollbar {
  display: none;
}

.delMl ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}

.lanShouOrdersBox .col10 ::v-deep .el-select {
  width: 100%;
}

.lanShouOrdersBox ::v-deep .el-form-item__label {
  padding: 0;
}

.lanShouOrdersBox .m ::v-deep .el-form-item__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px !important;
}

.lanShouOrdersBox .m ::v-deep .el-form-item__content .selectTimeClass {
  width: auto !important;
}

.lanShouOrdersBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-size: 14px;
  color: #606266;
  font-weight: bold;
  text-align: right;
  padding-left: 0;
  box-sizing: border-box;
}
</style>
