<template>
  <div class="app-container">
    <div ref="searchForm" v-resize="handleResize" style="margin-bottom: 10px;">
      <el-card>
        <el-form
          ref="form" :model="queryForm" label-width="auto"
          :label-position="'top'" size="small"
        >
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <!--账单批次号-->
              <el-form-item :label="$t('receivablePayable.BillingBatchNumber')">
                <el-input v-model="queryForm.params.billBatchNumber" clearable />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <!--账单日期-->
              <el-form-item :label="$t('receivablePayable.BillingDate')">
                <el-date-picker
                  v-model="queryForm.params.payableTimes"
                  style="width: 100%"
                  range-separator="-"
                  type="daterange"
                  :start-placeholder="$t('operationCenter.startDate')"
                  :end-placeholder="$t('operationCenter.endDate')"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <!--对账状态-->
              <el-form-item :label="$t('receivablePayable.ReconciliationStatus')">
                <el-select
                  v-model="queryForm.params.auditStatus"
                  filterable
                  multiple
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <!--                    <el-option key="0" :label="$t('basicData.all')" :value="null" />-->
                  <!--待审核-->
                  <el-option key="1" :label="$t('receivablePayable.ToBeReviewed')" :value="0" />
                  <!--已通过-->
                  <el-option key="2" :label="$t('receivablePayable.Passed')" :value="1" />
                  <!--未通过-->
                  <el-option key="3" :label="$t('receivablePayable.DoesNotPass')" :value="2" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <!--站点名称-->
              <el-form-item :label="$t('basicData.siteName')">
                <QuerySiteCmp :val="queryForm.params.siteId" provider-id="" @change="val => queryForm.params.siteId = val" />
                <!-- <el-select
                  v-model="queryForm.params.siteId"
                  filterable
                  :placeholder="$t('operationCenter.Only10AreDisplayed')"
                  :remote-method="getProviderPage"
                  remote
                  clearable
                  style="width: 100%;"
                  @focus="getProviderPage('')"
                >
                  <el-option
                    v-for="item in outletsList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select> -->
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <!--客户-->
              <el-form-item :label="$t('receivablePayable.customer')">
                <el-select
                  v-model="queryForm.params.customerId"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  :remote-method="getCustomerPage"
                  :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                  style="width: 100%;"
                  @focus="getCustomerPage()"
                >
                  <el-option
                    v-for="(item, ind) in customerList"
                    :key="ind"
                    :label="item.customerAlias + '(' + item.username + ')'"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <!-- 搜索 -->
      <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources()"
        >
          {{ $t('operationCenter.Procurar') }}
        </el-button>

        <!-- 重置 -->
        <el-button
          icon="el-icon-refresh"
          size="small"
          @click="onReset"
        >{{ $t('basicData.reset') }}
        </el-button>
      </el-card>
    </div>

    <el-card>
      <div>
        <!--审核-->
        <el-button
          v-permit:remove="'btn:receivableManagementCompany:toExamine:approved'"
          size="small"
          type="primary"
          @click="operationBtn(1)"
        >{{ $t('receivablePayable.examine') }}</el-button>
        <!--自定义栏目-->
        <el-button
          style="margin-left: 10px;"
          size="small"
          type="primary"
          @click="customColumn()"
        >{{ $t('orderCenterCont.CustomizeListColumn') }}</el-button>
        <!--        &lt;!&ndash;撤回&ndash;&gt;-->
        <!--        <el-button-->
        <!--          v-permit:remove="'btn:receivableManagementCompany:toExamine:cancelAR'"-->
        <!--          size="small"-->
        <!--          type="primary"-->
        <!--          @click="operationBtn(2)">{{ $t('receivablePayable.withdraw') }}</el-button>-->
      </div>

      <div class="pending">
        <el-table
          ref="elTable"
          :key="tableKey"
          :data="tableData"
          border
          class="table-height"
          :height="tableHeightRow.height"
          :row-class-name="rowClass"
          @header-dragend="headerWidthChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          />
          <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, ind) in currentColumns"
            :key="ind"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!--账单批次号-->
              <div v-if="item.prop === 'billBatchNumber'" class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.billBatchNumber }}</div>
              <div v-else-if="item.prop === 'auditStatus'">
                <div v-if="scope.row.auditStatus === 0">{{ $t('receivablePayable.ToBeReviewed') }}</div>
                <div v-if="scope.row.auditStatus === 1">{{ $t('receivablePayable.Passed') }}</div>
                <div v-if="scope.row.auditStatus === 2">{{ $t('receivablePayable.DoesNotPass') }}</div>
              </div>
              <div v-else-if="item.prop === 'createUserName'">{{ createUser[scope.row.createUser] }}</div>
              <div v-else-if="item.prop === 'auditUserName'">{{ createUser[scope.row.auditUserId] }}</div>
              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('basicData.operate')"
            align="center"
            width="140"
            fixed="right"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div v-if="((scope.row.auditStatus === 0 || scope.row.auditStatus === 2) && $store.state.user.id === scope.row.createUser) || $store.getters.button_permissions.includes('btn:receivableManagementCompany:toExamine:recall')">
                <el-button
                  type="danger"
                  size="mini"
                  @click="recall(scope.row)"
                >{{ $t('receivablePayable.withdraw') }}</el-button><!--撤回-->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getExaminePage"
          @size-change="getExaminePage"
        />
      </div>
    </el-card>

    <!--选择栏目-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="showColumn"
      direction="ltr"
      custom-class="drawerClass"
      :title="$t('orderCenterCont.SelectColumn')"
      width="500px"
      @close="closeDrawer()"
    >
      <div style="padding: 20px;height: calc(100vh - 140px);overflow: auto" class="columnTree">
        <el-tree
          ref="elTree"
          :key="dragging"
          :data="columnList"
          :props="props"
          node-key="prop"
          default-expand-all
          check-on-click-node
          :default-checked-keys="checkedColumns"
          show-checkbox
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          @check="handleCheckChange"
          @node-drop="handleDrop"
        />
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>
    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('receivablePayable.ReconcileBatchDetails') + '-' + batchNumber"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      custom-class="drawerClass"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="80%"
    >
      <div>
        <batch-number-detail
          :key="batchNumber"
          :batch-number="batchNumber"
          :bill-batch-data="billBatchData"
          @closeDetail="closeDetail"
        />
      </div>
    </el-drawer>
    <!-- 审核-->
    <el-dialog
      :title="$t('receivablePayable.examine')"
      :visible.sync="showExamine"
      width="500px"
    >
      <div>
        <div>
          <el-form
            :key="showExamine" ref="examineForm" :model="examineForm"
            label-width="auto"
          >
            <el-form-item
              :label="$t('receivablePayable.examine') + '：'"
              prop="examine"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-radio-group v-model="examineForm.examine">
                <div style="margin-bottom: 5px;">
                  <!--通过（多条数据合并生成一个收款单）-->
                  <el-radio :label="0">{{ $t('receivablePayable.PassMultipleDataMergingToGenerateOneReceipt') }}</el-radio>
                </div>
                <div style="margin-bottom: 5px;">
                  <!--通过（多条数据生成多个收款单）-->
                  <el-radio :label="1">{{ $t('receivablePayable.GenerateMultipleReceiptsThrough') }}</el-radio>
                </div>
                <div>
                  <!--不通过-->
                  <el-radio :label="2">{{ $t('receivablePayable.DoesNotPass') }}</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <!--备注-->
            <el-form-item
              :label="$t('receivablePayable.remark') + '：'"
              prop="remark"
              :rules="{ required: examineForm.examine === 2 ? true : false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input v-model="examineForm.remark" type="textarea" :rows="6" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examine(1)">{{ $t('basicData.Cancel') }}</el-button>
        <el-button type="primary" @click="examine(2)">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import batchNumberDetail from './cmp/batchNumberDetail';
import {
  apiCustomerBillPage,
  apiCustomerBillAudit,
  apiCustomerBillWithdraw,
  apiCustomColumnTemplateList, apiCustomColumnTemplateUpdate, apiCustomColumnTemplateInsert
} from '@/api/finance/receivables';
// import { getDistributionDotList } from '@/api/logisticsOrders';
import { apiCustomerPage } from '@/api/customer';
import { cloneDeep } from 'lodash-es';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

const PARAM = {
  billBatchNumber: '', // 对账单批次号
  auditStatus: [0, 2], // 对账状态
  customerId: '', // 客户id
  siteId: '', // 站点
  payableTimes: [] // 账单日期
};

export default {
  name: 'ToExamineSiteToCustomer',
  components: {
    batchNumberDetail,
    QuerySiteCmp
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10,
        params: {
          ...PARAM
          // billBatchNumber: '', // 对账单批次号
          // auditStatus: [0, 2], // 对账状态
          // customerId: '', // 客户id
          // siteId: '' // 站点
        }
      },
      createUser: [],
      showDetail: false,
      tableData: [],
      total: 0,
      height: 100,
      tableKey: new Date().getTime(),
      batchNumber: '',
      billBatchData: {},
      currentColumns: [],
      // outletsList: [],
      selectData: [],
      rowSelectFlag: false,
      showExamine: false,
      examineForm: {
        examine: 2,
        remark: ''
      },
      customerList: [], // 客户列表
      showColumn: false,
      dragging: new Date().getTime(),
      props: {
        label: 'label',
        children: 'children'
      },
      checkedColumns: [],
      checkedSelectColumns: [],
      customColumnId: ''
    };
  },
  computed: {
    columnList() {
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            // 账单批次号
            { prop: 'billBatchNumber', label: this.$i18n.t('receivablePayable.BillingBatchNumber'), width: 160, align: 'left', highlight: false, disabled: false },
            // 账单名称
            { prop: 'billName', label: this.$i18n.t('receivablePayable.BillingName'), width: 160, align: 'left', highlight: false, disabled: false },
            // 账单日期
            { prop: 'billTime', label: this.$i18n.t('receivablePayable.BillingDate'), width: 180, align: 'left', highlight: false, disabled: false },
            // 客户
            { prop: 'customerAlias', label: this.$i18n.t('receivablePayable.customer'), width: 160, align: 'left', highlight: false, disabled: false },
            // 账单状态
            { prop: 'auditStatus', label: this.$i18n.t('receivablePayable.BillingStatus'), width: 160, align: 'left', highlight: false, disabled: false },
            // 网点名称
            { prop: 'siteName', label: this.$i18n.t('basicData.siteName'), width: 160, align: 'left', highlight: true, disabled: false },
            // 订单数量
            { prop: 'orderQty', label: this.$i18n.t('receivablePayable.TheNumberOfOrders'), width: 160, align: 'left', highlight: true, disabled: false },
            // 费用条数
            { prop: 'feeQty', label: this.$i18n.t('receivablePayable.TheNumberOfFees'), width: 160, align: 'left', highlight: false, disabled: false },
            // 计费总重量（kg）
            { prop: 'chargeWeight', label: this.$i18n.t('receivablePayable.TotalBillableWeight'), width: 160, align: 'left', highlight: false, disabled: false },
            // 应收总金额
            { prop: 'totalReceivable', label: this.$i18n.t('receivablePayable.TotalAmountReceivable'), width: 160, align: 'left', highlight: false, disabled: false },
            // 生成人员
            { prop: 'createUserName', label: this.$i18n.t('receivablePayable.BuildThePerson'), width: 160, align: 'left', highlight: false, disabled: false },
            // 生成时间
            { prop: 'createTime', label: this.$i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
            // 审核人员
            { prop: 'auditUserName', label: this.$i18n.t('receivablePayable.Auditors'), width: 160, align: 'left', highlight: false, disabled: false },
            // 审核时间
            { prop: 'auditTime', label: this.$i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },
            // 审核原因
            { prop: 'auditRemark', label: this.$i18n.t('receivablePayable.AuditReason'), width: 160, align: 'left', highlight: false, disabled: false }
          ]
        }
      ];
    }
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getHeaderColumn();
    this.getExaminePage();
  },
  mounted() {
    this.bus.$on('pendingData', () => {
      this.getExaminePage();
    });
  },
  methods: {
    // 找出两个数组中相同的元素
    findCommonElements(arr1, arr2) {
      return arr1.filter((element) => arr2.includes(element));
    },
    // 找出两个数组中不相同的元素
    findDifferentElements(arr1, arr2) {
      // 找出arr1中不存在于arr2的元素
      const uniqueToArr1 = arr1.filter(element => !arr2.includes(element));
      // 找出arr2中不存在于arr1的元素
      const uniqueToArr2 = arr2.filter(element => !arr1.includes(element));
      // 将两个数组中的不同元素合并
      return [...uniqueToArr1, ...uniqueToArr2];
    },
    // 获取当前用户自定义列表表头
    getHeaderColumn() {
      const param = {
        type: 0,
        menuEnum: 'siteToCustomerBillReview'
      };
      apiCustomColumnTemplateList(param).then(res => {
        if (res.code === 200) {
          this.customColumnId = '';
          let selectedColumn = [];
          if (res.data.length > 0) {
            this.customColumnId = res.data[0].id;
            selectedColumn = JSON.parse(res.data[0].templateField);
          }
          this.currentColumns = [];
          let allColumn = [];
          const selectedProp = [];
          const allProp = [];
          selectedColumn.map(list => {
            if (selectedProp.includes(list.prop) === false) {
              selectedProp.push(list.prop);
            }
          });
          this.columnList[0].children.map(item => {
            if (allProp.includes(item.prop) === false) {
              allProp.push(item.prop);
            }
          });
          const commonElements = this.findCommonElements(selectedProp, allProp);
          if (commonElements.length > 0) {
            commonElements.map(list => {
              this.columnList[0].children.map(item => {
                if (list === item.prop) {
                  this.currentColumns.push(item);
                }
              });
            });
          } else {
            this.columnList[0].children.map(item => {
              if (selectedProp.includes(item.prop) === false) {
                selectedProp.push(item.prop);
              }
              this.currentColumns.push(item);
              // if (item.disabled) {
              //   if (selectedProp.includes(item.prop) === false) {
              //     selectedProp.push(item.prop);
              //   }
              //   this.currentColumns.push(item);
              // }
            });
          }
          allColumn = cloneDeep(this.currentColumns);
          const differentElements = this.findDifferentElements(allProp, selectedProp);
          this.columnList[0].children.map(item => {
            if (differentElements.includes(item.prop)) {
              allColumn.push(item);
            }
          });
          // 页面加载的时候设置table的高度
          this.$nextTick(() => {
            this.columnList[0].children = cloneDeep(allColumn);
            this.tableKey = new Date().getTime();
          });
        }
      });
    },
    // 打开自定义栏目弹窗
    customColumn() {
      const selectedColumn = cloneDeep(this.currentColumns);
      this.checkedColumns = [];
      this.checkedSelectColumns = cloneDeep(this.columnList[0].children);
      this.checkedSelectColumns.map(item => {
        if (selectedColumn.length > 0) {
          selectedColumn.map(list => {
            if (item.prop === list.prop) {
              this.checkedColumns.push(item.prop);
            }
          });
        } else {
          this.checkedColumns.push(item.prop);
          // if (item.disabled === true) {
          //   this.checkedColumns.push(item.prop);
          // }
        }
      });
      this.showColumn = true;
      this.dragging = new Date().getTime();
    },
    // 确定保存已选栏目
    determineColumns() {
      const columns = [];
      const neKey = this.$refs.elTree.getCheckedKeys();
      if (neKey.length > 0) {
        this.columnList[0].children.map(list => {
          if (neKey.includes(list.prop)) {
            columns.push(list);
          }
        });
      }
      const params = {
        templateField: JSON.stringify(columns),
        templateName: '网点对客户账单审核',
        type: 0,
        menuEnum: 'siteToCustomerBillReview',
        userId: this.$store.state.user.id
      };
      if (this.customColumnId) {
        params.id = this.customColumnId;
        apiCustomColumnTemplateUpdate(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      } else {
        apiCustomColumnTemplateInsert(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      }
      this.showColumn = false;
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
      });
    },
    // 关闭自定义栏目弹窗事件将未保存的数据还原
    closeDrawer() {
      this.columnList[0].children = this.checkedSelectColumns;
    },
    // 判断节点是否能被拖拽
    allowDrag(node) {
      return node.level === 2;
    },
    // 判断节点是否可放置
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 2 && type !== 'inner') {
        return true;
      } else {
        return false;
      }
    },
    // 拖拽成功后触发事件
    handleDrop(draggedNode, insertNode, dropLocation, event) {
      this.dragging = new Date().getTime();
    },
    // 栏目勾选
    handleCheckChange(code, node) {
      this.checkedColumns = node.checkedKeys;
    },
    // 获取客户
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          this.customerList = res.data.records;
        }
      });
    },
    // 撤回
    recall(row) {
      this.$confirm(this.$i18n.t('receivablePayable.AreYouSureYouWantToWithdraw'), this.$i18n.t('basicData.tips'), { // '确定要撤回?'
        confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
        cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiCustomerBillWithdraw(row.id).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
            this.getExaminePage();
          }
        });
      });
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 监听元素宽高变化
    handleResize() {
      // this.$nextTick(() => {
      //   this.height = this.tableHeight('searchForm');
      // });
    },
    // 列表高度计算
    tableHeight(name) {
      const searchFormHeight = this.$refs[name].clientHeight; // 可变的查询高度
      const pageHeight = document.documentElement.clientHeight; // 可用窗口的高度
      const tableHeight = Number(pageHeight - searchFormHeight - 190); // 计算完之后剩余table可用的高度
      this.tableKey = new Date().getTime();
      this.$nextTick(() => {
        this.rowSelectFlag = true;
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
        this.rowSelectFlag = false;
      });
      return tableHeight;
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    // 给反审行添加背景色
    rowClass({ row, column, rowIndex, columnIndex }) {
      const rowBackground = {};
      if (row.isAuditRollback) {
        rowBackground.background = '#f00';
      }
      return rowBackground;
    },
    // 获取站点
    // getProviderPage(name = '') {
    //   const param = {
    //     pageNumber: 1,
    //     pageSize: 10,
    //     params: {
    //       siteStatus: 1,
    //       name
    //     }
    //   };
    //   getDistributionDotList(param).then(res => {
    //     if (res.status === 'OK') {
    //       this.outletsList = res.data.records;
    //     }
    //   });
    // },
    // 获取列表数据
    getExaminePage() {
      apiCustomerBillPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          res.data.records.map(item => {
            if (item.billName) {
              item.billName = item.billName.replace('T', ' ');
            }
            if (item.createTime) {
              item.createTime = item.createTime.replace('T', ' ');
            }
            if (item.updateTime) {
              item.updateTime = item.updateTime.replace('T', ' ');
            }
          });
          // this.tableData = res.data.records;
          this.tableData = (res.data.records || []).map(item => {
            return Object.freeze({ ...item });
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 列表数据勾选
    handleSelectionChange(arr) {
      if (this.rowSelectFlag) return;
      this.selectData = arr;
    },
    // 获取对账批次详情
    showBillBatchNumber(data) {
      this.batchNumber = data.billBatchNumber;
      this.billBatchData = data;
      this.showDetail = true;
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getExaminePage();
    },

    // 重置
    onReset() {
      this.queryForm.params = {
        ...PARAM
      };

      this.searchResources();
    },
    // 操作
    operationBtn(type) {
      if (this.selectData.length === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.SelectAtLeastOnePieceOfData')); // 最少要选择一条数据
        return;
      }
      if (type === 1) {
        this.showExamine = true;
        return false;
      }
    },
    // 审核保存
    examine(type) {
      if (type === 2) {
        const ids = [];
        this.selectData.map(item => {
          ids.push(item.billBatchNumber);
        });
        const param = {
          billBatchNumbers: ids,
          operationType: this.examineForm.examine,
          auditReason: this.examineForm.remark
        };
        this.$refs['examineForm'].validate((valid) => {
          if (valid) {
            apiCustomerBillAudit(param).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                this.getExaminePage();
                this.showExamine = false;
                this.examineForm.examine = 2;
                this.examineForm.remark = '';
              }
            }).catch(err => {
              console.error(err);
            });
          }
        });
      } else {
        this.showExamine = false;
        this.examineForm.examine = 2;
        this.examineForm.remark = '';
      }
    }
  }
};
</script>

<style scoped>
.batchNumber {
  color: #007efd;
  cursor: pointer;
}
</style>
<style>
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #aaa;
}
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #aaa;
}
.pending .el-table tr.background {
  background-color: #FF0000!important;
  color: #FFF;
}
.pending .el-table tr.background:hover td {
  background-color: #FF0000!important;
  color: #FFF;
}
</style>
