// 客户管理

const customerManagements = {
  customerType: {
    zh: '客户类型',
    en: 'customer type',
    pu: 'Tipo de Cliente'
  },
  all: {
    zh: '全部',
    en: 'all',
    pu: 'Tudo'
  },
  enterprise: {
    zh: '企业',
    en: 'enterprise',
    pu: 'empresa'
  },
  personal: {
    zh: '个人',
    en: 'personal',
    pu: 'pessoal fisica'
  },
  AccountName: {
    zh: '账号名称',
    en: 'Account Name',
    pu: 'Nome da conta'
  },
  CompanyName: {
    zh: '公司名称',
    en: 'Company Name',
    pu: 'Nome da empresa'
  },
  FinancialStatus: {
    zh: '财务状态',
    en: '',
    pu: 'Status financeiro'
  },
  owe: {
    zh: '欠费',
    en: '',
    pu: 'devido'
  },
  normal: {
    zh: '正常',
    en: '',
    pu: 'normal'
  },
  AccountStatus: {
    zh: '账户状态',
    en: 'Account status',
    pu: 'Status da Conta'
  },
  Approval: {
    zh: '审核',
    en: 'Approval',
    pu: 'aprovação'
  },
  ApprovalStatus: {
    zh: '审核状态',
    en: 'Approval Status',
    pu: 'Status de aprovação'
  },
  loginAccount: {
    zh: '登录账户',
    en: 'login account',
    pu: 'login na conta'
  },
  AccountNickname: {
    zh: '账户别称',
    en: 'Account nickname',
    pu: 'Nome fantasia da conta'
  },
  LegalRepresentive: {
    zh: '企业负责人',
    en: 'Legal Representive',
    pu: 'Representante Legal'
  },
  clientID: {
    zh: '客户编号',
    en: '',
    pu: 'No. do cliente'
  },
  LogisticsContact: {
    zh: '物流联系人',
    en: 'Logistics Contact',
    pu: 'Contato de Logística'
  },
  AccountBalance: {
    zh: '账户余额',
    en: 'Account Balance',
    pu: 'Saldo da conta'
  },
  credits: {
    zh: '信用额度',
    en: 'credits',
    pu: 'Limite de crédito'
  },
  creditsL: {
    zh: '信用额度：',
    en: 'credits: ',
    pu: 'Limite de crédito: '
  },
  integral: {
    zh: '积分',
    en: 'integral',
    pu: 'integrante'
  },
  grade: {
    zh: '等级',
    en: 'grade',
    pu: 'Class'
  },
  resetPassword: {
    zh: '重置密码',
    en: 'reset password',
    pu: 'reiniciar a senha'
  },
  Recharge: {
    zh: '充值',
    en: 'Recharge',
    pu: 'Recargar'
  },
  ModifyCreditLimit: {
    zh: '修改信用额度',
    en: 'Modify credit limit',
    pu: 'Modificar o limite de crédito'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitar'
  },
  default: {
    zh: '系统默认',
    en: 'default',
    pu: 'O padrão do sistema'
  },
  enclosure: {
    zh: '附件',
    en: 'enclosure',
    pu: 'acompanhamento'
  },
  clickUpload: {
    zh: '点击上传',
    en: '',
    pu: 'Clicar para upload'
  },
  address: {
    zh: '地址',
    en: 'address',
    pu: 'endereço'
  },
  submit: {
    zh: '提交',
    en: 'submit',
    pu: 'Enviar'
  },
  Disable: {
    zh: '停用',
    en: 'Disable',
    pu: 'Desativar'
  },
  Approved: {
    zh: '已审核',
    en: 'Approved',
    pu: 'Aprovado'
  },
  AddedSuccessfully: {
    zh: '添加成功',
    en: 'Added successfully',
    pu: 'Adicionado com sucesso'
  },
  AccountAudit: {
    zh: '账户审核',
    en: '',
    pu: 'Revisão de conta'
  },
  AccountOpening: {
    zh: '账户启用',
    en: '',
    pu: 'Ativação da conta'
  },
  AccountDisabled: {
    zh: '账户停用',
    en: '',
    pu: 'Desativação de conta'
  },
  AccountsRelated: {
    zh: '账户相关',
    en: '',
    pu: 'Conta relacionada'
  },
  accountName: {
    zh: '账户名称',
    en: '',
    pu: 'Nome da conta'
  },
  password: {
    zh: '密码',
    en: '',
    pu: 'Senha'
  },
  passwordPrompt: {
    zh: '请输入密码',
    en: '',
    pu: 'Por favor digite a senha'
  },
  NotAudit: {
    zh: '未审核',
    en: '',
    pu: 'Não revisado'
  },
  approved: {
    zh: '审核通过',
    en: '',
    pu: 'Auditado e aprovado'
  },
  AccStatus: {
    zh: '账号状态：',
    en: '',
    pu: 'status da conta: '
  },
  enable: {
    zh: '启用',
    en: 'enable',
    pu: 'habilitar'
  },
  agent: {
    zh: '经办人',
    en: '',
    pu: 'Handler'
  },
  site: {
    zh: '网点',
    en: '',
    pu: 'Ponto de rede'
  },
  CustomerPhone: {
    zh: '客户手机',
    en: '',
    pu: 'Número de celular do cliente'
  },
  CustomersEmail: {
    zh: '客户邮箱',
    en: '',
    pu: 'E-mail do cliente'
  },
  ParcelError: {
    zh: '包裹误差(KG)',
    en: '',
    pu: 'Erro de encomenda (KG)'
  },
  SkipToCompanyCheck: {
    zh: '跳转到企查查',
    en: '',
    pu: ' Pular para verificação da empresa'
  },
  MobilePhoneOfCompanyOwner: {
    zh: '企业负责人手机',
    en: '',
    pu: 'Telefone celular da pessoa encarregada do negócio'
  },
  HeadEnterprisePhone: {
    zh: '企业负责人电话',
    en: '',
    pu: 'Número de telefone da pessoa encarregada do negócio'
  },
  HeadEnterpriseEmail: {
    zh: '企业负责人邮箱',
    en: '',
    pu: 'E-mail da pessoa encarregada do negócio'
  },
  HeadEnterpriseQQ: {
    zh: '企业负责人QQ',
    en: '',
    pu: 'QQ da pessoa encarregada do negócio'
  },
  companyAddress: {
    zh: '公司地址',
    en: '',
    pu: 'Endereço da empresa'
  },
  UnifiedSocialCodeOfCredit: {
    zh: '统一信用社会代码',
    en: '',
    pu: 'Código Social de Crédito Unificado'
  },
  uploadPicturesNoMoreThan5MB: {
    zh: '可以上传图片，不超过5MB',
    en: '',
    pu: 'As imagens podem ser carregadas em até 5MB'
  },
  attachmentHasBeenUploaded: {
    zh: '已上传附件',
    en: '',
    pu: 'Os anexos foram carregados'
  },
  IdNumber: {
    zh: '身份证号',
    en: '',
    pu: 'Número de identificação'
  },
  uploadPicturesAndPDFFilesUpTo5MB: {
    zh: '可以上传图片及PDF文件，且不超过5MB',
    en: '',
    pu: 'Imagens e arquivos PDF podem ser carregados e não devem exceder 5MB'
  },
  FinancialRelated: {
    zh: '财务相关',
    en: '',
    pu: 'Relacionado a finanças'
  },
  FinancialContactPerson: {
    zh: '财务联系人',
    en: '',
    pu: 'Contato Financeiro'
  },
  FinancialContactPersonPhone: {
    zh: '财务联系人手机',
    en: '',
    pu: 'Telefone celular do contato financeiro'
  },
  FinancialContactPersonTel: {
    zh: '财务联系人电话',
    en: '',
    pu: 'Número de telefone do contato financeiro'
  },
  FinancialContactPersonEmail: {
    zh: '财务联系人邮箱',
    en: '',
    pu: 'E-mail de contato financeiro'
  },
  FinancialContactPersonQQ: {
    zh: '财务联系人QQ',
    en: '',
    pu: 'QQ do contato financeiro'
  },
  LogisticsRelated: {
    zh: '物流相关',
    en: '',
    pu: 'Relacionado com a logística'
  },
  LogisticsContactPersonPhone: {
    zh: '物流联系人手机',
    en: '',
    pu: 'Telefone celular de contato logístico'
  },
  LogisticsContactPersonTel: {
    zh: '物流联系人电话',
    en: '',
    pu: 'Número de telefone do contato logístico'
  },
  LogisticsContactPersonEmail: {
    zh: '物流联系人邮箱',
    en: '',
    pu: 'E-mail de contato de logística'
  },
  LogisticsContactPersonQQ: {
    zh: '物流联系人QQ',
    en: '',
    pu: 'QQ do contato de logística'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reconfigurar'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  IfAgreeAudit: {
    zh: '是否同意审核？',
    en: '',
    pu: 'A revisão está de acordo?'
  },
  NoteDefaultAccount: {
    zh: '备注：审核通过后，该账户默认为',
    en: '',
    pu: 'Nota: Após a auditoria, a conta será padrão para'
  },
  accountState: {
    zh: '状态',
    en: 'state',
    pu: 'status'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'determinar'
  },
  ConfirmOperationAccount: {
    zh: '确定对该账户进行',
    en: '',
    pu: 'Certifique-se de que a conta está'
  }, // 确定对该账户进行  '启用' / '停用'   操作？
  operation: {
    zh: '操作？',
    en: 'operation ?',
    pu: 'Operação ?'
  },
  accountNameNotEmpty: {
    zh: '账户名称不能为空',
    en: '',
    pu: 'O nome da conta não pode estar vazio'
  },
  passwordNotEmpty: {
    zh: '密码不能为空',
    en: '',
    pu: 'A senha não pode estar vazia'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  input8to12SumCode: {
    zh: '请输入8-12位数字和英文组合',
    en: '',
    pu: 'Favor digitar uma combinação de 8-12 dígitos e inglês'
  },
  pleaseCheckSite: {
    zh: '请选择站点',
    en: '',
    pu: 'favor selecionar o ponto'
  },
  PleaseFillRequiredFieldsCorrectly: {
    zh: '请正确填写必填项',
    en: '',
    pu: 'Favor preencher corretamente os campos obrigatórios'
  },
  UploadOnlyBe: {
    zh: '上传只能是',
    en: '',
    pu: 'Os uploads só podem ser'
  }, // 上传只能是  格式!
  format: {
    zh: '格式!',
    en: '',
    pu: 'Formato!'
  },
  sizeNoMoreThan2MB: {
    zh: '上传大小不能超过 2MB!',
    en: '',
    pu: 'O tamanho do upload não pode exceder 2MB!'
  },
  ModificationSuccessful: {
    zh: '修改成功',
    en: 'Modification successful',
    pu: 'Modificação bem sucedida'
  },
  AccountVerificationSucceeded: {
    zh: '账号审核成功',
    en: '',
    pu: 'Revisão de conta bem sucedida'
  },
  accountHasBeenDeactivated: {
    zh: '该账号已停用',
    en: '',
    pu: 'Esta conta foi desativada'
  },
  accountHasBeenEnabled: {
    zh: '该账号已启用',
    en: '',
    pu: 'Esta conta está habilitada'
  },
  TopUpAmount: {
    zh: '充值金额',
    en: '',
    pu: 'Valor da recarga'
  },
  addCustomer: {
    zh: '新增客户',
    en: '',
    pu: 'Novos clientes adicionados'
  },
  resetSucceeded: {
    zh: '重置成功',
    en: '',
    pu: 'redefinir com sucesso'
  },
  newPwd: {
    zh: '新密码',
    en: '',
    pu: 'novas enha'
  },
  inputNewPwd: {
    zh: '请输入新密码',
    en: '',
    pu: 'Favor nova senha'
  },
  inputHint: {
    zh: '必须由 8-12 位小写或大写字母、数字组成，可选特殊符号',
    en: '',
    pu: 'Deve consistir de 8-12 letras minúsculas ou maiúsculas, números, símbolos especiais opcionais'
  },
  defaultPassword: {
    zh: '默认密码',
    en: '',
    pu: 'senha padrão'
  },
  checkPwd: {
    zh: '确认密码',
    en: '',
    pu: 'Confirmar senha'
  },
  inputNewCodeAgain: {
    zh: '请再次输入新密码',
    en: '',
    pu: 'Por favor digite novamente a nova senha'
  },
  inputConfirmCode: {
    zh: '请输入确认密码',
    en: '',
    pu: 'Introduza a senha de confirmação, por favor'
  },
  checkPwdNotNewPwd: {
    zh: '确认密码与新密码不相同',
    en: '',
    pu: 'a senha de confirmação não é a mesma que a nova senha'
  },
  pleaseEnterTheFullCompanyName: {
    zh: '请输入公司全称',
    en: '',
    pu: 'Por favor, digite o nome completo da empresa'
  },
  SubmittedOn: {
    zh: '提交于',
    en: 'Submitted on',
    pu: 'Enviado em'
  },
  deleted: {
    zh: '该内容已经被作者删除',
    en: '',
    pu: 'Este conteúdo foi removido pelo autor'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  AreYouSureDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure to delete it',
    pu: 'Tem certeza de apagar?'
  },
  NoContentYet: {
    zh: '暂无内容',
    en: 'No content yet',
    pu: 'Nenhum conteúdo ainda'
  },
  allChannels: {
    zh: '全部渠道',
    en: '',
    pu: 'Todos os canais'
  },
  rightToLeft: {
    zh: '从右侧移除到左侧',
    en: '',
    pu: 'Remover da direita para a esquerda'
  },
  leftToRight: {
    zh: '从左侧添加至右侧',
    en: '',
    pu: 'Adicionar da esquerda para a direita'
  },
  SelectedChannel: {
    zh: '已选渠道',
    en: '',
    pu: 'Canais selecionados'
  },
  Save: {
    zh: '保存',
    en: 'Save',
    pu: 'Salvar'
  },
  EditSave: {
    zh: '编辑保存',
    en: '',
    pu: 'Editar para salvar'
  },
  pleaseCheckLeftChannel: {
    zh: '请勾选左边渠道',
    en: '',
    pu: 'Por favor, assinale os canais à esquerda'
  },
  SetBasicCustomerInformationFirst: {
    zh: '请先设置客户基本信息',
    en: '',
    pu: 'Por favor, estabeleça primeiro as informações básicas do cliente'
  },
  pleaseSelectChannel: {
    zh: '请选择渠道',
    en: '',
    pu: 'Por favor, selecione um canal'
  },
  channel: {
    zh: '渠道：',
    en: '',
    pu: 'Canal:'
  },
  test: {
    zh: '（下单量/限额/称重重量差）只能填写正整数',
    en: '',
    pu: '(quantidade do pedido/limite/ diferença de peso) só pode ser preenchido com números inteiros positivos'
  },
  SetupSucceeded: {
    zh: '设置成功',
    en: 'Setup succeeded',
    pu: 'Configuração bem sucedida'
  },
  title: {
    zh: '标题',
    en: '',
    pu: 'Título'
  },
  authorizationCode: {
    zh: '授权码',
    en: '',
    pu: 'Código de autorização'
  },
  generateAuthorizationCode: {
    zh: '生成授权码',
    en: '',
    pu: 'Gerar Código de Autorização'
  },
  CustomerNoExist: {
    zh: '当前客户不存在',
    en: '',
    pu: 'O cliente atual não existe'
  },
  noAuthorizationCode: {
    zh: '暂无授权码（请点击右上角按钮生成或者联系管理员）',
    en: '',
    pu: 'Nenhum código de autorização disponível (favor clicar no botão no canto superior direito para gerar um ou entrar em contato com o administrador)'
  },
  copyAuthorizationCode: {
    zh: '复制授权码',
    en: '',
    pu: 'Cópia do Código de Autorização'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'Adicionar'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  View: {
    zh: '查看',
    en: 'View',
    pu: 'ver'
  },
  ReplicationSucceeded: {
    zh: '复制成功',
    en: 'Replication succeeded',
    pu: 'Replicação bem sucedida'
  },
  basicInformation: {
    zh: '基本信息',
    en: '',
    pu: 'Informações básicas'
  },
  WhetherToContinue: {
    zh: '之前生成的授权码将会失效, 是否继续?',
    en: '',
    pu: 'O código de autorização gerado anteriormente será invalidado, quer continue?'
  },
  AuthorizationCodeSuccessfully: {
    zh: '授权码生成成功',
    en: '',
    pu: 'Código de autorização gerado com sucesso'
  },
  PleaseUpgradeOrManualCopy: {
    zh: '当前浏览器版本过低，请升级浏览器或手动复制',
    en: '',
    pu: 'A versão atual do navegador é muito baixa, por favor atualize seu navegador ou copie manualmente'
  },
  IncorrectFormatting: {
    zh: '格式不正确',
    en: '',
    pu: 'Formatação incorreta'
  },
  createConsignee: {
    zh: '新建收件人',
    en: 'create consignee',
    pu: 'criar o destinário'
  },
  editConsignee: {
    zh: '编辑收件人',
    en: 'edit consignee',
    pu: 'editar o destinário'
  },
  createSender: {
    zh: '新建发件人',
    en: 'create sender',
    pu: 'criar o remetente'
  },
  editSender: {
    zh: '编辑发件人',
    en: 'edit sender',
    pu: 'editar o rementente'
  },
  createSiteName: {
    zh: '创建网点',
    en: 'create site',
    pu: 'criar o Ponto de rede'
  },
  updateSiteName: {
    zh: '修改网点',
    en: 'edit site',
    pu: 'editar o Ponto de rede'
  },
  priceTerms: {
    zh: '报价方式',
    en: '',
    pu: 'Método de cotação'
  },
  oneParagraph: {
    zh: '一段(快递)',
    en: '',
    pu: 'Primeria parte (Express)'
  },
  twoParagraph: {
    zh: '二段(揽收 + 派送)',
    en: '',
    pu: 'Segunda parte (Coleta+Entrega)'
  },
  VolumeBilling: {
    zh: '使用材积计费',
    en: 'Volume billing',
    pu: 'Faturamento por volume'
  },
  messageType: {
    zh: '消息类型',
    en: '',
    pu: 'Tipo de mensagem'
  },
  isTankuang: {
    zh: '是否弹框',
    en: '',
    pu: 'pop-up?'
  },
  acceptObject: {
    zh: '接收对象',
    en: '',
    pu: 'Destinatários'
  },
  headline: {
    zh: '标题',
    en: '',
    pu: 'Título'
  },
  updataTip: {
    zh: '更新提示',
    en: '',
    pu: 'Dica de renovação'
  },
  info: {
    zh: '通知',
    en: '',
    pu: 'Notificações'
  },
  content: {
    zh: '内容',
    en: '',
    pu: 'Conteúdo'
  },
  newcontent: {
    zh: '新增系统消息',
    en: '',
    pu: 'Novar mensagem de sistema'
  },
  FTPInformation: {
    zh: 'FTP信息',
    en: 'FTP information',
    pu: 'Informações de FTP'
  },
  otherInformation: {
    zh: '其他设置',
    en: 'Other information',
    pu: 'Outras configurações'
  },
  FTPAddress: {
    zh: 'FTP地址',
    en: 'FTP address',
    pu: 'Endereço FTP'
  },
  FTPAccount: {
    zh: 'FTP账号',
    en: 'FTP account',
    pu: 'Conta FTP'
  },
  FTPPassword: {
    zh: 'FTP密码',
    en: 'FTP password',
    pu: 'Senha FTP'
  },
  FTPPort: {
    zh: '端口',
    en: 'port',
    pu: 'Porta'
  },
  FTPPath: {
    zh: 'FTP路径',
    en: 'FTP path',
    pu: 'Caminho FTP'
  },
  fileReadingPath: {
    zh: '文件读取路径',
    en: 'file Reading Path',
    pu: 'Caminho de leitura do arquivo'
  },
  ConnectionType: {
    zh: '连接类型',
    en: 'Connection type',
    pu: 'Tipo de conexão'
  },
  orderNumberType: {
    zh: '返回单号规则',
    en: '',
    pu: 'Regra do número da ordem de retorno'
  },
  channel01: {
    zh: '渠道',
    en: '',
    pu: 'Canal'
  },
  realName: {
    zh: '真实姓名',
    en: '',
    pu: 'Nome real'
  },
  TrajectoryUploadPath: {
    zh: '轨迹上传路径',
    en: 'Trajectory upload path',
    pu: 'Trilha caminho de upload'
  },
  providerSite: {
    zh: '服务商 / 网点',
    en: '',
    pu: 'Prestador de Serviços / Ponto de Atendimento'
  },
  providerSystem: {
    zh: '服务商系统',
    en: '',
    pu: 'Sistema do Prestador de Serviços'
  },
  expressSystem: {
    zh: '快递系统',
    en: '',
    pu: 'Sistema de correio'
  },
  userSide: {
    zh: '用户端',
    en: '',
    pu: 'Usuário'
  },
  account: {
    zh: '账号',
    en: '',
    pu: 'Conta'
  },
  genterTiem: {
    zh: '生效时间',
    en: '',
    pu: 'Data de vigência'
  },
  newCusTime: {
    zh: '新增客户时效',
    en: '',
    pu: 'Adicione prazo de cliente'
  },
  downloadTimeTable: {
    zh: '下载时效表',
    en: '',
    pu: 'Baixe tabela de prazo'
  },
  selectCus: {
    zh: '选择客户',
    en: '',
    pu: 'Selecine cliente'
  },
  timeCalcuDay: {
    zh: '时效计算天数',
    en: '',
    pu: 'Cálculo de dias de prazo'
  },
  weekOne: {
    zh: '周一',
    en: '',
    pu: 'Segunda-feira'
  },
  weekTwo: {
    zh: '周二',
    en: '',
    pu: 'Terça-feira'
  },
  weekThree: {
    zh: '周三',
    en: '',
    pu: 'Quarta-feira'
  },
  weekFoure: {
    zh: '周四',
    en: '',
    pu: 'Quinta-feira'
  },
  weekFirtFive: {
    zh: '周五',
    en: '',
    pu: 'Sexta-feira'
  },
  weekSix: {
    zh: '周六',
    en: '',
    pu: 'Sábado'
  },
  weekSeven: {
    zh: '周日',
    en: '',
    pu: 'Domingo'
  },
  pleaseUploadLI: {
    zh: '(请先下载模板,后上传)',
    en: '',
    pu: '(Baixe o modelo e upload)'
  },
  newOptioner: {
    zh: '最新操作人',
    en: '',
    pu: 'Último operador'
  },
  newOptionTime: {
    zh: '最新操作时间',
    en: '',
    pu: 'Último tempo de operação'
  },
  areSureStop: {
    zh: '确定启用/停用该客户时效吗?',
    en: '',
    pu: 'Confirme activar/desactivar este prazo de cliente?'
  },
  areSureStop1: {
    zh: '确定启用/停用该运输时长吗?',
    en: '',
    pu: 'Confirme activar/desactivar este duração de transporte??'
  },
  areSureStop2: {
    zh: '确定启用/停用该中转时长吗?',
    en: '',
    pu: 'Confirme activar/desactivar este Duração de trânsito?'
  },
  pleaseUploadFile: {
    zh: '请上传时效表文件',
    en: '',
    pu: 'Upload o documento de tabela de prazo'
  },
  dataUpdateTime: {
    zh: '数据更新时间',
    en: '',
    pu: 'Horário da Última Atualização de Dados'
  },
  NumberArrived: {
    zh: '到件票数',
    en: '',
    pu: 'Qtd. de pacotes recebidos'
  },
  loaderArrived: {
    zh: '装车票数',
    en: '',
    pu: 'Qtd. de pacotes carregados'
  },
  differenceArrived: {
    zh: '差异票数',
    en: '',
    pu: 'Qtd. de pacotes com divergências'
  },
  NumberVotes: {
    zh: '滞留票数',
    en: '',
    pu: 'Qtd. de Pacotes Retidos'
  },
  downloadNumber: {
    zh: '下载单号',
    en: '',
    pu: 'Baixar Nº de pedido'
  },
  HoursDetention24: {
    zh: '24小时滞留件',
    en: '',
    pu: 'Pacotes Retidos por 24 Horas'
  },
  HoursDetention48: {
    zh: '48小时滞留件',
    en: '',
    pu: 'Pacotes Retidos por 48 Horas'
  },
  HoursDetention72: {
    zh: '72小时滞留件',
    en: '',
    pu: 'Pacotes Retidos por 72 Horas'
  },
  DayDetention7: {
    zh: '7天滞留件',
    en: '',
    pu: 'Pacotes Retidos por 7 Dias'
  },
  DayDetention15: {
    zh: '15天滞留件',
    en: '',
    pu: 'Pacotes Retidos por 15 Dias'
  },
  pleaseDownLoad: {
    zh: '请勿重复下载！',
    en: '',
    pu: 'Por Favor, Não Faça Download Repetido!'
  },
  cnpjMother: {
    zh: 'CNPJ-母',
    en: '',
    pu: 'CNPJ-Matriz'
  },
  cnpjSon: {
    zh: 'CNPJ-子',
    en: '',
    pu: 'CNPJ-Filial'
  },
  inputFormat: {
    zh: '请输入格式应为xx.xxx.xxx/xxxx-xx',
    en: '',
    pu: 'Por favor, insira no formato xx.xxx.xxx/xxxx-xx'
  },
  inputFormat1: {
    zh: '可以填入多个xx.xxx.xxx/xxxx-xx, 以,分隔',
    en: '',
    pu: 'É possível inserir vários números no formato xx.xxx.xxx/xxxx-xx, separados por vírgula'
  },
  problemObjLanjie: {
    zh: '释放问题件拦截',
    en: '',
    pu: 'Liberar Interceptação de Pacotes com Problema'
  },
  ReleaseSuccess: {
    zh: '释放成功',
    en: '',
    pu: 'Liberação Bem-sucedida'
  },
  ReleaseFail: {
    zh: '释放失败',
    en: '',
    pu: 'Liberação Bem-sucedida'
  },
  packageDetail: {
    zh: '包裹明细',
    en: '',
    pu: 'Detalhes do Pacote'
  },
  loadSiteIdList: {
    zh: '装车网点',
    en: '',
    pu: 'Ponto de Carregamento'
  },
  unLoadUserName: {
    zh: '卸车账号',
    en: '',
    pu: 'Conta de Descarregamento'
  },
  loadBags: {
    zh: '装车袋数',
    en: '',
    pu: 'Qtd. de Sacas Carregadas'
  },
  loadPackageNumber: {
    zh: '装车包裹数',
    en: '',
    pu: 'Qtd. de Pacotes Carregados'
  },
  varianceNumber: {
    zh: '差异数量',
    en: '',
    pu: 'Qtd. de Discrepância'
  },
  unLoadPackageNumber: {
    zh: '卸车包裹数',
    en: '',
    pu: 'Qtd. de Pacotes Descarregados'
  },
  loadUserName: {
    zh: '装车账号',
    en: '',
    pu: 'Conta de Carregamento'
  },
  daysDelayed: {
    zh: '滞留天数',
    en: '',
    pu: 'Dias de Retenção'
  },
  latestTraceSiteList: {
    zh: '最新轨迹产生网点',
    en: '',
    pu: 'Dias de Retenção'
  },
  upTransferName: {
    zh: '上级转运中心',
    en: '',
    pu: 'Centro de trânsito superior'
  },
  newupTransferName: {
    zh: '最新轨迹上级转运中心',
    en: '',
    pu: 'Centro de Distribuição Superior da Último Rastreio'
  },
  latestTraceOperatorName: {
    zh: '最新轨迹操作账号',
    en: '',
    pu: 'Conta de Operação do Último Rastreio'
  },
  operatorNameSite: {
    zh: '次新操作人网点',
    en: '',
    pu: 'Ponto do operador da operação secundária recente'
  },
  nextNewTransferName: {
    zh: '次新上级转运中心',
    en: '',
    pu: 'Centro de transferência superior da operação secundária recente'
  },
  nextNewOperateTypeName: {
    zh: '次新操作名称',
    en: '',
    pu: 'Nome da operação secundária recente'
  },
  nextNewOperatorName: {
    zh: '次新操作人',
    en: '',
    pu: 'Operador da operação secundária recente'
  },
  detailTitle: {
    zh: '明细',
    en: '',
    pu: 'Detalhes'
  },
  returnCount: {
    zh: '退件票数',
    en: '',
    pu: 'Qtd. de Devolução'
  },
  secondaryStorageCount: {
    zh: '二次入库票数',
    en: '',
    pu: 'Qtd. de Pacotes Reentrado'
  },
  returnedShipmentsCount: {
    zh: '退仓到件票数',
    en: '',
    pu: 'Qtd. de Pacotes Recebidos após Devolução'
  },
  discrepancyCount: {
    zh: '差异',
    en: '',
    pu: 'Discrepância'
  },
  returnedShipmentsTime: {
    zh: '退仓到件时间',
    en: '',
    pu: 'Hora de Chegada na Devolução'
  },
  timeframeStartingPointName: {
    zh: '时效起点',
    en: '',
    pu: 'Ponto de início do prazo'
  },
  anDay: {
    zh: '按天',
    en: '',
    pu: 'Por dia'
  },
  anTime: {
    zh: '按小时',
    en: '',
    pu: 'Por hora'
  },
  agingType: {
    zh: '时效模式',
    en: '',
    pu: 'Modo de prazo'
  },
  editCusTime: {
    zh: '编辑客户时效',
    en: '',
    pu: 'Editar Prazo do Cliente'
  },
  Resetconditions: {
    zh: '重置条件',
    en: '',
    pu: 'Redefinir condição'
  },
  newAddress: {
    zh: '添加地址',
    en: '',
    pu: 'Adicionar endereço'
  },
  exportAddress: {
    zh: '导出地址',
    en: '',
    pu: 'Exportar endereço'
  },
  streetName: {
    zh: '街道名称',
    en: '',
    pu: 'Nome da rua'
  },
  scannerRecord: {
    zh: '扫描记录',
    en: '',
    pu: 'Registro de bipe'
  },
  newFolder: {
    zh: '新增联系人',
    en: '',
    pu: 'Adicionar contactos novos'
  },
  editFolder: {
    zh: '编辑联系人',
    en: '',
    pu: 'Editar contato'
  },
  stateTwo: {
    zh: '省份或州二字码',
    en: '',
    pu: 'Código de Duas Letras do Estado ou Província'
  },
  cityName: {
    zh: '城市名称',
    en: '',
    pu: 'Nome de cidade'
  },
  schemeName: {
    zh: '方案名称',
    en: '',
    pu: 'Nome do programa'
  },
  tip100: {
    zh: '巴西安骏快递换单操作',
    en: '',
    pu: 'Operação de troca de etiquetas da Anjun Express Brasil'
  },
  tip101: {
    zh: '当前状态不支持更改发件人信息',
    en: '',
    pu: 'O estado atual não suporta alteração das informações do remetente'
  },
  butchInfoSender: {
    zh: '批量更改发件人信息',
    en: '',
    pu: 'Alteração em massa das informações do remetente'
  },
  tip102: {
    zh: '请选择发件人信息',
    en: '',
    pu: 'Selecione as informações do remetente'
  },
  tip103: {
    zh: '确定执行这操作吗?',
    en: '',
    pu: 'Confirme activar/desactivar este prazo de cliente?'
  },
  FalseSigningWarning: {
    zh: '虚假签收警告',
    en: 'False signing warning',
    pu: 'Aviso de Assinatura Falsa'
  },
  SendCount: {
    zh: '发送次数',
    en: 'Send Count',
    pu: 'Vezes de Envio'
  },
  SOUrceName: {
    zh: '搜索菜单名称',
    en: '',
    pu: 'Pesquisar Nome do Menu'
  },
  searchMenu: {
    zh: '搜索菜单栏',
    en: 'Send Count',
    pu: 'Vezes de Envio'
  }
};

export { customerManagements };
