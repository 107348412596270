<template>
  <div class="searchBox">
    <el-form
      ref="searchForm" v-model="queryForm" :inline="false"
      label-position="top"
    >
      <el-row v-show="show3" :gutter="20">
        <el-col :span="4">
          <!--运单状态-->
          <el-form-item :label="$t('newOrder.waybillStatus')">
            <el-select
              v-model="queryForm.params.waybillStatus"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              collapse-tags
              filterable
              multiple
              size="small"
              style="width: 100%"
              @change="selectStatus"
            >
              <el-option
                v-for="(item, ind) in waybillStatusList"
                :key="ind"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--客户-->
          <el-form-item :label="$t('newOrder.CustomerName')" name="customerIds">
            <el-select
              v-model="queryForm.params.customerIds"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="getCustomerPage"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              size="small"
              style="width: 100%"
              @focus="getCustomerPage()"
            >
              <el-option
                v-for="item in customerList"
                :key="item.id"
                :label="item.customerAlias + '(' + item.username + ')'"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--收件人州-->
          <el-form-item :label="$t('newOrder.RecipientState')">
            <el-select
              v-model="queryForm.params.receiveStateList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="item in StateList"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--揽收网点-->
          <el-form-item :label="$t('collectionCenter.LanShouSite')">
            <el-select
              v-model="queryForm.params.preCollectStationId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="remoteGetSiteList"
              clearable
              filterable
              remote
              reserve-keyword
              size="small"
              style="width: 100%"
              @focus="remoteGetSiteList('')"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--预派送网点-->
          <el-form-item :label="$t('GlobalSetObj.preEntrega')">
            <el-select
              v-model="queryForm.params.preDeliverStationId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="remoteGetSiteList"
              clearable
              filterable
              remote
              reserve-keyword
              size="small"
              style="width: 100%"
              @focus="remoteGetSiteList('')"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--派送员-->
          <el-form-item :label="$t('orderCenterCont.deliveryPerson')">
            <el-select
              v-model="queryForm.params.deliveryManName"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="getallDriver"
              clearable
              filterable
              remote
              size="small"
              style="width: 100%"
              @focus="getallDriver"
            >
              <el-option
                v-for="item in drvierList"
                :key="item.expressId"
                :label="item.deliveryManName"
                :value="item.deliveryManName"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--货物类型-->
          <el-form-item :label="$t('placeOrder.cargoType')" size="small">
            <el-select v-model="queryForm.params.cargoType" style="width: 100%">
              <el-option :label="$t('GlobalSetObj.all')" value="" />
              <el-option :label="$t('placeOrder.normal01')" :value="0" />
              <el-option :label="$t('GlobalSetObj.big01')" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--包裹类型-->
          <el-form-item :label="$t('placeOrder.packageType')" size="small">
            <el-select v-model="queryForm.params.orderPackageType" style="width: 100%">
              <el-option :label="$t('GlobalSetObj.all')" :value="0" />
              <el-option :label="$t('placeOrder.zhifa')" :value="1" />
              <el-option :label="$t('orderCenterCont.hedan')" :value="2" />
              <el-option :label="$t('GlobalSetObj.big01')" :value="4" />
              <el-option :label="$t('orderCenterCont.other')" :value="3" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--收件人邮编-->
          <el-form-item :label="$t('receivablePayable.RecipientzipCode')" size="small">
            <el-input
              v-model.trim="queryForm.params.receiveZipcode"
              :placeholder="$t('orderDetails.pleaseInput')"
              clearable
              size="small"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <!--创建时间-->
          <el-form-item prop="searchTimeType">
            <template slot="label">
              <el-select
                v-model="queryForm.params.searchTimeType"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                :style="IsWidthTime"
                class="selectClass"
                size="small"
                @change="SwitchTimeType"
              >
                <!--创建时间-->
                <el-option key="1" :label="$t('newOrder.createTime')" value="1" />
                <!--客户出仓时间-->
                <el-option key="5" :label="$t('orderCenterCont.customerDeliveryTime')" value="5" />
                <!--转运入库时间-->
                <el-option key="3" :label="$t('newOrder.TransitInboundTime')" value="3" />
                <!--转运出库时间-->
                <el-option key="4" :label="$t('newOrder.shipmentTime')" value="4" />
                <!--网点入库时间-->
                <!--                <el-option key="6" :label="$t('newOrder.StorageTimeOutlets')" value="6" />-->
                <!--                网点派件时间-->
                <el-option key="7" :label="$t('orderCenterCont.deliveryTime')" value="7" />
                <!--签收时间-->
                <el-option key="2" :label="$t('orderCenterCont.SigningTime')" value="2" />
              </el-select>
            </template>
            <el-date-picker
              v-model="timeValue"
              :default-time="['00:00:00', '23:59:59']"
              :end-placeholder="$t('operationCenter.endDate')"
              :format="$i18n.locale === 'zh' ? zhDate : ptDate"
              :picker-options="pickerOptions"
              :start-placeholder="$t('operationCenter.startDate')"
              range-separator="~"
              size="small"
              style="width: 100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="handleTime"
              @input="changeInput($event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--实际派送网点-->
          <el-form-item>
            <template slot="label">
              <el-select
                v-model="queryForm.params.deliverWarehouseStationType"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                class="selectClass"
                size="small"
              >
                <el-option :label="$t('GlobalSetObj.entrega')" value="1" />
                <el-option :label="$t('GlobalSetObj.infeatSite')" value="2" />
              </el-select>
            </template>
            <el-select
              v-model="queryForm.params.deliverStationId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="remoteGetSiteList"
              clearable
              filterable
              remote
              reserve-keyword
              size="small"
              style="width: 100%"
              @focus="remoteGetSiteList('')"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item>
            <template slot="label">
              <el-select
                v-model="queryForm.params.searchNumberType"
                :placeholder="$t('orderDetails.pleaseChoose')"
                :style="IsWidth"
                class="selectClass"
                size="small"
                @change="handleChangeLable"
              >
                <!--运单号-->
                <el-option key="1" :label="$t('orderCenterCont.anjunWaybillNumber')" value="1" />
                <!--扫描单号-->
                <el-option key="8" :label="$t('GlobalSetObj.ScanNumber')" value="8" />
                <!--服务商物流单号-->
                <el-option key="5" :label="$t('GlobalSetObj.serviceLogistics')" value="5" />
                <!--客户订单号-->
                <el-option key="2" :label="$t('newOrder.PlatformOrderNumber')" value="2" />
                <!--揽收袋号-->
                <!--                <el-option key="3" :label="$t('orderCenterCont.PickUpBagNumber')" value="3" />-->
                <!--中转仓袋号-->
                <el-option key="4" :label="$t('orderCenterCont.TransitBinBagNumber')" value="4" />
                <!--派送路线-->
                <!--                <el-option key="6" :label="$t('newOrder.InvoiceNumber')" value="6" />-->
                <!--子包号-->
                <el-option key="7" :label="$t('orderCenterCont.SubpackageNumber')" value="7" />
                <!--发票密钥-->
                <el-option key="9" :label="$t('newOrder.InvoiceKey')" value="9" />
              </el-select>
            </template>
            <div style="width: 100%; margin-top: 5px">
              <el-input
                v-model="searchNumberStr"
                :placeholder="$t('newOrder.PreciseSearch1')"
                :rows="2"
                size="small"
                type="textarea"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--收件人名字-->
          <el-form-item :label="$t('receivablePayable.receiveName')" size="small">
            <el-input
              v-model.trim="queryForm.params.receiveName"
              :placeholder="$t('orderDetails.pleaseInput')"
              clearable
              size="small"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--商户号-->
          <el-form-item :label="$t('collectionCenter.merchantCode')" size="small">
            <el-input
              v-model.trim="queryForm.params.merchantCode"
              :placeholder="$t('orderDetails.pleaseInput')"
              clearable
              size="small"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--商户名称-->
          <el-form-item :label="$t('collectionCenter.MerchantName')" size="small">
            <el-select
              v-model="queryForm.params.merchantCodeList"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="getMerchantList"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              size="small"
              style="width: 100%"
              @focus="getMerchantList(searMerchantName)"
            >
              <el-option
                v-for="(item, index) in merchantList"
                :key="index"
                :label="`${item.merchantName} - ${item.merchantCode}`"
                :value="item.merchantCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <div style="margin-top: 27px">
            <!--搜索-->
            <el-button
              size="small" type="primary" @click="getData()"
            >{{ $t('newOrder.search') }}
            </el-button>
            <!--清空当前内容-->
            <el-button
              size="small" style="margin-left: 20px" type="info"
              @click="resetForm"
            >
              {{ $t('customerManagements.reset') }}
            </el-button>
            <span class="SearchExpansion" @click="show3 = !show3">
              <i v-if="show3" class="iconfont icon-a-ziyuan410" />
              <i v-else class="iconfont icon-a-ziyuan310" />
            </span>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { collectDriverQueryCourier, providerchannelall } from '@/api/logisticsOrders';
import { apiCustomerPage, apimerchantName } from '@/api/customer';
import dayjs from 'dayjs';
import { waybillStatusList } from '@/utils/enumData';
import { siteFuzzyQuery } from '@/api/lanshou';
import { getLgAreaListData } from '@/api/orderApi';

export default {
  name: 'Search',
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      show3: true,
      queryForm: {
        pageSize: 10,
        number: 1,
        params: {
          searchTimeType: '1',
          waybillStatus: [],
          createStartTime: '',
          createEndTime: '',
          waybillNumber: '',
          searchNumberType: '1',
          deliverWarehouseStationType: '1',
          // providerChannelIdList: [],
          customerIds: [],
          deliverStationId: '',
          preCollectStationId: '', // 预备揽收网点
          preDeliverStationId: '', // 预派送网点
          orderPackageType: 0,
          cargoType: '',
          merchantCode: '',
          merchantCodeList: [],
          deliveryManName: '', // 派件人
          accountType: 0,
          receiveStateList: [],
          receiveName: '',
          receiveZipcode: ''
        }
      },
      searchNumberStr: '',
      searchNumberList: [],
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      // 是否格式化成巴西时间
      isTormat: false,
      // 查询列表数据
      selectLabel: [
        { id: 1, label: this.$t('newOrder.waybillNumber'), value: 1 },
        { id: 2, label: this.$t('newOrder.PlatformOrderNumber'), value: 2 },
        // { id: 3, label: this.$t('orderCenterCont.PickUpBagNumber'), value: 3 },
        { id: 4, label: this.$t('orderCenterCont.TransitBinBagNumber'), value: 4 },
        { id: 5, label: this.$t('GlobalSetObj.serviceLogistics'), value: 5 },
        // { id: 6, label: this.$t('newOrder.InvoiceNumber'), value: 6 },
        { id: 7, label: this.$t('orderCenterCont.SubpackageNumber'), value: 7 }
      ],
      searchNameObj: { id: 1, label: this.$t('newOrder.waybillNumber'), value: 1 },
      channelall: [],
      customerList: [], // 客户列表
      timeValue: [],
      drvierList: [], // 派件员
      siteList: [], // 网点列表
      selectDate: '',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = '';
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      waybillStatusList: waybillStatusList,
      StateList: [],
      merchantList: [],
      searMerchantName: ''
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '250px' : '130px'
      };
    },
    IsWidthTime() {
      return {
        width: this.isTormat ? '200px' : '150px'
      };
    }
  },
  watch: {
    pagination: {
      handler(newVal, oldVal) {
        console.log(1);
        this.queryForm.pageSize = newVal.pageSize;
        this.queryForm.pageNumber = newVal.pageNumber;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getallDriver();
    // 获取网点前十页
    this.remoteGetSiteList();
    this.getTimer();
    this.getAddress();
    this.getAllProviderChannel();
  },
  methods: {
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.searchNumberStr = '';
      this.searMerchantName = '';
      this.queryForm.params = {
        searchTimeType: '1',
        waybillStatus: [],
        createStartTime: '',
        createEndTime: '',
        waybillNumber: '',
        searchNumberType: '1',
        deliverWarehouseStationType: '1',
        // providerChannelIdList: [],
        customerIds: [],
        deliverStationId: '',
        preCollectStationId: '', // 预备揽收网点
        preDeliverStationId: '', // 预派送网点
        orderPackageType: 0,
        cargoType: '',
        merchantCode: '',
        merchantCodeList: [],
        deliveryManName: '', // 派件人
        accountType: 0,
        receiveStateList: [],
        receiveName: '',
        receiveZipcode: ''
      };
      this.getTimer();
    },
    // 获取最近一星期时间
    getTimer() {
      console.log('获取最近一星期时间');
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      // const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params.createStartTime = this.timeValue[0];
      this.queryForm.params.createEndTime = this.timeValue[1];
      console.log();
    },
    changeInput(val) {
      this.$emit('input', val);
    },
    // 修改时间
    handleTime(val) {
      if (val) {
        this.queryForm.params.createStartTime = val[0];
        this.queryForm.params.createEndTime = val[1];
      } else {
        this.queryForm.params.createStartTime = '';
        this.queryForm.params.createEndTime = '';
      }
      // this.$emit('searchFun', this.queryForm, this.searchNumberList);
    },
    // 获取所有渠道
    getAllProviderChannel() {
      this.channelall = [];
      providerchannelall().then((res) => {
        if (res.status === 'OK') {
          console.log('获取所有渠道', res.data);
          this.channelall = res.data;
        }
      });
    },
    // 获取派送员
    getallDriver(name) {
      const data = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          deliveryManName: ''
        }
      };
      if (name) {
        if (typeof name === 'string') {
          data.params.deliveryManName = name;
        }
      }
      this.drvierList = [];
      collectDriverQueryCourier(data).then((res) => {
        if (res.status === 'OK') {
          console.log('获取派送员', res.data);
          this.drvierList = res.data;
        }
      });
    },
    /**
     * @description 分页查询网点列表
     * @param val
     */
    remoteGetSiteList(val) {
      const value = val || '';
      siteFuzzyQuery(value).then((res) => {
        if (res.status === 'OK') {
          this.siteList = res.data;
        }
      });
    },
    // 切换时间
    SwitchTimeType(val) {
      this.queryForm.params.searchTimeType = val;
    },
    handleChangeLable(val) {
      const index = val - 1;
      this.searchNameObj = this.selectLabel[index];
    },
    selectStatus(val) {
      console.log(val, 'valvalselectStatus');
      console.log(val.indexOf(0), 'STATUS');
      if (val.indexOf(0) !== -1) {
        this.queryForm.params.waybillStatus = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22
        ];
      }
      // if (val.includes(3)) {
      //   val.push(19);
      // }
    },
    // 查询单号整理
    searchNumberCollation() {
      // if (this.searchNumberStr) {
      //   this.searchNumberList = this.searchNumberStr.split('\n');
      // } else {
      //   this.searchNumberList = [];
      // }
      if (this.searchNumberStr) {
        // 单号格式化，去除空格
        const orderNumber = this.searchNumberStr.split(/[(\r\n)\r\n]+/);
        const numberList = [];
        orderNumber.map((item) => {
          if (item) {
            numberList.push(item.trim());
          }
        });
        this.searchNumberList = numberList;
      } else {
        this.searchNumberList = [];
      }
    },
    // 回车查询
    searchFun() {
      console.log('2');
      this.searchNumberCollation();
      this.queryForm.number = this.pagination.number;
      this.queryForm.pageSize = this.pagination.pageSize;
      this.$emit('searchFun', this.queryForm, this.searchNumberList);
    },
    // 搜索组件点击搜索按钮事件
    getData() {
      this.searchNumberCollation();
      this.queryForm.number = this.pagination.number;
      this.queryForm.pageSize = this.pagination.pageSize;
      this.$emit('searchFun', this.queryForm, this.searchNumberList, 1);
    },
    /**
     * @description 分页获取客户列表
     * @param { string } name 客户名称
     */
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          this.customerList = res.data.records;
        }
      });
    },
    // 获取商户列表
    getMerchantList(val = '') {
      this.searMerchantName = val;
      const data = {
        pageSize: 20,
        pageNumber: 1,
        params: {
          merchantName: this.searMerchantName
        }
      };
      apimerchantName(data).then((res) => {
        if (res.code === 200) {
          this.merchantList = res.data.records;
        }
      });
    },
    // 州
    getAddress() {
      // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map((item) => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.searchBox >>> .selectClass .el-input__inner {
  font-weight: 700 !important;
}

.SearchExpansion {
  padding: 4px 20px;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-left: 20px;
  margin-top: 5px;
}
</style>
