<template>
  <div class="container">
    <div class="searchBox">
      <el-form
        ref="searchForm"
        v-model="queryForm"
        :inline="false"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="24">
                <!--订单状态-->
                <el-form-item :label="$t('navbar.providerOrderStatus')">
                  <el-select
                    v-model="queryForm.params.providerOrderStatus"
                    :placeholder="$t('GlobalSetObj.all')"
                    clearable
                    filterable
                    size="small"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item) in orderStatusList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <!--客户-->
                <el-form-item :label="$t('newOrder.CustomerName')" name="customerId">
                  <el-select
                    v-model="queryForm.params.customerId"
                    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                    clearable
                    filterable
                    size="small"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item) in customerList"
                      :key="item.id"
                      :label="item.customerAlias + '(' + item.username + ')'"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7">
            <!--服务商名称-->
            <el-form-item :label="$t('navbar.providerName')">
              <el-select
                v-model="queryForm.params.providerId"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                filterable
                size="small"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in provideNameList"
                  :key="item.id"
                  :label="item.providerChannelName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--创建时间-->
            <el-form-item prop="searchTimeType">
              <template slot="label">
                <el-select
                  v-model="queryForm.params.searchTimeType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :style="IsWidthTime"
                  class="selectClass"
                  size="small"
                >
                  <!--创建时间-->
                  <el-option key="1" :label="$t('newOrder.createTime')" value="1" />
                </el-select>
              </template>
              <el-date-picker
                v-model="timeValue"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('operationCenter.endDate')"
                :format="$i18n.locale==='zh'?zhDate:ptDate"
                :picker-options="pickerOptions"
                :start-placeholder="$t('operationCenter.startDate')"
                range-separator="~"
                size="small"
                style="width: 100%"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              <template slot="label">
                <el-select
                  v-model="queryForm.params.searchNumberType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                  :style="IsWidth"
                  class="selectClass"
                  size="small"
                >
                  <!--运单号-->
                  <el-option key="1" :label="$t('orderCenterCont.anjunWaybillNumber')" value="1" />
                  <!--扫描单号-->
                  <el-option key="4" :label="$t('GlobalSetObj.ScanNumber')" value="4" />
                  <!--客户订单号-->
                  <el-option key="2" :label="$t('newOrder.PlatformOrderNumber')" value="2" />
                  <!--服务商物流单号-->
                  <el-option key="5" :label="$t('GlobalSetObj.serviceLogistics')" value="5" />
                </el-select>
              </template>
              <div style="width: 100%;margin-top: 5px">
                <el-input
                  v-model="queryForm.params.searchNumberStr"
                  :placeholder="$t('newOrder.PreciseSearch2')"
                  :rows="3"
                  size="small"
                  type="textarea"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div style="margin-top: 32px">
              <!--搜索-->
              <el-button
                icon="el-icon-search" size="small" type="primary"
                @click="enterGetSearch"
              >{{
                $t('newOrder.search')
              }}
              </el-button>
              <!--重置-->
              <el-button
                size="small" style="margin-left: 20px" type="info"
                @click="resetData"
              >
                {{ $t('orderCenterCont.clearCurrentContent') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <!--申请服务商单号-->
      <el-button
        v-permit:remove="'btn:logisticsOrders:distribution-dot'"
        size="small"
        type="danger"
        @click="clickapplyForProvider"
      >
        {{ $t("navbar.applyForProvider") }}
      </el-button>
      <!--   重新分配服务商   -->
      <el-button size="small" type="danger" @click="reallocate">
        {{ $t("navbar.reallocate") }}
      </el-button>
      <!-- 下载服务商标签   -->
      <el-button size="small" type="primary" @click="downloadTheMenu">
        {{ $t("navbar.downloadTheMenu") }}
      </el-button>
      <!-- 批量更改发件人信息 -->
      <el-button size="small" type="success" @click="clickChageInfo">
        {{ $t("customerManagements.butchInfoSender") }}
      </el-button>
      <!--自定义显示列-->
      <el-button
        size="small"
        style="float: right"
        type="primary"
        @click="operationTableDialog"
      >{{ $t('orderCenterCont.customizingDisplayColumns') }}
      </el-button>
      <!--全部导出-->
      <el-button
        size="small"
        style="float: right"
        type="success"
        @click="allExportOrder"
      >{{ $t('collectionCenter.allExport') }}
      </el-button>
    </div>
    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :has-operation="true"
      :header-list="tableHeaderList"
      :hide="false"
      :is-index="true"
      :is-select-btn="true"
      :is-tip="true"
      :table-data="tableData"
      @selectTable="selectTable"
      @on-checkTrajectory="trajectoryQuery"
      @to-OrderDetail="toOrderDetail"
    />
    <el-pagination
      :current-page.sync="queryForm.pageNumber"
      :page-size.sync="queryForm.pageSize"
      :page-sizes="[10, 50, 100, 200, 400]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 20px 0; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--运单详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.LogisticsOrders')"
      :visible.sync="waybillDescription.visible"
      custom-class="drawerClass"
      direction="ltr"
      size="90%"
    >
      <EditCmp
        :key="showQueryTrack.trackKey"
        :ids="waybillDescription.ids"
        :is-edit="waybillDescription.isEdit"
        @cancel="waybillDescription.visible = false"
      />
    </el-drawer>
    <!--  操作表头  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('orderCenterCont.customizingDisplayColumns')"
      :visible.sync="isoperationTable"
      :width="`${$i18n.locale === 'zh' ? '30%' : '45%'}`"
    >
      <el-form ref="tableHeaderForm" :model="tableHeaderForm" label-width="auto">
        <el-form-item label="">
          <div style="font-size: 18px">{{ $t('GlobalSetObj.HideTheColumn') }}</div>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox-group v-model="tableHeaderForm.tableHeaderList">
            <el-row :gutter="0" style="display: flex; flex-flow: wrap">
              <el-col v-for="item in bindTableColumns" :key="item.id" :span="8">
                <div style="width: 140px; word-wrap: break-word; word-break: break-all">
                  <el-checkbox :label="item.label" />
                </div>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="float: left" @click="resetTable">{{
          $t('GlobalSetObj.reset')
        }}</el-button>
        <el-button @click="isoperationTable = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="operationTable">{{
          $t('GlobalSetObj.confirm')
        }}</el-button>
      </span>
    </el-dialog>
    <!--轨迹详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.TrajectoryQuery')"
      :visible.sync="showQueryTrack.visible"
      custom-class="drawerClass"
      direction="ltr"
      size="60%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <trackQuery
          :key="showQueryTrack.trackKey"
          :query-track-param="queryTrackParam"
        />
      </div>
    </el-drawer>
    <!--  重新分配服务器  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('navbar.reallocate')"
      :visible.sync="reDistruForm.reProviderDialog"
      width="400px"
      @close="handleReProviderDialog"
    >
      <div>
        <div class="titleLabel">{{ $t('navbar.pleaseSelectProvider') }}</div>
        <el-select
          v-model="reDistruForm.providerId"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
          clearable
          filterable
          size="small"
          style="width: 100%;"
        >
          <el-option
            v-for="item in provideNameList"
            :key="item.id"
            :label="item.providerChannelName"
            :value="`${item.id},${item.providerChannelName},${item.providerChannelCode}`"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="reDistruForm.reProviderDialog = false">{{ $t('newOrder.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="comfirmDistru">{{ $t('newOrder.save') }}</el-button>
      </span>
    </el-dialog>
    <!-- 批量更改发件人信息  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('customerManagements.butchInfoSender')"
      :visible.sync="senderInformationDialog"
      width="400px"
      @close="handleReProviderDialog"
    >
      <div>
        <div class="titleLabel">{{ $t('customerManagements.tip102') }}</div>
        <el-select
          v-model="senderInformationId"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
          clearable
          filterable
          size="small"
          style="width: 100%;"
          @change="handleSenderInformationId"
        >
          <el-option
            v-for="item in senderInformationList"
            :key="item.id"
            :label="item.scheme"
            :value="item.id"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="senderInformationDialog = false">{{ $t('newOrder.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="comfirmChange">{{ $t('newOrder.save') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setDateTime2 } from '@/utils/date_util';
import AnjunTableList from '@/components/anjun-tableList';
import {
  applyProviderNumber,
  providerorderpage,
  providerorderpageExpage,
  reDistruProvider,
  updatebatchAddress
} from '@/api/logisticsOrders/index.js';
import { batchPdfDownload } from '@/utils/PDF_util';
import { CustomerPage } from '@/api/customer';
import { cloneDeep } from 'lodash-es';
import { saveAs } from 'file-saver';
import { headerList, tableHeaderForm } from './tableHeadListData/index';
import dayjs from 'dayjs';
import EditCmp from './component/EditCmp/index.vue';
import TrackQuery from './component/trackQuery/trackQuery.vue';
import { getStartupProviderChannel } from '@/api/channelManage';
import { addressPage } from '@/api/orderApi';
import { commonDesensitize } from '@/utils/desensitization';

export default {
  name: 'ServiceOroviderOrder',
  components: {
    AnjunTableList,
    EditCmp,
    TrackQuery
  },
  data() {
    return {
      TrackParam: {}, // 轨迹详情
      // 操作表头弹窗
      isoperationTable: false,
      tableHeaderForm: {
        tableHeaderList: tableHeaderForm
      },
      // 是否格式化成巴西时间
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      isTormat: false,
      timeValue: [],
      provideNameList: [],
      orderStatusList: [
        { id: 1, label: this.$t('navbar.waitGetNumber') },
        { id: 2, label: this.$t('navbar.getNumberSuccess') },
        { id: 3, label: this.$t('navbar.getNumbering') },
        { id: 4, label: this.$t('navbar.getNumberFail') }
      ],
      tableHeaderList: headerList,
      tableData: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          searchTimeType: '1',
          providerOrderStatus: '',
          searchNumberType: '1',
          providerId: '',
          customerId: '',
          searchNumberStr: ''
        }
      },
      showQueryTrack: {
        visible: false,
        trackKey: new Date().getTime()
      },
      queryTrackParam: {},
      // 勾选表格数据
      selectionList: [],
      waybillDescription: {
        visible: false,
        ids: 0,
        isEdit: false // 能否修改，如果当前订单是 取号成功则不能编辑
      },
      // 切换表头列
      bindTableColumns: headerList,
      downloadArr: [],
      exportParam: {},
      customerList: [],
      waybillIds: [],
      routeIds: [],
      reDistruForm: {
        reProviderDialog: false, // 重新分配服务商
        providerId: ''
      },
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = '';
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 6 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      senderInformationDialog: false,
      senderInformationList: [],
      senderInformationId: '', // 发件人信息id
      senderInformationIdObj: {}// 发件人对象
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '250px' : '130px'
      };
    },
    IsWidthTime() {
      return {
        width: this.isTormat ? '200px' : '150px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch();
  },
  mounted() {
    // 获取客户列表
    this.getCustomerList();
    this.getsenderInforma();
    this.getProviderList();
  },
  methods: {
    // 获取最近一星期时间
    getTimer() {
      // const day = new Date();
      // const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs().format('YYYY-MM-DD');
      // const oneWeekAgo = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
    },
    resetData() {
      // 使用默认值重置 queryForm
      const defaultParams = {
        searchTimeType: '1',
        providerOrderStatus: '',
        searchNumberType: '1',
        providerId: '',
        customerId: '',
        searchNumberStr: ''
      };
      this.getTimer();
      this.queryForm = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ...defaultParams
        }
      };
      this.getSearch();
    },
    enterGetSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 搜索
    getSearch() {
      const data = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          providerOrderStatus: this.queryForm.params.providerOrderStatus || null,
          providerId: this.queryForm.params.providerId || null,
          customerId: this.queryForm.params.customerId || null,
          waybillNumberList: [],
          customerNumberList: [],
          providerNumberList: [],
          createEndTime: '',
          createStartTime: ''
        }
      };
      if (this.queryForm.params.searchNumberStr) {
        if (this.queryForm.params.searchNumberType === '1') {
          data.params.waybillNumberList = this.queryForm.params.searchNumberStr.split('\n');
        } else if (this.queryForm.params.searchNumberType === '2') {
          data.params.customerNumberList = this.queryForm.params.searchNumberStr.split('\n');
        } else if (this.queryForm.params.searchNumberType === '4') {
          data.params.scanNumberList = this.queryForm.params.searchNumberStr.split('\n');
        } else {
          data.params.providerNumberList = this.queryForm.params.searchNumberStr.split('\n');
        }
      }
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.createStartTime = this.timeValue[0];
        data.params.createEndTime = this.timeValue[1];
      } else {
        if (!this.queryForm.params.searchNumberStr) {
          this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
          return;
        }
      }
      this.exportParam = cloneDeep(data);
      providerorderpage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.$refs.anjunTable.toggleSelection();
          this.tableData.forEach((item) => {
            item['checkTrajectory1'] = true;

            // 根据权限控制收发件人信息的脱敏处理
            const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
            if (!hasPermission) {
              item.receiveName = commonDesensitize(item.receiveName);
            }
          });
        }
      });
    },
    // 获取客户选项
    getCustomerList() {
      const param = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {}
      };
      CustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          this.customerList = res.data.records;
        }
      });
    },
    // 获取客户选项
    getsenderInforma() {
      const param = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {}
      };
      addressPage(param).then((res) => {
        if (res.status === 'OK') {
          this.senderInformationList = res.data.records;
        }
      });
    },
    // 切换发件人取值
    handleSenderInformationId(val) {
      if (val) {
        console.log(val, 'valval');
        const obj = this.senderInformationList.find(item => item.id === val);
        this.senderInformationIdObj = {
          senderName: obj.name, // 发件人姓名
          senderCompany: obj.companyName, // 发件人公司
          senderMobile: obj.mobile, // 发件人手机
          senderStreet: obj.street, // 发件人街道
          senderHouseNumber: obj.houseNumber, // 发件人门牌号
          senderArea: obj.area, // 发件人区
          senderCity: obj.city, // 发件人城市
          senderState: obj.state, // 发件人州
          senderZipcode: obj.zipcode, // 发件人邮编
          senderComplement: obj.complement, // 地址补充
          senderTax: obj.cpf || obj.cnpj, // 发件人税号
          senderIeNumber: obj.ieNumber, // 州注册号
          senderAddress: obj.addressDetail, // 发件人地址
          senderPhone: obj.phone // 发件人电话
        };
        console.log(this.senderInformationIdObj, 'senderInformationIdObj');
      } else {
        this.senderInformationIdObj = {};
      }
    },
    clickChageInfo() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
        return;
      }
      const statusArr = [1, 4];
      const waybillNumberList = [];
      if (this.selectionList.length === 1) {
        if (!statusArr.includes(this.selectionList[0].providerOrderStatus)) {
          this.$message.error(this.$t('customerManagements.tip101'));
          return;
        }
      }
      this.selectionList.forEach(item => {
        if (statusArr.includes(item.providerOrderStatus)) {
          waybillNumberList.push(item.waybillNumber);
        }
      });
      if (waybillNumberList.length === 0) {
        this.$message.error(this.$t('customerManagements.tip101'));
        return;
      }
      this.senderInformationDialog = true;
    },
    // 确认更改
    comfirmChange() {
      if (this.senderInformationId) {
        const statusArr = [1, 4];
        const ordersId = [];
        this.selectionList.forEach(item => {
          if (statusArr.includes(item.providerOrderStatus)) {
            ordersId.push(item.orderId);
          }
        });
        const data = {
          orderIds: ordersId,
          senderInfo: this.senderInformationIdObj
        };
        updatebatchAddress(data).then(res => {
          if (res.status === 'OK') {
            this.getSearch();
            this.senderInformationDialog = false;
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          }
        });
      } else {
        this.$message.warning(this.$t('customerManagements.tip102'));
      }
    },
    // 获取服务商选项
    getProviderList() {
      getStartupProviderChannel().then((res) => {
        if (res.status === 'OK') {
          this.provideNameList = res.data;
        }
      });
    },
    // 全部导出
    allExportOrder() {
      if (!this.queryForm.params.searchNumberStr) {
        if (!this.timeValue || this.timeValue.length === 0) {
          this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
          return;
        }
      }
      providerorderpageExpage(this.exportParam).then((res) => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                if (res.data.taskId) {
                  this.$router.push({ path: '/resource/exportTaskList' });
                } else {
                  this.$router.push({ path: '/resource/exportList' });
                }
              }
            })
            .catch(() => {
            });
        }
      });
    },
    // 下载服务商标签
    downloadTheMenu() {
      console.log(this.downloadArr, ' this.downloadArr');
      this.downloadArr = [];
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$i18n.t('orderCenterCont.PleaseSelectTheWaybill')); // 请选择运单
        return;
      }
      if (this.selectionList.length > 200) {
        this.$message.warning(
          this.$i18n.t('orderCenterCont.TheNumberOfSelectedPolygonsExceedsTheMaximumLimit200')
        ); // 选中面单数超出最大限制：200
        return;
      }
      this.selectionList.forEach(item => {
        if (item.labelUrl) {
          this.downloadArr.push(item.labelUrl);
        }
      });
      if (this.downloadArr.length === 0) {
        this.$message.warning(this.$t('navbar.tip01')); // 暂无需要下载的服务商标签
        return;
      }
      // 手动显示加载中动画
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      batchPdfDownload(this.downloadArr)
        .then((res) => {
          const dateType = setDateTime2();
          const saveName = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
          saveAs(res, saveName);
        })
        .finally(() => {
          this.$refs.anjunTable.toggleSelection();
          loading.close();
        });
    },
    // 操作表头弹框
    operationTableDialog() {
      this.isoperationTable = true;
    },
    // 操作表头的显示与隐藏
    operationTable() {
      const tableTh = [];
      this.bindTableColumns.forEach((item) => {
        if (this.tableHeaderForm.tableHeaderList.includes(item.label)) {
          tableTh.push(item);
        }
      });
      this.tableHeaderList = tableTh;
      this.isoperationTable = false;
    },
    // 重置
    resetTable() {
      this.tableHeaderForm.tableHeaderList = tableHeaderForm;
      this.tableHeaderList = headerList;
      this.isoperationTable = false;
      window.location.reload();
    },
    // 点击申请服务商单号
    clickapplyForProvider() {
      if (this.selectionList.length !== 0) {
        const status = [2, 3];
        if (this.selectionList.length === 1) {
          if (status.includes(this.selectionList[0].providerOrderStatus)) {
            this.$message.error(this.$t('navbar.tip02'));
            return;
          }
        }
        const data = {
          waybillNumberList: [],
          waybillIds: [],
          providerNameList: []
        };
        console.log(data, 'data.waybillNumberList');
        this.selectionList.forEach(item => {
          if (!status.includes(item.providerOrderStatus)) {
            data.waybillNumberList.push(item.waybillNumber);
            if (item.providerName) {
              data.providerNameList.push(item.providerName);
            }
            data.waybillIds.push(item.id);
          }
        });
        if (data.waybillNumberList.length === 0) {
          this.$message.error(this.$t('navbar.tip02'));
          return;
        }
        console.log(this.selectionList, 'this.selectionList');
        const h = this.$createElement;
        this.$msgbox({
          title: this.$i18n.t('operationCenter.tips'),
          message: h('p', null, [
            h('span', this.$i18n.t('navbar.tip03')) // 确定要选中单号进行申请服务商单号吗
          ]),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('GlobalSetObj.determine'),
          cancelButtonText: this.$i18n.t('GlobalSetObj.cancel')
        })
          .then((action) => {
            if (action === 'confirm') {
              // return;
              applyProviderNumber(data).then(res => {
                if (res.status === 'OK') {
                  this.getSearch();
                  this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                }
              });
              console.log('确定');
            }
          })
          .catch(() => {
            console.log('取消');
          });
      } else {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
      }
    },
    // 重新分配服务商
    reallocate() {
      const statusArr = [1, 4];
      const waybillNumberList = [];
      if (this.selectionList.length !== 0) {
        if (this.selectionList.length === 1) {
          if (!statusArr.includes(this.selectionList[0].providerOrderStatus)) {
            this.$message.error(this.$t('navbar.tip04'));
            return;
          }
        }
        this.selectionList.forEach(item => {
          if (statusArr.includes(item.providerOrderStatus)) {
            waybillNumberList.push(item.waybillNumber);
            this.waybillIds.push(item.id);
          }
        });
        if (waybillNumberList.length === 0) {
          this.$message.error(this.$t('navbar.tip04'));
          return;
        }
        this.reDistruForm.reProviderDialog = true;
      } else {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
      }
    },
    // 列表数据勾选
    selectTable(data) {
      this.selectionList = data;
      this.waybillIds = [];
    },
    // 查看轨迹
    trajectoryQuery(row) {
      this.queryTrackParam = row;
      console.log(row, ':query-track-param="queryTrackParam"');
      this.showQueryTrack.visible = true;
      this.showQueryTrack.trackKey = new Date().getTime();
    },
    // 进入运单详情
    toOrderDetail(row) {
      this.showQueryTrack.trackKey = new Date().getTime();
      const statusArr = [2, 3];
      const { id, providerOrderStatus } = row;
      this.waybillDescription.visible = true;
      this.waybillDescription.ids = id;

      // 如果当前订单是 取号成功或取号中（2,3） 则不能编辑
      if (statusArr.includes(providerOrderStatus)) {
        this.waybillDescription.isEdit = false;
      } else {
        this.waybillDescription.isEdit = true;
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    handleReProviderDialog() {
      this.reDistruForm.providerId = '';
      this.senderInformationId = '';
    },
    // 确认分配
    comfirmDistru() {
      if (this.reDistruForm.providerId) {
        const arr = this.reDistruForm.providerId.split(',');
        const data = {
          waybillIds: this.waybillIds,
          providerId: arr[0],
          providerName: arr[1],
          providerCode: arr[2]
        };
        reDistruProvider(data).then(res => {
          if (res.status === 'OK') {
            this.getSearch();
            this.reDistruForm.reProviderDialog = false;
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          }
        });
      } else {
        this.$message.warning(this.$t('navbar.pleaseSelectProvider'));
      }
    }
  }
};
</script>

<style scoped>
.container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.container {
  padding: 10px;
}

.searchBox >>> .selectClass .el-input__inner {
  font-weight: 700 !important;
}

.titleLabel {
  font-size: 16px;
  margin-bottom: 8px;
}
</style>
