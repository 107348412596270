<!--表格组件-->
<template>
  <div>
    <el-table
      ref="wovenBagManagementTable"
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      :header-cell-style="{ 'text-align': 'center' }"
      border
      row-key="id"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <!-- 序列号/复选框 -->
      <el-table-column
        v-if="isSelectBtn"
        :reserve-selection="true"
        align="center"
        fixed="left"
        type="selection"
        width="50"
      />
      <el-table-column
        :label="$t('newOrder.SerialNumber')"
        align="center"
        fixed="left"
        type="index"
        width="70"
      >
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columnList"
        :key="index"
        :align="item.align"
        :label="item.label"
        :min-width="item.minWidth"
        :prop="item.prop"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <!-- 状态 -->
          <div v-if="item.prop === 'recycleStatus'">
            <!--回收状态-->
            <div v-if="tableType === 1 || tableType === 2">
              <span v-if="scope.row[item.prop] === 1" style="color: #67c23a">{{
                $t('GlobalSetObj.Confirmed')
              }}</span>
              <!--已确认-->
              <span v-if="scope.row[item.prop] === 0" style="color: #e6a23c">{{
                $t('GlobalSetObj.Unconfirmed')
              }}</span>
              <!--未确认-->
              <!--<el-tag v-if="scope.row[item.prop] === 1" type="success" size="mini">{{ $t('GlobalSetObj.Confirmed') }}</el-tag>-->
              <!--已确认-->
              <!--<el-tag v-if="scope.row[item.prop] === 0" type="warning" size="mini">{{ $t('GlobalSetObj.Unconfirmed') }}</el-tag>-->
              <!--未确认-->
            </div>
          </div>
          <div v-else-if="item.prop === 'enableStatus'">
            <!--启用状态-->
            <div>
              <el-switch
                v-model="scope.row[item.prop]"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                disabled
                inactive-color="#ccc"
              />
            </div>
          </div>
          <div v-else-if="item.prop === 'waybillPrintStatus'">
            <!--运单打印状态-->
            <div v-if="tableType === 1 || tableType === 2">
              <span v-if="scope.row[item.prop] === 'Y'" style="color: #67c23a">Y</span><!--已打印-->
              <span v-if="scope.row[item.prop] === 'N'" style="color: #e6a23c">N</span><!--未打印-->
            </div>
          </div>
          <div v-else>
            <div
              v-if="
                scope.row[item.prop] !== null &&
                  scope.row[item.prop] !== undefined &&
                  scope.row[item.prop] !== ''
              "
            >
              {{ scope.row[item.prop] }}
            </div>
            <div v-else>/</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableType === 1 || tableType === 2"
        :label="$t('newOrder.operation')"
        :width="tableType === 1 ? 160 : 240"
        align="center"
        fixed="right"
        type="index"
      >
        <template slot-scope="scope">
          <div>
            <div v-if="tableType === 1">
              <el-button
                v-if="scope.row.recycleStatus === 0"
                size="mini"
                type="danger"
                @click="operationDelete(scope.row)"
              >
                {{ $t('placeOrder.Delete') }} </el-button><!--删除-->
            </div>
            <div v-if="tableType === 2">
              <el-button
                v-if="scope.row.recycleStatus === 0"
                size="mini"
                type="primary"
                @click="operationConfirm(scope.row)"
              >
                {{ $t('GlobalSetObj.RecyclingConfirmation') }} </el-button><!--回收确认-->
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'TableCmp',
  props: {
    columnList: {
      type: Array,
      default() {
        return []; // 表格列
      }
    },
    isSelectBtn: {
      type: Boolean,
      default: false // 是否显示复选框
    },
    tableData: {
      type: Array,
      default() {
        return []; // 表格数据
      }
    },
    tableType: {
      type: Number,
      default: 1 // 1：我返回的 2：我回收的 3：领用明细 4：调整明细 5：流出明细 6：流入明细
    }
  },
  data() {
    return {};
  },
  methods: {
    // 数据勾选
    handleSelectionChange(arr) {
      this.$emit('selectionChange', arr, 'selected');
    },
    // 删除
    operationDelete(row) {
      this.$emit('selectionChange', row, 'delete');
    },
    // 回收确认
    operationConfirm(row) {
      this.$emit('selectionChange', row, 'recyclingConfirmation');
    },
    toDetailManage() {
      this.$router.push({ path: '/wovenBagManagement/detailManagement' });
    },
    // 取消勾选
    clearSelection() {
      this.$refs.wovenBagManagementTable.clearSelection();
    }
  }
};
</script>
<style scoped>
.isClickStyle {
  cursor: pointer;
  color: #409eff;
}
</style>
