// 揽收中心
const collectionCenter = {
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'Por favor digite'
  },
  collectionCenter: {
    zh: '揽收中心',
    en: '',
    pu: 'Centro de Coleta'
  },
  listingQuery: {
    zh: '揽收清单',
    en: '',
    pu: 'Lista de Coleta'
  },
  pleaseCheck: {
    zh: '请勾选要作废的数据',
    en: '',
    pu: 'Por favor, assinale os dados a serem anulados'
  },
  PleaseTickTheDataExport: {
    zh: '请选择需导出的数据',
    en: 'Please tick the data you want to export',
    pu: 'Selecione os dados que serão exportados'
  },
  lanshouTime: {
    zh: '揽收时间：',
    en: '',
    pu: 'tempo de coleta：'
  },
  StartTime: {
    zh: '开始时间',
    en: 'Start time',
    pu: 'Hora de início'
  },
  EndTime: {
    zh: '结束时间',
    en: 'End time',
    pu: 'Hora de fim'
  },
  customerOrderNumber: {
    zh: '客户订单号：',
    en: '',
    pu: 'No. de pedido do cliente'
  },
  siteNumber: {
    zh: '网点编号：',
    en: '',
    pu: 'Número do local：'
  },
  receiverNumber: {
    zh: '揽收人编号：',
    en: '',
    pu: 'No. do assistente de coleta：'
  },
  statusOfTheReceivingList: {
    zh: '揽收清单状态：',
    en: '',
    pu: 'Status da lista de coleta：'
  },
  receivingListNumber: {
    zh: '揽收清单号：',
    en: '',
    pu: 'No. de lista de coleta：'
  },
  search: {
    zh: '查询',
    en: '',
    pu: 'Consultar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reconfigurar'
  },
  export: {
    zh: '导出',
    en: 'export',
    pu: 'exportar'
  },
  allExport: {
    zh: '全部导出',
    en: '',
    pu: 'Exportar tudo'
  },
  '明细导出': {
    zh: '明细导出',
    en: '',
    pu: 'Exportar detalhes'
  },
  void: {
    zh: '作废',
    en: 'void',
    pu: 'invalido'
  },
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'No.'
  },
  customerOrderNum: {
    zh: '客户订单号',
    en: '',
    pu: 'No. do pedido do cliente'
  },
  CustomerName: {
    zh: '客户名称',
    en: 'Customer name',
    pu: 'Nome do cliente'
  },
  receivingListNum: {
    zh: '揽收清单号',
    en: '',
    pu: 'No. da lista de coleta'
  },
  stayLanshouPoll: {
    zh: '待揽收票数',
    en: '',
    pu: 'quantidade dos pacotes a serem coletados'
  },
  realityLanshouPoll: {
    zh: '实际揽收票数',
    en: '',
    pu: 'quantidade real dos pacotes coletados'
  },
  signerNumber: {
    zh: '签收票数',
    en: '',
    pu: 'qauntidade de pacotes assinados'
  },
  bagsNum: {
    zh: '袋数',
    en: '',
    pu: 'quantidade de sacas'
  },
  siteNum: {
    zh: '网点编号',
    en: '',
    pu: 'Netpoint nº'
  },
  receiverNum: {
    zh: '揽收人编号',
    en: '',
    pu: 'No. do responsável pela coleta'
  },
  receiverName: {
    zh: '揽收人名称',
    en: '',
    pu: 'Nome do responsável pela coleta'
  },
  ExpectedTimeOfCollection: {
    zh: '预期揽收时间',
    en: '',
    pu: 'Tempo previsto para a coleta'
  },
  ActualCollectionTime: {
    zh: '实际揽收时间',
    en: '',
    pu: 'Tempo real para a coleta'
  },
  listingStatus: {
    zh: '清单状态',
    en: '',
    pu: 'status da lista'
  },
  signatureImage: {
    zh: '签名图片',
    en: '',
    pu: 'imagem da assinatura'
  },
  InvalidTime: {
    zh: '作废时间',
    en: '',
    pu: 'Tempo de anulação'
  },
  InvalidPeople: {
    zh: '作废人',
    en: '',
    pu: 'anulado por'
  },
  customerMerchantNumber: {
    zh: '客户商户号',
    en: '',
    pu: 'razão social do cliente'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  viewInformation: {
    zh: '查看寄/收件人信息',
    en: '',
    pu: 'Ver informações sobre o remetente/destinatário'
  },
  detail: {
    zh: '详情',
    en: 'detail',
    pu: 'detalhe'
  },
  orderDetails: {
    zh: '详情',
    en: 'order details',
    pu: 'detalhes da ordem'
  },
  sendState: {
    zh: '寄件州',
    en: '',
    pu: 'Estado remetente'
  },
  sendCity: {
    zh: '寄件城市',
    en: '',
    pu: 'Cidade remetente'
  },
  sendRegional: {
    zh: '寄件区域',
    en: '',
    pu: 'Área remetente'
  },
  sendR: {
    zh: '寄件区域：',
    en: '',
    pu: 'Área remetente：'
  },
  sendAddress: {
    zh: '寄件地址',
    en: '',
    pu: 'Endereço remetente'
  },
  sendPeople: {
    zh: '寄件人',
    en: '',
    pu: 'remetente'
  },
  receivingState: {
    zh: '收件州',
    en: '',
    pu: 'Estado destinatário'
  },
  receivingCity: {
    zh: '收件城市',
    en: '',
    pu: 'Cidade destinatária'
  },
  receivingRegional: {
    zh: '收件区域',
    en: '',
    pu: 'Área destinatária'
  },
  receivingStreet: {
    zh: '收件街道',
    en: '',
    pu: 'Avenida destinatária'
  },
  receivingHouseNumber: {
    zh: '收件门牌号',
    en: '',
    pu: 'No. da porta destinatária'
  },
  // 客户上门交件 ----------------------
  deleteSure: {
    zh: '确定删除吗？',
    en: '',
    pu: 'confirmar para excluir?'
  },
  CustomerN: {
    zh: '客户名称：',
    en: 'Customer name：',
    pu: 'Nome do cliente：'
  },
  LanShouPerson: {
    zh: '揽收人：',
    en: '',
    pu: 'responsável pela coleta：'
  },
  lanshouT: {
    zh: '揽收时间',
    en: '',
    pu: 'Tempo de coleta'
  },
  LanShouSum: {
    zh: '揽收票数：',
    en: '',
    pu: 'quantidade de pacotes coletados：'
  },
  LSSum: {
    zh: '揽收总票数：',
    en: '',
    pu: 'Obtenha o total de votos：'
  },
  customerHandDoor: {
    zh: '客户上门交件',
    en: '',
    pu: 'Entrega porta-a-porta do cliente'
  },
  customerHandRegistrationScan: {
    zh: '客户交件登记扫描',
    en: '',
    pu: 'Varredura do registro de entrega ao cliente'
  },
  conditionQuery: {
    zh: '条件查询',
    en: '',
    pu: 'Pesquisa por condição'
  },
  phoneNumber: {
    zh: '手机号码',
    en: '',
    pu: 'Telefone'
  },
  clientID: {
    zh: '客户编号',
    en: '',
    pu: 'COSTUMER ID'
  },
  clientName: {
    zh: '客户姓名',
    en: '',
    pu: 'Nome do cliente'
  },
  clientN: {
    zh: '客户姓名：',
    en: '',
    pu: 'Nome do cliente：'
  },
  inputPrompt: {
    zh: '请输入内容',
    en: '',
    pu: 'Por favor digite conteúdo'
  },
  parcelNumber: {
    zh: '包裹号：',
    en: '',
    pu: 'No. do pacote：'
  },
  parcelNum: {
    zh: '包裹号',
    en: '',
    pu: 'No. do pacote'
  },
  sannePacotes: {
    zh: '扫描包裹',
    en: '',
    pu: 'Digitalização de pacotes'
  },
  scanner: {
    zh: '扫描人',
    en: '',
    pu: 'Scanner'
  },
  BagParcelNumber: {
    zh: '袋号/包裹号',
    en: '',
    pu: 'Número da saca/No. do pacote'
  },
  scanOrInputParcelNumber: {
    zh: '请扫描或输入包裹号',
    en: '',
    pu: 'Favor escanear ou digitar o número do pacote'
  },
  createConfirmList: {
    zh: '生成确认单',
    en: '',
    pu: 'Gerar tabela de confirmação'
  },
  ListOfScannedLogisticNumbers: {
    zh: '已扫描的物流单号列表：',
    en: '',
    pu: 'Lista de número de pedidos logísticos escaneados：'
  },
  scanTime: {
    zh: '扫描时间',
    en: '',
    pu: 'Tempo da coleta'
  },
  scannedNumber: {
    zh: '已扫描的数量：',
    en: '',
    pu: 'Quantidade de pacotes escaneados：'
  },
  customerHandFindTable: {
    zh: '客户交件查询列表',
    en: '',
    pu: 'Lista de consulta de entrega pelo cliente'
  },
  LanShouPeople: {
    zh: '揽收人',
    en: '',
    pu: 'responsável pela coleta'
  },
  LanShouBody: {
    zh: '揽收人：',
    en: '',
    pu: 'responsável pela coleta：'
  },
  Print: {
    zh: '打印',
    en: '',
    pu: 'Imprimir'
  },
  ballot: {
    zh: '票数',
    en: '',
    pu: 'Quantidade de ordem'
  },
  siteName: {
    zh: '网点名称',
    en: '',
    pu: 'Nome do Ponto de rede'
  },
  printNumber: {
    zh: '打印次数',
    en: '',
    pu: 'número de vezes da impressão'
  },
  printTime: {
    zh: '打印时间',
    en: '',
    pu: 'Tempo da impressão'
  },
  customerMsg: {
    zh: '客户信息',
    en: '',
    pu: 'Informações do cliente'
  },
  LanShouPersonnel: {
    zh: '揽收人员',
    en: '',
    pu: 'responsável pela coleta'
  },
  LanShouP: {
    zh: '揽收人员：',
    en: '',
    pu: 'responsável pela coleta：'
  },
  name: {
    zh: '姓名：',
    en: 'name：',
    pu: 'nome：'
  },
  lanshouMsg: {
    zh: '揽收信息',
    en: '',
    pu: 'Informações da coleta'
  },
  ticket: {
    zh: '票',
    en: 'ticket',
    pu: 'ordem'
  },
  customerSignature: {
    zh: '客户签名',
    en: '',
    pu: 'Assinatura do cliente'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'Confirma'
  },
  tableUpdatedPrintTimes: {
    zh: '查询表已更新打印次数',
    en: '',
    pu: 'Número de vezes de impressão do formulário de consulta já foi atualizado'
  },
  packageCollected: {
    zh: '当前包裹已揽收',
    en: '',
    pu: 'O pacote já foi coletado'
  },
  packageCollected1: {
    zh: '当前包裹已揽收！',
    en: '',
    pu: 'O pacote já foi coletado！'
  },
  pleaseCheckAndInput: {
    zh: '请选择条件查询并输入内容',
    en: '',
    pu: 'Favor selecionar as condições e digitir o conteúdo'
  },
  inputClientName: {
    zh: '请输入客户名称',
    en: '',
    pu: 'Por favor, digite o nome do cliente'
  },
  pleaseCheckMsg: {
    zh: '请选择条件查询内容',
    en: '',
    pu: 'Favor selecionar as condições para consultar o conteúdo'
  },
  inputParcelNumber: {
    zh: '请输入包裹号',
    en: '',
    pu: 'Favor inserir o número do pacote'
  },
  nullClientID: {
    zh: '客户id不存在，请重新搜索客户名称',
    en: '',
    pu: 'ID do cliente não existe, por favor pesquisar novamente o nome do cliente'
  },
  checkMsg: {
    zh: '请勾选数据',
    en: '',
    pu: 'Por favor, assinale os dados'
  },
  OnlyAllowedThreeMonthsOfData: {
    zh: '只允许查3个月内的数据',
    en: '',
    pu: 'Somente os dados dentro de 3 meses podem ser verificados'
  },
  OnlyAllowedOneMonthsOfData: {
    zh: '只允许查1个月内的数据',
    en: '',
    pu: 'Somente os dados dentro de 1 meses podem ser verificados'
  },
  checkExportList: {
    zh: '请选择需要导出的数据',
    en: '',
    pu: 'Por favor, selecione os dados a serem exportados'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  ExportSuccessful: {
    zh: '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，',
    en: 'If the export is successful, the data will be generated within 1~10 minutes, please download it from the resource export list in system management.',
    pu: 'A exportação foi bem sucedida, os dados serão gerados em 1 a 10 minutos, favor descarregá-los da lista de exportação de recursos no gerenciamento do sistema,'
  },
  ExportIsSuccessful: {
    zh: '如导出成功数据将会在1~10分钟内生成表格，请到系统管理中的',
    en: 'If the data is successfully exported, the table will be generated within 1~10 minutes, please go to',
    pu: 'Se os dados forem exportados com sucesso, a tabela será gerada dentro de 1 ~ 10 minutos, vá para'
  },
  ExportSuccessful1: {
    zh: '导出成功',
    en: 'Export successful',
    pu: 'Exportação bem-sucedida'
  },
  AListOfResourceExports: {
    zh: '资源导出列表',
    en: 'A list of resource exports',
    pu: 'Uma lista de exportações de recursos'
  },
  systemAdministrationToDownload: {
    zh: '中下载，',
    en: 'in the system administration to download,',
    pu: 'na administração do sistema para baixar,'
  },
  notRepeatTheExport: {
    zh: '请勿重复导出',
    en: 'Do not repeat the export！',
    pu: 'Não repita a exportação！'
  },
  CheckBackLater: {
    zh: '稍后查看',
    en: 'Check back later',
    pu: 'Volte mais tarde'
  },
  CheckItOutNow: {
    zh: '确认导出',
    en: 'Check it out now',
    pu: 'Confirmar a exportação'
  },
  ExportAs: {
    zh: '导出为',
    en: 'Export as',
    pu: 'Exportar como'
  },
  close: {
    zh: '关闭',
    en: 'Shut down',
    pu: 'Fechar'
  },
  Immediately: {
    zh: '立即前往',
    en: 'Go now',
    pu: 'Ir para'
  },
  // 揽收订单 -----------
  lanshouOrder: {
    zh: '揽收订单',
    en: '',
    pu: 'pedido de coleta'
  },
  lanshouOrderNumber: {
    zh: '揽收订单号：',
    en: '',
    pu: ' Número do pedido de coleta：'
  },
  orderNumber: {
    zh: '订单号',
    en: 'orderNumber',
    pu: 'Número do pacote'
  },
  site: {
    zh: '网点：',
    en: '',
    pu: 'Ponto de rede：'
  },
  orderStatus: {
    zh: '订单状态：',
    en: 'orderStatus：',
    pu: 'Status do pedido：'
  },
  SendZipCode: {
    zh: '寄件邮编：',
    en: '',
    pu: 'CEP remetente：'
  },
  SendZipcode: {
    zh: '寄件邮编',
    en: '',
    pu: 'CEP remetente'
  },
  receiptZipCode: {
    zh: '收件邮编：',
    en: '',
    pu: 'CEP destinatário：'
  },
  driver: {
    zh: '司机：',
    en: '',
    pu: 'motorista：'
  },
  bagsNumber: {
    zh: '袋号：',
    en: '',
    pu: 'número da saca：'
  },
  schedulingDriver: {
    zh: '调度司机',
    en: '',
    pu: 'Motoristas de despacho'
  },
  schedulingDriverAgain: {
    zh: '重新调度司机',
    en: '',
    pu: 'Redespachar motorista'
  },
  cancelSchedule: {
    zh: '取消调度',
    en: '',
    pu: 'cancelar o despacho'
  },
  lanshouOrderNum: {
    zh: '揽收订单号',
    en: '',
    pu: 'Número do pedido de coleta'
  },
  BagNumber: {
    zh: '袋号',
    en: 'Bag number',
    pu: 'Número da saca'
  },
  Barcode: {
    zh: '条形码',
    en: 'Barcode',
    pu: 'Código de barras'
  },
  BarcodeL: {
    zh: '条形码：',
    en: 'Barcode: ',
    pu: 'Código de barras：'
  },
  ExpectedCollectionTime: {
    zh: '期望揽收时间',
    en: '',
    pu: 'Tempo previsto para a coleta'
  },
  OrderStatus: {
    zh: '订单状态',
    en: 'Order status',
    pu: 'status do pedido'
  },
  driverName: {
    zh: '司机姓名',
    en: '',
    pu: 'Nome do motorista'
  },
  recipient: {
    zh: '收件人：',
    en: 'recipient：',
    pu: 'destinatário：'
  },
  RecipientMailbox: {
    zh: '收件人邮箱：',
    en: 'Recipient mailbox：',
    pu: 'email do destinatário：'
  },
  RecipientzipCode: {
    zh: '收件人邮编：',
    en: "Recipient's zip code：",
    pu: 'CEP do destinatário：'
  },
  RecipientPhone: {
    zh: '收件人电话：',
    en: "Recipient's phone：",
    pu: 'Telefone do destinatário：'
  },
  receiptState: {
    zh: '收件州：',
    en: '',
    pu: 'Estado destinatário：'
  },
  receiptCity: {
    zh: '收件城市：',
    en: '',
    pu: 'Cidade destinatária：'
  },
  receiptRegional: {
    zh: '收件区：',
    en: '',
    pu: 'Área destinatária：'
  },
  receiptAddress: {
    zh: '收件地址：',
    en: 'The delivery address：',
    pu: 'Endereço destinatário：'
  },
  receiptStreet: {
    zh: '收件街道：',
    en: '',
    pu: 'Avenida destinatária：'
  },
  receiptHouseNumber: {
    zh: '收件门牌号：',
    en: '',
    pu: 'No. da porta destinatário：'
  },
  receiptNeighborhood: {
    zh: '收件人邻里：',
    en: '',
    pu: 'vizinho destinatário: '
  },
  postPerson: {
    zh: '寄件人：',
    en: '',
    pu: 'Remetente：'
  },
  postMail: {
    zh: '寄件人邮箱：',
    en: '',
    pu: 'E-mail do remetente：'
  },
  postZip: {
    zh: '寄件人邮编：',
    en: '',
    pu: 'CEP do remetente：'
  },
  postTel: {
    zh: '寄件人电话：',
    en: '',
    pu: 'Tel. do remetente：'
  },
  postZ: {
    zh: '寄件人邮编',
    en: '',
    pu: 'CEP do remetente'
  },
  postT: {
    zh: '寄件人电话',
    en: '',
    pu: 'Tel. do remetente'
  },
  postState: {
    zh: '寄件州：',
    en: '',
    pu: 'Estado remetente：'
  },
  postCity: {
    zh: '寄件城市：',
    en: '',
    pu: 'Cidade remetente：'
  },
  postRegional: {
    zh: '寄件区：',
    en: '',
    pu: 'área remetente：'
  },
  postAddress: {
    zh: '寄件地址：',
    en: '',
    pu: 'Endereço remetente：'
  },
  postAds: {
    zh: '寄件地址',
    en: '',
    pu: 'Endereço remetente'
  },
  postStreet: {
    zh: '寄件街道：',
    en: '',
    pu: 'Avenida remetente：'
  },
  postHouseNumber: {
    zh: '寄件门牌号：',
    en: '',
    pu: 'No. da porta remetente：'
  },
  postNeighborhood: {
    zh: '寄件人邻里：',
    en: '',
    pu: 'Vizinho remetente：'
  },
  notLanShou: {
    zh: '未揽收',
    en: '',
    pu: 'Não coletado'
  },
  hasLanShou: {
    zh: '已揽收',
    en: '',
    pu: 'coletado'
  },
  cancelled: {
    zh: '已取消',
    en: '',
    pu: 'cancelado'
  },
  LanShouing: {
    zh: '揽收中',
    en: '',
    pu: 'a coletando'
  },
  WarehouseProcessing: {
    zh: '仓库处理',
    en: '',
    pu: 'Processamento de armazém'
  },
  scheduled: {
    zh: '已调度',
    en: '',
    pu: 'despachado'
  },
  AbnormalLanShou: {
    zh: '揽收异常',
    en: '',
    pu: 'excepção da coleta'
  },
  haveBeenSigned: {
    zh: '已签收',
    en: '',
    pu: 'Foi assinado'
  },
  pleaseCheckSite: {
    zh: '请选择网点',
    en: '',
    pu: 'Selecione uma tomada'
  },
  pleaseCheckDriver: {
    zh: '请选择司机',
    en: '',
    pu: 'Favor selecionar a motorista'
  },
  schedulingSuccess: {
    zh: '调度成功',
    en: '',
    pu: 'Despacho bem-sucedido'
  },
  // 网点 ----------------------
  siteManagement: {
    zh: '网点管理',
    en: '',
    pu: 'Gestão de Pontos'
  },
  createTime: {
    zh: '创建时间：',
    en: 'createTime：',
    pu: 'Tempo de criação：'
  },
  siteTitle: {
    zh: '网点名称：',
    en: '',
    pu: 'Nome do Ponto de rede：'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  SubordinateDistribution: {
    zh: '所属分拨',
    en: '',
    pu: 'distribuição'
  },
  siteCondition: {
    zh: '网点状态',
    en: '',
    pu: 'Status do site'
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'Tempo de criação'
  },
  creator: {
    zh: '创建人',
    en: 'creator',
    pu: 'Criado por'
  },
  ModificationTime: {
    zh: '修改时间',
    en: 'Modification time',
    pu: 'Tempo de modificação'
  },
  ModifiedBy: {
    zh: '修改人',
    en: 'Modified by',
    pu: 'Modificado por'
  },
  Zip: {
    zh: '邮编',
    en: 'Zip',
    pu: 'CEP'
  },
  state: {
    zh: '州',
    en: 'state',
    pu: 'Estado'
  },
  city: {
    zh: '城市',
    en: 'city',
    pu: 'cidade'
  },
  Area: {
    zh: '区',
    en: 'Area',
    pu: 'Distrito'
  },
  openBank: {
    zh: '开户银行',
    en: '',
    pu: 'Banco de abertura de conta'
  },
  RecipientStreet: {
    zh: '街道',
    en: 'Recipient street',
    pu: 'Rua Destinatário'
  },
  typeOperation: {
    zh: '操作类型：',
    en: 'The type of operation：',
    pu: 'Tipo de operação：'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  MoreActions: {
    zh: '更多操作',
    en: 'More actions',
    pu: 'Mais ações'
  },
  siteAllocation: {
    zh: '网点所属分拨：',
    en: '',
    pu: 'Subdistribuição do site'
  },
  openTheBank: {
    zh: '开户银行：',
    en: '',
    pu: 'Banco de abertura de conta：'
  },
  zipCode: {
    zh: '邮编：',
    en: 'Zip: ',
    pu: 'CEP：'
  },
  stateSite: {
    zh: '网点所属州：',
    en: '',
    pu: 'Estado de origem'
  },
  citySite: {
    zh: '网点所属城市：',
    en: '',
    pu: 'Cidade'
  },
  AreaSite: {
    zh: '网点所属区：',
    en: '',
    pu: 'Distrito'
  },
  operationType: {
    zh: '操作类型',
    en: 'The type of operation',
    pu: 'Tipo de operação'
  },
  notStarted: {
    zh: '未开始',
    en: '',
    pu: 'Não começou'
  },
  completed: {
    zh: '已完成',
    en: 'completed',
    pu: 'Concluído'
  },
  cancellation: {
    zh: '已作废',
    en: '',
    pu: 'Anulado'
  },
  ifEnabled: {
    zh: '是否启用：',
    en: '',
    pu: 'Ativar ou não：'
  },
  enable: {
    zh: '启用',
    en: 'enable',
    pu: 'ativo'
  },
  Disable: {
    zh: '停用',
    en: 'Disable',
    pu: 'Desativar'
  },
  notEnabled: {
    zh: '未启用',
    en: '',
    pu: 'desativo'
  },
  head: {
    zh: '负责人：',
    en: '',
    pu: 'responsável：'
  },
  contactPhone: {
    zh: '联系电话：',
    en: '',
    pu: 'tel. de contato：'
  },
  contactTel: {
    zh: '联系电话',
    en: '',
    pu: 'tel. de contato'
  },
  Save: {
    zh: '保存',
    en: 'Save',
    pu: 'Salvar'
  },
  empty: {
    zh: '清空',
    en: 'empty',
    pu: 'Limpar'
  },
  Confirm: {
    zh: '确认',
    en: '',
    pu: 'confirmar'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  inputLong6: {
    zh: '请填写长度6至30位的字母+数字组合',
    en: '',
    pu: 'Preencha uma combinação de letras + números de 6 a 30 dígitos.'
  },
  long4to100: {
    zh: '长度不能少于4位，不能大于100位',
    en: '',
    pu: 'O comprimento não pode ser inferior a 4 dígitos e não pode ser superior a 100 dígitos'
  },

  lanshou: {
    zh: '揽收',
    en: '',
    pu: 'coleta'
  },
  returnCourier: {
    zh: '退件',
    en: '',
    pu: 'devolução'
  },
  SaveSuccessfully: {
    zh: '保存成功',
    en: 'Save successfully',
    pu: 'Salvar com sucesso'
  },
  DeleteSucceeded: {
    zh: '删除成功',
    en: 'Delete succeeded',
    pu: 'Excluir foi bem sucedido'
  },
  ModificationSuccessful: {
    zh: '修改成功',
    en: 'Modification successful',
    pu: 'Modificação bem sucedida'
  },
  // 司机管理 --------------
  driverManagement: {
    zh: '司机管理',
    en: '',
    pu: 'Gerenciamento de motorista'
  },
  accountState: {
    zh: '状态：',
    en: 'state：',
    pu: 'status：'
  },
  belongToSite: {
    zh: '所属网点：',
    en: 'belonging point：',
    pu: 'ponto afiliado：'
  },
  driverN: {
    zh: '司机姓名：',
    en: '',
    pu: 'Nome do motorista：'
  },
  resetPassword: {
    zh: '重置密码',
    en: 'reset password',
    pu: 'redefinir a senha'
  },
  driverNum: {
    zh: '司机编号',
    en: '',
    pu: 'No. do motorista'
  },
  theDriverName: {
    zh: '司机名称',
    en: '',
    pu: 'nome do motorista'
  },
  belongSite: {
    zh: '所属网点',
    en: 'belonging point',
    pu: 'ponto afiliado'
  },
  ifUse: {
    zh: '是否启用',
    en: '',
    pu: 'ativar ou não'
  },
  contact: {
    zh: '联系方式',
    en: '',
    pu: 'contato'
  },
  updateSite: {
    zh: '修改网点',
    en: '',
    pu: 'Modificar tomadas'
  },
  driverNumber: {
    zh: '司机编号：',
    en: '',
    pu: 'No. do motorista: '
  },
  realNameL: {
    zh: '真实姓名：',
    en: '',
    pu: 'nome real：'
  },
  AccStatus: {
    zh: '账号状态：',
    en: '',
    pu: 'status da conta: '
  },
  telephoneNumber: {
    zh: '电话号码：',
    en: 'telephone number: ',
    pu: 'número telefônico: '
  },
  mailbox: {
    zh: '邮箱：',
    en: 'mailbox：',
    pu: 'email：'
  },
  spareMailbox: {
    zh: '备用邮箱：',
    en: '',
    pu: 'e-mail alternativo'
  },
  OpPwd: {
    zh: '操作密码：',
    en: '',
    pu: 'senha de operação：'
  },
  inputNumber: {
    zh: '请输入6到10位编号',
    en: '',
    pu: 'Por favor, digite um número de 6 a 10 dígitos'
  },
  onlyHalfAYear: {
    zh: '只允许查半年内的数据',
    en: '',
    pu: 'Somente os dados dentro de seis meses podem ser verificados'
  },
  inputOpPwd: {
    zh: '请输入操作密码',
    en: '',
    pu: 'Por favor, digite a senha de operação'
  },
  checkResetPwd: {
    zh: '请勾选需要重置密码的数据项',
    en: '',
    pu: 'Assinale os itens de dados que requerem redefinição de senha'
  },
  ifResetPwd: {
    zh: '是否需要重置改密码？ 初始密码为：123456',
    en: '',
    pu: 'Precisa redefinir e mudar senha? A senha inicial é: 123456'
  },
  resetSucceeded: {
    zh: '重置成功',
    en: '',
    pu: 'redefinir com sucesso'
  },
  Null: {
    zh: '暂无',
    en: 'No',
    pu: 'Não'
  },
  NoContentYet: {
    zh: '暂无内容',
    en: 'No content yet',
    pu: 'Nenhum conteúdo ainda'
  },
  // 签收查询
  signForQuery: {
    zh: '签收查询',
    en: '',
    pu: 'Consulta de entrega'
  },
  signTime: {
    zh: '签收时间：',
    en: '',
    pu: 'Tempo de entrega：'
  },
  signT: {
    zh: '签收时间',
    en: '',
    pu: 'Tempo de entrega'
  },
  bigBagNumber: {
    zh: '大包号：',
    en: '',
    pu: 'Grande bolsa：'
  },
  packageNumber: {
    zh: '大包号',
    en: '',
    pu: 'Grande bolsa'
  },
  addresserCode: {
    zh: '发件人编码',
    en: '',
    pu: 'código do remetente'
  },
  addresser: {
    zh: '发件人',
    en: '',
    pu: 'Remetente'
  },
  ExpectedNumberOfDeliveries: {
    zh: '预计派件数',
    en: '',
    pu: 'quantidade estimada de pacotes entregues'
  },
  ActualNumberOfDispatches: {
    zh: '实际派件数',
    en: '',
    pu: 'quantidade real de pacotes entregues'
  },
  SendPerson: {
    zh: '派件人',
    en: '',
    pu: 'entregador'
  },
  signer: {
    zh: '签收人',
    en: '',
    pu: 'signatário'
  },
  signImage: {
    zh: '签收图片',
    en: '',
    pu: 'imagem de entrega'
  },
  note: {
    zh: '备注：',
    en: 'remark: ',
    pu: 'observações: '
  },
  Note: {
    zh: '备注',
    en: 'remark',
    pu: 'observações'
  },
  RegistrationTime: {
    zh: '登记时间：',
    en: '',
    pu: 'tempo de registro：'
  },
  RegistrationT: {
    zh: '登记时间',
    en: '',
    pu: 'tempo de registro'
  },
  customerMerchantNum: {
    zh: '客户商户号：',
    en: '',
    pu: 'razão social do cliente: '
  },
  ifExists: {
    zh: '请点击搜索按钮确认客户是否存在',
    en: '',
    pu: 'Favor clicar no botão de busca para verificar se o cliente existev'
  },
  PackageExist: {
    zh: '当前包裹号重复',
    en: '',
    pu: 'Duplicação o número do pacote atual'
  },
  ReceivingAbnormalPush: {
    zh: '揽收异常推送',
    en: '',
    pu: 'Empurrão de exceção de coleta'
  },
  NameOfCustomerMerchant: {
    zh: '客户商户名称',
    en: '',
    pu: 'razão social do cliente'
  },
  ProblemPiece: {
    zh: '问题件',
    en: 'Problem piece',
    pu: 'Peça problemática'
  },
  CauseOfTheProblem: {
    zh: '问题件原因：',
    en: '',
    pu: 'Motivo do pacote problemático：'
  },
  CauseOfTheP: {
    zh: '问题件原因',
    en: '',
    pu: 'Motivo do pacote problemático：'
  },
  AreYouSureToSave: {
    zh: '是否确认保存?',
    en: '',
    pu: 'tem certeza de salvar?'
  },
  registrar: {
    zh: '登记人',
    en: '',
    pu: 'registrado por'
  },
  ProblemFileRegistrationQuery: {
    zh: '问题件登记查询',
    en: '',
    pu: 'consulta de registro de pacotes problemáticos'
  },
  ProblemFileRegistration: {
    zh: '问题件登记',
    en: '',
    pu: 'registro de pacotes problemáticos'
  },
  ProblemPieceProcessing: {
    zh: '问题件处理',
    en: '',
    pu: 'tratamento de pacotes problemáticos'
  },
  DataRecovery: {
    zh: '数据恢复',
    en: '',
    pu: 'Recuperação de dados'
  },
  MerchantNum: {
    zh: '商户号',
    en: '',
    pu: 'SELLER ID'
  },
  MerchantNumBer: {
    zh: '商户号：',
    en: '',
    pu: 'Número do comerciante：'
  },
  MerchantName: {
    zh: '商户名称',
    en: '',
    pu: 'Nome do Mercador'
  },
  MerchantN: {
    zh: '商户名称：',
    en: '',
    pu: 'Nome do Mercador：'
  },
  MerchantCode: {
    zh: '商户编码',
    en: '',
    pu: 'Código do Mercador'
  },
  TheSenderCouldNotBeReached: {
    zh: '联系不上发件人',
    en: '',
    pu: 'O remetente não pode ser contatado'
  },
  TheMailingAddressCouldNotBeFound: {
    zh: '发件地址找不到',
    en: '',
    pu: 'Endereço remetente não encontrado'
  },
  PackageFound: {
    zh: '包裹未找到',
    en: '',
    pu: 'pacote não encontrado'
  },
  parcelNotReady: {
    zh: '包裹未准备好',
    en: '',
    pu: 'Pacote não pronto'
  },
  cancelOrder: {
    zh: '取消订单',
    en: '',
    pu: 'Cancelar o pedido'
  },
  TimeoutLanShou: {
    zh: '超时揽收',
    en: '',
    pu: 'coleta fora do tempo-limite'
  },
  goodsWasRobbed: {
    zh: '货物被抢',
    en: '',
    pu: 'mercadoria roubada'
  },
  other: {
    zh: '其他',
    en: '',
    pu: 'outros'
  },
  NumName: {
    zh: '商户号/商户名称：',
    en: '',
    pu: 'Número do comerciante/Nome do comerciante：'
  },
  packageMsg: {
    zh: '包裹详情',
    en: '',
    pu: 'Detalhes do pacote'
  },
  BatchQuery: {
    zh: '支持批量查询(回车换行分割)',
    en: '',
    pu: 'Suporta a busca de lotes (múltiplas quebras de linha são inseridas)'
  },
  orderCreateTime: {
    zh: '订单创建时间：',
    en: 'createTime：',
    pu: 'Tempo de criação：'
  },
  orderCreateT: {
    zh: '订单创建时间',
    en: '',
    pu: 'Tempo de criação de pedido'
  },
  restoreSuccess: {
    zh: '恢复成功!',
    en: '',
    pu: 'Sucesso na recuperação!'
  },
  chooseCustomerName: {
    zh: '请选择客户名称',
    en: '',
    pu: 'Selecione o nome do cliente'
  },
  tableUpdated: {
    zh: '已生成确认单',
    en: '',
    pu: 'Tabela Atualizada'
  },
  pollList: {
    zh: '票数信息',
    en: '',
    pu: 'Informação de ordem'
  },
  createReceivingListNum: {
    zh: '生成的揽收清单号为：',
    en: '',
    pu: 'No.de lista de coleta atualizada: '
  },
  // 期望揽收时间：
  ExpectedCollectionT: {
    zh: '期望揽收时间：',
    en: '',
    pu: 'Tempo previsto para a coleta: '
  },
  ExpectedCollectionStartTime: {
    zh: '期望揽收 - 开始时间',
    en: '',
    pu: 'Tempo previsto para a coleta - Tempo de início '
  },
  ExpectedCollectionEndTime: {
    zh: '期望揽收 - 结束时间',
    en: '',
    pu: 'Tempo previsto para a coleta - Tempo de fim'
  },
  LanShouAddress: {
    zh: '揽收地址：',
    en: '',
    pu: 'endereço para coleta：'
  },
  confirmSchedule: {
    zh: '确认调度?',
    en: '',
    pu: 'Confirma o despacho?'
  },
  lanshouImg: {
    zh: '揽收图片',
    en: '',
    pu: 'Imagem de coleta'
  },
  LanShouDriver: {
    zh: '揽收司机：',
    en: '',
    pu: 'motorista de coleta：'
  },
  TotalNumberOfOrdersReceived: {
    zh: '总揽收订单数',
    en: '',
    pu: 'QUANTIDADE TOTAL DE PEDIDOS A SEREM COLETADOS'
  },
  ActualNumberOfOrdersReceived: {
    zh: '实际已揽收订单数',
    en: '',
    pu: 'QUANTIDADE DE PEDIDOS COLETADOS REAL'
  },
  NumberOfUnreceivedOrders: {
    zh: '未揽收订单数',
    en: '',
    pu: 'QUANTIDADES DE PEDIDOS NÃO COLETADOS'
  },
  AbnormalNumberOfOrdersReceived: {
    zh: '揽收异常订单数',
    en: '',
    pu: 'QUANTIDADE DE EXCEÇÕES DE PEDIDOS DE COLETA/ QUANTIDADE DE PEDIDOS DE COLETA ANOMALIA'
  },
  SuccessRateOfCollection: {
    zh: '揽收成功率',
    en: '',
    pu: 'Taxa de sucesso da coleta'
  },
  PleaseSelectTime: {
    zh: '请选择日期时间',
    en: '',
    pu: 'Favor selecionar uma data e hora'
  },
  summary: {
    zh: '汇总',
    en: '',
    pu: 'resumo'
  },
  huiz: {
    zh: '汇总：',
    en: '',
    pu: 'resumo：'
  },
  particulars: {
    zh: '明细',
    en: '',
    pu: 'Detalhes'
  },
  Number: {
    zh: '单号',
    en: '',
    pu: 'Número de pedido'
  },
  LanShouSite: {
    zh: '揽收网点',
    en: '',
    pu: 'Pontos de coleta'
  },
  LSSite: {
    zh: '揽收网点：',
    en: '',
    pu: 'Pontos de coleta：'
  },
  resetPasswordL: {
    zh: '重置密码：',
    en: 'reset password：',
    pu: 'redefinir a senha：'
  },
  inputPwdAgian: {
    zh: '请再次输入新密码',
    en: '',
    pu: 'Favor digitar nova senha novamente'
  },
  passwordPrompt: {
    zh: '密码必须由 8-12位字母、数字组成，可选特殊符号.',
    en: '',
    pu: 'A senha deve ser composta de 8-12 letras e números, com a opção de símbolos especiais.'
  },
  defaultPassword: {
    zh: '默认密码',
    en: '',
    pu: 'senha padrão'
  },
  newPassword: {
    zh: '新密码',
    en: 'new password',
    pu: 'nova senha'
  },
  requestedInputCode: {
    zh: '请按照要求输入密码',
    en: 'Please enter the password as required',
    pu: 'Por favor digitar a senha conforme requerido'
  },
  checkPwd: {
    zh: '确认密码',
    en: 'Confirm password',
    pu: 'Confirmar senha'
  },
  inputConfirmCode: {
    zh: '请输入确认密码',
    en: 'Please confirm your password',
    pu: 'Introduza a senha de confirmação, por favor'
  },
  checkPwdNotNewPwd: {
    zh: '确认密码与新密码不相同',
    en: '',
    pu: 'a senha de confirmação não é a mesma que a nova senha'
  },
  operator: {
    zh: '操作人',
    en: 'operator',
    pu: 'Operador'
  },
  describe: {
    zh: '描述',
    en: '',
    pu: 'descrição'
  },
  ContactNumber: {
    zh: '联系人电话',
    en: '',
    pu: 'telefone'
  },
  selectedTimeThanCurrentTime: {
    zh: '选中时间需大于当前时间',
    en: '',
    pu: 'tempo seleccionado deve ser superior ao tempo actual'
  },
  addressCollection: {
    zh: '揽收地址',
    en: '',
    pu: 'endereço para coleta'
  },
  BatchRepush: {
    zh: '批量重推',
    en: '',
    pu: 'Batch Re-push'
  },
  OperationSite: {
    zh: '操作网点',
    en: '',
    pu: 'Locais de operação'
  },
  PushResults: {
    zh: '推送结果',
    en: '',
    pu: 'Resultados de push'
  },
  pushresults: {
    zh: '推送结果：',
    en: '',
    pu: 'Resultados de push：'
  },
  OnTheWay: {
    zh: '投递中',
    en: '',
    pu: 'Entrega em andamento'
  },
  DeliveredSuccessfully: {
    zh: '投递成功',
    en: '',
    pu: 'Entrega com sucesso'
  },
  FailureDeliver: {
    zh: '投递失败',
    en: '',
    pu: 'Falha na entrega'
  },
  SuccessConsumption: {
    zh: '消费成功',
    en: '',
    pu: 'Consumo com sucesso'
  },
  FailureConsume: {
    zh: '消费失败',
    en: '',
    pu: 'Consumo falhou'
  },
  PushContent: {
    zh: '推送内容',
    en: '',
    pu: 'Conteúdo de Push'
  },
  UpToOneYear: {
    zh: '最多可查询到一年的轨迹数据',
    en: '',
    pu: 'Pode pesquisar o rastreamento até um ano '
  },
  PleaseReselectIt: {
    zh: '所选数据不符合要求，已消费成功数据不支持重新推送，请重新勾选',
    en: '',
    pu: 'Os dados selecionados não atendem aos requisitos, os dados de consumo bem sucedidos não suportam o re-push, por favor, verifique novamente assinale.'
  },
  byTimeRange: {
    zh: '按时间范围',
    en: '',
    pu: ' por faixa de tempo'
  },
  orderNumberHasBeenSigned: {
    zh: '已签收订单数',
    en: '',
    pu: 'QUANTIDADE DE PEDIDOS ASSINADOS'
  },
  orderNumberHasBeenCancelled: {
    zh: '已取消订单数',
    en: '',
    pu: 'QUANTIDADE DE PEDIDOS CANCELADOS'
  },
  byOdd: {
    zh: '按单号',
    en: '',
    pu: 'por número de ordem'
  },
  unsigned: {
    zh: '未签名!',
    en: '',
    pu: 'Não assinado'
  },
  SealedBag: {
    zh: '已封袋',
    en: '',
    pu: 'saco selado'
  },
  PleaseEnterPhone: {
    zh: '请输入手机号',
    en: '',
    pu: 'Favor digitar o número de telefone'
  },
  PushTrack: {
    zh: '轨迹推送',
    en: '',
    pu: 'Empurrão de via '
  },
  consumptionNumber: {
    zh: '消费次数',
    en: '',
    pu: 'Número de consumos'
  },
  hasNotBeenObtainedPleaseTryAgain: {
    zh: '还未获取到揽收图片，请稍后再保存',
    en: '',
    pu: 'Eu não obtive a coleção de fotos, salve -a mais tarde'
  },
  scannedPackageID: {
    zh: '当前被扫描的包裹号为：',
    en: '',
    pu: 'O número do pacote atualmente digitalizado é：'
  },
  pgMsg: {
    zh: '揽收包裹明细',
    en: '',
    pu: 'Detalhamento da Recolha de Pacotes'
  },
  pgBarCode: {
    zh: '包裹条形码',
    en: '',
    pu: 'Código de barras de pacotes '
  },
  pgTotal: {
    zh: '包裹总计：',
    en: '',
    pu: 'Total de pacotes：'
  },
  loading: {
    zh: '正在努力加载中...',
    en: '',
    pu: 'Carregamento em andamento...'
  },
  ParcelSubtotal: {
    zh: '包裹小计：',
    en: '',
    pu: 'Subtotal de pacotes：'
  },
  RangeEarningRate: {
    zh: '揽收率',
    en: '',
    pu: 'PORCENTAGEM DE COLETADOS'
  },
  RateOfSigning: {
    zh: '签收率',
    en: '',
    pu: 'PORCENTAGEM DE ASSINADOS'
  },
  SealedBagOrderNumber: {
    zh: '已封袋订单数',
    en: '',
    pu: 'QUANTIDADES DE PEDIDOS SELADOS/LACRADOS'
  },
  signSite: {
    zh: '签收网点',
    en: '',
    pu: 'Pontos de venda de assinatura'
  },
  MonitoringReport: {
    zh: '订单揽收监控报表',
    en: '',
    pu: 'Relatórios de monitoramento de coleta de pedidos'
  },
  collectXlsx: {
    zh: '订单揽收监控报表汇总',
    en: '',
    pu: 'Resumo do relatório de monitoramento da coleta de pedidos'
  },
  detailXlsx: {
    zh: '订单揽收监控报表明细',
    en: '',
    pu: 'Detalhes do relatório de monitoramento da coleta de pedidos'
  },
  selectData: {
    zh: '请选择需要操作的数据!',
    en: '',
    pu: 'Por favor, selecione os dados a serem manipulados!'
  },
  SendSMS: {
    zh: '发送短信',
    en: '',
    pu: 'mensagens'
  },
  pleaseEnterInteger: {
    zh: '请输入整数',
    en: '',
    pu: 'Por favor, insira o número inteiro'
  },
  enclosure: {
    zh: '附件：',
    en: 'enclosure：',
    pu: 'anexo：'
  },
  enclosureFJ: {
    zh: '附件',
    en: 'enclosure',
    pu: 'anexo'
  },
  cldrUpload: {
    zh: '点击或拖拽文件到此处上传',
    en: '',
    pu: 'Clicar ou arrastar arquivo para upload aqui'
  },
  OnlyImageFormatIsSupported: {
    zh: '仅支持png、bmp、jpg、jpeg格式的图片',
    en: '',
    pu: 'Apenas imagens nos formatos png, bmp, jpg e jpeg são suportadas'
  },
  SMSType: {
    zh: '短信类型：',
    en: '',
    pu: 'Tipo de mensagem de texto：'
  },
  messageType: {
    zh: '短信类型',
    en: '',
    pu: 'Tipo de mensagem de texto'
  },
  SMSStatus: {
    zh: '短信状态：',
    en: '',
    pu: 'Estado da mensagem de texto：'
  },
  messageStatus: {
    zh: '短信状态',
    en: '',
    pu: 'Estado da mensagem de texto'
  },
  BatchPush: {
    zh: '批量补推',
    en: '',
    pu: 'Batch multi-selecção e dados push novamente'
  },
  ReceivingCall: {
    zh: '接收电话',
    en: '',
    pu: 'Recepção de chamadas'
  },
  SMSSource: {
    zh: '短信来源',
    en: '',
    pu: 'Fonte da mensagem de texto'
  },
  messageContent: {
    zh: '短信内容',
    en: '',
    pu: 'Conteúdo da mensagem de texto'
  },
  SendingTime: {
    zh: '发送时间',
    en: '',
    pu: 'Hora de envio'
  },
  SendErrorMessage: {
    zh: '发送错误信息',
    en: '',
    pu: 'Envio de mensagens de erro'
  },
  NotSent: {
    zh: '未发送',
    en: '',
    pu: 'Não enviado'
  },
  InTransit: {
    zh: '发送中',
    en: '',
    pu: 'No processo de envio'
  },
  sent: {
    zh: '已发送',
    en: '',
    pu: 'Enviado'
  },
  SendFailure: {
    zh: '发送失败',
    en: '',
    pu: 'Envia falhou'
  },
  ModifyTotalParcelsReceived: {
    zh: '修改揽收包裹总数',
    en: '',
    pu: 'Rever o número total de parcelas recolhidas'
  },
  NumberPackages: {
    zh: '包裹数量',
    en: 'Number of packages',
    pu: 'Número de pacotes'
  },
  packagesReceivedNumber: {
    zh: '揽收包裹数为：',
    en: '',
    pu: 'O número de parcelas recolhidas foi de：'
  },
  intercept: {
    zh: '单次查询最多1000条，已截取前1000条数据',
    en: '',
    pu: 'Consulta única até 1000 dados, os primeiros 1000 dados recebidos'
  },
  intercept1: {
    zh: '单次查询最多10000条，已截取前10000条数据',
    en: '',
    pu: 'Consulta única até 10000 dados, os primeiros 10000 dados recebidos'
  },
  intercept50: {
    zh: '单次查询最多50条，已截取前50条数据',
    en: '',
    pu: 'Consulta única até 50 dados, os primeiros 50 dados recebidos'
  },
  intercept100: {
    zh: '单次查询最多100条，已截取前100条数据',
    en: '',
    pu: 'Consulta única até 100 dados, os primeiros 100 dados recebidos'
  },
  modify: {
    zh: '修改',
    en: 'modify',
    pu: 'Modificar'
  },
  inputNum: {
    zh: '请输入数字',
    en: '',
    pu: 'Favor digitar número'
  },
  input8Sum: {
    zh: '请输入8位数字',
    en: '',
    pu: 'favro digitar 8 dígitos'
  },
  emailNotFormat: {
    zh: '邮箱格式不正确',
    en: '',
    pu: 'Formato de e-mail incorreto'
  },
  disable: {
    zh: '禁用',
    en: '',
    pu: 'Proibição de uso'
  },
  contactAddress: {
    zh: '联系地址',
    en: '',
    pu: 'Endereço para contato'
  },
  contactArs: {
    zh: '联系地址：',
    en: '',
    pu: 'Endereço para contato：'
  },
  SourceProblemPiece: {
    zh: '问题件来源：',
    en: '',
    pu: 'Fonte do problema：'
  },
  SourcePPiece: {
    zh: '问题件来源',
    en: '',
    pu: 'Fonte do problema'
  },
  merchantEmail: {
    zh: '商户邮箱',
    en: '',
    pu: 'E-mail do comerciante'
  },
  merchantE: {
    zh: '商户邮箱：',
    en: '',
    pu: 'E-mail do comerciante：'
  },
  moreYear: {
    zh: '最多可查询到一年的数据',
    en: 'Até um ano de dados pesquisáveis'
  },
  MerchantManagement: {
    zh: '商户管理',
    en: '',
    pu: 'Gestão do comerciante'
  },
  receiverBelongsSite: {
    zh: '揽收人所属网点',
    en: '',
    pu: 'Pontos de venda de propriedade do receptor'
  },
  DataSource: {
    zh: '数据来源',
    en: '',
    pu: 'Fontes de dados'
  },
  receivedPackagesTotalNumber: {
    zh: '已揽收包裹总数',
    en: '',
    pu: 'Número total de parcelas receptadas'
  },
  TotalNumberOfParcelsSignedIn24Hours: {
    zh: '24小时内签收包裹总数',
    en: '',
    pu: 'Número total de pacotes assinados dentro de 24 horas'
  },
  over24HoursUnsignedPackagesTotalNumber: {
    zh: '超24小时未签收包裹总数',
    en: '',
    pu: 'Número total de pacotes não assinados por mais de 24 horas'
  },
  in24UnsignedPackagesTotalNumber: {
    zh: '24小时内未签收包裹总数',
    en: '',
    pu: 'Número total de pacotes não assinados no prazo de 24 horas'
  },
  SigningRateWithin24Hours: {
    zh: '24小时内签收率',
    en: '',
    pu: 'Taxa de assinatura para pacotes dentro de 24 horas'
  },
  signerBelongsSite: {
    zh: '签收人所属网点',
    en: '',
    pu: 'A agência do signatário'
  },
  SignPrescriptionStatement: {
    zh: '签收时效报表汇总',
    en: '',
    pu: 'Resumo das declarações de limitação assinada'
  },
  StatisticsOfSignedPrescriptionStatements: {
    zh: '签收时效报表统计',
    en: '',
    pu: 'Estatísticas das declarações de limitação assinada'
  },
  total: {
    zh: '总计：',
    en: 'total：',
    pu: 'total：'
  },
  all: {
    zh: '总计',
    en: '',
    pu: 'total'
  },
  scannedPackagesTotal: {
    zh: '扫描包裹总数',
    en: '',
    pu: 'Número total de pacotes escaneados'
  },
  receivedParcelsTotal: {
    zh: '揽收包裹总数',
    en: '',
    pu: 'Número total de pacotes coletados'
  },
  sealingBagTotal: {
    zh: '封袋总数',
    en: '',
    pu: 'Número total de sacos selados'
  },
  sealingBagTime: {
    zh: '封袋时间',
    en: '',
    pu: 'Tempo de selagem do saco'
  },
  receiveDailyReport: {
    zh: '揽收日报表',
    en: '',
    pu: 'Declaração de dias de coleta'
  },
  vehicleManagement: {
    zh: '车辆管理',
    en: '',
    pu: 'Gestão de veículos'
  },
  carNumber: {
    zh: '车签号',
    en: '',
    pu: 'Número de escaneamento do veículo'
  },
  carID: {
    zh: '车辆编号',
    en: '',
    pu: 'Número do veículo'
  },
  carName: {
    zh: '车辆名称',
    en: '',
    pu: 'Nome do veículo'
  },
  carN: {
    zh: '车辆名称：',
    en: '',
    pu: 'Nome do veículo：'
  },
  licensePlateNumber: {
    zh: '车牌号',
    en: '',
    pu: 'número da placa de identificação'
  },
  licensePlateNum: {
    zh: '车牌号：',
    en: '',
    pu: 'número da placa de identificação：'
  },
  carType: {
    zh: '车辆类型',
    en: '',
    pu: 'Tipo de veículo'
  },
  carT: {
    zh: '车辆类型：',
    en: '',
    pu: 'Tipo de veículo：'
  },
  smallBoxTruck: {
    zh: '小型厢式货车',
    en: '',
    pu: 'Camião pequeno'
  },
  mediumBoxTruck: {
    zh: '中型厢式货车',
    en: '',
    pu: 'Camião médio'
  },
  largeBoxTruck: {
    zh: '大型半挂货车',
    en: '',
    pu: 'Camião semi-reboque grande'
  },
  owningSite: {
    zh: '所属网点：',
    en: 'belonging point：',
    pu: 'ponto afiliado：'
  },
  vehicleDriver: {
    zh: '车辆驾驶人',
    en: '',
    pu: 'Motorista de veículo'
  },
  vehicleD: {
    zh: '车辆驾驶人：',
    en: '',
    pu: 'Motorista de veículo：'
  },
  operationMode: {
    zh: '运营模式',
    en: 'operationMode',
    pu: 'Modelo de operação'
  },
  operationM: {
    zh: '运营模式：',
    en: 'operationMode',
    pu: 'Modelo de operação：'
  },
  directOperation: {
    zh: '直营',
    en: '',
    pu: 'Direto'
  },
  thirdPartyCooperation: {
    zh: '第三方合作',
    en: '',
    pu: 'Cooperação com terceiros'
  },
  registrant: {
    zh: '登记人',
    en: '',
    pu: 'Registrado'
  },
  codeDownload: {
    zh: '二维码下载',
    en: '',
    pu: 'Download do Código QR'
  },
  codeDownload1: {
    zh: '一维码下载',
    en: '',
    pu: 'Download de código unidimensional'
  },
  register: {
    zh: '登记',
    en: '',
    pu: 'Registro'
  },
  VehicleRegistration: {
    zh: '车辆登记',
    en: '',
    pu: 'Registro de veículos'
  },
  addVehicleRegistration: {
    zh: '新增 - 车辆登记',
    en: '',
    pu: 'Novo - Registro de veículos'
  },
  editVehicleRegistration: {
    zh: '修改 - 车辆登记',
    en: '',
    pu: 'Emendas - Registro de veículos'
  },
  printT: {
    zh: '打印时间：',
    en: '',
    pu: 'Tempo da impressão：'
  },
  printQuantity: {
    zh: '打印数量',
    en: '',
    pu: 'Número de impressões'
  },
  printQ: {
    zh: '打印数量：',
    en: '',
    pu: 'Número de impressões：'
  },
  printer: {
    zh: '打印人',
    en: '',
    pu: 'Impressoras'
  },
  viewDetails: {
    zh: '查看明细',
    en: '',
    pu: 'Ver detalhes'
  },
  pleaseInputNumber: {
    zh: '请输入数字类型',
    en: '',
    pu: 'Por favor, digite o tipo de número'
  },
  startThanEnd: {
    zh: '开始时间不能大于结束时间',
    en: 'The start time cannot be greater than the end time',
    pu: 'O tempo de início não pode ser maior do que o tempo final'
  },
  enterGreaterThan0: {
    zh: '请输入大于0的数字',
    en: 'Please enter a number greater than 0',
    pu: 'Por favor, digite um número maior que 0'
  },
  pleaseInputInteger: {
    zh: '请输入正整数',
    en: '',
    pu: 'Por favor, insira um número inteiro'
  },
  chooseSite: {
    zh: '请选择所属网点',
    en: '',
    pu: 'Selecione a filial à qual você pertence'
  },
  inputPrintSum: {
    zh: '请输入打印数量',
    en: '',
    pu: 'Favor inserir o número de impressões'
  },
  once100: {
    zh: '最多一次可打印100张',
    en: '',
    pu: 'Imprimir até 100 folhas de cada vez'
  },
  sucToPrint: {
    zh: '车签号生成成功，可前往打印',
    en: '',
    pu: 'Número de escaneamento do veículo gerado com sucesso e pronto para impressão'
  },
  printSuc: {
    zh: '打印成功',
    en: '',
    pu: 'Impresso com sucesso'
  },
  generate: {
    zh: '生成',
    en: '',
    pu: 'Gerar'
  },
  msgType: {
    zh: '消息类型：',
    en: '',
    pu: 'Tipo de mensagem：'
  },
  pushMsgType: {
    zh: '消息类型',
    en: '',
    pu: 'Tipo de mensagem'
  },
  truckLoading: {
    zh: '装车',
    en: '',
    pu: 'Carregado'
  },
  unloading: {
    zh: '卸车',
    en: '',
    pu: 'Descarregado'
  },
  // scanLargePackage: {
  //   zh: '请先扫描大包号',
  //   en: '',
  //   pu: 'Por favor, escanear primeiro o número da saca'
  // },
  // scanSmallBag: {
  //   zh: '已有大包号，请扫描小包号',
  //   en: '',
  //   pu: 'Número da saca já disponível, por favor, escanear número de pacote pequeno'
  // },
  scanLargePackage: {
    zh: '请先扫描袋号',
    en: '',
    pu: 'Por favor, digitalizar primeiro o número da saca'
  },
  scanSmallBag: {
    zh: '已有袋号，请扫描包裹号',
    en: '',
    pu: 'Número da saca já disponível, por favor digitalizar o número do pacote'
  },
  mustLargePackage: {
    zh: '包裹必须有一个大包，不能删除',
    en: '',
    pu: 'Os pacotes devem ter o número da saca e não podem ser excluídos'
  },
  largePackageNotBeNull: {
    zh: '大包不能为空，当前包裹号不能删除',
    en: '',
    pu: 'O número da saca não pode estar vazio e o número do pacote atual não pode ser apagado'
  },
  emptyPackage: {
    zh: '空包无法提交',
    en: '',
    pu: 'Pacotes vazios não podem ser submetidos'
  },
  bigPackageExist: {
    zh: '当前大包号重复',
    en: '',
    pu: 'Duplicação atual do grande número de sacas'
  },
  scanBtn: {
    zh: '扫描：',
    en: '',
    pu: 'Bipar：'
  },
  scanTip: {
    zh: '请扫描或输入袋号/包裹号',
    en: '',
    pu: 'Favor escanear ou digitar o Número da saca/número do pacote'
  },
  scanBag: {
    zh: '请扫描或输入袋号',
    en: '',
    pu: 'Favor escanear ou digitar o Número da saca'
  },
  SealingBags: {
    zh: '封袋',
    en: 'Sealing bags',
    pu: 'Sacos de vedação'
  },
  bagExist: {
    zh: '当前袋号重复',
    en: '',
    pu: 'Duplicação atual do grande número de sacas'
  },
  Bagged: {
    zh: '已装袋',
    en: 'Bagged',
    pu: 'Ensacado'
  },
  customerNumber: {
    zh: '客户单号：',
    en: 'customerNumber：',
    pu: 'Número do pedido do cliente：'
  },
  customerNum: {
    zh: '客户单号',
    en: 'customerNumber',
    pu: 'Número do pedido do cliente'
  },
  AccountBalance: {
    zh: '账户余额：',
    en: 'Account Balance：',
    pu: 'Saldo da conta：'
  },
  channel: {
    zh: '渠道：',
    en: '',
    pu: 'Canal：'
  },
  providerChannel: {
    zh: '服务商渠道：',
    en: 'providerChannel：',
    pu: 'Canal de fornecedor de serviços：'
  },
  providerC: {
    zh: '服务商：',
    en: 'providerChannel：',
    pu: 'provedor de serviços：'
  },
  sealBagScan: {
    zh: '封袋扫描',
    en: '',
    pu: 'Saca selada e digitalizada'
  },
  nullSubmit: {
    zh: '无数据提交',
    en: '',
    pu: 'Não apresentação de dados'
  },
  dotCode: {
    zh: '网点编号：',
    en: '',
    pu: 'Netpoint nº：'
  },
  dotName: {
    zh: '网点名称：',
    en: '',
    pu: 'Nome do ponto de rede：'
  },
  owningTransitCenter: {
    zh: '所属转运中心：',
    en: '',
    pu: 'Centros de Trânsito Filiados：'
  },
  businessModel: {
    zh: '经营模式：',
    en: '',
    pu: 'Modelo de negócio：'
  },
  directSales: {
    zh: '直营',
    en: '',
    pu: 'Vendas directas'
  },
  joinSales: {
    zh: '加盟',
    en: '',
    pu: 'Franchisado'
  },
  businessType: {
    zh: '业务类型：',
    en: '',
    pu: 'Tipo de negócio：'
  },
  businessT: {
    zh: '业务类型',
    en: '',
    pu: 'Tipo de negócio'
  },
  turnTransport: {
    zh: '转运',
    en: '',
    pu: 'Trânsito'
  },
  collect: {
    zh: '揽收',
    en: '',
    pu: 'Colecção'
  },
  dispatch: {
    zh: '派送',
    en: '',
    pu: 'Entrega'
  },
  dotZipCode: {
    zh: '网点邮编：',
    en: '',
    pu: 'Código postal do Ponto：'
  },
  dotState: {
    zh: '网点所属州：',
    en: '',
    pu: 'Estado do ponto de rede：'
  },
  dotCity: {
    zh: '网点所属城市：',
    en: '',
    pu: 'Cidade do ponto de rede：'
  },
  dotArea: {
    zh: '网点所属区域：',
    en: '',
    pu: 'Área do ponto de rede：'
  },
  dotStreet: {
    zh: '网点所属街道：',
    en: '',
    pu: 'Rua a que o ponto pertence：'
  },
  dotAdress: {
    zh: '网点地址：',
    en: '',
    pu: 'Endereço do ponto de rede：'
  },
  longitudeAndLatitude: {
    zh: '经纬度：',
    en: '',
    pu: 'Latitude e longitude：'
  },
  settlementCycle: {
    zh: '结算周期：',
    en: '',
    pu: 'Ciclo de colonização：'
  },
  receivingRange: {
    zh: '揽收范围：',
    en: '',
    pu: 'Âmbito da colecção.'
  },
  deliveryRange: {
    zh: '派送范围：',
    en: '',
    pu: 'Área de entrega.'
  },
  accumulationDeduction: {
    zh: '累积扣款：',
    en: '',
    pu: 'Deduções acumuladas：'
  },
  businessSubject: {
    zh: '业务主体：',
    en: '',
    pu: 'Assunto de negócios：'
  },
  dotType: {
    zh: '网点类型：',
    en: '',
    pu: 'Tipo de ponto de rede：'
  },
  hut: {
    zh: '安骏小屋',
    en: '',
    pu: 'Mini drop'
  },
  ColetaTerceirizadaSeller: {
    zh: '揽收第三方上门交件',
    en: '',
    pu: 'Coleta terceirizada no Seller'
  },
  dot: {
    zh: '网点',
    en: '',
    pu: 'Ponto de rede'
  },
  transferCenter: {
    zh: '转运中心',
    en: '',
    pu: 'Centro de Trânsito'
  },
  headOffice: {
    zh: '总部',
    en: '',
    pu: 'Sede'
  },
  // 网点邮编：格式:1000000~22222222或30000000
  format1: {
    zh: '格式:1000000~22222222或30000000',
    en: '',
    pu: 'Formato:1000000~22222222 ou 30000000'
  },
  format: {
    zh: '格式:1000000~22222222',
    en: '',
    pu: 'Formato:1000000~22222222'
  },
  input8ZipCode: {
    zh: '请输入8位邮编',
    en: '',
    pu: 'Favor digitar o CEP de 8 dígitos'
  },
  placeholder: {
    zh: '填写对应范围(回车换行分割)',
    en: '',
    pu: 'Preencher a gama correspondente(clique em entrar para quebrar a linha)'
  },
  placeholderInclude: {
    zh: '(回车换行分割)',
    en: '',
    pu: '(clique em entrar para quebrar a linha)'
  },
  accountBalance: {
    zh: '账户余额：',
    en: '',
    pu: 'Saldo da conta：'
  },
  hint: {
    zh: '请填写数字，整数部分最大长度9位，可保留两位有效小数',
    en: '',
    pu: 'Favor preencher os números, o comprimento máximo da parte inteira é de 9 dígitos, duas decimais válidas podem ser retidas'
  },
  isSave: {
    zh: '是否保存',
    en: '',
    pu: 'Está salvo'
  },
  code: {
    zh: '员工编号：',
    en: '',
    pu: 'número do funcionário：'
  },
  employeeCode: {
    zh: '员工编号',
    en: '',
    pu: 'número do funcionário'
  },
  codeStatus: {
    zh: '账号状态',
    en: '',
    pu: 'status da conta'
  },
  useRange: {
    zh: '使用范围：',
    en: '',
    pu: 'Âmbito de utilização.：'
  },
  useR: {
    zh: '使用范围',
    en: '',
    pu: 'Âmbito de utilização.'
  },
  codeUse: {
    zh: '员工编号可用',
    en: '',
    pu: 'Número de funcionários disponível'
  },
  codeRepeat: {
    zh: '员工编号重复，不可用',
    en: '',
    pu: 'Número de funcionários em duplicado, não disponível'
  },
  dispatchedGoods: {
    zh: '已派件',
    en: '',
    pu: 'Saiu para entrega'
  },
  senderPhone: {
    zh: '发件人电话',
    en: "The sender's phone",
    pu: 'O telefone do remetente'
  },
  SenderAddress: {
    zh: '发件人地址',
    en: 'Sender address',
    pu: 'Endereço de remetente'
  },
  ReceivingAddress: {
    zh: '收件人地址',
    en: 'Receiving Address',
    pu: 'Endereço do destinatário'
  },
  recipientS: {
    zh: '收件人',
    en: 'recipient',
    pu: 'Destinatário'
  },
  RecipientPhoneNumber: {
    zh: '收件人电话',
    en: "Recipient's phone",
    pu: 'Telefone do destinatário'
  },
  predictWeight: {
    zh: '预计重量',
    en: 'estimated weight',
    pu: 'peso estimado'
  },
  basicInformation: {
    zh: '基本信息',
    en: '',
    pu: 'Informações básicas'
  },
  quantity: {
    zh: '件数',
    en: 'quantity',
    pu: 'Número de pacotes'
  },
  verificationCode: {
    zh: '验证码',
    en: '',
    pu: 'Código de verificaçaõ'
  },
  lanshouPeople: {
    zh: '揽收-联系人',
    en: 'pick up-contact person',
    pu: 'coleta-pessoa de contato'
  },
  lanshouPhone: {
    zh: '揽收-手机',
    en: 'pick up-phone',
    pu: 'coleta-phone'
  },
  lanshouAddress: {
    zh: '揽收-地址',
    en: 'pick up-address',
    pu: 'coleta-endereço'
  },
  recipientInformation: {
    zh: '收件人信息',
    en: 'pick up-consignee information',
    pu: 'coleta-informação de destinário'
  },
  receivePeople: {
    zh: '收件-联系人',
    en: 'consignee-contact person',
    pu: 'destinário-pessoa de contato'
  },
  receivePhone: {
    zh: '收件-手机',
    en: 'consignee-phone',
    pu: 'destinário-phone'
  },
  receiveAddress: {
    zh: '收件-地址',
    en: 'consignee-address',
    pu: 'destinário-endereço'
  },
  pickUpTotal: {
    zh: '揽收总计',
    en: 'pick-up total',
    pu: 'total de coleta'
  },
  scanOrderNumber: {
    zh: '扫码单号',
    en: 'scanned order number',
    pu: 'número de pedido escaneado'
  },
  actualWeight: {
    zh: '称重重量(KG)',
    en: 'measured weight(KG)',
    pu: 'peso medido(KG)'
  },
  View: {
    zh: '查看',
    en: 'View',
    pu: 'ver'
  },
  actualReceivedNumber: {
    zh: '实收件数',
    en: 'actual collected quantity',
    pu: 'quantidade atual de coleta'
  },
  actualReceivedWeight: {
    zh: '实收重量',
    en: 'actual weight',
    pu: 'peço atual'
  },
  belongDot: {
    zh: '所属网点：',
    en: 'belonging point：',
    pu: 'ponto afiliado：'
  },
  belongD: {
    zh: '所属网点',
    en: 'belonging point',
    pu: 'ponto afiliado'
  },
  employeeName: {
    zh: '员工姓名：',
    en: 'employee name：',
    pu: 'nome de empregado：'
  },
  employeeN: {
    zh: '员工姓名',
    en: 'employee name',
    pu: 'nome de empregado'
  },
  editSite: {
    zh: '修改网点',
    en: 'change point',
    pu: 'modificar o ponto'
  },
  orderDate: {
    zh: '下单日期：',
    en: 'placing order time：',
    pu: 'data de pedido：'
  },
  mainWaybillNumber: {
    zh: '主运单号：',
    en: 'main airway bill：',
    pu: 'número de rastreio pricipal：'
  },
  orderD: {
    zh: '下单日期',
    en: 'placing order time',
    pu: 'data de pedido'
  },
  mainWaybillNum: {
    zh: '主运单号',
    en: 'main airway bill',
    pu: 'número de rastreio pricipal'
  },
  senderMobile: {
    zh: '发件人联系电话',
    en: 'sender mobile',
    pu: 'telefone de remetente'
  },
  estimatedCollectedQuantity: {
    zh: '预计揽收件数',
    en: 'estimated collected quantity',
    pu: 'quantidade estimado de coleta'
  },
  actualCollectedQuantity: {
    zh: '实际揽收件数',
    en: 'actual collected quantity',
    pu: 'quantidade atual de coleta'
  },
  actualCollectedWeight: {
    zh: '实际揽收重量',
    en: 'actual collected weight',
    pu: 'peço atual de coleta'
  },
  fetchCode: {
    zh: '取件码',
    en: 'collection code',
    pu: 'código de recolha'
  },
  collectingInformation: {
    zh: '揽收人信息',
    en: 'collecting staff information',
    pu: 'informação de coletador'
  },
  collectionDot: {
    zh: '揽收网点',
    en: 'collection spot',
    pu: 'ponto de coleção'
  },
  collectionSite: {
    zh: '揽收网点：',
    en: 'collection spot：',
    pu: 'ponto de coleção：'
  },
  collectionSignaturePhoto: {
    zh: '揽收签名图片',
    en: 'collection signature and photo',
    pu: 'foto da assinatura de coleta'
  },
  collectPhoto: {
    zh: '揽收照片',
    en: '',
    pu: 'foto da Colecção'
  },
  deliveryDot: {
    zh: '签收网点',
    en: 'delivery point',
    pu: 'ponto de entrega'
  },
  signForSignaturePhoto: {
    zh: '签收签名图片',
    en: 'collection signature and photo',
    pu: 'foto da assinatura de entrega'
  },
  bagNumberNotExisted: {
    zh: '袋号不存在',
    en: 'bag number not existed',
    pu: 'não existe o número de saco'
  },
  province: {
    zh: '省份：',
    en: 'province：',
    pu: 'província：'
  },
  provinceee: {
    zh: '省份',
    en: 'province',
    pu: 'província'
  },
  startZipCode: {
    zh: '起始邮编',
    en: '',
    pu: 'CEP do início'
  },
  endZipCode: {
    zh: '结束邮编',
    en: '',
    pu: 'CEP do fim'
  },
  startZipC: {
    zh: '起始邮编：',
    en: '',
    pu: 'CEP do início：'
  },
  endZipC: {
    zh: '结束邮编：',
    en: '',
    pu: 'CEP do fim：'
  },
  zipCodeType: {
    zh: '邮编类型',
    en: '',
    pu: 'Tipo de CEP'
  },
  BulkExport: {
    zh: '批量导出',
    en: 'Bulk export',
    pu: 'Exportar em lote'
  },
  Import: {
    zh: '导入',
    en: 'Import',
    pu: 'Importação'
  },
  TemplateDownload: {
    zh: '下载模版',
    en: '',
    pu: 'Download do modelo'
  },
  TheTypeOfExpense: {
    zh: '费用类型',
    en: 'The type of expense',
    pu: 'Tipo de custo'
  },
  TypeOfExpense: {
    zh: '费用类型：',
    en: 'The type of expense：',
    pu: 'Tipo de custo：'
  },
  freight: {
    zh: '运费',
    en: 'freight',
    pu: 'Frete'
  },
  stateZT: {
    zh: '状态',
    en: 'state',
    pu: 'status'
  },
  NumberOfPushes: {
    zh: '推送次数',
    en: 'Number of pushes',
    pu: 'Número de empurrões'
  },
  uploadOnlyXlsx: {
    zh: '上传文件只能是xlsx格式',
    en: '',
    pu: 'O arquivo carregado só pode ser feito em formato xlsx'
  },
  uploadNot2MB: {
    zh: '上传文件大小不能超过2MB',
    en: '',
    pu: 'O tamanho do arquivo carregado não pode exceder 2MB'
  },
  uploadNot10MB: {
    zh: '上传文件大小不能超过10MB',
    en: '',
    pu: 'O tamanho do arquivo carregado não pode exceder 10MB'
  },
  nullMsg: {
    zh: '- 暂无数据 -',
    en: '',
    pu: 'Sem dados'
  },
  provincialCapital: {
    zh: '州府(省会)城市区内',
    en: '',
    pu: 'Na capital do estado (capital provincial)'
  },
  nonProvincialCapital: {
    zh: '非州府(省会)城市',
    en: '',
    pu: 'Cidades não-capital (provinciais)'
  },
  pleaseInputStateTwoCode: {
    zh: '请填写对应州二字码(大写)',
    en: '',
    pu: 'Favor preencher o código de dois caracteres do estado correspondente (em letras maiúsculas)'
  },
  billGenerationTime: {
    zh: '账单生成时间：',
    en: '',
    pu: 'Fatura gerada quando.：'
  },
  billGenerationT: {
    zh: '账单生成时间',
    en: '',
    pu: 'Fatura gerada quando.'
  },
  distributionCost: {
    zh: '配送费',
    en: '',
    pu: 'Taxa de entrega'
  },
  collectCost: {
    zh: '揽收费',
    en: '',
    pu: 'Taxas de solicitação'
  },
  pushStatus: {
    zh: '推送状态：',
    en: '',
    pu: 'Status do impulso.：'
  },
  unpushed: {
    zh: '未推送',
    en: '',
    pu: 'Não impulsionado'
  },
  pushed: {
    zh: '已推送',
    en: '',
    pu: 'Impulsionado'
  },
  pushFailure: {
    zh: '推送失败',
    en: '',
    pu: 'Falha de impulso'
  },
  logisticsNumber: {
    zh: '物流号：',
    en: '',
    pu: 'Número de rastro：'
  },
  logisticsNum: {
    zh: '物流号',
    en: '',
    pu: 'Número de rastro'
  },
  uploadData: {
    zh: '上传数据',
    en: '',
    pu: 'Carregar dados'
  },
  trackPushTime: {
    zh: '轨迹推送时间',
    en: '',
    pu: 'Tempo de impulso do ratro'
  },
  chargedWeight: {
    zh: '计费重量',
    en: '',
    pu: 'Peso faturável'
  },
  haveRepeatData: {
    zh: '有重复数据，不能提交',
    en: '',
    pu: 'Há dados duplicados que não podem ser entregados'
  },
  dataError: {
    zh: '数据错误，不能提交',
    en: '',
    pu: 'Erro de dados, não pode entregar'
  },
  chooseNumber: {
    zh: '请选择单号类型',
    en: '',
    pu: 'Selecione o tipo de número do ordem.'
  },
  inputNumFind: {
    zh: '请在文本框内输入单号进行查询',
    en: '',
    pu: 'Digite o número do ordem na caixa de texto para consulta.'
  },
  ViewDetails: {
    zh: '查看详情',
    en: 'View details',
    pu: 'Ver detalhes'
  },
  choose100: {
    zh: '单次最多可选100个，已截取前100个商户',
    en: '',
    pu: 'Até 100 em uma única seleção, os primeiros 100 sellers foram interceptados'
  },
  pushTime: {
    zh: '推送时间',
    en: '',
    pu: 'Hora de empurrão'
  },
  pickUp: {
    zh: '上门取件',
    en: '',
    pu: 'coleta porta-a-porta'
  },
  toCollect: {
    zh: '上门揽收',
    en: '',
    pu: 'Coleta porta a porta'
  },
  dropOff: {
    zh: '上门交件',
    en: '',
    pu: 'entrega porta-a-porta'
  },
  doorPickUp: {
    zh: '上门揽收',
    en: 'door-to-door pick up',
    pu: 'coleta à porta'
  },

  lanshouTypee: {
    zh: '揽收方式',
    en: '',
    pu: 'maneira de coleta'
  },
  lanshouType: {
    zh: '揽收方式：',
    en: '',
    pu: 'maneira de coleta：'
  },
  input10Number: {
    zh: '请输入最多10位正整数',
    en: 'Please enter up to 10 positive integers',
    pu: 'Favor inserir até 10 números inteiros positivos'
  },
  waybillNumRepeat: {
    zh: '当前运单号重复',
    en: '',
    pu: 'Duplicar o número de rastreamento atual'
  },
  chooseChannel: {
    zh: '请选择业务主体',
    en: '',
    pu: 'seleccione uma entidade comercial'
  },
  pleaseSelectDotToEdit: {
    zh: '请选择网点进行编辑',
    en: '',
    pu: 'Favor selecionar um ponto de venda para edição'
  },
  ifGiveUp: {
    zh: '是否放弃编辑内容？',
    en: '',
    pu: 'O conteúdo editorial está abandonado?'
  },
  authorization: {
    zh: '授权',
    en: '',
    pu: 'autorizar'
  },
  enterMultipleVal: {
    zh: '请输入，多个值需用逗号隔开',
    en: '',
    pu: 'favor entrar, valores múltiplos a serem separados por vírgulas'
  },
  PlatformWhichBelongs: {
    zh: '所属平台：',
    en: 'The platform to which it belongs：',
    pu: 'A plataforma à qual pertence：'
  },
  platform: {
    zh: '所属平台',
    en: 'The platform to which it belongs',
    pu: 'A plataforma à qual pertence'
  },
  expressDelivery: {
    zh: '快递',
    en: '',
    pu: 'expresso'
  },
  bagWeight: {
    zh: '包裹重量',
    en: '',
    pu: 'parcel weight'
  },
  bagCost: {
    zh: '包裹申明价值',
    en: '',
    pu: 'Package Affirmation Value'
  },
  minimumCharge: {
    zh: '最低费用：',
    en: '',
    pu: 'custo mínimo：'
  },
  SerialNumber: {
    zh: '编号',
    en: '',
    pu: 'Número'
  },
  partitionName: {
    zh: '分区名称',
    en: '',
    pu: 'nome da divisão'
  },
  effectiveTime: {
    zh: '有效时间：',
    en: '',
    pu: 'tempo válido：'
  },
  effectiveStartTime: {
    zh: '有效起始时间：',
    en: '',
    pu: 'hora efetiva de início：'
  },
  eStartTime: {
    zh: '有效起始时间',
    en: '',
    pu: 'hora efetiva de início'
  },
  effectiveEndTime: {
    zh: '有效结束时间',
    en: '',
    pu: 'tempo final efetivo'
  },
  billingMode: {
    zh: '计费模式：',
    en: '',
    pu: 'modelo de faturamento：'
  },
  distance: {
    zh: '距离',
    en: '',
    pu: 'distância'
  },
  Weight: {
    zh: '重量',
    en: 'Weight',
    pu: 'Peso'
  },
  trialCalculation: {
    zh: '试算',
    en: '',
    pu: 'cálculo do julgamento'
  },
  trialResult: {
    zh: '试算结果：',
    en: '',
    pu: 'resultados dos cálculos dos ensaios：'
  },
  tableTips: {
    zh: '提示：d(distance)距离；w(weight)重量；p票数',
    en: '',
    pu: 'Dica: d(distance) distância; w(weight) peso; p número de votos'
  },
  tableExplain: {
    zh: '符号说明：>大于；<小于；>=大于或等于；<=小于或等于',
    en: '',
    pu: 'Descrição dos símbolos: > maior que; < menor que; >= maior ou igual a; <= menor ou igual a'
  },
  distanceMin: {
    zh: '距离(最小值)',
    en: '',
    pu: 'distância (mín.)'
  },
  pollMin: {
    zh: '票数(最小值)',
    en: '',
    pu: 'número de votos (min)'
  },
  weightMin: {
    zh: '重量(最小值)',
    en: '',
    pu: 'peso (min.)'
  },
  distanceMax: {
    zh: '距离(最大值)',
    en: '',
    pu: 'distância (máx)'
  },
  pollMax: {
    zh: '票数(最大值)',
    en: '',
    pu: 'número de votos (máximo)'
  },
  weightMax: {
    zh: '重量(最大值)',
    en: '',
    pu: 'peso (máximo)'
  },
  formula: {
    zh: '公式',
    en: '',
    pu: 'fórmula'
  },
  selectReceivingOutlets: {
    zh: '揽收网点选择',
    en: '',
    pu: 'seleção do ponto de coleta'
  },
  ZipCodeAreaName: {
    zh: '邮编分区名称：',
    en: '',
    pu: 'Divisão de Código Postal：'
  },
  query: {
    zh: '查询：',
    en: '',
    pu: 'Consultar：'
  },
  sendArea: {
    zh: '寄件区域：',
    en: '',
    pu: 'Área remetente：'
  },
  mailingAreaSelection: {
    zh: '寄件区域选择',
    en: '',
    pu: 'seleção da área de embarque'
  },
  enterNumber3significantDecimals: {
    zh: '请输入数字，可保留3位有效小数',
    en: '',
    pu: 'Favor inserir um número, 3 casas decimais válidas podem ser retidas'
  },
  regionName: {
    zh: '区域名称：',
    en: '',
    pu: 'nome da área：'
  },
  regionN: {
    zh: '区域名称',
    en: '',
    pu: 'nome da área'
  },
  selectiveData: {
    zh: '选择数据',
    en: '',
    pu: 'selecione os dados'
  },
  pleaseFillAreaName: {
    zh: '请填写区域名称',
    en: '',
    pu: 'por favor, preencha o nome da região'
  },
  pleaseSelectData: {
    zh: '请选择数据',
    en: '',
    pu: 'selecione os dados'
  },
  selectZonedZipCode: {
    zh: '选择分区邮编',
    en: '',
    pu: 'selecione um código postal zonal'
  },
  vehicleLife: {
    zh: '车辆使用年限：',
    en: '',
    pu: 'Idade do veículo：'
  },
  vehicleYear: {
    zh: '车辆使用年限',
    en: '',
    pu: 'Idade do veículo'
  },
  personalTaxNumber: {
    zh: '个人税号：',
    en: '',
    pu: 'Código tributário individual：'
  },
  personalTaxNum: {
    zh: '个人税号',
    en: '',
    pu: 'Código tributário individual'
  },
  drivingLicenseNumber: {
    zh: '驾驶证编号：',
    en: '',
    pu: 'número da carteira de motorista：'
  },
  drivingLicenseNum: {
    zh: '驾驶证编号',
    en: '',
    pu: 'número da carteira de motorista'
  },
  driveValidTime: {
    zh: '驾驶证有效期限：',
    en: '',
    pu: 'período de validade da carteira de motorista：'
  },
  driveValidT: {
    zh: '驾驶证有效期限',
    en: '',
    pu: 'período de validade da carteira de motorista'
  },
  driveIssuingAgency: {
    zh: '驾驶证颁发机构：',
    en: '',
    pu: 'Órgãos emissores de carteiras de motorista：'
  },
  driveIssuingA: {
    zh: '驾驶证颁发机构',
    en: '',
    pu: 'Órgãos emissores de carteiras de motorista'
  },
  to: {
    zh: '至',
    en: 'to',
    pu: 'Para'
  },
  minimumNotThanMaximum: {
    zh: '最小值不能大于最大值',
    en: '',
    pu: 'O valor mínimo não pode ser maior que o valor máximo'
  },
  operatingCost: {
    zh: '操作费用',
    en: '',
    pu: 'custos operacionais'
  },
  warehouseAnomalyCharge: {
    zh: '仓库异常费用',
    en: '',
    pu: 'custos de exceção do depósito'
  },
  orderWeight: {
    zh: '订单重量',
    en: '',
    pu: 'peso do pedido'
  },
  commodityInvoiceNumber: {
    zh: '商品发票号',
    en: '',
    pu: 'número da fatura do produto'
  },
  logisticsInvoiceType: {
    zh: '物流发票类型',
    en: '',
    pu: 'tipos de faturas de logística'
  },
  logisticsInvoiceNumber: {
    zh: '物流发票号',
    en: '',
    pu: 'número da fatura de logística'
  },
  collectScanTime: {
    zh: '揽收扫描时间',
    en: '',
    pu: 'solicitation scanning time'
  },
  actualSigningTime: {
    zh: '实际签收时间',
    en: '',
    pu: 'actual sign-off time'
  },
  oneselfQuery: {
    zh: '自定义查询',
    en: '',
    pu: 'consultas personalizadas'
  },
  criterionQuery: {
    zh: '固定查询',
    en: '',
    pu: 'consultas corrigidas'
  },
  allSelect: {
    zh: '全选',
    en: '',
    pu: 'Selecionar tudo'
  },
  queryField: {
    zh: '自定义查询展示栏位',
    en: '',
    pu: 'Campos de exibição de consulta personalizados'
  },
  collectionScanUploadTime: {
    zh: '揽收上传时间',
    en: '',
    pu: 'Tempo de coleta e upload'
  },
  signatureScanTime: {
    zh: '签收扫描时间',
    en: '',
    pu: 'tempo de assinatura e digitalização'
  },
  signatureUploadTime: {
    zh: '签收上传时间',
    en: '',
    pu: 'tempo de assinatura e upload'
  },
  collectionTrackCreateTime: {
    zh: '揽收轨迹创建时间',
    en: '',
    pu: 'tempo de criação da trilha de coleta'
  },
  receivingTrackPushTime: {
    zh: '揽收轨迹推送时间',
    en: '',
    pu: 'tempo de envio da trilha de coleta'
  },
  signatureTrackCreateTime: {
    zh: '签收轨迹创建时间',
    en: '',
    pu: 'hora da criação da trilha de aprovação'
  },
  signatureTrackPushTime: {
    zh: '签收轨迹推送时间',
    en: '',
    pu: 'tempo de envio da trilha de assinatura'
  },
  receiptArs: {
    zh: '收件地址',
    en: 'The delivery address',
    pu: 'Endereço destinatário'
  },
  SendAddress: {
    zh: '发件地址',
    en: 'Sending Address',
    pu: 'Endereço do remetente'
  },
  Recipientzc: {
    zh: '收件人邮编',
    en: "Recipient's zip code",
    pu: 'CEP do destinatário'
  },
  senderZipCode: {
    zh: '发件人邮编',
    en: 'Recipient Name',
    pu: 'Nome do destinatário'
  },
  SenderCity: {
    zh: '发件人城市',
    en: 'Sender city',
    pu: 'cidade do remetente'
  },
  receiveCity: {
    zh: '收件人城市',
    en: '',
    pu: 'Cidade destinatária'
  },
  invoiceType: {
    zh: '发票类型：',
    en: '',
    pu: 'tipo de fatura：'
  },
  invoiceT: {
    zh: '发票类型',
    en: '',
    pu: 'tipo de fatura'
  },
  receivingMode: {
    zh: '揽收模式',
    en: '',
    pu: 'Modelo de coleção'
  },
  declaredValue: {
    zh: '申明价值',
    en: '',
    pu: 'Afirmação de valor'
  },
  collectionFee: {
    zh: '揽收费用',
    en: '',
    pu: 'Cobrança de taxas'
  },
  actualCollectionMode: {
    zh: '实际揽收模式',
    en: '',
    pu: 'Padrões reais de coleta'
  },
  closeTime: {
    zh: '闭环时间',
    en: '',
    pu: 'tempo de loop fechado'
  },
  closedLoop: {
    zh: '已闭环',
    en: '',
    pu: 'Finalizado'
  },
  noDataExport: {
    zh: '无可导出数据',
    en: '',
    pu: 'Nenhum dado exportável'
  },
  dataNotExist: {
    zh: '数据不存在',
    en: '',
    pu: 'os dados não existem'
  },
  invoiceSecretKey: {
    zh: '发票秘钥',
    en: '',
    pu: 'Faturas Chave secreta'
  },
  exportFailure: {
    zh: '未导出成功，请重新导出',
    en: '',
    pu: 'A exportação não foi bem-sucedida, favor reexportar'
  },
  commaSeparation: {
    zh: '填写对应范围(逗号分割)',
    en: '',
    pu: 'Preencha o intervalo correspondente (separado por vírgulas)'
  },
  commaSeparationTips: {
    zh: '(逗号分割)',
    en: '',
    pu: '(separado por vírgulas)'
  },
  outRange: {
    zh: '超出范围',
    en: '',
    pu: 'Fora da faixa'
  },
  allowCreatePrompt: {
    zh: '只允许建立总部下的同一层级或者其下级网点',
    en: '',
    pu: 'Somente o mesmo nível abaixo da matriz ou de suas filiais subordinadas pode ser estabelecido'
  },
  signatureTime: {
    zh: '签名时间',
    en: '',
    pu: 'Tempo de assinatura'
  },

  bagNumber: {
    zh: '车签号：',
    en: '',
    pu: 'Número de escaneamento do veículo：'
  },
  cabinetNumber: {
    zh: '柜子编号',
    en: '',
    pu: 'Número do gabinete'
  },
  cabinetNum: {
    zh: '柜子编号：',
    en: '',
    pu: 'Número do gabinete：'
  },
  cabinetType: {
    zh: '柜子类型',
    en: '',
    pu: 'Tipo de gabinete'
  },
  cabinetT: {
    zh: '柜子类型：',
    en: '',
    pu: 'Tipo de gabinete：'
  },
  gridNumber: {
    zh: '格子编号',
    en: '',
    pu: 'Número da grade'
  },
  latticeSize: {
    zh: '格子规格',
    en: '',
    pu: 'Especificação da rede'
  },
  cabinet: {
    zh: '柜子位置',
    en: '',
    pu: 'Localização do gabinete'
  },
  cabinetAddress: {
    zh: '柜子位置：',
    en: '',
    pu: 'Localização do gabinete：'
  },
  LAL: {
    zh: '经纬度',
    en: '',
    pu: 'Latitude e longitude'
  },
  gridNum: {
    zh: '格子编号：',
    en: '',
    pu: 'Número da grade：'
  },
  belongToCabinet: {
    zh: '所属柜子编号：',
    en: '',
    pu: 'Número do gabinete：'
  },
  belongToCabinetNum: {
    zh: '所属柜子编号',
    en: '',
    pu: 'Número do gabinete'
  },
  collectParcel: {
    zh: '取件',
    en: '',
    pu: 'coleta'
  },
  delivery: {
    zh: '派件',
    en: '',
    pu: 'despacho'
  },
  mixed: {
    zh: '混合',
    en: '',
    pu: 'híbrido'
  },
  latticeS: {
    zh: '格子规格：',
    en: '',
    pu: 'Especificação da rede：'
  },
  oversized: {
    zh: '超大',
    en: '',
    pu: 'tamanho grande'
  },
  big: {
    zh: '大',
    en: '',
    pu: 'mais antigo'
  },
  middle: {
    zh: '中',
    en: '',
    pu: 'médio'
  },
  small: {
    zh: '小',
    en: '',
    pu: 'poucos'
  },
  addSuccessful: {
    zh: '新增成功',
    en: '',
    pu: 'adicionado com sucesso'
  },
  enableNotAllowDel: {
    zh: '启用状态的数据不允许删除',
    en: '',
    pu: 'a exclusão de dados no estado habilitado não é permitida'
  },
  DeleteSuccessful: {
    zh: '删除成功',
    en: 'Delete successful',
    pu: 'Excluir bem-sucedido'
  },
  towOne: {
    zh: '一拖一',
    en: '',
    pu: 'um reboque (veículo)'
  },
  doorToDoorDeliveryInquiryForm: {
    zh: '上门交件查询表',
    en: '',
    pu: 'formulário de consulta para entrega porta a porta'
  },
  dailyReportsDetailedList: {
    zh: '揽收日报表明细表',
    en: '',
    pu: 'detalhamento dos extratos diários de cobrança'
  },
  loader: {
    zh: '装车人',
    en: '',
    pu: 'Carregadeiras'
  },
  loadTime: {
    zh: '装车时间',
    en: '',
    pu: 'Hora de carga'
  },
  loadSiteIdList: {
    zh: '装车网点',
    en: '',
    pu: 'Ponto de Carregamento'
  },
  loadSign: {
    zh: '装车签名',
    en: '',
    pu: 'Assinatura de carregamento'
  },
  unloader: {
    zh: '卸车人',
    en: '',
    pu: 'Descarregadores'
  },
  unloadTime: {
    zh: '卸车时间',
    en: '',
    pu: 'Tempo de descarregamento'
  },
  unLoadSign: {
    zh: '卸车签名',
    en: '',
    pu: 'Assinatura de descarregamento'
  },
  totalLoad: {
    zh: '装车总数',
    en: '',
    pu: 'número total de veículos carregados'
  },
  totalUnloading: {
    zh: '卸车总数',
    en: '',
    pu: 'total de descarregamento'
  },
  cityName: {
    zh: '城市名称：',
    en: '',
    pu: 'Cidade：'
  },
  unclaimedItem: {
    zh: '待取件：',
    en: '',
    pu: 'Pendente de coleta：'
  },
  unsolicitedItem: {
    zh: '待揽件：',
    en: '',
    pu: 'Pendente：'
  },
  detentionShipment: {
    zh: '滞留件：',
    en: '',
    pu: 'item retido：'
  },
  space: {
    zh: '空白格：',
    en: '',
    pu: 'espaço em branco：'
  },
  unclaimed: {
    zh: '待取件',
    en: '',
    pu: 'Pendente de coleta'
  },
  unsolicited: {
    zh: '待揽件',
    en: '',
    pu: 'Pendente'
  },
  detentionShip: {
    zh: '滞留件',
    en: '',
    pu: 'item retido'
  },
  blankGrid: {
    zh: '空格子',
    en: '',
    pu: 'espaço em branco'
  },
  red: {
    zh: '红色：',
    en: '',
    pu: 'vermelho'
  },
  green: {
    zh: '绿色：',
    en: '',
    pu: 'Verde'
  },
  orange: {
    zh: '橙色：',
    en: '',
    pu: 'Laranja'
  },
  white: {
    zh: '白色：',
    en: '',
    pu: 'fig. reacionário'
  },
  pleaseNumber: {
    zh: '请填写数字，多个值用逗号分隔',
    en: '',
    pu: 'preencha os números e separe vários valores com vírgulas'
  },
  selectCabinet: {
    zh: '请选择柜子',
    en: '',
    pu: 'Cidade：'
  },
  cityExtraName: {
    zh: '城市扩展名称：',
    en: '',
    pu: 'nome do ramal da cidade：'
  },
  startZipcodeLessEndZipcode: {
    zh: '开始邮编不能大于或等于结束邮编',
    en: '',
    pu: 'O código postal inicial não pode ser maior ou igual ao código postal final'
  },
  driversLicenseType: {
    zh: '驾照类型：',
    en: '',
    pu: 'Tipo de carteira de motorista：'
  },
  driversLicenseT: {
    zh: '驾照类型',
    en: '',
    pu: 'Tipo de carteira de motorista'
  },
  formulaExample: {
    zh: '公式示例：(1 + 2) * 3 - 4 / d',
    en: 'Formula example：(1 + 2) * 3 - 4 / d',
    pu: 'Fórmula de exemplo：(1 + 2) * 3 - 4 / d'
  },
  formulaError: {
    zh: '公式错误，请检查',
    en: '',
    pu: 'Erro de fórmula, verifique'
  },
  NoDataWasQueried: {
    zh: '未查询到数据',
    en: 'No data was queried',
    pu: 'Nenhum dado foi consultado'
  },
  querySiteCode: {
    zh: '仅支持网点编号或网点名称查询',
    en: '',
    pu: 'Suporta apenas a pesquisa do número ou do nome da filial'
  },
  intervalDataOverlaps: {
    zh: '区间段数据维护重叠，请检查后重新维护',
    en: '',
    pu: 'Sobreposição na manutenção dos dados do segmento de intervalo, verifique e atualize novamente'
  },
  provinceExtensionName: {
    zh: '省份扩展名称：',
    en: '',
    pu: 'nome estendido da província：'
  },
  signBag: {
    zh: '签收：',
    en: '',
    pu: 'Assinatura：'
  },
  PleaseScan: {
    zh: '请扫描',
    en: 'Please scan',
    pu: 'Favor de escanear'
  },
  pleaseEnterOrScan: {
    zh: '请输入或扫描',
    en: '',
    pu: 'digite ou escaneie'
  },
  signature: {
    zh: '签名',
    en: '',
    pu: 'Assinatura'
  },
  submit: {
    zh: '提交',
    en: 'submit',
    pu: 'Enviar'
  },
  submitSuccessfully: {
    zh: '提交成功',
    en: '',
    pu: 'Enviado com sucesso'
  },
  submitSignature: {
    zh: '提交签名',
    en: '',
    pu: 'Envio de assinaturas'
  },
  compellation: {
    zh: '姓名',
    en: 'name',
    pu: 'Nome'
  },
  deliveryPersonnelInfo: {
    zh: '派件人员信息',
    en: '',
    pu: 'Informações do despachante'
  },
  jobNumber: {
    zh: '工号',
    en: '',
    pu: 'número do trabalho'
  },
  deliverPackageInfo: {
    zh: '派送包裹信息',
    en: '',
    pu: 'Informações sobre entrega de encomendas'
  },
  signTotal: {
    zh: '签收总数',
    en: '',
    pu: 'número total de assinaturas'
  },
  howManyParcel: {
    zh: '个包裹', // 只有1个
    en: '',
    pu: 'parcelas'
  },
  howManyParcels: {
    zh: '个包裹', // 大于1个
    en: '',
    pu: 'parcelas'
  },
  bag: {
    zh: '袋', // 只有1个
    en: 'bag',
    pu: 'saco'
  },
  bags: {
    zh: '袋', // 大于1个
    en: 'bag',
    pu: 'bolsas'
  },
  recipientSign: {
    zh: '收件人签名',
    en: '',
    pu: 'assinatura do destinatário'
  },
  senderMobileNumber: {
    zh: '发件人手机号码',
    en: '',
    pu: 'número de celular do remetente'
  },
  stateOrigin: {
    zh: '发件州',
    en: '',
    pu: 'estado de envio'
  },
  sendingCity: {
    zh: '发件城市',
    en: '',
    pu: 'cidade de envio'
  },
  sendingDetailsAddress: {
    zh: '发件详细地址',
    en: '',
    pu: 'detalhes de envio'
  },
  RecipientPhoneNum: {
    zh: '收件人电话',
    en: 'Recipient phone number',
    pu: 'Nº de telefone do destinatário'
  },
  RecipientP: {
    zh: '收件人手机',
    en: 'Recipient\'s cell phone',
    pu: 'Nº de celular do destinatário'
  },
  boxNumber: {
    zh: '箱子号',
    en: '',
    pu: 'número do caso'
  },
  boxBarCode: {
    zh: '箱子条码',
    en: '',
    pu: 'código de barras da caixa'
  },
  CommodityInvoiceKey: {
    zh: '商品发票秘钥',
    en: 'Commodity invoice key',
    pu: 'Chave da fatura da mercadoria'
  },
  tradeName: {
    zh: '商品名称',
    en: '',
    pu: 'nome do produto'
  },
  declaredVal: {
    zh: '声明价值',
    en: '',
    pu: 'valor declarado'
  },
  numberItems: {
    zh: '商品件数',
    en: '',
    pu: 'número de itens'
  },
  cteInvoiceKey: {
    zh: 'cte发票秘钥',
    en: '',
    pu: 'chave de fatura cte'
  },
  SenderInformation: {
    zh: '发件人信息',
    en: 'Sender information',
    pu: 'Informações sobre remetente'
  },
  cargoInformation: {
    zh: '货物信息',
    en: '',
    pu: 'Informações sobre a carga'
  },
  phone: {
    zh: '电话：',
    en: 'Phone：',
    pu: 'Telefone：'
  },
  cellphone: {
    zh: '手机：',
    en: '',
    pu: 'Celular：'
  },
  detailedArs: {
    zh: '详细地址：',
    en: 'detailed address：',
    pu: 'endereo detalhado：'
  },
  CPF: {
    zh: '税号：',
    en: 'CPF：',
    pu: 'CPF/CNPJ：'
  },
  adrser: {
    zh: '发件人：',
    en: '',
    pu: 'remetente：'
  },
  senderName: {
    zh: '发件人',
    en: '',
    pu: 'Remetente'
  },
  stateOri: {
    zh: '发件州：',
    en: '',
    pu: 'estado de envio：'
  },
  sendingCt: {
    zh: '发件城市：',
    en: '',
    pu: 'cidade de envio：'
  },
  tradeN: {
    zh: '商品名称：',
    en: '',
    pu: 'nome do produto：'
  },
  totalWeight: {
    zh: '总重量：',
    en: 'Total weight：',
    pu: 'Peso total：'
  },
  totalParcels: {
    zh: '总包裹数：',
    en: '',
    pu: 'número total de parcelas：'
  },
  totalDeclaredValue: {
    zh: '总声明价值：',
    en: '',
    pu: 'Valor total declarado：'
  },
  boxLen: {
    zh: '箱子长：',
    en: '',
    pu: 'comprimento da caixa：'
  },
  boxWei: {
    zh: '箱子宽：',
    en: '',
    pu: 'largura da caixa：'
  },
  boxHei: {
    zh: '箱子高：',
    en: '',
    pu: 'altura da caixa：'
  },
  supportModifyData: {
    zh: '仅支持修改一条数据',
    en: '',
    pu: 'suporta a modificação de apenas uma parte dos dados'
  },
  oddNumber: {
    zh: '单号：',
    en: '',
    pu: 'número ímpar'
  },
  TypesProblem: {
    zh: '问题件类型：',
    en: 'Types of problem parcels：',
    pu: 'Tipo de pacote anormal：'
  },
  selectProblemFirst: {
    zh: '请先选择问题件类型',
    en: '',
    pu: 'selecione primeiro o tipo de problema'
  },
  RecipientProvince: {
    zh: '收件人省份',
    en: 'Recipient province',
    pu: 'Província do destinatário'
  },
  cancelReason: {
    zh: '取消原因',
    en: '',
    pu: 'motivo do cancelamento'
  },
  actPickUpCode: {
    zh: '实际揽收码',
    en: '',
    pu: 'Código atual'
  },
  batchNo: {
    zh: '波次号',
    en: '',
    pu: 'Número de Onda'
  },
  clearImage: {
    zh: '清场图片',
    en: '',
    pu: 'Imagem de Apuramento'
  },
  pickupCode: {
    zh: '揽收码',
    en: '',
    pu: 'Código'
  },
  pleaseFillLetterOrNum: {
    zh: '请填写字母或数字',
    en: '',
    pu: 'por favor, preencha com letras ou números'
  },
  pleaseFillNumAndSpecialSymbol: {
    zh: '请填写数字及特殊符号, 例: 005.339.857-22',
    en: '',
    pu: 'preencha os números e os símbolos especiais, por exemplo, 005.339.857-22.'
  },
  brazilPhoneRestrict: {
    zh: '长度不低于10位，允许包含 + - 空格等特殊字符',
    en: '',
    pu: 'o comprimento não deve ser inferior a 10 dígitos, permitindo a inclusão de caracteres especiais, como espaços +-.'
  },
  enterpriseTaxNumber: {
    zh: '企业税号：',
    en: '',
    pu: 'código tributário da empresa：'
  },
  enterpriseTaxNumTips: {
    zh: '数字长度为14，包含.-/等特殊字符',
    en: '',
    pu: 'the number is 14 characters in length and contains special characters such as periods (.) -/'
  },
  enterpriseTaxNumTips11: {
    zh: '数字长度为11，格式为`xxx.xxx.xxx-yy`',
    en: '',
    pu: 'Número com 11 dígitos, formato `xxx.xxx.xxx-yy`'
  },
  enterpriseTaxNumTips14: {
    zh: '数字长度为14，格式为`xx.xxx.xxx/yyyy-zz`',
    en: '',
    pu: 'Número com 14 dígitos, formato `xx.xxx.xxx-yyyy-zz`'
  },
  problemPieceType: {
    zh: '问题件类型',
    en: 'The type of problem piece',
    pu: 'Tipo de problema'
  },
  checkItemTips: {
    zh: '勾选项中订单状态为问题件的数据已过滤',
    en: '',
    pu: 'os dados marcados com status de pedido de Peças problemáticas são filtrados.'
  },
  dataBeingGenerated: {
    zh: '正在生成数据',
    en: '',
    pu: 'dados que estão sendo gerados'
  },
  loadingSuc: {
    zh: '装车成功',
    en: '',
    pu: 'Carregamento bem-sucedido'
  },
  carNum: {
    zh: '车辆编号：',
    en: '',
    pu: 'Número do veículo：'
  },
  next: {
    zh: '下一步',
    en: 'Next',
    pu: 'Próximo passo'
  },
  scanningVehicle: {
    zh: '扫描车辆',
    en: '',
    pu: 'escaneamento de veículos'
  },
  scanningBag: {
    zh: '扫描袋号',
    en: '',
    pu: 'Leitura do número da saca'
  },
  scanningCarTag: {
    zh: '扫描车签号',
    en: '',
    pu: 'Leitura da ordem de coleta'
  },
  carScan: {
    zh: '车辆扫描：',
    en: '',
    pu: 'Scaneamento do veículo：'
  },
  scanQRCodeOrNum: {
    zh: '请扫描车辆二维码或输入车辆编号',
    en: '',
    pu: ' Leia o código QR do veículo'
  },
  carDriver: {
    zh: '驾驶人：',
    en: '',
    pu: 'motorista：'
  },
  bagNumScan: {
    zh: '袋号扫描：',
    en: '',
    pu: 'leitura do número da bolsa：'
  },
  pleaseScanBagNum: {
    zh: '请扫描袋号',
    en: '',
    pu: 'favor escanear o número da sacola'
  },
  carNumScan: {
    zh: '车签号扫描：',
    en: '',
    pu: 'verificação do número do carro：'
  },
  pleaseScanCarNum: {
    zh: '请扫描车签号',
    en: '',
    pu: 'digitalize o número do adesivo do carro'
  },
  loadingEnd: {
    zh: '装车结束',
    en: '',
    pu: 'Fim do carregamento'
  },
  scanned: {
    zh: '已扫描',
    en: '',
    pu: 'digitalizado'
  },
  signFor: {
    zh: '签收',
    en: '',
    pu: 'Assinatura'
  },
  cabinetPickup: {
    zh: '取件(智能柜取件)',
    en: '',
    pu: 'Coleta(Captador de gabinete inteligente)'
  },
  smartCabinet: {
    zh: '智能柜',
    en: '',
    pu: 'gabinete inteligente'
  },
  SMSManagement: {
    zh: '短信管理',
    en: '',
    pu: 'Gerenciamento de SMS'
  },
  UnacquiredTime: {
    zh: '未获取到时间',
    en: '',
    pu: 'O horário não foi obtido'
  },
  unloadingEnd: {
    zh: '卸车结束',
    en: '',
    pu: 'Fim do descarregamento'
  },
  bagsAmount: {
    zh: '袋数：',
    en: '',
    pu: 'quantidade de sacas：'
  },
  scanningTime: {
    zh: '扫描时间：',
    en: '',
    pu: 'Tempo de varredura：'
  },
  unloadedSuccessfully: {
    zh: '卸车成功',
    en: '',
    pu: 'Descarregado com sucesso'
  },
  carTagNull: {
    zh: '车签号不能为空',
    en: '',
    pu: 'O número de registro do carro não pode estar vazio'
  },
  endOfUnloading: {
    zh: '结束卸车',
    en: '',
    pu: 'Fim do descarregamento'
  },
  max180DaysDate: {
    zh: '最多可查询到180天的数据',
    en: '',
    pu: 'Até 180 dias de dados'
  },
  loaderDot: {
    zh: '装车人所属网点',
    en: '',
    pu: 'rede do carregador'
  },
  loadingAddress: {
    zh: '装车地址',
    en: '',
    pu: 'endereço de carregamento'
  },
  unloaderDot: {
    zh: '卸车人所属网点',
    en: '',
    pu: 'rede de descarregadores'
  },
  unloadingAddress: {
    zh: '卸车地址',
    en: '',
    pu: 'endereço de descarregamento'
  },
  totalNumberBagsLoaded: {
    zh: '装车总袋数',
    en: '',
    pu: 'número total de sacos carregados'
  },
  totalBagsUnloaded: {
    zh: '卸车总袋数',
    en: '',
    pu: 'Total de sacos descarregados'
  },
  BagSealer: {
    zh: '封袋人',
    en: '',
    pu: 'Selador'
  },
  bagPackerDot: {
    zh: '封袋人所属网点',
    en: '',
    pu: 'saída do selador de sacos'
  },
  InPocketTotal: {
    zh: '袋内总包裹数',
    en: '',
    pu: 'número total de pacotes na sacola'
  },
  loadingScanningTime: {
    zh: '装车扫描时间',
    en: '',
    pu: 'tempo de varredura de carga'
  },
  unloadingScanningTime: {
    zh: '卸车扫描时间',
    en: '',
    pu: 'tempo de varredura de descarga'
  },
  loadingUploadingTime: {
    zh: '装车上传时间',
    en: '',
    pu: 'tempo de carregamento e upload'
  },
  unloadingUploadTime: {
    zh: '卸车上传时间',
    en: '',
    pu: 'tempo de descarregamento e upload'
  },
  compatibleBasecode: {
    zh: '兼容字母、特殊字符（-）数字的组合，最大长度为30',
    en: '',
    pu: 'Compatível com combinações de letras, caracteres especiais (-), números, comprimento máximo de 30'
  },
  tipoTitle: {
    zh: '订单类型',
    en: '',
    pu: 'tipo'
  },
  tipo: {
    zh: '订单类型：',
    en: '',
    pu: 'tipo：'
  },
  tudo: {
    zh: '全部',
    en: 'all',
    pu: 'tudo'
  },
  normal: {
    zh: '普通订单',
    en: '',
    pu: 'normal'
  },
  cutOff: {
    zh: '截单订单',
    en: '',
    pu: 'cut-off'
  },
  max3DaysDate: {
    zh: '最多可查询到3天的数据',
    en: '',
    pu: 'Até 3 dias de dados'
  },
  max7DaysDate: {
    zh: '最多可查询到7天的数据',
    en: '',
    pu: 'Até 7 dias de dados'
  },
  max15DaysDate: {
    zh: '最多可查询到15天的数据',
    en: '',
    pu: 'Até 15 dias de dados'
  },
  SenderMailbox: {
    zh: '发件人邮箱',
    en: 'Sender mailbox',
    pu: 'email de remetente'
  },
  MerchantNumber: {
    zh: '商户编号',
    en: '',
    pu: 'Número do comerciante'
  },
  toBeCollectedNumber: {
    zh: '待揽收包裹数',
    en: '',
    pu: 'Número de pacotes aguardando coleta'
  },
  MerchantClass: {
    zh: '商家等级',
    en: '',
    pu: 'Nível do comerciante'
  },
  addresseeContactNumber: {
    zh: '收件人联系电话',
    en: '',
    pu: 'Número de contato do destinatário'
  },
  choose1000: {
    zh: '单次最多可选1000个，已截取前1000个商户',
    en: '',
    pu: 'Até 1000 em uma única seleção, os primeiros 1000 sellers foram interceptados'
  },
  ForecastWeight: {
    zh: '预报重量',
    en: 'Forecast weight',
    pu: 'Peso previsto'
  },
  ActualWeight: {
    zh: '实际重量',
    en: '',
    pu: 'peso real'
  },
  commodityInvoiceType: {
    zh: '商品发票类型',
    en: '',
    pu: 'Tipo de fatura de mercadoria'
  },
  commodityInvoiceKey: {
    zh: '商品发票密钥',
    en: '',
    pu: 'Chave da fatura de mercadorias'
  },
  LogisticsInvoiceType: {
    zh: '物流发票类型',
    en: '',
    pu: 'Tipo de fatura de logística'
  },
  LogisticsInvoiceKey: {
    zh: '物流发票密钥',
    en: '',
    pu: 'Chave de fatura logística'
  },
  carLoadingVotes: {
    zh: '装车总票数',
    en: '',
    pu: 'Número total de votos carregados'
  },
  carUnloadingVotes: {
    zh: '卸车总票数',
    en: '',
    pu: 'Número total de votos descarregados'
  },
  loadingPicture: {
    zh: '装车图片',
    en: '',
    pu: 'Carregando imagem'
  },
  unloadingPicture: {
    zh: '卸车图片',
    en: '',
    pu: 'imagem de descarregamento'
  },
  sealVehiclePicture: {
    zh: '封车图片',
    en: '',
    pu: 'imagem de fechamento do veículo'
  },
  unsealVehiclePicture: {
    zh: '解封车图片',
    en: '',
    pu: 'imagem de desbloquear o veículo'
  },
  upload5Photos: {
    zh: '最多上传5张照片',
    en: '',
    pu: 'Faça upload de até 5 fotos'
  },
  upload1Photo: {
    zh: '最多上传1张照片',
    en: '',
    pu: 'Faça upload de até 1 foto'
  },
  // 上传装/卸车图片
  Upload: {
    zh: '上传',
    en: '',
    pu: 'Enviar'
  },
  picture: {
    zh: '图片',
    en: '',
    pu: 'fotos'
  },
  pleaseUploadLI: {
    zh: '请上传装车照片.',
    en: '',
    pu: 'Faça favor de carregar a foto de carregamento.'
  },
  pleaseUploadUnLI: {
    zh: '请上传卸车照片.',
    en: '',
    pu: 'Faça favor de carregar a foto de descarregamento.'
  },
  proofArrival: {
    zh: '到达凭证',
    en: '',
    pu: 'comprovante de chegada'
  },
  departureCertificate: {
    zh: '离开凭证',
    en: '',
    pu: 'certificado de partida'
  },
  merchantData: {
    zh: '商家数据展示',
    en: '',
    pu: 'Dados de seller'
  },
  merchantsTotalNumber: {
    zh: '商家总数',
    en: '',
    pu: 'total'
  },
  completeMerchant: {
    zh: '完成商家',
    en: '',
    pu: 'quantidade concluído'
  },
  residualMerchant: {
    zh: '剩余商家',
    en: '',
    pu: 'quantidade restante'
  },
  degreeCompletion: {
    zh: '完成度',
    en: '',
    pu: 'persentagem de conclusão'
  },
  singleVolumeData: {
    zh: '单量数据展示',
    en: '',
    pu: 'Dados de pedido'
  },
  unit: {
    zh: '单量',
    en: 'quantity per unit',
    pu: 'quantidade por unidade'
  },
  totalUnit: {
    zh: '总计单量',
    en: '',
    pu: 'total'
  },
  completionUnit: {
    zh: '完成单量',
    en: '',
    pu: 'quantidade concluído'
  },
  residualUnit: {
    zh: '剩余单量',
    en: '',
    pu: 'quantidade restante'
  },
  outgoingVehiclesNumber: {
    zh: '出车数量',
    en: '',
    pu: 'soma de veículo saído'
  },
  assistantsNumber: {
    zh: '助理数量',
    en: '',
    pu: 'quantidade de ajudante'
  },
  closedLoopUnit: {
    zh: '闭环单量',
    en: '',
    pu: 'quantidade de closed-loop'
  },
  complaintsNumber: {
    zh: '投诉数量',
    en: '',
    pu: 'quantidade de reclamação'
  },
  AbnormalUnivariate: {
    zh: '异常单量',
    en: '',
    pu: 'quantidade de anormal'
  },
  lanshouLargeScreen: {
    zh: '揽收看板可视化大屏',
    en: '',
    pu: 'Tela de visulização de coleta'
  },
  SenderCompany: {
    zh: '发件人公司',
    en: 'Sender Company',
    pu: 'Empresa do remetente'
  },
  lanshouZipcode: {
    zh: '揽收-邮编',
    en: '',
    pu: 'coleta-CEP'
  },
  receiveZipcode: {
    zh: '收件-邮编',
    en: '',
    pu: 'destinário-CEP'
  },
  scanPgTrc: {
    zh: '扫描大包号/运单号',
    en: '',
    pu: 'Digitalização do número da parcela/número da carta de porte'
  },
  scanPgTrcN: {
    zh: '扫描大包号/运单号：',
    en: '',
    pu: 'Digitalização do número da parcela/número da carta de porte：'
  },
  BagTrcNumber: {
    zh: '袋号/运单号',
    en: '',
    pu: 'Número da saca/número da carta de porte'
  },
  maxQuery1000: {
    zh: '最大查询1000条',
    en: '',
    pu: 'Consulta máxima de 1000 registros'
  },
  totalNumberOfPackages: {
    zh: '包裹总数',
    en: 'Total number of packages',
    pu: 'Total de pacotes'
  },
  pleaseRescan: {
    zh: '单号不符合要求请重新扫描',
    en: '',
    pu: 'Por favor, escaneie novamente o número de rastreamento, pois não está de acordo com os requisitos.'
  },
  largeVan: {
    zh: '大型厢式货车',
    en: '',
    pu: 'Van grande'
  },
  lightweightEnclosedCar: {
    zh: '轻型封闭式货车',
    en: '',
    pu: 'Veículo de carga leve fechado'
  },
  Volume: {
    zh: '车辆容积',
    en: '',
    pu: 'Volume do veículo'
  },
  Payload: {
    zh: '车辆载重',
    en: '',
    pu: 'Carga do veículo'
  },
  GrossWeight: {
    zh: '车辆毛重',
    en: '',
    pu: 'Gross Weight'
  },
  CNPJ: {
    zh: '车辆所属公司税号',
    en: '',
    pu: 'Código tributário da empresa de veículos'
  },
  trailerPlates: {
    zh: '拖车车牌',
    en: '',
    pu: 'Placa de licença do reboque'
  },
  vehicleStateRgtNumber: {
    zh: '车辆州注册号',
    en: '',
    pu: 'Número de registro estadual do veículo'
  },
  RNTRC: {
    zh: 'RNTRC',
    en: '',
    pu: 'RNTRC'
  },
  documentation: {
    zh: '车辆文件编号',
    en: '',
    pu: 'Número do documento do veículo'
  },
  trailerPlatesNotNull: {
    zh: '车辆拖车车牌不能为空',
    en: '',
    pu: 'As placas do reboque do veículo não podem estar vazias'
  },
  returnRegistration: {
    zh: '退件登记：',
    en: '',
    pu: 'Registro de devoluções：'
  },
  returnPoint: {
    zh: '退回网点：',
    en: '',
    pu: 'Retorno aos pontos de venda：'
  },
  returnSite: {
    zh: '退回网点',
    en: '',
    pu: 'Retorno aos pontos de venda'
  },
  UploadSuccessful: {
    zh: '上传成功',
    en: 'Upload successful',
    pu: 'Carregar bem-sucedido'
  },
  required: {
    zh: '请填写网点编号、网点名称、网点类型',
    en: '',
    pu: 'Preencha o número da tomada, o nome da tomada e o tipo de tomada'
  },
  requiredSiteStatus: {
    zh: '请填写是否启用项',
    en: '',
    pu: 'Preencha se o item está habilitado ou não'
  },
  orderPerDay: {
    zh: '日均下单量',
    en: '',
    pu: 'Volume de criação diária'
  },
  orderNum: {
    zh: '应揽收量',
    en: '',
    pu: 'Volume de contas a receber'
  },
  collectNum: {
    zh: '实际揽收量',
    en: '',
    pu: 'Cobranças reais'
  },
  unreceivedNum: {
    zh: '未揽收量',
    en: '',
    pu: 'Volume não coletado'
  },
  unreceivedOrder: {
    zh: '未揽收订单',
    en: '',
    pu: 'Pedidos não coletados'
  },
  registrantSiteName: {
    zh: '登记人所属网点',
    en: '',
    pu: 'Rede do registrante'
  },
  operatedObject: {
    zh: '操作对象',
    en: 'Operated object',
    pu: 'Objeto operado'
  },
  exportAllOrderData: {
    zh: '导出全部订单数据',
    en: 'Export all order data',
    pu: 'Exportar todos os dados de pedidos'
  },
  deliveryPostcode: {
    zh: '派送邮编：',
    en: '',
    pu: 'Código postal de entrega：'
  },
  zipCodeRangeSizePrompts: {
    zh: '起始邮编不能大于结束邮编',
    en: '',
    pu: 'O código postal inicial não pode ser maior que o código postal final'
  },
  noDuplicationAllowed: {
    zh: '不允许存在重复',
    en: '',
    pu: 'Não são permitidas duplicatas'
  },
  repeat: {
    zh: '重复值为：',
    en: '',
    pu: 'O valor de repetição é'
  },
  upload3Photos: {
    zh: '最多上传3张照片',
    en: '',
    pu: 'Faça upload de até 3 fotos'
  },
  deliverer: {
    zh: '派送人',
    en: '',
    pu: 'Entregador'
  },
  waybillNumber: {
    zh: '运单号',
    en: 'waybillNumber',
    pu: 'Número de waybill'
  },
  collectWay: {
    zh: '实际揽收方式',
    en: '',
    pu: 'Modo real de cobrança'
  },
  zipCodeDistance: {
    zh: '邮编距离',
    en: '',
    pu: 'Distância do Código Postal'
  },
  regionalType: {
    zh: '地域类型',
    en: '',
    pu: 'Tipo Regional'
  },
  riskRatio: {
    zh: '风险比例',
    en: '',
    pu: 'Proporção de Risco'
  },
  returnRegistrationTime: {
    zh: '退件登记时间',
    en: '',
    pu: 'Tempo de registro para devoluções'
  },
  returnReceiptTime: {
    zh: '退件签收时间',
    en: '',
    pu: 'Retorna o tempo de assinatura'
  },
  returnRegistrationPoint: {
    zh: '退件登记网点',
    en: '',
    pu: 'Devolve pontos de registro'
  },
  returnDignatureNetwork: {
    zh: '退件签收网点',
    en: '',
    pu: 'Devoluções Locais de assinaturas'
  },
  registrationNetwork: {
    zh: '登记网点',
    en: '',
    pu: 'Pontos de registro'
  },
  printDetails: {
    zh: '打印详情',
    en: '',
    pu: 'Imprimir detalhes'
  },
  printDot: {
    zh: '打印网点',
    en: '',
    pu: 'Locais de impressão'
  },
  vehicleNumber: {
    zh: '车辆号',
    en: 'Vehicle Number',
    pu: 'Número do Veículo'
  },
  vehicleLocation: {
    zh: '车辆所属网点',
    en: 'Vehicle Branch/Location',
    pu: 'Filial/Localização do Veículo'
  },
  arrivalLocation: {
    zh: '到达地点',
    en: 'Arrival Location',
    pu: 'Local de Chegada'
  },
  arrivalScanningPersonnel: {
    zh: '到达扫描人',
    en: 'Arrival Scanning Personnel',
    pu: 'Pessoal de Escaneamento de Chegada'
  },
  departureTime: {
    zh: '离开时间',
    en: 'Departure Time',
    pu: 'Hora de Saída'
  },
  departureScanningPersonnel: {
    zh: '离开扫描人',
    en: 'Departure Scanning Personnel',
    pu: 'Pessoal de Escaneamento de Saída'
  },
  imageDetails: {
    zh: '图片详情',
    en: 'Image Details',
    pu: 'Detalhes da Imagem'
  },
  arrival: {
    zh: '到达',
    en: 'Arrival',
    pu: 'Chegada'
  },
  departure: {
    zh: '离开',
    en: 'Departure',
    pu: 'Saída'
  },
  leaveLocation: {
    zh: '离开地点',
    en: 'Leave location',
    pu: 'Deixar local'
  },
  noImageAvailable: {
    zh: '暂无图片',
    en: 'No image available',
    pu: 'Nenhuma imagem disponível'
  },
  shennAgingExpirationTime: {
    zh: 'SHEIN时效到期时间',
    en: '',
    pu: 'Tempo de exigência de prazo de SHEIN'
  },
  TheAffiliatedNetworkEmpty: {
    zh: '所属网点为空',
    en: 'The affiliated network is empty',
    pu: 'A rede afiliada está vazia'
  },
  arrivalPoint: {
    zh: '到达网点',
    en: '',
    pu: 'chegar ao ponto'
  },
  exitNode: {
    zh: '离开网点',
    en: '',
    pu: 'sair do ponto'
  },
  rangeWaveNumber: {
    zh: '揽收波次号',
    en: '',
    pu: 'número da onda de coleta'
  },
  waveNumberCreationTime: {
    zh: '波次号创建时间',
    en: '',
    pu: 'tempo de criação do número da onda de coleta'
  },
  collectionPointInventory: {
    zh: '揽收网点入库',
    en: '',
    pu: 'Coletar o pool de receitas'
  },
  connectedSuccessfully: {
    zh: '揽收客户端设备对接成功!',
    en: '',
    pu: 'Integração bem-sucedida do dispositivo cliente da coleta!'
  },
  scheduleCollectionTime: {
    zh: '计划揽收时间',
    en: '',
    pu: 'Horário de coleta programado'
  },
  englishSplit: {
    zh: '用英文逗号隔开',
    en: '',
    pu: 'Separa-los por vírgula em inglês'
  },
  merchant: {
    zh: '商家',
    en: '',
    pu: 'Seller'
  },
  BagManage: {
    zh: '袋号管理',
    en: '',
    pu: 'Gerenciamento de sacas'
  },
  packageCollection: {
    zh: '包裹收集',
    en: 'Package Collection',
    pu: 'Coleta de pacotes'
  },
  confirmPackage: {
    zh: '确认包裹',
    en: '',
    pu: 'Confirmar pacotes'
  },
  quantityBagsLabel: {
    zh: '袋子数量',
    en: '',
    pu: 'Qtd. Sacas'
  },
  CommercialOwner: {
    zh: '商户',
    en: '',
    pu: 'Comerciante'
  },
  scanCount: {
    zh: '扫描计数',
    en: '',
    pu: 'Qtd. Sacas'
  },
  exportChilderInfor: {
    zh: '子包信息导出',
    en: '',
    pu: 'Exportar informações do subpacote'
  },
  export1: {
    zh: '勾选导出',
    en: '',
    pu: 'Exportar com seleção'
  },
  repeatDataTips: {
    zh: '请不要重复扫描',
    en: '',
    pu: 'não repita a varredura'
  },
  auditTime: {
    zh: '稽查时间',
    en: '',
    pu: 'Tempo de inspeção'
  },
  auditNetwork: {
    zh: '稽查网点',
    en: '',
    pu: 'Ponto de inspeção'
  },
  auditor: {
    zh: '稽查人',
    en: '',
    pu: 'Inspetor'
  },
  auditResult: {
    zh: '稽查结果',
    en: '',
    pu: 'Resultado'
  },
  auditBatchNumber: {
    zh: '稽查批次号',
    en: '',
    pu: 'Número de Lote de inspeção'
  },
  billOrBag: {
    zh: '单号/袋号',
    en: '',
    pu: 'Nº do pedido/ Nº da saca'
  },
  processingResult: {
    zh: '处理结果',
    en: '',
    pu: 'Comentário de tratamento'
  },
  auditImg: {
    zh: '稽查照片',
    en: '',
    pu: 'Foto de inspeção'
  },
  checkParcel: {
    zh: '稽查包裹',
    en: '',
    pu: 'Qtd. De Pacote'
  },
  abnormalData: {
    zh: '异常数据',
    en: '',
    pu: 'Dados anormais'
  },
  SelectOnly: {
    zh: '网点或者格口只需选中其中一个',
    en: '',
    pu: 'Selecionar apenas ponto ou grade'
  },
  SelectNet: {
    zh: '选择网点',
    en: '',
    pu: 'Selecionar ponto'
  },
  SelectGriude: {
    zh: '选择格口',
    en: '',
    pu: 'Selecionar grade'
  },
  PrintCopies: {
    zh: '打印份数',
    en: '',
    pu: 'Quantidade de cópias para impressão'
  },
  printRecord: {
    zh: '打印记录',
    en: '',
    pu: 'Registro de impressão'
  },
  copies: {
    zh: '份数',
    en: '',
    pu: 'Quantidade de cópias'
  },
  selectOr: {
    zh: '请选择网点或者格口',
    en: '',
    pu: 'Selecionar ponto ou grade'
  },
  inputcopies: {
    zh: '请输入打印份数',
    en: '',
    pu: 'Faça favor de inserir a quantidade de cópias'
  },
  more20: {
    zh: '最多一次打印20份',
    en: '',
    pu: 'Imprir até 20 cópias por vez'
  },
  bangdingduoge: {
    zh: '该网点绑定了多个格口, 请选择格口打印!',
    en: '',
    pu: 'O ponto está vingulado a várias grades, selecione grade para imprimir!'
  },
  netAdress: {
    zh: '网点地址',
    en: '',
    pu: 'Endereço de Ponto'
  },
  threeCode: {
    zh: '三段码',
    en: '',
    pu: 'Código de três segmentos'
  },
  fenboCode: {
    zh: '分拨编码',
    en: '',
    pu: 'Código de distribuição'
  },
  inputLWH: {
    zh: '请输入长宽高',
    en: '',
    pu: 'Digite o comprimento, a largura e a altura'
  },
  pleaseChooseNextSite: {
    zh: '请选择下一站',
    en: '',
    pu: 'Por favor, selecione a próxima parada'
  },
  bei: {
    zh: '被',
    en: '',
    pu: 'foi'
  },
  sendNextStop: {
    zh: '发往下一站',
    en: '',
    pu: 'foi enviado para próxima parada'
  },
  bbei: {
    zh: '并被',
    en: '',
    pu: 'e foi'
  },
  signExport: {
    zh: '签收信息导出',
    en: '',
    pu: 'Exportar informações de recebimento'
  },
  // xxxxx已被【xxxx】的【xxx】揽收
  // xxxxx já foi coletado por 【xxx】 de 【xxxx】
  yibei: {
    zh: '已被',
    en: '',
    pu: 'já foi coletado por'
  },
  de: {
    zh: '的',
    en: '',
    pu: 'de'
  },
  haveClosedLoop: {
    zh: '已被闭环',
    en: '',
    pu: 'Ciclo já foi fechado'
  },
  // 专为轨迹查询描述判断使用-------start
  status1: {
    zh: '闭环',
    en: '',
    pu: 'Circuito fechado'
  },
  status2: {
    zh: '已揽收',
    en: '',
    pu: 'Coletados'
  },
  status3: {
    zh: '已签收',
    en: '',
    pu: 'Assinatura'
  },
  status4: {
    zh: '封袋',
    en: '',
    pu: 'Selar a saca'
  },
  status5: {
    zh: '换袋',
    en: '',
    pu: 'Mudar de saco'
  },
  // 专为轨迹查询描述判断使用-------end
  secondLoadingLicensePlate: {
    zh: '第二次装车车牌号',
    en: '',
    pu: 'o número da placa no segundo carregamento'
  },
  firstLoadingTime: {
    zh: '第一次装车时间',
    en: '',
    pu: 'tempo do primeiro carregamento'
  },
  firstUnloadingTime: {
    zh: '第一次卸车时间',
    en: '',
    pu: 'tempo do primeiro descarregamento'
  },
  secondLoadingTime: {
    zh: '第二次装车时间',
    en: '',
    pu: 'tempo do segundo carregamento '
  },
  vehicleArrivalTime: {
    zh: '车辆到达时间',
    en: '',
    pu: 'tempo de chegada do veículo'
  },
  collectDuration: {
    zh: '揽收时长(小时)',
    en: '',
    pu: 'Duração da coleta（hora）'
  },
  cDuration: {
    zh: '揽收时长',
    en: '',
    pu: 'Duração da coleta'
  },
  transferDuration: {
    zh: '中转时长(小时)',
    en: '',
    pu: 'Duração de trânsito（hora）'
  },
  tfDuration: {
    zh: '中转时长',
    en: '',
    pu: 'Duração de trânsito'
  },
  transportDuration: {
    zh: '运输时长(小时)',
    en: '',
    pu: 'Duração de transportar（hora）'
  },
  tDuration: {
    zh: '运输时长',
    en: '',
    pu: 'Duração de transportar'
  },
  ScannerFail: {
    zh: '扫描失败',
    en: '',
    pu: 'Falha na bipagem'
  },
  tip01: {
    zh: '袋内以下单号尚未做到件, 请先操作到件',
    en: '',
    pu: 'O pedido de pacote desta saca ainda não entrega, por favor, opere “a entrega de pacote“ primeiro'
  },
  tip02: {
    zh: '以下订单推送服务商失败',
    en: '',
    pu: 'Os pedidos enviados ao parceiro falhou'
  },
  tip03: {
    zh: '订单推送成功：',
    en: '',
    pu: 'Os pedidos foram enviados com sucesso：'
  },
  tip04: {
    zh: '个包裹',
    en: '',
    pu: 'pacotes'
  },
  pleaseSelectBusinessTime: {
    zh: '请选择营业时间',
    en: '',
    pu: 'Os dados do horário de funcionamento não podem estar vazios'
  },
  pleaseSelectBusinessWeek: {
    zh: '营业星期不能为空',
    en: '',
    pu: ' A semana de funcionamento não pode estar vazio'
  },
  pleaseChooseAgainTime: {
    zh: '时间选择有误，请重新选择',
    en: '',
    pu: 'O tempo selecionado está errado, por favor, selecine novamente'
  },
  finance: {
    zh: '财务',
    en: '',
    pu: 'Finanças'
  },
  trackingPackage: {
    zh: '轨迹',
    en: 'Tracking Package',
    pu: 'Tracking do Pacote'
  },
  BasicInformation: {
    zh: '基础资料',
    en: 'Basic information',
    pu: 'Informação básica'
  },
  module: {
    zh: '模块',
    en: '',
    pu: 'módulo'
  },
  titleName: {
    zh: '标题名称',
    en: '',
    pu: 'nome de título'
  },
  transfer: {
    zh: '中转',
    en: '',
    pu: 'trânsito'
  },
  serviceQuality: {
    zh: '服务质量',
    en: '',
    pu: 'qualidade de serviço'
  },
  max5Files: {
    zh: '限制最多上传5个文件',
    en: '',
    pu: 'Máximo arquivo a upload é 5'
  },
  max1Files: {
    zh: '限制最多上传1个文件',
    en: '',
    pu: 'Máximo arquivo a upload é 1'
  },
  pleaseWait: {
    zh: '文件上传中，请等待...',
    en: '',
    pu: 'O arquivo está sendo carregado, por favor, aguarde...'
  },
  uploadFileFormat: {
    zh: '上传文件支持PDF、doc、docx、xls、xlsx、csv、png、jpg、bmg、jpeg、txt、MP4、GIF等格式',
    en: '',
    pu: 'A transferência de arquivos suporta formatos como PDF, doc, docx, xls, xlsx, csv, png, jpg, bmg, jpeg, txt, MP4, GIF, entre outros.'
  },
  notGetSignImg: {
    zh: '还未获取到签名截图，请稍后...',
    en: '',
    pu: 'Por favor, aguarde, ainda não recebe a captura de tela de assinatura'
  },
  closedTotalParcels: {
    zh: '闭环包裹总数',
    en: '',
    pu: 'Níumero total de pacotes fechados'
  },
  unloadTotalParcels: {
    zh: '卸车总包裹数',
    en: '',
    pu: 'Total de pacotes descarregados'
  },
  signTotalParcels: {
    zh: '签收包裹总数',
    en: '',
    pu: 'Total de pacotes recebidos'
  },
  warehouseDeliveryAbnormalData: {
    zh: '送仓异常数据',
    en: '',
    pu: 'Dados anômalos de entrega ao armazém'
  },
  suspectedMissingItem: {
    zh: '疑似丢件',
    en: '',
    pu: 'Possível perda de pacote'
  },
  suspectedMissedScan: {
    zh: '疑似漏扫',
    en: '',
    pu: 'Possível falta de escaneamento'
  },
  unloadDot: {
    zh: '卸车网点',
    en: '',
    pu: 'Ponto de descarregamento'
  },
  currentUnloadingVehicle: {
    zh: '当前卸车车辆',
    en: '',
    pu: 'Veículo descarregando '
  },
  shouldUnload: {
    zh: '应卸车',
    en: '',
    pu: 'Veículo a descarregar'
  },
  Type: {
    zh: '类型',
    en: '',
    pu: 'Tipo de saca'
  },
  numberPackages: {
    zh: '包裹数',
    en: '',
    pu: 'Quantidade de pacotes'
  },
  group: {
    zh: '组别',
    en: '',
    pu: 'Group'
  },
  region: {
    zh: '大区',
    en: '',
    pu: 'Macro region'
  },
  area: {
    zh: '区域',
    en: '',
    pu: 'Micro region'
  },
  DotHoldUp: {
    zh: '网点滞留量',
    en: '',
    pu: 'Qtd. de retenção de pontos'
  },
  within5Hours: {
    zh: '5小时内',
    en: '',
    pu: 'Dentro de 5 horas'
  },
  within10Hours: {
    zh: '10小时内',
    en: '',
    pu: 'Dentro de 10 horas'
  },
  over10Hours: {
    zh: '超10小时',
    en: '',
    pu: 'Mais de 10 horas'
  },
  bulkyOrder: {
    zh: '大件订单',
    en: '',
    pu: 'Pedido de carga grande'
  },
  responseResult: {
    zh: '响应结果',
    en: '',
    pu: 'Resposta do resultado'
  },
  allDeclaredValue: {
    zh: '总申明价值',
    en: '',
    pu: 'Valor declarado total'
  },
  suborderDeclaredValue: {
    zh: '子单申明价值',
    en: '',
    pu: 'Valor declarado de pedido subsidiário'
  },
  receiveBatchNumber: {
    zh: '揽收批次号',
    en: '',
    pu: 'Número do lote de coleta'
  },
  doorToLanSCollectionsTotal: {
    zh: '上门揽收总揽收量',
    en: '',
    pu: 'Qtd. total de coleta nos seller'
  },
  doorToLanSTotal: {
    zh: '上门揽收总数',
    en: '',
    pu: 'Total de coletas'
  },
  doorToDoorTotal: {
    zh: '上门交件总量',
    en: '',
    pu: 'Total de entregas'
  },
  doorToDoorCollectionsTotal: {
    zh: '上门交件总揽收量',
    en: '',
    pu: 'Qtd. total de entrega nos Mini Drop'
  },
  value: {
    zh: '值',
    en: '',
    pu: 'número'
  },
  punctualDepartureRateOutlets: {
    zh: '网点准时发车率',
    en: '',
    pu: 'taxa de pontualidade de partida no ponto'
  },
  transitCenterPunctualDepartureRate: {
    zh: '转运中心准时发车率',
    en: '',
    pu: 'taxa de pontualidade de partida no centro de trânsito'
  },
  OnTimeArrivalRate: {
    zh: '运抵准时率',
    en: '',
    pu: 'taxa de pontualidade de chegada'
  },
  networkLoadingTime: {
    zh: '网点装车时间',
    en: '',
    pu: 'tempo de carregamento no ponto'
  },
  networkLoader: {
    zh: '网点装车人',
    en: '',
    pu: 'carregador no ponto'
  },
  unloadingTransferCenterTime: {
    zh: '转运中心卸车时间',
    en: '',
    pu: 'tempo de descarregamento no centro de trânsito'
  },
  unloadingTransferCenterP: {
    zh: '转运中心卸车人',
    en: '',
    pu: 'descarregador no centro de trânsito'
  },
  transferCenterLoadingTime: {
    zh: '转运中心装车时间',
    en: '',
    pu: 'tempo de carregamento no centro de trânsito'
  },
  transferCenterLoader: {
    zh: '转运中心装车人',
    en: '',
    pu: 'carregador no centro de trânsito'
  },
  chooseOneWeek: {
    zh: '单次最多查询一个星期的数据',
    en: '',
    pu: 'Cada vez só pode consultar dados de uma semana ao máximo'
  },
  senderSubregion: {
    zh: '寄件人所属大区',
    en: '',
    pu: 'Macro region de rementente'
  },
  senderOwningRegion: {
    zh: '寄件人所属区域',
    en: '',
    pu: 'Micro region de rementente'
  },
  collectionNetworkSubregion: {
    zh: '揽收网点所属大区',
    en: '',
    pu: 'Macro region de pontos coletado'
  },
  collectionNetworkOwningRegion: {
    zh: '揽收网点所属区域',
    en: '',
    pu: 'Micro region de pontos coletados'
  },
  QYcnpj: {
    zh: '签约CNPJ',
    en: '',
    pu: 'Assinar CNPJ'
  },
  modalDriverLicence: {
    zh: '驾驶证',
    en: '',
    pu: 'Carteira de motorista'
  },
  driver1: { // 员工管理用于判断角色葡语
    zh: '司机',
    en: '',
    pu: 'motorista'
  },
  FileReadingFailurePleaseWait: {
    zh: '文件读取失败，请稍后再试',
    en: '',
    pu: 'A leitura do arquivo falhou, por favor tente novamente mais tarde'
  },
  TypeBill: {
    zh: '账单类型',
    en: 'Type of bill',
    pu: 'Tipo de fatura'
  },
  BillingTime: {
    zh: '账单时间',
    en: 'billing time',
    pu: 'tempo de faturamento'
  },
  infoPackage: {
    zh: '包裹信息',
    en: '',
    pu: 'Informação do pacote'
  },
  loadingPoint: {
    zh: '装车地点',
    en: '',
    pu: 'Local de carregamento'
  },
  signatureConfirmation: {
    zh: '签名确认',
    en: '',
    pu: 'Assinatura confirmada'
  },
  driverIsExpect: {
    zh: '司机是否符合预期',
    en: '',
    pu: 'O motorista está de acordo com as expectativas?'
  },
  driverRemark: {
    zh: '司机如果跟预期不一致，可在该栏位备注',
    en: '',
    pu: 'Se o motorista não corresponder às expectativas, pode fazer uma observação neste campo.'
  },
  loadingSignPicture: {
    zh: '装车签名图片',
    en: '',
    pu: 'foto de assinatura de carregamento'
  },
  networkLoadingSignaturePicture: {
    zh: '网点装车签名图片',
    en: '',
    pu: 'Foto de assinatura de carregamento no ponto'
  },
  transferCenterLoadingSignaturePicture: {
    zh: '转运中心装车签名图片',
    en: '',
    pu: 'Foto de assinatura de carregamento no centro de  trânsito'
  },
  currentOddNumberRepeat: {
    zh: '当前单号重复',
    en: '',
    pu: 'Pedido repetido'
  },
  pushSucess: {
    zh: '推送成功',
    en: '',
    pu: 'sucesso de push'
  },
  Falha: {
    zh: '推送失败',
    en: '',
    pu: 'Falha de impulso'
  },
  pushException: {
    zh: '推送异常',
    en: '',
    pu: 'envio abnormal'
  },
  parcelTrack: {
    zh: '包裹轨迹',
    en: '',
    pu: 'rastreio de pacote'
  },
  batchTrack: {
    zh: '批量轨迹',
    en: '',
    pu: 'rastreios em massa'
  },
  truncatedBatchTrack: {
    zh: '截单批量轨迹',
    en: '',
    pu: 'rastreio de lote de cut-off'
  },
  lastLoadingTrack: {
    zh: '最后一次装车轨迹',
    en: '',
    pu: 'rastreio de último carregamento'
  },
  ReasonForFailure: {
    zh: '失败原因',
    en: 'Reason for failure',
    pu: 'Motivo do fracasso'
  },
  collectWarehousing: {
    zh: '网点揽收入库',
    en: '',
    pu: 'Entrada do armazém na coleta no Ponto'
  },
  collectPackageTrack: {
    zh: '揽收包裹轨迹',
    en: '',
    pu: 'rastreamento de coleta de pacotes'
  },
  max2000Line: {
    zh: '精确搜索，一行一项，最多支持2000行',
    en: '',
    pu: 'Pesquisa precisa, 1 linha por 1 item, ao máximo 2000 linhas'
  },
  senderProvince: {
    zh: '寄件人省份',
    en: '',
    pu: 'Província de remetente'
  },
  delivererJobType: {
    zh: '派送员岗位类型',
    en: '',
    pu: 'Tipo de cargo de entregador'
  },
  clearSearchCriteria: {
    zh: '清空搜索条件',
    en: '',
    pu: 'Limpar critérios de pesquisa'
  },
  outletWarehouse: {
    zh: '网点出库',
    en: '',
    pu: 'Saída de ponto'
  },
  customerOrderStatus: {
    zh: '客户订单状态',
    en: '',
    pu: 'Status de pedido de cliente'
  },
  nowOperating: {
    zh: '最新操作',
    en: '',
    pu: 'Última operação'
  },
  nowOperatingTime: {
    zh: '最新操作时间',
    en: '',
    pu: 'Tempo da última operação'
  },
  IntermediateWeight: {
    zh: '中转复重(kg) ',
    en: '',
    pu: 'Re-pesagem de trânsito(kg)'
  },
  expectedDeliveryOutlets: {
    zh: '预计派送网点',
    en: '',
    pu: 'Ponto de entrega previsto'
  },
  max100Tips: {
    zh: '本操作一次最多可执行100条数据，当前已超过100条，请重新勾选！',
    en: '',
    pu: 'Esta peração pode executar no máximo 100 dados por vez, já excedeu 100, por favor reselecione'
  },
  NoDistributionRequired: {
    zh: '选中的订单不是待分配状态，无需分配！',
    en: '',
    pu: 'Pedido selecinado não está em status de distribuir,não precisa de distribuir'
  },
  // 为了方便拼接——————————start
  // 已执行成功xx条数据
  // Já executou xx dados com successo
  executedSuccessfully: {
    zh: '已执行成功',
    en: '',
    pu: 'Já executou'
  },
  pieceData: {
    zh: '条数据',
    en: '',
    pu: 'dados com successo'
  },
  // 为了方便拼接——————————end
  pleaseEnterRemarks: {
    zh: '请输入备注内容',
    en: '',
    pu: 'Insira a observação'
  },
  distributionWaybillCollection: {
    zh: '将选中的运单分配给以下指定揽收网点进行揽收',
    en: '',
    pu: 'Distribue o pedido  selecionado para o ponto de coleta designado abaixo'
  },
  allowedDistributionWaybill: {
    zh: '仅状态为\'待分配\'的运单可分配揽收网点',
    en: '',
    pu: 'Apenas com status \'Aguardando distribuir\' podem ser designadas para pontos de coleta'
  },
  operationComplete: {
    zh: '操作完成',
    en: '',
    pu: 'Operação concluída'
  },
  waybillTrack: {
    zh: '运单轨迹',
    en: '',
    pu: 'Rastreio'
  },
  noteRecord: {
    zh: '备注记录',
    en: '',
    pu: 'Registros de observação'
  },
  photoInformation: {
    zh: '照片信息',
    en: '',
    pu: 'Informação de foto'
  },
  send: {
    zh: '寄',
    en: '',
    pu: 'Remetente'
  },
  receiver: {
    zh: '收',
    en: '',
    pu: 'Destinatário'
  },
  sendingPhone: {
    zh: '发件手机',
    en: '',
    pu: 'Celular do remetente'
  },
  sendingTelephone: {
    zh: '发件电话',
    en: '',
    pu: 'Telefone do remetente'
  },
  sendingCompany: {
    zh: '发件公司',
    en: '',
    pu: 'Empresa  remetente'
  },
  receivingPhone: {
    zh: '收件手机',
    en: '',
    pu: 'Celular do destinatário'
  },
  receivingTelephone: {
    zh: '收件电话',
    en: '',
    pu: 'Telefone do destinatário'
  },
  receivingCompany: {
    zh: '收件公司',
    en: '',
    pu: 'Empresa  destinatária'
  },
  customerOrderWeight: {
    zh: '客户下单重量(kg)',
    en: '',
    pu: 'Peso de pedido de cliente(kg)'
  },
  collectLenWidHgh: {
    zh: '揽收长宽高(cm)',
    en: '',
    pu: 'Dimensões de coleta (comprimento x largura x altura em cm)'
  },
  preCollectionOutlets: {
    zh: '预揽收网点',
    en: '',
    pu: 'Ponto de coleta previsto'
  },
  rejectWhetherReturn: {
    zh: '拒收是否退回',
    en: '',
    pu: 'Rejeitado, deve ser devolvido?'
  },
  viewSheet: {
    zh: '查看面单',
    en: '',
    pu: 'Verificar etiqueta'
  },
  placeOrderLenWidHgh: {
    zh: '下单长宽高(cm)',
    en: '',
    pu: 'Comprimento, largura e altura do pedido (cm)'
  },
  transferReHeavy: {
    zh: '中转仓复重(kg)',
    en: '',
    pu: 'Re-pesagem no armazém de trânsito(kg)'
  },
  receivingWeight: {
    zh: '揽收重量(kg)',
    en: '',
    pu: 'Peso da coleta (kg)'
  },
  transferLenWidHgh: {
    zh: '中转长宽高(cm)',
    en: '',
    pu: 'Comprimento, largura e altura do trânsito (cm)'
  },
  deliveryRoute: {
    zh: '派送路线',
    en: '',
    pu: 'Rota de entrega'
  },
  cnsignmentInformation: {
    zh: '托寄物信息',
    en: '',
    pu: 'Informações do item enviado'
  },
  InternalLocus: {
    zh: '内部轨迹',
    en: '',
    pu: 'Rastreio interno'
  },
  customerTrajectory: {
    zh: '客户轨迹',
    en: '',
    pu: 'Rastreio pra cliente'
  },
  controlsEmployee: {
    zh: '操作员',
    en: '',
    pu: 'Operador'
  },
  largeOrderDetails: {
    zh: '大件订单详情',
    en: '',
    pu: 'Detalhes do pedido de carga grande'
  },
  waybillSRMessage: {
    zh: '运单寄收信息',
    en: '',
    pu: 'Informações de envio e recebimento do rastreio'
  },
  predictedTrajectory: {
    zh: '预测轨迹',
    en: '',
    pu: 'Rastreio previsto'
  },
  EstimatedTimeDelivery: {
    zh: '预计送达时间',
    en: '',
    pu: 'Horário estimado de entrega'
  },
  realTimeTrajectory: {
    zh: '实时轨迹',
    en: '',
    pu: 'Rastreio em tempo real'
  },
  receiveSign: {
    zh: '揽收签收',
    en: '',
    pu: 'Recebimento e assinatura'
  },
  senderSignature: {
    zh: '发件人签名',
    en: '',
    pu: 'Assinatura do remetente'
  },
  signatory: {
    zh: '签署人',
    en: '',
    pu: 'Assinante'
  },
  signTimeS: {
    zh: '签署时间',
    en: '',
    pu: 'Horário da assinatura'
  },
  sendSign: {
    zh: '派件签收',
    en: '',
    pu: 'Recebimento de entrega'
  },
  SignedByDOC: {
    zh: '签署人DOC',
    en: '',
    pu: 'Documento do assinante'
  },
  signatoryTime: {
    zh: '签署人时间',
    en: '',
    pu: 'Hora da assinatura'
  },
  collectSignTakePicture: {
    zh: '揽收签收拍照',
    en: '',
    pu: 'Foto de confirmação da coleta '
  },
  collectCarNumTakePicture: {
    zh: '揽收车签号拍照',
    en: '',
    pu: 'Foto do nº do veículo de coleta'
  },
  transferPhotograph: {
    zh: '中转拍照',
    en: '',
    pu: 'Foto de transbordo'
  },
  sendTakePhotos: {
    zh: '派件签收拍照',
    en: '',
    pu: 'Foto de confirmação de entrega'
  },
  physicalTransferWarehouse: {
    zh: '实际中转仓',
    en: '',
    pu: 'Armazém de transbordo real'
  },
  lenWidHgh: {
    zh: '长宽高（cm）',
    en: '',
    pu: ''
  },
  prescriptionName: {
    zh: '时效名称',
    en: '',
    pu: 'Nome do Prazo'
  },
  ageingStandardValue: {
    zh: '时效标准值（单位：h）',
    en: '',
    pu: 'Valor Padrão do Prazo (unidade: h)'
  },
  prescriptionArrival: {
    zh: '运抵时效',
    en: '',
    pu: 'Prazo de Entrega'
  },
  firstLoadingLicensePlate: {
    zh: '第一次装车车牌号',
    en: '',
    pu: 'Placa do primeiro carregamento'
  },
  firstLoadedUser: {
    zh: '第一次装车操作人',
    en: '',
    pu: 'Primeiro Operador de Carregamento'
  },
  firstLoadSite: {
    zh: '第一次装车网点',
    en: '',
    pu: 'Primeiro Ponto de Carregamento'
  },
  firstUnloadUser: {
    zh: '第一次卸车操作人',
    en: '',
    pu: 'Primeiro Operador de Descarregamento'
  },
  firstUnloadSite: {
    zh: '第一次卸车网点',
    en: '',
    pu: 'Primeiro Ponto de Descarregamento'
  },
  secondLoadedUser: {
    zh: '第二次装车操作人',
    en: '',
    pu: 'Segundo Operador de Carregamento'
  },
  secondLoadSite: {
    zh: '第二次装车网点',
    en: '',
    pu: 'Segundo Ponto de Carregamento'
  },
  networkLoadScanTime: {
    zh: '网点装车扫描时间',
    en: '',
    pu: 'Horário de Bipagem do Carregamento no Ponto'
  },
  networkLoadVehicleNum: {
    zh: '网点装车车牌号',
    en: '',
    pu: 'Placa do Veículo no Carregamento do Ponto'
  },
  networkLoadUploadingTime: {
    zh: '网点装车上传时间',
    en: '',
    pu: 'Horário de Upload do Carregamento no Ponto'
  },
  transferLoadScanTime: {
    zh: '转运中心装车扫描时间',
    en: '',
    pu: 'Horário de Bipagem do Carregamento no Centro de Transferência'
  },
  transferLoadVehicleNum: {
    zh: '转运中心装车车牌号',
    en: '',
    pu: 'Placa do Veículo no Carregamento do Centro de Transferência'
  },
  transferLoadUploadingTime: {
    zh: '转运中心装车上传时间',
    en: '',
    pu: 'Horário de Upload do Carregamento no Centro de Transferência'
  },
  outletUnloader: {
    zh: '网点卸车人',
    en: '',
    pu: 'Operador de Descarregamento no Ponto'
  },
  outletUnloadingTime: {
    zh: '网点卸车时间',
    en: '',
    pu: 'Horário de Descarregamento no Ponto'
  },
  exportChangeTip: {
    zh: '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，',
    en: '',
    pu: 'Exportação bem-sucedida, os dados serão gerados em uma tabela dentro de 1 a 10 minutos. Por favor, acesse A Lista de Exportação de Recursos em Administração do Sistema para baixar.'
  },
  vehicleMsgConfirmTime: {
    zh: '车辆信息确认时间',
    en: '',
    pu: 'Horário de Confirmação das Informações do Veículo'
  },
  vehicleMsgConfirmPeople: {
    zh: '车辆信息确认人',
    en: '',
    pu: 'Responsável pela Confirmação das Informações do Veículo'
  },
  loadPkgConfirmTime: {
    zh: '装车包裹确认时间',
    en: '',
    pu: 'Horário de Confirmação dos Pacotes Carregados'
  },
  loadPkgConfirmPeople: {
    zh: '装车包裹确认人',
    en: '',
    pu: 'Responsável pela Confirmação dos Pacotes Carregados'
  },
  loadPkgTicketSum: {
    zh: '装车包裹票数',
    en: '',
    pu: 'Qtd. dos Pacotes Carregados'
  },
  loadPkgBagSum: {
    zh: '装车包裹袋数',
    en: '',
    pu: 'Qtd. de Sacas Carregadas'
  },
  loadSumConfirm: {
    zh: '装车数量确认',
    en: '',
    pu: 'Confirmação da Qtd. Carregada'
  },
  loadDataConfirmRemark: {
    zh: '装车数据确认备注',
    en: '',
    pu: 'Observações sobre a Confirmação dos Dados de Carregamento'
  },
  vehicleConfirmScan: {
    zh: '车辆确认扫描',
    en: '',
    pu: 'Bipagem de Confirmação do Veículo'
  },
  inputVehicleNumber: {
    zh: '请输入车牌号码',
    en: '',
    pu: 'Insira o Número da Placa'
  },
  licensePlateRules: {
    zh: '格式为三个字母 + 四个数字（例如：ABC1234）或者三字母 + 一数字 + 一字母 + 两数字（例如：ABC1D23）',
    en: '',
    pu: 'Formato: Três Letras + Quatro Números (ex.: ABC1234) ou Três Letras + Um Número + Uma Letra + Dois Números (ex.: ABC1D23)'
  },
  bagCountDiscrepancy: {
    zh: '装卸车总袋数差异',
    en: '',
    pu: 'Diferença na Qtd. Total de Sacas entre Carregamento e Descarregamento'
  },
  votesDiscrepancy: {
    zh: '装卸车总票数差异',
    en: '',
    pu: 'Diferença na Qtd. Total de Pacotes entre Carregamento e Descarregamento'
  },
  unloadSignImg: {
    zh: '卸车签名图片',
    en: '',
    pu: 'Imagem da Assinatura do Descarregamento'
  },
  unloadPoint: {
    zh: '卸车地点',
    en: '',
    pu: 'Local de Descarregamento'
  },
  unloadCompletionTime: {
    zh: '卸车完成时间',
    en: '',
    pu: 'Tempo de Conclusão do Descarregamento'
  },
  errRemark: {
    zh: '如果卸车数据出现异常经现场核实，可在该栏位备注',
    en: '',
    pu: 'Caso haja alguma anomalia nos dados de descarregamento confirmada no local, uma observação pode ser feita nesta coluna.'
  },
  withLoadDataDiff: {
    zh: '与装车数据的差异：xx袋（xx票）',
    en: '',
    pu: 'Diferença nos Dados de Carregamento: xx sacas (xx pacotes)'
  },
  w1: {
    zh: '与装车数据的差异：',
    en: '',
    pu: 'Diferença nos Dados de Carregamento: '
  },
  w2: {
    zh: '袋（',
    en: '',
    pu: ' sacas ('
  },
  w3: {
    zh: '票）',
    en: '',
    pu: ' pacotes)'
  },
  toUnload: {
    zh: '已卸车数量为0，请先卸车',
    en: '',
    pu: 'A Qtd. descarregada é 0, por favor, descarregue primeiro.'
  },
  verifyDot: {
    zh: '确认网点',
    en: '',
    pu: 'Confirmar ponto de coleta.'
  },
  verifyTime: {
    zh: '确认时间',
    en: '',
    pu: 'Horário da Confirmação'
  },
  verifyPerson: {
    zh: '确认人',
    en: '',
    pu: 'Responsável pela Confirmação'
  },
  shouldUnloaded: {
    zh: '应卸',
    en: '',
    pu: 'Deveria Ser Descarregado'
  },
  actualUnloaded: {
    zh: '实卸',
    en: '',
    pu: 'Descarregado Realmente'
  },
  owningTransitCenter1: {
    zh: '所属转运中心',
    en: '',
    pu: 'Centros de Trânsito Filiados'
  },
  stateSite1: {
    zh: '网点所属州',
    en: '',
    pu: 'Estado de origem'
  },
  shouldAuditedTotal: {
    zh: '应稽查总数',
    en: '',
    pu: 'Qtd. total de auditorias devidas'
  },
  physicalAuditTotal: {
    zh: '实际稽查总数',
    en: '',
    pu: 'Qtd. total de auditorias efectivas'
  },
  discrepancyTotal: {
    zh: '差异总数',
    en: '',
    pu: 'Total de discrepâncias'
  },
  auditedBagOrBill: {
    zh: '稽查袋号/单号',
    en: '',
    pu: 'Nº de Saca/Rastreio Auditado'
  },
  pocketParcelNumber: {
    zh: '袋内包裹单号',
    en: '',
    pu: 'Nº de rastreio dos pacotes na saca'
  },
  actualAuditScanNumber: {
    zh: '实际稽查扫描单号',
    en: '',
    pu: 'Nº de rastreio de auditorias efectivas'
  },
  checkScanningTime: {
    zh: '稽查扫描时间',
    en: '',
    pu: 'Hora da auditoria de bipagem'
  },
  driverLicenseAttachment: {
    zh: '驾照附件',
    en: '',
    pu: 'Anexo da carteira de motorista'
  },
  ScanNumber1: {
    zh: '单号',
    en: '',
    pu: 'Número de pedido'
  },
  shouldSignedGrossAmount: {
    zh: '应签收总量',
    en: '',
    pu: 'Total Estimado de Entregas'
  },
  actualSignGrossAmount: {
    zh: '实际签收总量',
    en: '',
    pu: 'Total Real de Entregas'
  },
  unsignedGrossAmount: {
    zh: '未签收总量',
    en: '',
    pu: 'Total de Entregas Não Realizadas'
  },
  glpUnloadTotal: {
    zh: 'GLP未卸车总量',
    en: '',
    pu: 'Total de GLP Não Descarregado'
  },
  today: {
    zh: '当日',
    en: '',
    pu: 'No Dia'
  },
  sameDay: {
    zh: '当天',
    en: '',
    pu: 'No dia'
  },
  nearlyAWeek: {
    zh: '近一周',
    en: '',
    pu: 'Última semana'
  },
  aboutAMonth: {
    zh: '近一月',
    en: '',
    pu: 'Último mês'
  },
  sameMonth: {
    zh: '当月',
    en: '',
    pu: 'No mês'
  },
  mdRetention: {
    zh: 'MD滞留量',
    en: '',
    pu: 'Qtd. de Retenção MD'
  },
  mdLanshou: {
    zh: 'MD揽收量',
    en: '',
    pu: 'Volume de Coleta de MD'
  },
  spmdRetention: {
    zh: 'SP-MD滞留量',
    en: '',
    pu: 'Qtd. de Retenção SP-MD'
  },
  francamdRetention: {
    zh: 'Franca-MD滞留量',
    en: '',
    pu: 'Qtd. de Retenção Franca-MD'
  },
  jaumdRetention: {
    zh: 'Jau-MD滞留量',
    en: '',
    pu: 'Qtd. de Retenção Jaú-MD'
  },
  rjmdRetention: {
    zh: 'RJ-MD滞留量',
    en: '',
    pu: 'Qtd. de Retenção RJ-MD'
  },
  mgmdRetention: {
    zh: 'MG-MD滞留量',
    en: '',
    pu: 'Qtd. de Retenção MG-MD'
  },
  mdToxxx: {
    zh: 'MD至***转运中',
    en: '',
    pu: 'MD para *** em Trânsito'
  },
  mdToSpzh005: {
    zh: 'MD至SP-Z-H005',
    en: '',
    pu: 'MD para SP-Z-H005'
  },
  mdToSpzh006: {
    zh: 'MD至SP-Z-H006',
    en: '',
    pu: 'MD para SP-Z-H006'
  },
  mdToSpzh002: {
    zh: 'MD至SP-Z-H-002',
    en: '',
    pu: 'MD para SP-Z-H002'
  },
  mdToSpzh004: {
    zh: 'MD至SP-Z-H004',
    en: '',
    pu: 'MD para SP-Z-H004'
  },
  mdToMgzh001: {
    zh: 'MD至MG-Z-H001',
    en: '',
    pu: 'MD para MG-Z-H001'
  },
  mdToRjwh001: {
    zh: 'MD至RJ-W-H001',
    en: '',
    pu: 'MD para RJ-W-H001'
  },
  arrivexxx: {
    zh: '到达***转运中心',
    en: '',
    pu: 'Chegada no Centro de Transferência ***'
  },
  packageDetail: {
    zh: '包裹明细',
    en: '',
    pu: 'Detalhes do Pacote'
  },
  arriveSpzh005: {
    zh: '到达SP-Z-H005转运中心',
    en: '',
    pu: 'Chegada no Centro de Transferência SP-Z-H005'
  },
  arriveSpzh006: {
    zh: '到达SP-Z-H006转运中心',
    en: '',
    pu: 'Chegada no Centro de Transferência SP-Z-H006'
  },
  arriveSpzh002: {
    zh: '到达SP-Z-H002转运中心',
    en: '',
    pu: 'Chegada no Centro de Transferência SP-Z-H002'
  },
  arriveSpzh004: {
    zh: '到达SP-Z-H004转运中心',
    en: '',
    pu: 'Chegada no Centro de Transferência SP-Z-H004'
  },
  arriveMgzh001: {
    zh: '到达MG-Z-H001转运中心',
    en: '',
    pu: 'Chegada no Centro de Transferência MG-Z-H001'
  },
  arriveRjwh001: {
    zh: '到达RJ-W-H001转运中心',
    en: '',
    pu: 'Chegada no Centro de Transferência RJ-W-H001'
  },
  xxxToWrglp01: {
    zh: '****至WR-GLP-01',
    en: '',
    pu: '**** para WR-GLP-01'
  },
  spzh005ToWrglp01: {
    zh: 'SP-Z-H005至WR-GLP-01',
    en: '',
    pu: 'SP-Z-H005 para WR-GLP-01'
  },
  spzh006ToWrglp01: {
    zh: 'SP-Z-H006至WR-GLP-01',
    en: '',
    pu: 'SP-Z-H006 para WR-GLP-01'
  },
  spzh004ToWrglp01: {
    zh: 'SP-Z-H004至WR-GLP-01',
    en: '',
    pu: 'SP-Z-H004 para WR-GLP-01'
  },
  spzh002ToWrglp01: {
    zh: 'SP-Z-H002至WR-GLP-01',
    en: '',
    pu: 'SP-Z-H002 para WR-GLP-01'
  },
  mgzh001ToWrglp01: {
    zh: 'MG-Z-H001至WR-GLP-01',
    en: '',
    pu: 'MG-Z-H001 para WR-GLP-01'
  },
  rjwh001ToWrglp01: {
    zh: 'RJ-W-H001至WR-GLP-01',
    en: '',
    pu: 'RJ-W-H001 para WR-GLP-01'
  },
  dataVisual: {
    zh: '安骏营运看板数据可视化',
    en: '',
    pu: 'Visualização de dados do painel de operações da Anjun'
  },
  signConditionView: {
    zh: '签收情况概览',
    en: '',
    pu: 'Visão geral da situação de entrega'
  },
  unsignEachLinkdetail: {
    zh: '未签收各环节明细',
    en: '',
    pu: 'Detalhes de não entrega em cada etapa'
  },
  MDScanVolume: {
    zh: 'MD扫描总量',
    en: '',
    pu: 'Qtd. total de bipagem em MD'
  },
  loadCapacity: {
    zh: '已装车量',
    en: '',
    pu: 'Qtd. já carregado'
  },
  stayTruckload: {
    zh: '待装车量',
    en: '',
    pu: 'Qtd. a ser carregado'
  },
  loadingRate: {
    zh: '装车率',
    en: '',
    pu: 'Taxa de carregamento'
  },
  stayLoadRate: {
    zh: '待装车率',
    en: '',
    pu: 'Taxa de qtd. a ser carregado'
  },
  MDName: {
    zh: 'MD名称',
    en: '',
    pu: 'Nome do MD'
  },
  predictTruckload: {
    zh: '预计装车量',
    en: '',
    pu: 'Qtd. estimada de carregamento'
  },
  loadedScansNumber: {
    zh: '已装车扫描量',
    en: '',
    pu: 'Qtd. já bipada no carregamento'
  },
  loadingScanRate: {
    zh: '装车扫描率',
    en: '',
    pu: 'Taxa de bipagem no carregamento'
  },
  retention: {
    zh: '滞留',
    en: '',
    pu: 'Retenção'
  },
  signedUnloadedDiffPackages: {
    zh: '签收与卸车差异包裹总数',
    en: '',
    pu: 'Diferença no total de pacotes entre descarregamento e assinatura'
  },
  closedSignedDiffPackages: {
    zh: '闭环与签收差异包裹总数',
    en: '',
    pu: 'Diferença no total de pacotes entre o ciclo fechado e a assinatura'
  },
  unloadedParcelsTotalNum: {
    zh: '卸车包裹总数',
    en: '',
    pu: 'Total de pacotes descarregados'
  },
  loadingInfo: {
    zh: '装车信息统计列表',
    en: '',
    pu: 'Lista de estatísticas de informações de carregamento'
  },
  baggingInfo: {
    zh: '装袋信息统计列表',
    en: '',
    pu: 'Lista de estatísticas de informações de bipagem'
  },
  bagInfo: {
    zh: '袋子详细信息列表',
    en: '',
    pu: 'Lista de informações detalhadas das sacas'
  },
  SuspectedLostPackage: {
    zh: '包裹疑似丢失',
    en: 'Suspected lost package',
    pu: 'Pacote possivelmente perdido'
  },
  CollectionPunctualityRate: {
    zh: '揽收准时率',
    en: 'Collection punctuality rate',
    pu: 'Taxa de pontualidade na coleta'
  },
  NationalPunctualityRate: {
    zh: '全国准时率',
    en: 'National punctuality rate',
    pu: 'Taxa de pontualidade nacional'
  },
  SPPunctualityRate: {
    zh: 'SP准时率',
    en: 'SP punctuality rate',
    pu: 'Taxa de pontualidade SP'
  },
  FrancaPunctualityRate: {
    zh: 'Franca准时率',
    en: 'Franca punctuality rate',
    pu: 'Taxa de pontualidade Franca'
  },
  JauPunctualityRate: {
    zh: 'Jau准时率',
    en: 'Jau punctuality rate',
    pu: 'Taxa de pontualidade Jau'
  },
  MGPunctualityRate: {
    zh: 'MG准时率',
    en: 'MG punctuality rate',
    pu: 'Taxa de pontualidade MG'
  },
  RJPunctualityRate: {
    zh: 'RJ准时率',
    en: 'RJ punctuality rate',
    pu: 'Taxa de pontualidade RJ'
  },
  TotalCollectionPackages: {
    zh: '揽收包裹总数',
    en: 'Total collection packages',
    pu: 'Total de pacotes coletados'
  },
  TotalPackagesLoadedBranch: {
    zh: '网点装车包裹总数',
    en: 'Total packages loaded at the branch',
    pu: 'Total de pacotes carregados no ponto'
  },
  TotalUnloadedParcelsTransferCenter: {
    zh: '转运中心卸车包裹总数',
    en: 'Total unloaded parcels at the transfer center',
    pu: 'Total de pacotes descarregados no centro de transferência'
  },
  TotalPackagesLoadedTransferCenter: {
    zh: '转运中心装车包裹总数',
    en: 'Total packages loaded at the transfer center',
    pu: 'Total de pacotes carregados no centro de transferência'
  },
  TotalUnloadedParcelsDestinationWarehouse: {
    zh: '揽收目的仓卸车包裹总数',
    en: 'Total unloaded parcels at the destination warehouse',
    pu: 'Total de pacotes descarregados no armazém de destino de coleta'
  },
  TotalSignedParcelsDestinationWarehouse: {
    zh: '目的仓签收包裹总数',
    en: 'Total signed parcels at the destination warehouse',
    pu: 'Total de pacotes recebidos no armazém de destino'
  },
  TotalClosedPackages: {
    zh: '闭环包裹总数',
    en: 'Total closed packages',
    pu: 'Níumero total de pacotes fechados'
  },
  SecondarySummary: {
    zh: '二级汇总',
    en: 'Secondary summary',
    pu: 'Resumo de segundo nível'
  },
  SecondarySummaryDetail: {
    zh: '二级汇总明细',
    en: 'Secondary summary detail',
    pu: 'Detalhes do resumo de segundo nível'
  },
  TotalCollected: {
    zh: '已揽收总量',
    en: 'Total collected',
    pu: 'Total coletado'
  },
  TotalSealedBags: {
    zh: '已封袋总量',
    en: 'Total sealed bags',
    pu: 'Total selado'
  },
  TotalLoadedBranch: {
    zh: '网点装车总量',
    en: 'Total loaded at the branch',
    pu: 'Total carregado no ponto'
  },
  TotalUnloadedTransferCenter: {
    zh: '转运中心卸车总数',
    en: 'Total unloaded at the transfer center',
    pu: 'Total descarregado no centro de transferência'
  },
  TotalLoadedTransferCenter: {
    zh: '转运中心装车总数',
    en: 'Total loaded at the transfer center',
    pu: 'Total carregado no centro de transferência'
  },
  TotalSigned: {
    zh: '已签收总量',
    en: 'Total signed',
    pu: 'Total recebido'
  },
  BranchLoadingLicensePlate: {
    zh: '网点装车车牌',
    en: 'Branch loading license plate',
    pu: 'Placa do veículo carregado no ponto'
  },
  LoadingTransferCenter: {
    zh: '装车转运中心',
    en: 'Loading transfer center',
    pu: 'Centro de transferência de carregamento'
  },
  UnloadingTransferCenter: {
    zh: '卸车转运中心',
    en: 'Unloading transfer center',
    pu: 'Centro de transferência de descarregamento'
  },
  longitudeInputError: { // 经度
    zh: '格式：[-180 ,180] 最多小数点后7位',
    en: '',
    pu: 'Formato: [-180,180] com até 7 casas decimais'
  },
  latitudeInputError: { // 纬度
    zh: '格式：[-90,90] 最多小数点后7位',
    en: '',
    pu: 'Formato: [-90,90] com até 7 casas decimais'
  },
  SummarySuspectedLostPackageLinks: {
    zh: '疑似丢失包裹环节汇总',
    en: 'Summary of suspected lost package links',
    pu: 'Resumo de Etapas de Suspeita de Pacotes Perdidos'
  },
  SuspectedLostPackageDetails: {
    zh: '疑似丢失包裹明细',
    en: 'Suspected lost package details',
    pu: 'Detalhes de Pacotes Suspeitos de Estarem Perdidos'
  },
  SummaryExport: {
    zh: '汇总导出',
    en: 'Summary export',
    pu: 'Resumo da exportação'
  },
  AnjunTrackingNumber: {
    zh: '安骏单号',
    en: 'Anjun tracking number',
    pu: 'Número de localização Anjun'
  },
  yesterday: {
    zh: '昨日',
    en: '',
    pu: 'Ontem'
  },
  smallCollect: {
    zh: '小件揽收',
    en: '',
    pu: 'Coleta de Pequenos Pacotes'
  },
  smallDelivery: {
    zh: '小件派送',
    en: '',
    pu: 'Entrega de pacotes pequenos'
  },
  largeDelivery: {
    zh: '大件派送',
    en: '',
    pu: 'Entrega de cargas grandes'
  },
  smallCollectRange: {
    zh: '小件揽收范围',
    en: '',
    pu: 'Área de Coleta de Pequenos Pacotes'
  },
  smallDeliveryRange: {
    zh: '小件派送范围',
    en: '',
    pu: 'Área de Entrega de Pequenos Pacotes'
  },
  largeDeliveryRange: {
    zh: '大件派送范围',
    en: '',
    pu: 'Área de Entrega de Volumes Grandes'
  },
  collector: {
    zh: '揽收员',
    en: '',
    pu: 'Coletador'
  },
  collectingLoadingRecords: {
    zh: '揽收装车记录',
    en: '',
    pu: 'Registro de Carregamento de Coleta'
  },
  ji: { // 寄
    zh: '寄',
    en: '',
    pu: 'Enviar'
  },
  shou: {
    zh: '收',
    en: '',
    pu: 'Receber'
  },
  TimeOfArrivalAtGLP: {
    zh: '到达GLP时间',
    en: 'Time of arrival at GLP',
    pu: 'Hora de chega em GLP'
  },
  unloadedVehicleDetailsGLP: {
    zh: 'GLP未卸车车维度明细',
    en: 'GLP unloaded vehicle dimension details',
    pu: 'Detalhes de veículo não descarregado em GLP'
  },
  unloadedParcelDimensionGLP: {
    zh: 'GLP未卸车包裹维度明细',
    en: 'GLP unloaded parcel dimension details',
    pu: 'Detalhes de pacote não descarregado em GLP'
  },
  unloadedBagDimensionGLP: {
    zh: 'GLP未卸车袋维度明细',
    en: 'GLP unloaded bag dimension details',
    pu: 'Detalhes de saca não descarregada em GLP'
  },
  SealBagBatchTrack: {
    zh: '封袋批量轨迹',
    en: 'Seal Bag Batch Track',
    pu: 'Rastreio em Lote de Lacre'
  },
  PreparationOfParcelTrajectories: {
    zh: '备货包裹轨迹',
    en: 'Preparation of parcel trajectories',
    pu: 'Rastreio de Pacotes em Preparação'
  },
  BoxWrapTrack: {
    zh: '箱子包裹轨迹',
    en: 'Box Wrap Track',
    pu: 'Rastreio de Pacotes em Caixas'
  },
  kwaiParcelTrack: {
    zh: 'kwai包裹轨迹',
    en: 'kwai parcel track',
    pu: 'Rastreio de Pacotes de Kwai'
  },
  greaterThanAddresseeDistance: {
    zh: '与收件人距离   大于',
    en: '',
    pu: 'Distância até o Destinatário    Maior que'
  },
  lessThanAddresseeDistance: {
    zh: '与收件人距离   小于',
    en: '',
    pu: 'Distância até o Destinatário    Menor que'
  },
  SigningTimeoutTotal: {
    zh: '目的仓签收超时包裹总数',
    en: 'Total number of overdue parcels signed for at destination warehouse',
    pu: 'Número total de encomendas em atraso assinadas no armazém de destino'
  },
  SecondUnloadingTime: {
    zh: '第二次卸车时间',
    en: 'Second unloading time',
    pu: 'Segundo tempo de descarga'
  },
  TransportationArrivalHours: {
    zh: '运抵操作时长(小时)',
    en: 'Transportation arrival operation hours (hours)',
    pu: 'Duração da operação de chegada do transporte (horas)'
  },
  merchantCode: {
    zh: '商户号',
    en: '',
    pu: 'Número do comerciante'
  }
};
export {
  collectionCenter
};
