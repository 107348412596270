<template>
  <div class="container">
    <div class="searchBox">
      <search-box
        :key="searchKey" ref="searchBox" :pagination="queryForm"
        @searchFun="searchFun"
      />
    </div>
    <div class="dialog">
      <el-dropdown size="small" trigger="click">
        <el-button
          size="small" type="primary"
        >{{ $t('navbar.export') }}<i
          class="el-icon-arrow-down el-icon--right"
        /></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <el-button
              v-permit:remove="'btn:logisticsOrders:home-export'"
              size="small"
              style="margin-bottom: 2px"
              type="success"
              @click="allExportOrder"
            >{{ $t('collectionCenter.allExport') }}
            </el-button>
          </el-dropdown-item>
          <el-dropdown-item>
            <!--勾选导出-->
            <el-button
              v-permit:remove="'btn:logisticsOrders:home-export'"
              size="small"
              style="margin-bottom: 2px"
              type="success"
              @click="exportOrder"
            >{{ $t('collectionCenter.export1') }}
            </el-button>
          </el-dropdown-item>
          <el-dropdown-item>
            <!--批量单号导出-->
            <el-button
              v-permit:remove="'btn:logisticsOrders:home-export'"
              size="small"
              style="margin-bottom: 2px"
              type="success"
              @click="orderNumberExport"
            >{{ $t('placeOrder.BatchExport') }}
            </el-button>
          </el-dropdown-item>
          <el-dropdown-item>
            <!--导出子包信息-->
            <el-button
              v-permit:remove="'btn:logisticsOrders:home-export'"
              size="small"
              type="success"
              @click="exportOrderChilder"
            >{{ $t('collectionCenter.exportChilderInfor') }}
            </el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!--订单导入-->
      <el-button
        v-permit:remove="'btn:logisticsOrders:importOrderList'"
        size="small"
        style="margin-left: 10px"
        type="primary"
        @click="importOrders()"
      >{{ $t('orderCenterCont.ImportOrdersBulk') }}
      </el-button>
      <!--打印Iata-->
      <el-button
        v-permit:remove="'btn:logisticsOrders:printIATA'" size="small" @click="printIATA"
      >{{ $t('orderCenterCont.printIATA') }}
      </el-button>
      <!--订单分配新的服务商-->
      <el-button
        size="small" @click="orderDistruProvider"
      >{{ $t('navbar.orderDistruProvider') }}
      </el-button>
      <!--自定义显示列-->
      <el-button
        size="small" style="float: right" type="primary"
        @click="operationTableDialog"
      >{{ $t('orderCenterCont.customizingDisplayColumns') }}
      </el-button>
      <!--      更多操作-->
      <el-popover placement="top" trigger="hover">
        <div style="text-align: center">
          <el-button
            v-permit:remove="'btn:logisticsOrders:problemObjLanjie'"
            size="small"
            type="primary"
            @click="problemInterceptor"
          >
            {{ $t('customerManagements.problemObjLanjie') }}
          </el-button>
        </div>
        <el-button
          slot="reference" size="small" style="float: right"
          type="primary"
        >
          {{ $t('orderCenterCont.moreActions') }}
        </el-button>
      </el-popover>
    </div>
    <div style="margin-bottom: 10px">
      <!--重新分配网点-->
      <el-button
        v-permit:remove="'btn:logisticsOrders:distribution-dot'"
        size="small"
        type="primary"
        @click="dotClick"
      >
        {{ $t('orderCenterCont.RedistributionOfOutlets') }}
      </el-button>
      <!--下载快递标签  v-permit:remove="'btn:logisticsOrders:downloadTheMenu'"-->
      <el-button size="small" type="primary" @click="downloadTheMenu()">
        {{ $t('orderCenterCont.downloadExpressLabels') }}
      </el-button>
      <!--更新面单-->
      <el-button
        v-permit:remove="'btn:logisticsOrders:UpdateTheFaceSheet'"
        size="small"
        type="warning"
        @click="updateFaceSheet"
      >
        {{ $t('newOrder.UpdateNet') }}
      </el-button>
      <!--取消订单 -->
      <el-button
        v-permit:remove="'btn:logisticsOrders:cancelOrder'"
        size="small"
        type="warning"
        @click="cancelOrder()"
      >
        {{ $t('collectionCenter.cancelOrder') }}
      </el-button>
      <!--修改预派送网点-->
      <el-button
        v-permit:remove="'btn:logisticsOrders:PreDeliverySite'"
        size="small"
        @click="changeOutlets"
      >{{ $t('GlobalSetObj.ModifyPreDeliveryLocation') }}
      </el-button>
      <!--修改收件地址-->
      <el-button
        v-permit:remove="'btn:logisticsOrders:recipientAddress'"
        size="small"
        @click="recipientAddress"
      >
        {{ $t('GlobalSetObj.fixaddress') }}
      </el-button>
    </div>
    <!--    分配网点Dialog -->
    <DistributionDot
      :anjun-table="$refs['anjunTable']"
      :dot-list="dotList"
      :netdot="ShowDistributionDot"
      :selection-list="selectionList"
      @closeDot="closeDot"
      @getSearchFun="getSearchFun"
    />
    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :has-operation="true"
      :header-list="tableHeaderList"
      :hide="false"
      :is-index="true"
      :is-select-btn="true"
      :is-tip="true"
      :table-data="tableData"
      @selectTable="selectTable"
      @on-checkTrajectory="trajectoryQuery"
      @to-OrderDetail="toOrderDetail"
      @on-editWaybillRemark="editWaybillRemark"
    />
    <!--  分页  -->
    <!--    <Pagination :query-form="queryForm" @getSearchFun="getSearchFun" />-->
    <el-pagination
      :current-page.sync="queryForm.number"
      :page-size.sync="queryForm.pageSize"
      :page-sizes="[10, 50, 100, 200, 400]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 20px 0; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--轨迹-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.TrajectoryQuery')"
      :visible.sync="showQueryTrack.visible"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <trackQuery
          :key="showQueryTrack.trackKey"
          :query-track-param="queryTrackParam"
          query-type="1"
        />
      </div>
    </el-drawer>
    <!--运单详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.LogisticsOrders')"
      :visible.sync="waybillDescription.visible"
      custom-class="drawerClass"
      direction="ltr"
      size="90%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <waybill-information
          :key="showQueryTrack.trackKey"
          :query-track-param="TrackParam"
          query-type="1"
        />
      </div>
    </el-drawer>
    <!--  操作表头  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="isoperationTable"
      :width="`${$i18n.locale === 'zh' ? '30%' : '45%'}`"
    >
      <el-form ref="tableHeaderForm" :model="tableHeaderForm" label-width="auto">
        <el-form-item label="">
          <div style="font-size: 18px">{{ $t('GlobalSetObj.HideTheColumn') }}</div>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox-group v-model="tableHeaderForm.tableHeaderList">
            <el-row :gutter="0" style="display: flex; flex-flow: wrap">
              <el-col v-for="item in bindTableColumns" :key="item.id" :span="8">
                <div style="width: 140px; word-wrap: break-word; word-break: break-all">
                  <el-checkbox :label="item.label" />
                </div>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="float: left" @click="resetTable">{{
          $t('GlobalSetObj.reset')
        }}</el-button>
        <el-button @click="isoperationTable = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="operationTable">{{
          $t('GlobalSetObj.confirm')
        }}</el-button>
      </span>
    </el-dialog>
    <!--问题件-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('newOrder.registrationOfProblemPieces')"
      :visible.sync="showProblemPiece"
      width="500px"
      @close="problemPieceClose"
    >
      <template slot="title">
        <div style="font-weight: bold; font-size: 20px">
          {{ $t('newOrder.registrationOfProblemPieces') }}
        </div>
      </template>
      <div style="padding: 20px">
        <el-form
          :key="showProblemPiece"
          ref="problemPiece"
          :model="problemPieceForm"
          label-position="top"
          label-width="auto"
        >
          <!--问题原因-->
          <el-form-item
            :label="$t('newOrder.TheCauseOfTheProblem')"
            :rules="{
              required: true,
              message: $t('newOrder.PleaseSelectReasonProblem'),
              trigger: ['blur', 'change']
            }"
            prop="dictId"
          >
            <el-select
              v-model="problemPieceForm.dictId"
              :placeholder="$t('orderDetails.pleaseChoose')"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(item, ind) in problemReasonOption"
                :key="ind"
                :label="item.dictDescribe"
                :value="item.dictId"
              />
            </el-select>
          </el-form-item>
          <!--问题件备注-->
          <el-form-item :label="$t('newOrder.problemPieceRemarks')" prop="problemRemark">
            <el-input
              v-model="problemPieceForm.problemRemark"
              :placeholder="$t('newOrder.PleaseEnterDescriptionPortuguese')"
              :rows="4"
              maxlength="255"
              show-word-limit
              type="textarea"
            />
          </el-form-item>
          <!--运单号-->
          <el-form-item
            :label="$t('newOrder.waybillNumber')"
            :rules="{
              required: true,
              message: $t('newOrder.PleaseEnterWaybillNumber'),
              trigger: ['blur', 'change']
            }"
            prop="waybillNumbers"
          >
            <el-input
              v-model="problemPieceForm.waybillNumbers"
              :placeholder="$t('newOrder.EachLineHasOneSingleNumber')"
              :rows="8"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small" @click="showProblemPiece = false"
        >{{ $t('GlobalSetObj.Cancelar') }}
        </el-button>
        <el-button
          size="small" type="primary" @click="saveProblemPiece"
        >{{ $t('GlobalSetObj.Salve') }}
        </el-button>
      </div>
    </el-dialog>
    <!--问题件登记-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('newOrder.registrationOfProblemPieces')"
      :visible.sync="showProblemPieceTips"
      width="500px"
    >
      <template slot="title">
        <div style="font-weight: bold; font-size: 20px">
          {{ $t('newOrder.registrationOfProblemPieces') }}
        </div>
      </template>
      <div style="padding: 20px">
        <div>
          <div style="width: 100%; background: #dadada; border-radius: 20px; height: 10px">
            <div
              :style="{ width: widthProgress + '%' }"
              style="background: #42b983; height: 100%; border-radius: 20px"
            />
          </div>
          <div style="text-align: right; color: #13adf0; font-size: 13px; margin-top: 10px">
            {{
              widthProgress < 100 ? $t('newOrder.processing') : $t('newOrder.ProcessingComplete')
            }}
          </div>
        </div>
        <div v-if="widthProgress === 100">
          <div style="margin-bottom: 20px">
            <span>
              {{ $t('operationCenter.succeed') }}：<span
                style="color: #42b983; margin-right: 30px"
              >{{ problemPieceReturn.successNumber }}</span>
              {{ $t('operationCenter.fail') }}：<span style="color: red">{{
                problemPieceReturn.failureNumber
              }}</span>
            </span>
          </div>
          <div
            v-if="problemPieceReturn.list && problemPieceReturn.list.length > 0"
            style="border: 1px solid #ccc; padding: 10px; max-height: 170px; overflow-y: auto"
          >
            <div
              v-for="(item, ind) in problemPieceReturn.list"
              :key="ind"
              style="margin-bottom: 10px"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small" @click="showProblemPieceTips = false"
        >{{ $t('GlobalSetObj.close') }}
        </el-button>
      </div>
    </el-dialog>
    <!--导入订单-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('orderCenterCont.ImportOrdersBulk')"
      :visible.sync="showImportOrder"
      width="600px"
    >
      <div>
        <import-orders
          :key="showImportOrder"
          :customer-list="customerList"
          @importOrder="importOrder"
        />
      </div>
    </el-dialog>
    <!--更新面单-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('orderExport.hint')"
      :visible.sync="showFaceSheets"
      width="450px"
      @close="handleClose"
    >
      <div>
        <!--操作成功-->
        <p>{{ $t('GlobalSetObj.TheOperationSucceeded') }}!</p>
        <div style="display: flex; justify-content: space-between">
          <!--全部-->
          <div>{{ $t('GlobalSetObj.all') }}: {{ totalFaceSheets }}</div>
          <!--更新成功-->
          <div>{{ $t('newOrder.TheUpdateWasSuccessful') }}: {{ successful }}</div>
          <!--更新失败-->
          <div>{{ $t('newOrder.TheUpdateFailed') }}: {{ failOrder }}</div>
        </div>
        <div v-if="errList.length">
          <div style="margin: 30px 0 10px 0">{{ $t('newOrder.TheUpdateFailed') }}：</div>
          <div
            style="
              border: 1px solid #ccc;
              border-radius: 3px;
              padding: 10px;
              max-height: 260px;
              overflow: auto;
            "
          >
            <div v-for="(item, ind) in errList" :key="ind" style="margin: 5px 0">
              {{ item.waybillNumber }}：{{ item.failReason }}
            </div>
          </div>
        </div>
        <div style="margin-top: 40px; text-align: center">
          <!--打印面单-->
          <el-button
            size="small" type="primary" @click="printedSheet"
          >{{ $t('GlobalSetObj.PrintedSheet') }}
          </el-button>
          <!--查看详情-->
          <el-button
            size="small" type="primary" @click="checkedOrder()"
          >{{ $t('orderCenterCont.ViewDetails') }}
          </el-button>
          <!--关闭-->
          <el-button
            size="small" type="primary" @click="showFaceSheets = false"
          >{{ $t('orderCenterCont.ShutDown') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!--取消订单-->
    <clearOrder
      ref="clearOrder"
      :customer-list="customerList"
      :selection-list="selectionList"
      @getSearchFun="getSearchFun"
    />
    <!--批量导出-->
    <orderNumberExport ref="OrderExport" />
    <!--修改预派送网点-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.ModifyPreDeliveryLocation')"
      :visible.sync="showChangeOutlets"
      width="500px"
    >
      <modifyPreDeliveryLocation
        :key="showChangeOutlets"
        :selection-list="selectionList"
        :site-id-list="siteIdList"
        @changeOutletsDataClose="changeOutletsDataClose"
      />
    </el-dialog>
    <!-- 订单备注 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.remark')"
      :visible.sync="showOrderNote"
      width="500px"
    >
      <div class="remarkBox">
        <el-input
          v-model="orForm.waybillRemark"
          :placeholder="$t('collectionCenter.pleaseInput')"
          maxlength="200"
          show-word-limit
          type="textarea"
        />
        <el-button type="primary" @click="orSubmit">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </div>
    </el-dialog>
    <!--  修改收件地址  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('GlobalSetObj.fixaddress')"
      :visible.sync="fixAddress"
      top="10vh"
      width="900px"
      @close="clearForm"
    >
      <el-form
        ref="receviceRuleForm"
        :model="receviceRuleForm"
        class="demo-receviceRuleForm"
        inline
        label-position="top"
      >
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item :label="$t('newOrder.Street')" prop="receiveStreet" style="width: 100%">
              <el-input
                v-model="receviceRuleForm.receiveStreet"
                :placeholder="$t('newOrder.ConfirmStreetNumber')"
                clearable
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('placeOrder.HouseNumber')"
              :rules="checkStr5"
              prop="receiveHouseNumber"
              style="width: 100%"
            >
              <el-input
                v-model="receviceRuleForm.receiveHouseNumber"
                :placeholder="$t('placeOrder.pleaseInput')"
                clearable
                maxlength="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('placeOrder.Area')"
              :rules="addressComplement"
              prop="receiveArea"
              style="width: 100%"
            >
              <el-input
                v-model="receviceRuleForm.receiveArea"
                :placeholder="$t('placeOrder.pleaseInput')"
                clearable
                maxlength="32"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item
              :label="$t('placeOrder.addressComplement')"
              :rules="addressComplement"
              prop="receiveComplement"
              style="width: 100%"
            >
              <el-input
                v-model="receviceRuleForm.receiveComplement"
                :placeholder="$t('placeOrder.pleaseInput')"
                clearable
                maxlength="32"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('placeOrder.city')"
              :rules="cityComplement"
              prop="receiveCity"
              style="width: 100%"
            >
              <el-input
                v-model="receviceRuleForm.receiveCity"
                :placeholder="$t('placeOrder.pleaseInput')"
                clearable
                maxlength="32"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('placeOrder.State')"
              :rules="{
                required: true,
                message: $t('placeOrder.pleaseInput'),
                trigger: ['blur', 'change']
              }"
              prop="receiveState"
              style="width: 100%"
            >
              <el-select
                v-model="receviceRuleForm.receiveState"
                :placeholder="$t('placeOrder.pleaseInput')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in senderStateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('placeOrder.zipcode')"
              :rules="isZipCode"
              prop="receiveZipcode"
              style="width: 100%"
            >
              <el-input
                v-model.trim="receviceRuleForm.receiveZipcode"
                :placeholder="$t('placeOrder.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('placeOrder.detailedAddress')"
              :rules="isReceiveAddress"
              prop="receiveAddress"
              style="width: 100%"
            >
              <el-input
                v-model.trim="receviceRuleForm.receiveAddress"
                :placeholder="$t('placeOrder.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center">
        <el-button @click="fixAddress = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button
          type="primary" @click="comfireFixAddress"
        >{{ $t('GlobalSetObj.determine') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 提示 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.tips')"
      :visible.sync="tipDialog"
      width="800px"
    >
      <el-row :gutter="10" style="font-size: 16px !important">
        <el-col
          :span="8"
        >{{ $t('GlobalSetObj.fixTotal') }}:<span class="tipclass">{{
          tipDialogData.updateTotal
        }}</span></el-col>
        <el-col
          :span="8"
        >{{ $t('GlobalSetObj.ModificationSuccessful') }}:<span class="tipclass">{{
          tipDialogData.successTotal
        }}</span></el-col>
        <el-col
          :span="8"
        >{{ $t('GlobalSetObj.failTotal') }}:<span class="tipclass" style="color: red">{{
          tipDialogData.failTotal
        }}</span></el-col>
      </el-row>
      <div
        style="
          margin-top: 20px;
          font-size: 16px;
          line-height: 30px;
          height: 200px;
          overflow-y: auto;
        "
      >
        <div v-if="tipDialogData.failTotal">
          <div>{{ $t('GlobalSetObj.notFix') }}:</div>
          <div v-for="(item, index) in tipDialogData.errorList" :key="index">
            <span>{{ index + 1 }}.</span>
            <span>{{ item.waybillNumber }}</span>
            <span style="margin-left: 10px">{{ item.errorMessage }}</span>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin: 15px 0">
        <el-button
          size="small" type="primary" @click="tipDialog = false"
        >{{ $t('GlobalSetObj.determine') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 打印面单  -->
    <div style="display: none">
      <pdf ref="pdfPrint" :src="viewUrl" />
    </div>
    <!-- 打印预派送网点  -->
    <div style="display: none">
      <table id="printpreDeliverNameList" style="text-align: center; width: 100%">
        <tr v-for="(item, index) in preDeliverNameList" :key="index" style="text-align: center">
          <td style="text-align: center; font-size: 30px; line-height: 40px">{{ item }}</td>
        </tr>
      </table>
    </div>
    <!-- 提示 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('customerManagements.problemObjLanjie')"
      :visible.sync="tipDialog1"
      width="620px"
    >
      <el-row :gutter="10" style="font-size: 16px !important">
        <el-col
          :span="4"
        >{{ $t('GlobalSetObj.all') }}:<span class="tipclass">{{
          problemObj.totalCount
        }}</span></el-col>
        <el-col
          :span="10"
        >{{ $t('customerManagements.ReleaseSuccess') }}:<span class="tipclass">{{
          problemObj.successCount
        }}</span></el-col>
        <el-col
          :span="10"
        >{{ $t('customerManagements.ReleaseFail') }}:<span class="tipclass" style="color: red">{{
          problemObj.failCount
        }}</span></el-col>
      </el-row>
      <div
        style="
          margin-top: 20px;
          font-size: 16px;
          line-height: 30px;
          height: 200px;
          overflow-y: auto;
        "
      >
        <div v-if="problemObj.failCount">
          <div>{{ $t('GlobalSetObj.ReasonForFailure') }}:</div>
          <div v-for="(item, index) in problemObj.failReasonList" :key="index">
            <span>{{ index + 1 }}.</span>
            <span>{{ item.waybillNumber }}</span>
            <span style="margin-left: 10px">{{ item.reason }}</span>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin: 15px 0">
        <el-button
          size="small" type="primary" @click="tipDialog1 = false"
        >{{ $t('GlobalSetObj.determine') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import searchBox from '@/views/orderCenter/logisticsOrders/components/search/search';
import DistributionDot from '@/views/orderCenter/logisticsOrders/components/DistributionDot';
import trackQuery from '@/views/orderCenter/logisticsOrders/components/trackQuery/trackQuery';
import waybillInformation from '@/views/orderCenter/logisticsOrders/components/waybillInformation/waybillInformation';
import { setDateTime2 } from '@/utils/date_util';
import AnjunTableList from '@/components/anjun-tableList';
import clearOrder from './components/clearOrder';
import orderNumberExport from './components/orderNumberExport';
import modifyPreDeliveryLocation from '../orderPush/cmp/modifyPreDeliveryLocation';
import {
  apiGetProblemReason,
  apiSaveProblemReason,
  apiUpdateLabelCql,
  downloadExpressLabeling,
  getProviderSiteTree,
  liftInterceptType,
  OrderRemark,
  orderWaybillPage,
  providerapplychangeprovider,
  updateReceiveAddress,
  waybillExportAll,
  waybillExportAllSubpackage
} from '@/api/logisticsOrders/index.js';
import { batchPdfDownload } from '@/utils/PDF_util';
import importOrders from '@/views/orderCenter/logisticsOrders/components/importOrders';
import { CustomerPage } from '@/api/customer';
import { getqueryAll } from '@/api/automaticSorting';
import { checkAddressComplement, checkCityComplement, E_Mail } from '@/utils/validate';
import { getLgAreaListData } from '@/api/orderApi';
import pdf from 'vue-pdf-messy-code-fix';
import printXq from 'print-js';
import { cloneDeep } from 'lodash-es';
// import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { headerList, tableHeaderForm, waybillStatusObj } from '@/utils/enumData';
import { commonDesensitize } from '@/utils/desensitization';

export default {
  name: 'LogisticsOrder',
  components: {
    searchBox,
    DistributionDot,
    trackQuery,
    waybillInformation,
    AnjunTableList,
    importOrders,
    clearOrder,
    orderNumberExport,
    modifyPreDeliveryLocation,
    pdf
  },
  provide(data) {
    return {
      dotList: data
    };
  },
  data() {
    return {
      viewUrl: '',
      receviceRuleForm: {
        receiveZipcode: '', // 邮编
        receiveStreet: '', // 街道（精确到街道号）
        receiveHouseNumber: '', // 门牌号
        receiveState: '', // 州
        receiveCity: '', // 城市
        receiveArea: '', // 区
        receiveComplement: '',
        receiveAddress: '' // 详细地址
      },
      fixAddress: false,
      senderStateList: [],
      // 邮箱 E_Mail
      checkMail: [{ required: false }, { validator: E_Mail, trigger: ['blur', 'change'] }],
      // 城市
      cityComplement: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: checkCityComplement, trigger: ['blur', 'change'] }
      ],
      // 地址补充
      addressComplement: [
        { required: false },
        { validator: checkAddressComplement, trigger: ['blur', 'change'] }
      ],
      // 校验5个字符
      checkStr5: [
        {
          required: false,
          message: this.$t('placeOrder.pleaseInput'),
          trigger: ['blur', 'change']
        },
        { min: 1, max: 5, message: this.$t('placeOrder.input5'), trigger: ['blur', 'change'] }
      ],
      isZipCode: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] }
        // { validator: isZipCodeAsync, trigger: ['blur'] }
      ],
      isReceiveAddress: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] }
      ],
      orForm: {
        id: '',
        waybillRemark: ''
      },
      showOrderNote: false,
      TrackParam: {}, // 轨迹详情
      // 操作表头弹窗
      isoperationTable: false,
      tableHeaderForm: {
        tableHeaderList: tableHeaderForm
      },
      // 是否格式化成巴西时间
      isTormat: false,
      tableHeaderList: headerList,
      tableData: [],
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0
      },
      ShowDistributionDot: false,
      showQueryTrack: {
        visible: false,
        trackKey: new Date().getTime()
      },
      queryTrackParam: {},
      // 勾选表格数据
      selectionList: [],
      waybillDescription: {
        visible: false
      },
      // 查询分配网点
      queryDistributionDotList: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          code: '',
          name: '',
          siteStatus: '',
          term: '1'
        }
      },
      // 查询条件
      searchQuery: {},
      // 网点列表
      dotList: [],
      // 切换表头列
      bindTableColumns: headerList,
      downloadArr: [],
      showProblemPiece: false,
      problemPieceForm: {
        dictId: '', // 问题件类型
        problemReason: '', // 问题件原因
        problemRemark: '', // 问题件备注
        waybillNumbers: '' // 运单号
      },
      problemReasonOption: [],
      showProblemPieceTips: false,
      problemPieceReturn: {},
      widthProgress: 0,
      exportParam: {},
      showImportOrder: false,
      customerList: [],
      showFaceSheets: false,
      totalFaceSheets: 0,
      successful: 0,
      failOrder: 0,
      searchKey: new Date().getTime(),
      waybillNumbers: null,
      errList: [],
      showChangeOutlets: false,
      siteIdList: [],
      tipDialog: false, // 修改收件地址提示
      tipDialogData: {},
      SubpackageParam: {},
      labelList: [],
      preDeliverNameList: [],
      tipDialog1: false,
      problemObj: {},
      waybillStatusObj: waybillStatusObj
    };
  },
  watch: {
    'problemPieceForm.dictId'(val) {
      this.problemReasonOption.map((item) => {
        if (val === item.dictId) {
          this.problemPieceForm.problemReason = item.dictDescribe;
        }
      });
    }
  },
  created() {
    this.getState();
    // this.netList();
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getProblemReason();
    // 获取客户列表
    // this.getCustomerList();
    // 获取服务商网点
    // this.getRoleTree();
  },
  mounted() {
    this.getSearchFun();
  },
  activated() {
    console.log('ComponentB 被激活了');
    this.queryForm.number = 1;
    this.$nextTick(() => {
      // this.$refs.searchBox.resetForm();
      this.$refs.searchBox.searchFun();
    });
  },
  methods: {
    // 关闭修改网点弹窗
    changeOutletsDataClose() {
      this.showChangeOutlets = false;
      this.selectionList = [];
      this.getSearchFun();
      this.$refs.anjunTable.$refs.multipleTable.clearSelection();
    },
    // 打开修改预派送网点弹窗
    changeOutlets() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg'));
        return;
      }
      if (this.siteIdList?.length === 0) {
        this.getRoleTree();
      }
      this.showChangeOutlets = true;
    },
    recipientAddress() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg'));
        return;
      }
      this.fixAddress = true;
      console.log(this.selectionList, 'this.selectionList');
      if (this.selectionList.length === 1) {
        this.receviceRuleForm = {
          ...this.selectionList[0]
        };
        console.log(this.receviceRuleForm, 'this.receviceRuleForm');
      } else {
        this.receviceRuleForm = {
          receiveZipcode: '', // 邮编
          receiveStreet: '', // 街道（精确到街道号）
          receiveHouseNumber: '', // 门牌号
          receiveState: '', // 州
          receiveCity: '', // 城市
          receiveArea: '', // 区
          receiveComplement: '',
          receiveAddress: '' // 详细地址
        };
      }
    },
    comfireFixAddress() {
      const _this = this;
      this.$refs['receviceRuleForm'].validate((valid) => {
        if (valid) {
          console.log(this.receviceRuleForm, 'lskd');
          console.log(this.selectionList, 'this.selectionList');
          const data = {
            ...this.receviceRuleForm
          };
          const orderIds = [];
          const waybillNumberList = [];
          this.selectionList.map((item) => {
            orderIds.push(item.orderId);
            waybillNumberList.push(item.waybillNumber);
          });
          data.orderIds = orderIds;
          data.waybillNumbers = waybillNumberList;
          updateReceiveAddress(data).then((res) => {
            console.log(res, 'res');
            if (res.status === 'OK') {
              this.tipDialogData = res.data;
              this.fixAddress = false;
              // this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
              this.$nextTick(() => {
                this.tipDialog = true;
                _this.$refs.searchBox.searchFun();
              });
              this.$refs['anjunTable'].toggleSelection();
            }
          });
        }
      });
    },
    // 获取服务商网点树
    getRoleTree() {
      const param = {
        providerChannelName: ''
      };
      getProviderSiteTree(param).then((res) => {
        if (res.status === 'OK') {
          const listData = (data) => {
            data.map((item) => {
              if (item.siteList && item.siteList.length > 0) {
                item.children = item.siteList;
                listData(item.siteList);
              }
            });
            return data;
          };
          this.siteIdList = listData(res.data);
        }
      });
    },
    // 取消订单选择客户
    async cancelOrder() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('receivablePayable.SelectAtLeastOnePieceOfData'));
        return;
      }
      if (this.customerList?.length === 0) {
        await this.getCustomerList();
      }
      this.$refs.clearOrder.cancelOrder();
    },
    // 更新面单
    updateFaceSheet() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg'));
        return;
      }
      const orderList = this.selectionList.map((item) => {
        return item.waybillNumber;
      });
      apiUpdateLabelCql(orderList).then((res) => {
        if (res.status === 'OK') {
          this.successful = res.data.successRequest;
          this.failOrder = res.data.failRequest;
          this.totalFaceSheets = res.data.countRequest;
          this.errList = res.data.failList;
          this.labelList = res.data.labelList;
          this.showFaceSheets = true;
        }
      });
    },
    // 查看详情
    checkedOrder() {
      const waybillNumber = this.selectionList.map((item) => {
        return item.waybillNumber;
      });
      this.showFaceSheets = false;
      this.searchKey = new Date().getTime();
      this.waybillNumbers = waybillNumber;
      this.$nextTick(() => {
        this.$refs.searchBox.searchFun(2);
      });
    },
    // 导入订单完成
    importOrder(val) {
      this.showImportOrder = false;
      if (val) {
        this.getSearchFun('a');
      }
    },
    // 获取客户选项
    getCustomerList() {
      const param = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {}
      };
      CustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          this.customerList = res.data.records;
        }
      });
    },
    // 打开导入订单弹窗
    async importOrders() {
      if (this.customerList?.length === 0) {
        await this.getCustomerList();
      }
      this.showImportOrder = true;
    },
    // 全部导出
    allExportOrder() {
      const param = {
        ...this.exportParam.params,
        // providerChannelCodeList,
        operator: this.$store.state.user.realName,
        operatorId: this.$store.state.user.id,
        type: 'CSV',
        sourceType: 2
      };
      // let diff = 0;
      // if (param.createStartTime && param.createEndTime) {
      //   diff = dayjs(param.createEndTime).diff(dayjs(param.createStartTime), 'day');
      // } else {
      //   this.$message.warning(this.$t('newOrder.timeIsNotNull'));
      //   return;
      // }
      // if (diff > 31) {
      //   this.$message.error('时间范围不能超过31天');
      //   return;
      // }
      if (param.numList.length === 0) {
        if (!param.numList01 && !param.numList02) {
          this.$message.warning(this.$t('newOrder.timeIsNotNull'));
          return;
        }
      }
      // delete param.providerChannelIdList;
      waybillExportAll(param).then((res) => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                if (res.data.taskId) {
                  this.$router.push({ path: '/resource/exportTaskList' });
                } else {
                  this.$router.push({ path: '/resource/exportList' });
                }
              }
            })
            .catch(() => {});
        }
      });
    },
    // 批量导出单号
    orderNumberExport() {
      this.$refs.OrderExport.handleOpen();
    },
    // 问题件登记弹窗关闭
    problemPieceClose() {
      this.problemPieceForm = {
        dictId: '', // 问题件类型
        problemReason: '', // 问题件原因
        problemRemark: '', // 问题件备注
        waybillNumbers: '' // 运单号
      };
    },
    // 保存登记
    saveProblemPiece() {
      const searchArr = [];
      if (this.problemPieceForm.waybillNumbers) {
        const dataArr = this.problemPieceForm.waybillNumbers.split(/[(\r\n)\r\n]+/);
        for (let i = 0; i < dataArr.length; i++) {
          if (dataArr[i]) {
            if (searchArr.length < 100) {
              searchArr.push(dataArr[i].trim());
            }
          }
        }
      }
      this.$refs.problemPiece.validate((valid) => {
        if (valid) {
          const param = {
            ...this.problemPieceForm,
            waybillNumbers: searchArr
          };
          apiSaveProblemReason(param).then((res) => {
            if (res.status === 'OK') {
              this.problemPieceReturn = res.data.data;
              this.showProblemPiece = false;
              this.showProblemPieceTips = true;
              this.widthProgress = 0;
              this.getSearchFun();
              const time = setInterval(() => {
                this.widthProgress++;
                if (this.widthProgress < 100) {
                  this.widthProgress++;
                  if (this.widthProgress === 100) {
                    clearInterval(time);
                  }
                }
              }, 50);
            }
          });
        }
      });
    },
    // 获取问题见原因
    getProblemReason() {
      apiGetProblemReason().then((res) => {
        if (res.status === 'OK') {
          this.problemReasonOption = res.data.data;
        }
      });
    },
    // 打开问题件登记弹窗
    problemPiece() {
      this.showProblemPiece = true;
    },
    // 下载面单
    downloadTheMenu() {
      const downloadArr = this.downloadArr;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$i18n.t('orderCenterCont.PleaseSelectTheWaybill')); // 请选择运单
        return;
      }
      if (this.selectionList.length > 200) {
        this.$message.warning(
          this.$i18n.t('orderCenterCont.TheNumberOfSelectedPolygonsExceedsTheMaximumLimit200')
        ); // 选中面单数超出最大限制：200
        return;
      }
      // 手动显示加载中动画
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      batchPdfDownload(downloadArr)
        .then((res) => {
          const dateType = setDateTime2();
          const saveName = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
          saveAs(res, saveName);
          // const a = document.createElement('a');
          // a.download = `${dateType}`;
          // a.href = res;
          // a.download = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
          // a.click();
          // a.remove();
          this.getLog();
        })
        .finally(() => {
          loading.close();
        });
    },
    // 走后端日志
    async getLog() {
      const waybillNumberList = this.selectionList.map((item) => {
        return item.waybillNumber;
      });
      const data = { numberLists: waybillNumberList };
      await downloadExpressLabeling(data);
    },
    // 打印面单
    printedSheet() {
      if (this.labelList && this.labelList.length) {
        // 手动显示加载中动画
        const loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
          background: 'rgba(250, 250, 250, 0.6)'
        });
        batchPdfDownload(this.labelList)
          .then((res) => {
            this.viewUrl = res;
            setTimeout(() => {
              this.$refs.pdfPrint.print();
            }, 1000);
          })
          .finally(() => {
            loading.close();
          });
      } else {
        this.$message.warning(this.$t('GlobalSetObj.noPrintedData'));
      }
    },
    handleClose() {
      this.$refs['anjunTable'].$refs.multipleTable.clearSelection();
    },
    // 操作表头弹框
    operationTableDialog() {
      this.isoperationTable = true;
    },
    // 操作表头的显示与隐藏
    operationTable() {
      const tableTh = [];
      this.bindTableColumns.forEach((item) => {
        if (this.tableHeaderForm.tableHeaderList.includes(item.label)) {
          tableTh.push(item);
        }
      });
      this.tableHeaderList = tableTh;
      this.isoperationTable = false;
    },
    // 重置
    resetTable() {
      this.tableHeaderForm.tableHeaderList = tableHeaderForm;
      this.tableHeaderList = headerList;
      this.isoperationTable = false;
      window.location.reload();
    },
    // 网点列表，这里的网点列表在订单详情里面使用，该功能废除，这里无效
    netList() {
      getqueryAll().then((res) => {
        if (res.status === 'OK') {
          this.dotList = res.data;
          // sessionStorage.setItem('netList', JSON.stringify(res.data));
        }
      });
    },
    // 点击分配网点
    dotClick() {
      if (this.dotList?.length === 0) {
        this.netList();
      }
      if (this.selectionList.length !== 0) {
        this.ShowDistributionDot = true;
      } else {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
      }
    },
    closeDot(item) {
      this.ShowDistributionDot = item;
    },
    // 列表数据勾选
    selectTable(data) {
      this.selectionList = data;
      const newArray = cloneDeep(data);
      this.downloadArr = newArray.map((item) => {
        if (item.labelUrl === '' || item.labelUrl === null) {
          item.labelUrl = item.serviceLableUrl;
        }
        return item.labelUrl;
      });
    },
    // 搜索
    getSearchFun(type) {
      const data = type || 0;
      this.$refs.searchBox.searchFun(data);
    },
    searchFun(queryForm, numList, type) {
      console.log(queryForm, 'queryFormqueryForm', numList);
      if (numList && numList.length > 2000) {
        numList = numList.slice(0, 2000); // 截取前2000个单号
        console.log('numList', numList);
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        // return;
      }
      if (type === 1 || type === 2 || type === 'a') {
        queryForm.number = 1;
        this.queryForm.number = queryForm.number;
        this.selectionList = [];
        this.downloadArr = [];
        this.$refs['anjunTable'].$refs.multipleTable.clearSelection();
      }
      let statusList = [];
      if (queryForm.params.waybillStatus.includes(3)) {
        statusList = cloneDeep(queryForm.params.waybillStatus);
        if (!statusList.includes(19)) {
          statusList.push(19);
        }
      } else {
        statusList = queryForm.params.waybillStatus;
      }
      const param = {
        pageSize: queryForm.pageSize,
        pageNumber: queryForm.number,
        params: {
          customerIds: queryForm.params.customerIds,
          waybillStatus: statusList,
          preCollectStationId: queryForm.params.preCollectStationId,
          preDeliverStationId: queryForm.params.preDeliverStationId,
          orderPackageType: queryForm.params.orderPackageType
            ? queryForm.params.orderPackageType
            : null,
          receiveStateList: queryForm.params.receiveStateList,
          deliveryManName: queryForm.params.deliveryManName,
          deliverStationId: queryForm.params.deliverStationId,
          accountType: queryForm.params.accountType,
          merchantCode: queryForm.params.merchantCode,
          merchantCodeList: queryForm.params.merchantCodeList,
          cargoType: queryForm.params.cargoType,
          receiveName: queryForm.params.receiveName,
          receiveZipcode: queryForm.params.receiveZipcode
        }
      };
      if (queryForm.params.searchTimeType === '2') {
        param.params.deliveredStartTime = queryForm.params.createStartTime;
        param.params.deliveredEndTime = queryForm.params.createEndTime;
      } else if (queryForm.params.searchTimeType === '3') {
        param.params.receiptStartTime = queryForm.params.createStartTime;
        param.params.receiptEndTime = queryForm.params.createEndTime;
      } else if (queryForm.params.searchTimeType === '4') {
        param.params.sendStartTime = queryForm.params.createStartTime;
        param.params.sendEndTime = queryForm.params.createEndTime;
      } else if (queryForm.params.searchTimeType === '5') {
        param.params.customerSendStartTime = queryForm.params.createStartTime;
        param.params.customerSendEndTime = queryForm.params.createEndTime;
      } else if (queryForm.params.searchTimeType === '1') {
        param.params.createStartTime = queryForm.params.createStartTime;
        param.params.createEndTime = queryForm.params.createEndTime;
      } else if (queryForm.params.searchTimeType === '6') {
        param.params.dispatchReceiptTimeStartTime = queryForm.params.createStartTime;
        param.params.dispatchReceiptTimeEndTime = queryForm.params.createEndTime;
      } else if (queryForm.params.searchTimeType === '7') {
        param.params.deliveryStartTime = queryForm.params.createStartTime;
        param.params.deliveryEndTime = queryForm.params.createEndTime;
      } else {
        param.params.lastDeliveryTimeStartTime = queryForm.params.createStartTime;
        param.params.lastDeliveryTimeEndTime = queryForm.params.createEndTime;
      }
      if (queryForm.params.searchNumberType === '2') {
        // param.params.customerNumber = waybillNumber;
        param.params.customerNumbers = numList;
      } else if (queryForm.params.searchNumberType === '1') {
        // param.params.waybillNumber = waybillNumber;
        param.params.waybillNumbers = numList;
      } else if (queryForm.params.searchNumberType === '3') {
        param.params.bagNumbers = numList;
      } else if (queryForm.params.searchNumberType === '5') {
        param.params.logisticsNumbers = numList;
      } else if (queryForm.params.searchNumberType === '6') {
        param.params.invoiceNumbers = numList;
      } else if (queryForm.params.searchNumberType === '7') {
        param.params.subPackageNumbers = numList;
      } else if (queryForm.params.searchNumberType === '8') {
        param.params.scanOrderNumbers = numList;
      } else if (queryForm.params.searchNumberType === '9') {
        param.params.invoiceSecretList = numList;
      } else {
        param.params.transitBagNumbers = numList;
      }
      param.params.numList = numList;
      param.params.numList01 = queryForm.params.createStartTime;
      param.params.numList02 = queryForm.params.createEndTime;
      if (queryForm.params.deliverWarehouseStationType === '2') {
        param.params.deliverWarehouseStationId = param.params.deliverStationId || null;
        param.params.deliverStationId = null;
      }
      console.log(param.params, 'param.params.param');
      this.exportParam = param;
      this.SubpackageParam = param;
      // let diff = 0;
      // if (queryForm.params.createStartTime && queryForm.params.createEndTime) {
      //   diff = dayjs(queryForm.params.createEndTime).diff(dayjs(queryForm.params.createStartTime), 'day');
      // } else {
      //   this.$message.warning(this.$t('newOrder.timeIsNotNull'));
      //   return;
      // }
      // if (diff > 31) {
      //   this.$message.error('时间范围不能超过31天');
      //   return;
      // }
      if (numList.length === 0) {
        if (!queryForm.params.createStartTime && !queryForm.params.createEndTime) {
          this.$message.warning(this.$t('newOrder.timeIsNotNull'));
          return;
        }
      }
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: 'loading...',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      orderWaybillPage(param)
        .then((res) => {
          if (res.status === 'OK') {
            this.$refs['anjunTable'].toggleSelection();
            this.tableData = res.data.records;
            this.queryForm.total = res.data.total;
            this.exportParam = param;
            this.SubpackageParam = param;
            this.tableData.map((item) => {
              item['checkTrajectory'] = true;
              // if (this.waybillStatusObj[item.waybillStatus].label) {
              //   try {
              //     item['waybillStatusName'] = this.waybillStatusObj[item.waybillStatus].label;
              //   } catch (e) {
              //     item['waybillStatusName'] = '--';
              //   }
              // }
              if (item.deliveryDistance) {
                item['deliveryDistance'] = item.deliveryDistance + '(km)';
              }
              if (item.cargoType === 0) {
                item['cargoTypeName'] = this.$t('placeOrder.normal01');
              } else if (item.cargoType === 1) {
                item['cargoTypeName'] = this.$t('placeOrder.big01');
              }
              // 根据权限控制收发件人信息的脱敏处理
              const hasPermission = this.$store.getters.button_permissions.includes(
                'field:customer-sensitive-info'
              );
              if (!hasPermission) {
                if (item.receiveName) {
                  item.receiveName = commonDesensitize(item.receiveName);
                }
              }
            });
            loading.close();
          }
        })
        .catch(() => {
          loading.close();
        });
    },
    // 订单备注
    editWaybillRemark(row) {
      this.showOrderNote = true;
      this.orForm = {
        id: row.id,
        waybillRemark: row.waybillRemark || ''
      };
    },
    orSubmit() {
      OrderRemark(this.orForm).then((res) => {
        if (res.status === 'OK') {
          this.showOrderNote = false;
          this.$refs.searchBox.searchFun();
        }
      });
    },
    // 查看轨迹
    trajectoryQuery(row) {
      this.queryTrackParam = row;
      this.showQueryTrack.visible = true;
      this.showQueryTrack.trackKey = new Date().getTime();
    },
    // 进入运单详情
    toOrderDetail(row) {
      this.TrackParam = row;
      this.showQueryTrack.trackKey = new Date().getTime();
      console.log(row, ':query-track-param="queryTrackParam"');
      this.waybillDescription.visible = true;
    },
    // 关闭弹窗后冲重置表单
    clearForm() {
      Object.assign(this.receviceRuleForm, {
        receiveZipcode: '', // 邮编
        receiveStreet: '', // 街道（精确到街道号）
        receiveHouseNumber: '', // 门牌号
        receiveState: '', // 州
        receiveCity: '', // 城市
        receiveArea: '', // 区
        receiveComplement: ''
      });
      this.$refs['receviceRuleForm'].resetFields();
    },
    // 导出子包信息
    exportOrderChilder() {
      waybillExportAllSubpackage(this.SubpackageParam).then((res) => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.ExportSuccessful1'));
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                if (res.data.taskId) {
                  this.$router.push({ path: '/resource/exportTaskList' });
                } else {
                  this.$router.push({ path: '/resource/exportList' });
                }
              }
            })
            .catch(() => {});
        }
      });
    },
    /**
     * 物流订单导出
     */
    async exportOrder() {
      // 后端导出接口
      if (this.selectionList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const waybillNumberList = this.selectionList.map((item) => {
        return item.waybillNumber;
      });
      const param = {
        waybillNumbers: waybillNumberList,
        operator: this.$store.state.user.realName,
        operatorId: this.$store.state.user.id,
        type: 'CSV',
        sourceType: 1
      };
      waybillExportAll(param).then((res) => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$refs['anjunTable'].toggleSelection();
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                if (res.data.taskId) {
                  this.$router.push({ path: '/resource/exportTaskList' });
                } else {
                  this.$router.push({ path: '/resource/exportList' });
                }
              }
            })
            .catch(() => {});
        }
      });

      // 前端导出代码
    },
    getState() {
      // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.senderStateList = res.data.map((item) => {
            return {
              id: item.id,
              label: `${item.nameEn}${item.nameCn ? '(' + item.nameCn + ')' : ''}${
                item.twoCode ? '(' + item.twoCode + ')' : ''
              }`
            };
          });
        }
      });
    },
    // 分页
    handleSizeChange(val) {
      this.queryForm.number = 1;
      this.$nextTick(() => {
        this.$refs.searchBox.searchFun();
      });
    },
    handleCurrentChange(val) {
      this.$nextTick(() => {
        this.$refs.searchBox.searchFun();
      });
    },
    problemInterceptor() {
      if (this.selectionList.length <= 0) {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
        return;
      }
      const arr = this.selectionList.map((item) => {
        return item.waybillNumber;
      });
      const data = {
        waybillNumbers: arr,
        type: 2
      };
      liftInterceptType(data).then((res) => {
        if (res.status === 'OK') {
          console.log(res, 'liftInterceptType');
          this.problemObj = res.data;
          this.tipDialog1 = true;
          this.$refs['anjunTable'].toggleSelection();
        }
      });
      console.log(arr, '12313');
    },
    printIATA() {
      if (this.selectionList.length <= 0) {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
        return;
      }
      this.preDeliverNameList = this.selectionList.map((item) => {
        return item.preDeliverStationName;
      });
      this.$nextTick(() => {
        printXq({
          printable: 'printpreDeliverNameList', // // 文档来源：pdf或图像的url，html元素的id或json数据的对象
          type: 'html', // 可打印的类型。可用的打印选项包括：pdf，html，图像，json和raw-html。
          targetStyles: ['*'], // 库在打印HTML元素时处理任何样式
          font_size: ''
        });
        this.$refs['anjunTable'].toggleSelection();
      });
      console.log(this.preDeliverNameList, 'preDeliverNameList');
    },
    // 跳转到服务商订单
    orderDistruProvider() {
      if (this.selectionList.length !== 0) {
        console.log(this.selectionList, 'this.selectionList');
        const h = this.$createElement;
        this.$msgbox({
          title: this.$i18n.t('operationCenter.tips'),
          message: h('p', null, [
            h('span', this.$i18n.t('navbar.tip08')) // 确定要对选中单号分盘新的服务商单吗
          ]),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('GlobalSetObj.determine'),
          cancelButtonText: this.$i18n.t('GlobalSetObj.cancel')
        })
          .then((action) => {
            if (action === 'confirm') {
              const data = {
                waybillIds: []
              };
              this.selectionList.forEach((item) => {
                data.waybillIds.push(item.id);
              });
              providerapplychangeprovider(data).then((res) => {
                if (res.status === 'OK') {
                  this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                  this.$router.push({
                    path: '/orderCenter/serviceOroviderOrder'
                  });
                }
              });
              console.log('确定');
            }
          })
          .catch(() => {
            console.log('取消');
          });
      } else {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
      }
    }
  }
};
</script>

<style scoped>
.remarkBox ::v-deep textarea.el-textarea__inner {
  height: 200px !important;
}

.remarkBox {
  text-align: right;
}

.remarkBox ::v-deep .el-button {
  margin-top: 10px;
}

.container >>> .hiddenTitle .el-dialog__header {
  display: none;
}

.container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

/*>>> .el-dialog__body {*/
/*  padding: 10px 15px;*/
/*}*/

.container {
  padding: 10px;
}

.dialog {
  margin-bottom: 10px;
}

.buttonList {
  margin-bottom: 15px;
}

.containerExport {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 10px;
}

.closeStyle {
  font-size: 26px;
  float: right;
  line-height: 20px;
  padding: 2px 5px;
  cursor: pointer;
}

.hoverColor .el-button:hover {
  background-color: #409eff;
}

.container >>> .el-dialog__body {
  padding: 10px 15px;
}

.tipclass {
  margin-left: 10px;
  font-size: 16px;
}
</style>
